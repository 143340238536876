//Stop error resizeObserver

export const preventResizeObserverError = () => {
  const debounce = (callback, delay) => {
    let tid
    return function(...args) {
      // eslint-disable-next-line no-restricted-globals
      const ctx = self
      tid && clearTimeout(tid)
      tid = setTimeout(() => {
        callback.apply(ctx, args)
      }, delay)
    }
  }

  const _ = window.ResizeObserver
  window.ResizeObserver = class ResizeObserver extends _ {
    constructor(callback) {
      callback = debounce(callback, 20)
      super(callback)
    }
  }
};
