import React, {Fragment, useContext, useEffect, useRef, useState,} from 'react';
import { Link } from "react-router-dom";

import { apiProvider } from '../../../../../api/core';
import { SvgIco } from '../../../../SharedComponents/Icons';
import GenerateProspectusModal from '../../../Modals/GenerateProspectusModal';
import VisualsProposalsForm from '../../../Modals/VisualsProposalsForm';
import { Context } from '../../index';
import VisualsBlock from './VisualsBlock';
import FixedNavigationBar from './FixedNavigationBar';

import './index.sass';

let interval;


const Visuals = () => {

  const [showNavigationMenu, setShowNavigationMenu] = useState();
  const [proposalFormIsOpen, setProposalFormIsOpen] = useState(false);
  const [prospectusError, setProspectusError] = useState(false);
  const [prospectusIsLoading, setProspectusIsLoading] = useState(false);
  const [prospectusModalIsOpen, setProspectusModalIsOpen] = useState(false);
  const [proposalFormTrigger, setProposalFormTrigger] = useState(null);
  const [activeSection, setActiveSection] = useState(null);
  const [generatedProspectusUrl, setGeneratedProspectusUrl] = useState(null);


  const {
    currentLocale,
    currentContactId,
    locationId,
    patternedProspectusEnabled,
    qualityScore,
    refetchVisualValues,
    setVisualsData,
    siteKey,
    visualsData,
  } = useContext(Context);


  const refsList = {
    navigationLinksList: useRef(),
    photosRef: useRef(),
    floorPlansRef: useRef(),
    interiorDesignRef: useRef(),
    virtualTourRef: useRef(),
    vimeoRef: useRef(),
    prospectusRef: useRef(),
  };

  const {
    floorPlansRef,
    photosRef,
    prospectusRef,
    virtualTourRef,
    vimeoRef,
  } = refsList;

  const { floorPlans, photos, prospectus, address } = visualsData;
  const { prospectusPath } = prospectus || {};
  const showBanner = siteKey === 'dk';


  useEffect(() => {
    handleShowNavigationMenu();
    window.addEventListener('scroll', handleShowNavigationMenu);

    return () => window.removeEventListener('scroll', handleShowNavigationMenu);
  }, []);


  const activateSection = () =>
    setTimeout(handleActiveAreaSection, 10);


  const onScrollEnd = (targetCoords) =>
    setTimeout(() => endOfScroll(targetCoords), 20);


  const handleShowNavigationMenu = () => {
    const topOffset = 104;
    const showNavigation =
      photosRef.current.getBoundingClientRect().top - 100 <= topOffset;

    if (showNavigation !== showNavigationMenu) {
      showNavigation && handleActiveAreaSection();
      setShowNavigationMenu(showNavigation);
    } else if (showNavigation) {
      activateSection();
    }
  };


  const handleActiveAreaSection = () => {
    const topOffset = document.getElementById('provider-header').offsetHeight + 48;
    const sectionsList = document.querySelectorAll('.visuals__block-wrapper');
    const viewPortBottomBorder = (window.innerHeight - topOffset) / 2;

    if (photosRef.current.getBoundingClientRect().top <= topOffset) {
      Object.keys(sectionsList).forEach((elem) => {
        const node = sectionsList[elem];
        const nodeName =
          node && node.firstChild && node.firstChild.dataset.name;

        if (nodeName && activeSection !== nodeName) {
          const nodeTop = node.getBoundingClientRect().top;
          const nodeBottom = node.getBoundingClientRect().bottom;

          // If node is partially in area viewPort
          if (nodeTop < viewPortBottomBorder && nodeBottom > topOffset) {
            // If node is 100% in area viewPort
            if (
              (nodeTop < topOffset && nodeBottom > viewPortBottomBorder) ||
              (nodeTop > topOffset && nodeBottom < viewPortBottomBorder)
            ) {
              setActiveSection(nodeName);
            } else {
              const viewPortNodeTop = nodeTop < topOffset ? topOffset : nodeTop;
              const viewPortNodeBottom =
                nodeBottom > viewPortBottomBorder
                  ? viewPortBottomBorder
                  : nodeBottom;

              // If node takes at least 50% of area viewPort
              if (
                viewPortNodeBottom - viewPortNodeTop >=
                (viewPortBottomBorder - topOffset) / 2
              ) {
                setActiveSection(nodeName);
              }
            }
          }
        }
      });
    } else if (activeSection !== 'photosRef') {
      setActiveSection('photosRef');
    }
  };


  const scrollToTarget = (target) => {
    const targetTopCoords = refsList[target].current.getBoundingClientRect().top;
    const headerHeight = document.getElementById('provider-header').offsetHeight;
    const windowHeight = window.pageYOffset;
    const targetCoords = Math.floor(targetTopCoords + windowHeight - (headerHeight + 80));

    window.removeEventListener('scroll', handleShowNavigationMenu);
    window.scroll(0, targetCoords);
    window.addEventListener('scroll', onScrollEnd(targetCoords));
  };


  const endOfScroll = (targetCoords) => {
    if (window.pageYOffset === targetCoords) {
      window.addEventListener('scroll', handleShowNavigationMenu);
      window.removeEventListener('scroll', onScrollEnd(targetCoords));
    }
  };


  const openProposalForm = (triggerSection) => {
    setProposalFormTrigger(triggerSection);
    setProposalFormIsOpen(true);
  };


  const closeProposalForm = () => {
    setProposalFormTrigger(null);
    setProposalFormIsOpen(false);
  };


  const BuyNowFormButton = ({ triggerSection, buttonText }) =>
    <div className="input-banner__buy-button input-banner__order-service-button"
         onClick={() => openProposalForm(triggerSection)}>
      { buttonText }
    </div>;


  const GenerateProspectusButton = () =>
    <div className="input-banner__buy-button"
         onClick={() => generatedProspectusUrl ? setProspectusModalIsOpen(true) : generateProspectus()}>
      { I18n.t('order_confirmations.show.generate-prospectus') }
    </div>;


  const generateProspectus = () => {
    const url = `/api/frontend/locations/${locationId}/patterned_prospectus`;

    setProspectusIsLoading(true);
    setProspectusModalIsOpen(true);

    apiProvider
      .post(url, { location_id: locationId })
      .then((response) => {
        const { pdf_url, status_url } = response;

        setGeneratedProspectusUrl(pdf_url);

        interval = setInterval(
          () =>
            apiProvider.getAll(status_url).then((response) => {
              if (response.status === 'finished') {
                clearInterval(interval);
                setProspectusIsLoading(false);
              }
            }),
          2000,
        );
      })
      .catch((error) => {
        setProspectusIsLoading(false);
        setProspectusError(true);
        clearInterval(interval);
        console.log('error', error);
      });
  };


  const handleCloseProspectusModal = () => {
    setProspectusModalIsOpen(false);
    prospectusError && setProspectusError(false);
  };


  return (
    <div className="visuals">
      <VisualsProposalsForm
        handleClose={closeProposalForm}
        currentContactId={currentContactId}
        locationAddress={address}
        locationId={locationId}
        showModal={proposalFormIsOpen}
        trigger={proposalFormTrigger}
      />

      { showNavigationMenu &&
        <FixedNavigationBar
          activeSection={activeSection}
          scrollToTarget={scrollToTarget}
          showNavigationMenu={showNavigationMenu}
        />
      }

      <div className="visuals__block-wrapper">
        <div
          className="sectionScrollTarget"
          data-name="photosRef"
          id="photo"
          ref={photosRef}
        />
        <VisualsBlock
          bannerContent={I18n.t('providers.locations.form.photos_banner_content')}
          bannerHeadline={I18n.t('providers.locations.form.photos_banner_headline')}
          buyNowLink={<BuyNowFormButton triggerSection="photo" buttonText="Køb fotopakke" />}
          defaultFileList={photos}
          fileTypes="image/bmp, image/gif, image/jpeg, image/jpg, image/png, image/tif, image/tiff, image/webp"
          inputHeadline={I18n.t('providers.photos.form.add_pictures')}
          inputLabel={I18n.t('providers.locations.form.add_photos_label')}
          inputType="file"
          name="photos"
          qualityScore={qualityScore}
          qualityScoreName="photos"
          readMoreLink={'/serviceydelser-til-udlejere/fotos'}
          setVisualsData={setVisualsData}
          showBanner={showBanner}
          tooltip={I18n.t('providers.locations.form.add_photos_tooltip')}
        />
      </div>

      <div className="visuals__block-wrapper">
        <div
          className="sectionScrollTarget"
          data-name="floorPlansRef"
          id="floor-plans"
          ref={floorPlansRef}
        />
        <VisualsBlock
          bannerContent={I18n.t('providers.locations.form.floor_plan_banner_content')}
          bannerHeadline={I18n.t('providers.locations.form.floor_plan_banner_headline')}
          buyNowLink={<BuyNowFormButton triggerSection="floorPlan" buttonText="Køb plantegning" />}
          defaultFileList={floorPlans}
          fileTypes="image/bmp, image/gif, image/jpeg, image/jpg, image/png, image/tif, image/tiff, image/webp, application/pdf, application/x-pdf"
          inputHeadline={I18n.t('providers.locations.edit.assets.add_floor_plans')}
          inputLabel={I18n.t('providers.locations.form.add_floor_plans_label')}
          inputType="file"
          name="floor_plans"
          qualityScore={qualityScore}
          qualityScoreName="floorPlan"
          readMoreLink={'/serviceydelser-til-udlejere/fotos'}
          refetchValues={() => refetchVisualValues({ id: locationId })}
          setVisualsData={setVisualsData}
          showBanner={showBanner}
          tooltip={I18n.t('providers.locations.form.add_floor_plan_tooltip')}
        />
      </div>

      {/*<div className="visuals__block-wrapper">*/}
      {/*  <div className="sectionScrollTarget" ref={interiorDesignRef} data-name="interiorDesignRef"/>*/}
      {/*  <VisualsBlock*/}
      {/*    name="interiorDesign"*/}
      {/*    inputHeadline={I18n.t('providers.locations.form.add_interior_design')}*/}
      {/*    inputLabel={I18n.t('providers.locations.form.add_floor_interior_design_label')}*/}
      {/*    tooltip={I18n.t('providers.locations.form.add_interior_design_tooltip')}*/}
      {/*    inputType="file"*/}
      {/*    fileTypes="image/bmp, image/gif, image/jpeg, image/jpg, image/png, image/tif, image/tiff, application/pdf, application/x-pdf"*/}
      {/*    showBanner={showBanner}*/}
      {/*    bannerHeadline={I18n.t('providers.locations.form.interior_design_banner_headline')}*/}
      {/*    bannerContent={I18n.t('providers.locations.form.interior_design_banner_content')}*/}
      {/*    readMoreLink={'/serviceydelser-til-udlejere/fotos'}*/}
      {/*    buyNowLink={<InteriorDesignForm />} />*/}
      {/*</div>*/}

      <div className="visuals__block-wrapper">
        <div
          className="sectionScrollTarget"
          data-name="virtualTourRef"
          id="virtual-tour"
          ref={virtualTourRef}
        />
        <VisualsBlock
          bannerContent={I18n.t('providers.locations.form.virtual_tour_banner_content')}
          bannerHeadline={I18n.t('providers.locations.form.virtual_tour_banner_headline')}
          buyNowLink={<BuyNowFormButton triggerSection="virtualTour" buttonText="Køb virtuel tur" />}
          inputHeadline={I18n.t('providers.locations.form.add_virtual_tour')}
          inputLabel={I18n.t('providers.locations.form.virtual_tour_label')}
          name="virtualTour"
          qualityScore={qualityScore}
          qualityScoreName="virtualTour"
          readMoreLink={'/serviceydelser-til-udlejere/fotos'}
          showBanner={false}
          tooltip={I18n.t('providers.locations.form.add_virtual_tour_tooltip')}
        />
      </div>

      <div className="visuals__block-wrapper">
        <div
          className="sectionScrollTarget"
          data-name="vimeoRef"
          id="vimeo-id"
          ref={vimeoRef}
        />
        <VisualsBlock
          bannerContent={I18n.t('providers.locations.form.video_banner_content')}
          bannerHeadline={I18n.t('providers.locations.form.video_banner_headline')}
          buyNowLink={<BuyNowFormButton triggerSection="video" buttonText="Køb video" />}
          inputHeadline={I18n.t('providers.locations.form.add_video')}
          inputLabel={I18n.t('providers.locations.form.add_video_label')}
          inputType="number"
          name="vimeoId"
          qualityScore={qualityScore}
          qualityScoreName="video"
          readMoreLink={'/serviceydelser-til-udlejere/fotos'}
          showBanner={false}
          tooltip={I18n.t('providers.locations.form.add_video_tooltip')}
        />
      </div>

      <div className="visuals__block-wrapper">
        <div
          className="sectionScrollTarget"
          data-name="prospectusRef"
          id="prospectuses"
          ref={prospectusRef}
        />
        <VisualsBlock
          bannerClass="green"
          bannerContent={I18n.t('providers.locations.form.prospectus_banner_content')}
          bannerHeadline={I18n.t('providers.locations.form.prospectus_banner_headline')}
          buyNowLink={<GenerateProspectusButton />}
          fileTypes="application/pdf, application/x-pdf"
          inputHeadline={I18n.t('providers.prospectuses.show.add_prospect')}
          inputType="file"
          isPdf={true}
          linkFile={{ url: prospectusPath, name: address }}
          name="prospectus"
          qualityScore={qualityScore}
          qualityScoreName="prospectus"
          refetchValues={() => refetchVisualValues({ id: locationId })}
          setVisualsData={setVisualsData}
          showBanner={patternedProspectusEnabled && !prospectusPath}
          tooltip={I18n.t('providers.prospectuses.show.add_prospectus_tooltip')}
          inputLabel={
            <Fragment>
              <p>{ I18n.t('providers.prospectuses.show.add_prospectus_label') }</p>
              { prospectusPath && <p>{ I18n.t('providers.prospectuses.show.notice') }</p>}
            </Fragment>
          }
        />

        <GenerateProspectusModal
          error={prospectusError}
          generatedProspectusUrl={generatedProspectusUrl}
          handleClose={handleCloseProspectusModal}
          loading={prospectusIsLoading}
          showModal={prospectusModalIsOpen}
        />
      </div>

      <div className="visuals__save-button-wrapper">
        <Link to={`${currentLocale}/new_providers/locations/edit/${locationId}/description`} className="visuals__back-button">
          <SvgIco name="arrow-back" size={24} />
          <span>{ I18n.t('generic.back') }</span>
        </Link>
        <Link to={`${currentLocale}/new_providers/locations/info/${locationId}`} className="visuals__save-button">
          { I18n.t('generic.finish') }
        </Link>
      </div>

    </div>
  );
};

export default Visuals;
