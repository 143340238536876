import React, { useState, useEffect, useRef } from 'react';

import {
  KeyboardArrowRightRounded,
  KeyboardArrowLeftRounded,
} from '../../../../SharedComponents/Icons';

import './FixedNavigationBar.sass';

const FixedNavigationBar = (props) => {
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [activeSection, setActiveSection] = useState(
    props.activeSection || null,
  );
  const [isScrollable, setIsScrollable] = useState(false);
  const [initialization, setInitialization] = useState(true);

  const refsList = {
    navigationLinksList: useRef(),
    photosRef: useRef(),
    floorPlansRef: useRef(),
    interiorDesignRef: useRef(),
    virtualTourRef: useRef(),
    vimeoRef: useRef(),
    prospectusRef: useRef(),
  };

  const {
    photosRef,
    floorPlansRef,
    interiorDesignRef,
    virtualTourRef,
    vimeoRef,
    prospectusRef,
  } = refsList;
  const navigationLinksList = refsList.navigationLinksList.current;

  useEffect(() => {
    setActiveSection(props.activeSection || null);
    props.activeSection && handleActiveSectionChange(props.activeSection);
    window.addEventListener('resize', handleHorizontalScroll);
    return () => {
      window.removeEventListener('resize', handleHorizontalScroll);
    };
  }, []);

  useEffect(() => {
    handleActiveSectionChange(props.activeSection);
    return () => null;
  }, [props.activeSection]);

  useEffect(() => {
    if (navigationLinksList) {
      handleArrowAppearance();
      handleHorizontalScroll();
    }

    return () => null;
  }, [navigationLinksList]);

  const handleHorizontalScroll = () => {
    if (navigationLinksList) {
      const navigationListFirstChild =
        navigationLinksList && navigationLinksList.firstChild;

      const isScrollable =
        navigationListFirstChild &&
        (Math.round(navigationListFirstChild.getBoundingClientRect().left) <
          navigationLinksList.getBoundingClientRect().left ||
          Math.round(
            navigationLinksList.lastChild.getBoundingClientRect().right,
          ) > navigationLinksList.getBoundingClientRect().right);

      handleArrowAppearance();
      navigationLinksList.addEventListener('scroll', handleArrowAppearance);
      setIsScrollable(isScrollable);
    }
  };

  const handleActiveSectionChange = (targetName) => {
    const targetSection = refsList[targetName] && refsList[targetName].current;

    if (targetSection) {
      if (targetName !== activeSection || initialization) {
        if (isScrollable) {
          const targetRightCoodrs = targetSection.getBoundingClientRect().right,
            targetLeftCoords = targetSection.getBoundingClientRect().left,
            navigationListFirstChild = navigationLinksList.firstChild,
            navigationListLastChild = navigationLinksList.lastChild,
            navigationListRightCoords =
              targetSection === navigationListLastChild
                ? navigationLinksList.getBoundingClientRect().right
                : navigationLinksList.getBoundingClientRect().right - 24,
            navigationListLeftCoords =
              targetSection === navigationListFirstChild
                ? navigationLinksList.getBoundingClientRect().left
                : navigationLinksList.getBoundingClientRect().left + 24;

          targetRightCoodrs > navigationListRightCoords
            ? scrollToRightTarget(targetLeftCoords)
            : targetLeftCoords < navigationListLeftCoords
            ? scrollToLeftTarget(targetLeftCoords)
            : null;
        }
        activeSection &&
          refsList[activeSection].current.classList.remove('active');
        targetSection.classList.add('active');
        setActiveSection(targetName);
        initialization && setInitialization(false);
      }
    } else {
      // At first render React needs time to initialize ref and we get undefined
      setTimeout(() => handleActiveSectionChange(targetName), 10);
    }
  };

  const handleArrowAppearance = () => {
    if (navigationLinksList) {
      const leftBoundary =
        navigationLinksList.firstChild.getBoundingClientRect().left;
      const rightBoundary =
        navigationLinksList.lastChild.getBoundingClientRect().right;
      const navigationListLeftBorder =
        navigationLinksList.getBoundingClientRect().left;
      const navigationListRightBorder =
        navigationLinksList.getBoundingClientRect().right;

      setShowLeftArrow(Math.round(leftBoundary) < navigationListLeftBorder);
      setShowRightArrow(Math.round(rightBoundary) > navigationListRightBorder);
    }
  };

  const scrollToTarget = (target) => {
    if (activeSection !== target) {
      handleActiveSectionChange(target);
      props.scrollToTarget(target);
    }
  };

  const handleLeftScroll = () =>
    (navigationLinksList.scrollLeft =
      navigationLinksList.scrollLeft - navigationLinksList.offsetWidth);

  const handleRightScroll = () =>
    (navigationLinksList.scrollLeft =
      navigationLinksList.offsetWidth + navigationLinksList.scrollLeft);

  const scrollToRightTarget = (targetLeftCoords) => {
    navigationLinksList.scrollLeft =
      targetLeftCoords - 44 + navigationLinksList.scrollLeft;
  };

  const scrollToLeftTarget = (targetLeftCoords) => {
    const targetLeftScroll =
      targetLeftCoords >= 0 ? targetLeftCoords : targetLeftCoords * -1;
    navigationLinksList.scrollLeft =
      navigationLinksList.scrollLeft - (targetLeftScroll + 44);
  };

  const { showNavigationMenu } = props;
  const classes = `fixed-nav${showNavigationMenu ? ' active' : ''}`;

  return (
    <div className={classes}>
      <div className="fixed-nav__container">
        <ul
          className="fixed-nav__links-wrapper"
          ref={refsList.navigationLinksList}
        >
          <li className="fixed-nav__link" ref={photosRef}>
            <span onClick={() => scrollToTarget('photosRef')}>
              {I18n.t('providers.photos.form.add_pictures')}
            </span>
          </li>

          <li className="fixed-nav__link" ref={floorPlansRef}>
            <span onClick={() => scrollToTarget('floorPlansRef')}>
              {I18n.t('providers.locations.edit.assets.add_floor_plans')}
            </span>
          </li>

          {/*<li className="fixed-nav__link" ref={interiorDesignRef}>*/}
          {/*  <span onClick={() => scrollToTarget('interiorDesignRef')}>{I18n.t('providers.locations.form.add_interior_design')}</span>*/}
          {/*</li>*/}

          <li className="fixed-nav__link" ref={virtualTourRef}>
            <span onClick={() => scrollToTarget('virtualTourRef')}>
              {I18n.t('providers.locations.form.add_virtual_tour')}
            </span>
          </li>

          <li className="fixed-nav__link" ref={vimeoRef}>
            <span onClick={() => scrollToTarget('vimeoRef')}>
              {I18n.t('providers.locations.form.add_video')}
            </span>
          </li>

          <li className="fixed-nav__link" ref={prospectusRef}>
            <span onClick={() => scrollToTarget('prospectusRef')}>
              {I18n.t('providers.prospectuses.show.add_prospect')}
            </span>
          </li>
        </ul>
        {showLeftArrow && (
          <div className="fixed-nav__arrow-left" onClick={handleLeftScroll}>
            <KeyboardArrowLeftRounded />
          </div>
        )}
        {showRightArrow && (
          <div className="fixed-nav__arrow-right" onClick={handleRightScroll}>
            <KeyboardArrowRightRounded />
          </div>
        )}
      </div>
    </div>
  );
};

export default FixedNavigationBar;
