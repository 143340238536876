import React from 'react';

import './index.sass';


const RadioButtons = ({ options,
                        name,
                        handleChange,
                        wrapperClass,
                        activeClass,
                      }) => {
  return (
    <div className={`radio-buttons ${wrapperClass || ''}`}>
      {options.map((option) => (
        <div
          className={`radio-buttons__button-wrapper${ option.checked ? ' checked' : '' }`}
          key={`${name}${option.value}`}
        >
          <label
            className={`radio-buttons__label${ option.checked && activeClass ? ` ${activeClass}` : '' }`}
            htmlFor={`${name}-${option.value}`}
          >
            <input
              type="radio"
              value={option.value}
              name={name}
              id={`${name}-${option.value}`}
              checked={option.checked}
              onChange={() => handleChange(option.value)}
            />
            { option.label }
          </label>

          <div className="radio-buttons__icon">
            <span />
          </div>
        </div>
      ))}
    </div>
  );
};

export default RadioButtons;
