import React, { useEffect, useState, Fragment } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { GET_CONTACT_INFO, GET_PROVIDER, UPDATE_PROVIDER } from '../ApolloClient/queries';
import PhotoUpload from './PhotoUpload';
import FormMessageBlock from '../../SharedComponents/FormMessages/FormMessageBlock';
import UpdateProvider from './UpdateProvider';
import { SvgIco } from '../../SharedComponents/Icons';
import './ProviderSettings.sass';


function ProviderSettings(props) {

  const [providerInfo, setProviderInfo] = useState({});
  const [emailsLocale, setEmailsLocale] = useState('');
  const [mainContact, setMainContact] = useState(true);
  const [interfaceLocale, setInterfaceLocale] = useState('');
  const [showNotification, setSnowNotification] = useState({
    show: false,
    message: '',
    color: 'green',
  });

  const {
    contractName,
    countryAlpha2,
    currentContactId,
    emailLocales,
    formAuthToken,
    locales,
    providerId,
    removeProviderLogoPath,
    showSmsOrderNotifications,
    siteKey,
    updateProviderInfo,
    uploadProviderLogoPath,
  } = props;

  const checkDk = siteKey === 'dk';

  const { data, refetch } = useQuery(GET_PROVIDER, { variables: { id: providerId } });
  const { data: contact } = useQuery(GET_CONTACT_INFO, { variables: { id: currentContactId } });

  const [updateProvider] = useMutation(UPDATE_PROVIDER);

  const formatEmailLocales = Object.keys(emailLocales).map((key) => ({
    value: key,
    label: emailLocales[key],
  }));

  const formatLocales = Object.keys(locales).map((key) => ({
    value: key,
    label: locales[key],
  }));

  const showChangeLanguageBlock = formatLocales.length > 1 || formatEmailLocales.length > 1;
  const showEmailChangeLanguage = formatEmailLocales.length > 1;
  const showInterfaceChangeLanguage = formatLocales.length > 1;


  useEffect(() => {
    if (contact) {
      setMainContact(!contact.contact.isDestroyable);
    }
    if (data) {
      setProviderInfo(data.provider);
      setEmailsLocale(data.provider.emailsLocale);
      setInterfaceLocale(data.provider.interfaceLocale);
      updateProviderInfo(data.provider);
    }
  }, [data, contact]);


  const providerSubmit = (values) => {
    const {
      companyName,
      vatId,
      phoneNumber,
      address,
      postalCode,
      city,
      isMailSubjectIncludeInternalIdentifier,
      smsOrderNotification,
      ean,
      invoicingEmail,
    } = values;

    const input = {
      id: parseInt(providerId),
      companyName,
      vatId,
      phoneNumber,
      line1: address,
      postalCode,
      city,
      isMailSubjectIncludeInternalIdentifier,
      smsOrderNotification,
      ean: ean || null,
      emailsLocale: emailsLocale || '',
      interfaceLocale: interfaceLocale || '',
      invoicingEmail: invoicingEmail || ''
    };

    updateProvider({
      variables: { input },
      refetchQueries: ['getProvider'],
      onCompleted: (data) => {
        const { errors } = data.updateProvider;
        if (
          providerInfo.emailsLocale !== emailsLocale ||
          providerInfo.interfaceLocale !== interfaceLocale
        ) {
          const currentLocale = interfaceLocale && interfaceLocale === 'fr-BE' ? `/${interfaceLocale}` : '';
          window.location.assign(`${currentLocale}/new_providers/settings/provider`);
        }
        if (errors && errors.length) {
          console.log(errors);
        } else {
          setSnowNotification({
            show: true,
            message: I18n.t('providers.contacts.edit.provider_information_updated'),
            color: 'green',
          });
          window.scrollTo(0, 0);
        }
      },
    });
  };


  const handleChangeEmailLanguage = (value) =>
    setEmailsLocale(value);


  const changeInterFaceLanguage = (value) =>
    setInterfaceLocale(value);


  const handleCloseNotification = () =>
    setSnowNotification({ show: false, message: '' });


  return (
    <div className="provider-settings">

      <FormMessageBlock
        show={showNotification}
        closeNotification={handleCloseNotification}
      />

      <h3>{ I18n.t('providers.providers.form.company_information') }</h3>

      { !mainContact &&
        <div>
          <p className="second-contact-message" dangerouslySetInnerHTML={{__html: I18n.t('providers.contacts.edit.second_contact_cant_edit')}} />

          <div className="second-contact-logo-wrap">
            {providerInfo.logoUrl ? (
              <img
                className="photo-upload__provider-logo"
                src={providerInfo.logoUrl}
                alt="provider-logo"
              />
              ) : (
                <div className="photo-upload__photo-place">
                  <span>{ I18n.t('providers.contacts.edit.no_logo') }</span>
                </div>
              )}

          </div>

          <p>{ I18n.t('generic.company_name') }: <b>{providerInfo.companyName }</b></p>
          <p>{ I18n.t('signup.new_form.register.vat_id.placeholder') }: <b>{ providerInfo.vatId }</b></p>
          { providerInfo.ean &&
            <p>{ I18n.t('activerecord.attributes.provider.ean') }: <b>{ providerInfo.ean }</b></p>
          }
          <p>{ I18n.t('signup.new_form.register.city.placeholder') }: <b>{ providerInfo.city }</b></p>
          <p>{ I18n.t('signup.new_form.register.line_1.placeholder') }: <b>{ providerInfo.line1 }</b></p>

          <p>{ I18n.t('signup.new_form.register.phone_number.placeholder') }: <b>{providerInfo.phoneNumber }</b></p>
          <p>{ I18n.t('signup.new_form.register.invoice_email.placeholder') }: <b>{ providerInfo.invoicingEmail }</b></p>

          { providerInfo.postalCode &&
            <p>{ I18n.t('signup.new_form.register.postal_code.placeholder') }: <b>{providerInfo.postalCode }</b></p>
          }

          <p>{ I18n.t('providers.providers.provider_info.payment_model') }: <b>{contractName}</b></p>

          <div className="provider-settings__checkbox-wrapper">
            <label
              className="checkbox-label"
              htmlFor="isMailSubjectIncludeInternalIdentifier"
            >
              <input
                name="isMailSubjectIncludeInternalIdentifier"
                type="checkbox"
                checked={providerInfo.isMailSubjectIncludeInternalIdentifier}
                id="isMailSubjectIncludeInternalIdentifier"
                disabled
              />
              <span className="checkmark">
              <SvgIco name="check" size={17} />
            </span>
              <div className="checkbox-label">
                { I18n.t('activerecord.attributes.provider.is_mail_subject_include_internal_identifier') }
              </div>
            </label>
          </div>

          { showSmsOrderNotifications && (
            <div className="provider-settings__checkbox-wrapper">
              <label className="checkbox-label" htmlFor="smsOrderNotification">
                <input
                  name="smsOrderNotification"
                  type="checkbox"
                  checked={showSmsOrderNotifications}
                  id="smsOrderNotification"
                  disabled
                />
                <span className="checkmark">
                <SvgIco name="check" size={17} />
              </span>
                <div className="checkbox-label">
                  { I18n.t('activerecord.attributes.provider.sms_order_notification') }
                </div>
              </label>
            </div>
          )}
        </div>
      }

      { mainContact &&
        <Fragment>
          <PhotoUpload
            formAuthToken={formAuthToken}
            handleRefetch={refetch}
            imageUrl={providerInfo.logoUrl}
            provider
            removePath={removeProviderLogoPath}
            removeTranslation={I18n.t('providers.contacts.edit.remove_logo')}
            uploadPath={uploadProviderLogoPath}
          />

          <UpdateProvider
            changeEmailLanguage={handleChangeEmailLanguage}
            changeInterFaceLanguage={changeInterFaceLanguage}
            checkDk={checkDk}
            contractName={contractName}
            countryAlpha2={countryAlpha2}
            emailsLocale={emailsLocale}
            formatEmailLocales={formatEmailLocales}
            formatLocales={formatLocales}
            interfaceLocale={interfaceLocale}
            providerSubmit={providerSubmit}
            providerInfo={providerInfo}
            showChangeLanguageBlock={showChangeLanguageBlock}
            showEmailChangeLanguage={showEmailChangeLanguage}
            showInterfaceChangeLanguage={showInterfaceChangeLanguage}
            showSmsOrderNotifications={showSmsOrderNotifications}
          />
        </Fragment>
      }

    </div>
  );
};


ProviderSettings.propTypes = {
  contractName: PropTypes.string,
  showSmsOrderNotifications: PropTypes.bool,
  locales: PropTypes.oneOfType([PropTypes.object]),
  emailLocales: PropTypes.oneOfType([PropTypes.object]),
  removeProviderLogoPath: PropTypes.string,
  formAuthToken: PropTypes.string,
  uploadProviderLogoPath: PropTypes.string,
  providerId: PropTypes.string,
};

ProviderSettings.defaultProps = {
  contractName: '',
  showSmsOrderNotifications: false,
  locales: {},
  emailLocales: {},
  removeProviderLogoPath: '',
  formAuthToken: '',
  uploadProviderLogoPath: '',
  providerId: '',
};


export default ProviderSettings;
