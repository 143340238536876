import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Table from '../Table';
import NavigationButtons from '../LocationForm/NavigationButtons';
import { SvgIco } from "../../SharedComponents/Icons";

import './ReaportDealTable.sass';


function ReaportDealTable({
  step,
  title,
  description,
  data,
  loading,
  columns,
  nextStep,
  prevStep,
  backButton,
  changeSearchInput,
  locations,
  handleInput,
  inputValue,
  handleFilters,
  hiddenColumns,
  totalCount,
  filtersData,
  query,
}) {

  const [noneOrder, setNoneOrder] = useState(false);
  const [noneLocation, setNoneLocation] = useState(false);

  useEffect(() => {
    inputValue && checkNoneRadio();
  }, [inputValue]);

  const { page, perPage } = filtersData;

  const perPageOptions = [
    { value: '5', label: '5' },
    { value: '10', label: '10' },
  ];

  const setQueryValue = (searchValue) =>
    locations
      ? changeSearchInput(searchValue, true)
      : changeSearchInput(searchValue);

  const firstStep = step === 1;

  const checkNoneRadio = () => {
    firstStep ? setNoneOrder('none-order') : setNoneLocation('non-location');
  };

  const setFilters = (newFilters) =>
    handleFilters(prevFilters => ({
      ...prevFilters,
      ...newFilters,
    }));


  return (
    <div className="table-block">
      <h3 className="table-block__title">
        {step}.{title}
        <span> ({description})</span>
      </h3>

      <div className="search-input-wrapper">
        <input
          type="text"
          className="search-input"
          value={query}
          placeholder={I18n.t('generic.search')}
          onChange={(e) => setQueryValue(e.target.value)}
          maxLength="256"
        />
        <SvgIco className="search-icon" name="search" size={16} />
      </div>

      <Table
        data={data}
        columns={columns}
        perPage={perPage}
        perPageOptions={perPageOptions}
        loading={loading}
        currentPage={page}
        hiddenColumns={hiddenColumns}
        disableSortBy={true}
        setPage={(page) => setFilters({ page })}
        setPerPage={(perPage) => setFilters({ perPage, page: 1 })}
        totalCount={totalCount}
      />

      <div className="table-block__company-input-wrapper">
        <div className="check-button">
          <input
            type="radio"
            name={firstStep ? 'company-name' : 'address'}
            id="none-above"
            value={firstStep ? noneOrder : noneLocation}
            checked={
              firstStep
                ? noneOrder === 'none-order'
                : noneLocation === 'non-location'
            }
            className="checkoption-input"
          />
          <label
            htmlFor="none-above"
            className="select-option"
            onClick={() => checkNoneRadio()}
          >
            <span className="option-checkmark" />
            { firstStep
              ? I18n.t('providers.leases.new.step_1_none_label')
              : I18n.t('providers.leases.new.step_2_none_label')
            }
          </label>
        </div>

        <div>
          <input
            type="text"
            className="table-block__company-name-input"
            value={inputValue}
            placeholder={
              firstStep
                ? I18n.t('providers.leases.new.type_company_name')
                : I18n.t('providers.leases.new.type_address_name')
            }
            onChange={(e) => handleInput(e.target.value)}
            maxLength="256"
          />
        </div>
      </div>

      <div>
        <NavigationButtons
          handleNext={() => nextStep()}
          handleBack={backButton ? () => prevStep() : false}
        />
      </div>
    </div>
  );
}

ReaportDealTable.propTypes = {
  step: PropTypes.number,
  nextStep: PropTypes.func,
  prevStep: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.array]),
  handleInput: PropTypes.func,
  locationQuery: PropTypes.string,
  orderQuery: PropTypes.string,
  setPage: PropTypes.func,
  inputValue: PropTypes.string,
  locations: PropTypes.bool,
  changeSearchInput: PropTypes.func,
  backButton: PropTypes.bool,
  page: PropTypes.number,
};

ReaportDealTable.defaultProps = {
  step: 1,
  nextStep: () => true,
  prevStep: () => true,
  title: '',
  description: '',
  data: [],
  handleInput: () => true,
  locationQuery: '',
  orderQuery: '',
  setPage: () => true,
  inputValue: '',
  locations: false,
  changeSearchInput: () => true,
  backButton: false,
  page: 1,
};

export default ReaportDealTable;
