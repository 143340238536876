import React, { Fragment, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Description from './Steps/Description';
import Location from './Steps/Location';
import FactsAndFacilities from './Steps/FactsAndFacilities';
import Economy from './Steps/Economy';
import Contacts from './Steps/Contacts';
import Visuals from './Steps/Visuals';

const StepsWrapper = ({ setActiveSection, stepsData, setLastAvailableStep, showFacts, activeSection: currentActiveSection, stepsRefs, setShowNotFoundPage  }) => {

  const { section: activeSection } = useParams();

  useEffect(() => {
    setActiveSection(activeSection);

    const getAllSteps = Object.keys(stepsData);
    const checkExistStep = getAllSteps.includes(activeSection);

    if (checkExistStep) {
      !stepsData[activeSection].passed && setLastAvailableStep(activeSection);
    } else {
      setShowNotFoundPage(true)
    }
  }, [activeSection]);

  const renderStep = () => {
    switch (true) {
      case activeSection === 'description':
        return <Description ref={stepsRefs.description} />;
      case activeSection === 'location':
        return <Location />
      case activeSection === 'facts' && showFacts:
        return <FactsAndFacilities />
      case activeSection === 'economy':
        return <Economy ref={stepsRefs.economy} />
      case activeSection === 'contacts':
        return <Contacts />;
      case activeSection === 'visuals':
        return <Visuals />
      default:
        return <Location />;
    }
  };


  return (
    <Fragment>
      { currentActiveSection && renderStep() }
    </Fragment>
  )
}

export default StepsWrapper;
