import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import Yup from 'yup';

import { passwordValidation, passwordConfirmationValidation } from '../../../SharedComponents/Forms/ValidationSchemaPattern';
import Input from '../../../SharedComponents/Forms/Input';

import './index.sass';

function ChangePasswordFormWrapper({
  handleSubmit,
  values,
  handleChange,
  touched,
  handleBlur,
  passwordForm,
  errors,
}) {
  return (
    <div
      className={`change-password__form-wrapper ${
        passwordForm ? 'form-active' : ''
      }`}
    >
      <form
        name="changePassword"
        className="change-password__form"
        onSubmit={handleSubmit}
        noValidate
      >
        <Input
          name="oldPassword"
          type="password"
          value={values.oldPassword}
          handleChange={handleChange}
          handleBlur={handleBlur}
          label={I18n.t('generic.current_password')}
          error={touched.oldPassword && errors.oldPassword}
          required
        />

        <Input
          name="password"
          type="password"
          value={values.password}
          handleChange={handleChange}
          handleBlur={handleBlur}
          label={I18n.t('generic.new_password')}
          error={touched.password && errors.password}
          required
        />
        <Input
          name="passwordConfirmation"
          type="password"
          value={values.passwordConfirmation}
          handleChange={handleChange}
          handleBlur={handleBlur}
          label={I18n.t('activerecord.attributes.contact.password_confirmation')}
          error={touched.passwordConfirmation && errors.passwordConfirmation}
          required
        />

        <button type="submit" className="change-password__save-button">
          { I18n.t('generic.save') }
        </button>
      </form>
    </div>
  );
}

const ChangePasswordForm = withFormik({
  validationSchema: Yup.object().shape({
    oldPassword: Yup.string(),
    password: passwordValidation,
    passwordConfirmation: passwordConfirmationValidation,
  }),

  handleSubmit: (values, bag) => {
    bag.props.submitHandler(values);
  },
})(ChangePasswordFormWrapper);

ChangePasswordFormWrapper.propTypes = {
  values: PropTypes.oneOfType([PropTypes.object]),
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  touched: PropTypes.bool,
  handleBlur: PropTypes.func,
  passwordForm: PropTypes.bool,
  errors: PropTypes.oneOfType([PropTypes.array]),
};

ChangePasswordFormWrapper.defaultProps = {
  values: {},
  handleSubmit: () => true,
  handleChange: () => true,
  touched: false,
  handleBlur: () => true,
  passwordForm: false,
  errors: [],
};

export default ChangePasswordForm;
