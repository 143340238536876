import React, { Fragment, useState } from 'react';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import AnonymousPlaceholder from '../../../src/images/provider/anonymous-placeholder.svg';
import { DetailedViewSet, SvgIco } from "../../SharedComponents/Icons";
import Map from "../../Map";

import './AssetsBlock.sass';


const AssetsBlock = ({ photos, floorPlans, latitude, longitude, vimeoId, virtualTour, googleApiKey }) => {

  const assetsList = [
    { name: 'photos', available: !!photos.length },
    { name: 'floor-plans', available: !!floorPlans.length },
    { name: 'map', available: !!(latitude && longitude) },
    { name: 'videos', available: !!vimeoId },
    { name: 'virtual_tour', available: !!virtualTour }
  ];

  const showAssetsBlock = assetsList.some(asset => asset.available);

  const [activeTab, setActiveTab] = useState(showAssetsBlock ? assetsList.find(asset => asset.available).name : 'photos');


  const Tab = ({ name }) =>
    <div className={`assets-block__tab ${ activeTab === name ? 'active' : '' }`} onClick={() => setActiveTab(name)}>
      { DetailedViewSet[name] }
      <span>
        { I18n.t(`lb_showroom.detailed.asset_box.new_${ name }_button`) }
      </span>
    </div>;


  const Gallery = ({ imgList }) =>
    <Carousel
      infiniteLoop={true}
      renderArrowPrev={(clickHandler) =>
        <div className="arrow-prev" onClick={clickHandler} >
          <SvgIco name="keyboard-arrow-left-rounded-ios" size={22} />
        </div>
      }
      renderArrowNext={(clickHandler) =>
        <div className="arrow-next" onClick={clickHandler} >
          <SvgIco name="keyboard-arrow-right-rounded-ios" size={22} />
        </div>
      }
      showIndicators={false}
      showStatus={true}
      showThumbs={false}
      statusFormatter={(current, total) => `${current}/${total}`}
      swipeable={imgList.length !== 1}
    >
      { imgList.map(elem => (
        <div className="assets-block__carousel-img-wrapper"
             key={elem.thumbnailW600}
             style={{ backgroundImage: `url(${elem.thumbnailW600})` }}>
        </div>)
      )}
    </Carousel>


  const VideoBlock = ({ url }) =>
    <div className="assets-block__frame-wrapper">
      <iframe
        allowFullScreen
        frameBorder={0}
        id="provider-video"
        mozallowfullscreen="mozallowfullscreen"
        src={url}
        webkitallowfullscreen="webkitallowfullscreen"
      />
    </div>;


  const MapBlock = () =>
    <Map
      googleApiKey={googleApiKey}
      isGalleryComponent={true}
      latitude={latitude}
      longitude={longitude}
      orientationHorizontalMobile={false} />


  const showContent = () => {
    switch (activeTab) {
      case 'photos':
      case 'floor-plans':
        return <Gallery imgList={ activeTab === 'photos' ? photos : floorPlans }/>;
      case 'map':
        return <MapBlock />
      case 'videos':
      case 'virtual_tour':
        return <VideoBlock url={activeTab === 'videos' ? `https://player.vimeo.com/video/${vimeoId}?autoplay=1&badge=0` : virtualTour} />
    }
  };


  return (
    <div className="assets-block">
      { showAssetsBlock
        ? <Fragment>
            <div className="assets-block__gallery-wrapper">
              { showContent() }
            </div>
            <div className="assets-block__tabs-wrapper">
              { assetsList.map(asset => asset.available && <Tab name={asset.name} key={asset.name} />) }
            </div>
          </Fragment>
        : <div className="assets-block__placeholder">
            <img src={AnonymousPlaceholder} alt="Anonymous" width="100%" height="auto" />
          </div>
      }
    </div>
  )
}


export default AssetsBlock;
