import React, { useEffect, useState, Fragment } from 'react';
import { debounce } from 'throttle-debounce';
// import { useTable } from 'react-table/src/hooks/useTable';
// import { useSortBy } from 'react-table/src/plugin-hooks/useSortBy';
// import { usePagination } from 'react-table/src/plugin-hooks/usePagination';

import { useTable, useSortBy, usePagination } from 'react-table'
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import MaUTable from '@material-ui/core/Table';
import TableFooter from '@material-ui/core/TableFooter';
import ReactPaginate from 'react-paginate';
import { useSticky } from 'react-table-sticky';

import NoResultsImage from '../../../src/images/icons/no-rsults.svg';
import BasicDropdown from '../../SharedComponents/Forms/BasicDropdown';
import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from '../../SharedComponents/Icons';
import { SkeletonLoader } from '../Loaders';

import './index.sass';

const Table = ({
  data,
  columns,
  footerLoading,
  perPage,
  setPerPage,
  perPageOptions,
  initialLoad,
  setPage,
  totalCount,
  showFooter,
  name,
  noPagination,
  disableSortBy,
  hideBottomFilters,
  currentPage,
  extraClass,
  hiddenColumns,
  noScrollToTop,
  loading,
  passPageIndex,
  disableSort,
  gotAllData,
  expandable,
  renderExpandableRow,
}) => {

  const [showStickyBorder, setShowStickyBorder] = useState(true);

  const tableData = React.useMemo(
    () => (loading ? Array(perPage).fill({}) : data),
    [loading, data],
  );

  const tableColumns = React.useMemo(
    () =>
      loading
        ? columns.map((column) => ({
            ...column,
            Cell: <SkeletonLoader className="table-loader" count={1} />,
          }))
        : columns,
    [loading, columns],
  );

  const noResults = React.useMemo(
    () => !loading && !initialLoad && !data.length,
    [loading, initialLoad, data],
  );

  const isExpandable = React.useMemo(
    () => expandable,
    [expandable],
  );

  useEffect(() => {
    const table = document.getElementById(`${name || ''}TableWrapper`);
    const stickyStartTarget = document.getElementById(`${name}-sticky-start-target`);

    table && stickyStartTarget && table.addEventListener('scroll', debounce(10, handleTableScroll));
  }, []);

  const handleTableScroll = () => {
    const stickyStartTarget = Math.round(document.getElementById(`${name}-sticky-start-target`).getBoundingClientRect().left);
    const scrollEndTarget = Math.round(document.getElementById(`${name}-scroll-end-target`).getBoundingClientRect().right);

    stickyStartTarget === scrollEndTarget
      ? setShowStickyBorder(false)
      : setShowStickyBorder(true);
  };

  useEffect(() => {
    setHiddenColumns(hiddenColumns || []);
  }, [hiddenColumns]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    footerGroups,
    pageCount,
    gotoPage,
    setPageSize,
    setHiddenColumns,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: tableColumns,
      data: tableData,
      disableSortRemove: disableSort,
      disableSortBy,
      initialState: {
        pageIndex: passPageIndex ? currentPage - 1 : 0,
        pageSize: perPage,
        hiddenColumns: hiddenColumns || [],
        sortBy: disableSort
          ? [
              {
                id: 'name',
                asc: true,
              },
              {
                id: 'roleTranslated',
                asc: true,
              },
            ]
          : [],
      },
    },
    useSortBy,
    usePagination,
    useSticky,
  );

  useEffect(() => {
    setPageSize(perPage);
  }, [perPage]);

  useEffect(() => {
    if (!noPagination && gotAllData)
      setPage(pageIndex+1)
  }, [pageSize]);

  const handlePerPage = (value) => {
    const scrollTop = perPage > value;
    setPageSize(value);

    setPerPage(value);
    scrollTop && window.scroll(0, 0)
  };

  const handlePage = (value) => {
    !noScrollToTop && window.scroll({ top: 0, behavior: 'smooth' });

    // We need to set timeout for correct scroll behaviour on Safari and Firefox,
    // as by the moment scrolling and changing window content causes bugs in this browsers
    setTimeout(() => {
      setPage(value);
      gotAllData ? gotoPage(value - 1) : null;
    }, 35);
  };

  const showTable = !noResults && !!tableData.length;


  return (
    <div className="provider-table-wrapper">
      <div
        className={`provider-table ${extraClass}`}
        id={`${name || ''}TableWrapper`}
      >
        { noResults && !initialLoad && (
          <div className="provider-table__no-results-wrapper">
            <p>{I18n.t('search_agent.no_results')}</p>
            <img
              src={NoResultsImage}
              alt="No results"
              className="provider-table__no-results-img"
              width={457}
              height={271}
            />
          </div>
        ) }
        {showTable && (
          <MaUTable {...getTableProps()} className={`table sticky ${showStickyBorder ? 'sticky-border' : ''}`}>
            <TableHead className="header">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </TableHead>

            <TableBody
              {...getTableBodyProps()}
              className={`body ${loading ? 'loading' : ''}`}
            >
              { page.map((row) => {
                prepareRow(row);
                return (
                  <Fragment key={`table-row-${row.index}`}>

                    <TableRow
                      {...row.getRowProps()}
                      className={`
                        ${(!row.original.isDestroyable && name === 'contacts') ? 'main-contact' : ''}
                        ${row.index % 2 !== 0 ? ' even' : ''}
                      `}>
                      { row.cells.map((cell) =>
                          <TableCell {...cell.getCellProps([{ style: cell.column.style }])}>
                            { cell.render('Cell') }
                          </TableCell>
                      )}
                    </TableRow>

                    { isExpandable && !loading &&
                      <tr className={`provider-table__expanded-row${row.original.expanded ? ' expanded' : ''}${row.index % 2 !== 0 ? ' even' : ''}`}>
                        <div className="wrapper" style={{ position: 'relative', display: 'contents' }}>
                          { renderExpandableRow(row.original) }
                        </div>
                        {/*We need this to properly show the background*/}
                        { Array.apply('test', Array(row.cells.length)).map((elem, index) =>
                          <td key={'helper-cell' + index} className={row.original.expanded ? ' expanded' : ''} />
                        )}
                      </tr>
                    }

                  </Fragment>
                );
              })}
            </TableBody>
            { showFooter && (
              <TableFooter>
                {footerGroups.map((group) => (
                  <TableRow {...group.getFooterGroupProps()}>
                    {group.headers.map((column) => (
                      <TableCell {...column.getFooterProps()}>
                        { footerLoading ? <SkeletonLoader className="table-loader" count={1} /> : column.render('Footer') }

                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableFooter>
            )}
          </MaUTable>
        )}
      </div>

      {!noPagination && showTable && (
        <div className="pagination-wrapper">
          {!hideBottomFilters && (
            <Fragment>
              <ReactPaginate
                previousLabel={<KeyboardArrowLeftRounded size={20} />}
                nextLabel={<KeyboardArrowRightRounded size={20} />}
                pageCount={
                  totalCount ? Math.ceil(totalCount / perPage) : pageCount
                }
                breakLabel={'...'}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                onPageChange={(data) =>
                  setPage
                    ? handlePage(data.selected + 1)
                    : gotoPage(data.selected)
                }
                containerClassName={'pagination'}
                activeClassName={'active'}
                forcePage={currentPage ? currentPage - 1 : 0}
              />

              <div className="per-page-wrapper">
                <div className="per-page-label">{I18n.t('generic.show')}:</div>
                <BasicDropdown
                  options={perPageOptions}
                  value={`${perPage}`}
                  name="perPage"
                  wrapperClass="per-page"
                  handleChange={(option) => handlePerPage(Number(option))}
                />
              </div>
            </Fragment>
          )}
        </div>
      )}
    </div>
  );
};


export default Table;
