import React from 'react';

import { SvgIco } from "../../SharedComponents/Icons";

import './ModalNetworkError.sass';


const ModalNetworkError = () => {

  return (
    <div className="modal-network-error">
      <SvgIco name="caution-outline" size={28} />
      <div className="modal-network-error__message">
        { I18n.t('generic.server-error-listview-page') } <a href={window.location.href}>{ I18n.t('general.refresh-page') }</a>.
      </div>
    </div>
  )
}

export default ModalNetworkError;
