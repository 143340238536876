import React from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import { SvgIco } from '../../Icons';

import './index.sass';


const BasicDropdown = ({ disabled, error, options, handleChange, value, name, wrapperClass, required, placeholder }) => {

  return (
    <div className={`basic-dropdown ${wrapperClass || ''}${ error ? ' error' : '' }${ disabled ? ' disabled' : '' }`}>
      <Dropdown
        arrowClosed={<SvgIco name="expand-more" size={24} />}
        arrowOpen={<SvgIco name="expand-less" size={24} />}
        id={name}
        name={name}
        onChange={(option) => handleChange(option.value, name)}
        options={options}
        placeholder={placeholder || I18n.t('helpers.select.prompt')}
        value={value}
      />
      { required && <span className="required-icon">*</span> }
      { error && <div className="error-tooltip">{ error }</div> }
    </div>

  )
}

export default BasicDropdown;
