import React, { useState } from 'react';
import { Link } from "react-router-dom";

import AssistanceBanner from '../../../src/images/provider/get-assistance.svg';
import Clients from "../Clients";
import ContactsPage from "../ContactsPage";
import Locations from "../Locations";

import './index.sass';


const MainDashboard = ({ contactName,
                         currencySymbolFormatter,
                         currentLocale,
                         enquiryStates,
                         isNumber,
                         providerApproved,
                         providerOnDealStrategy,
                         resourceNotFoundError,
                         siteName,
                         states,
                       }) => {

  const [showCreateLeaseButton, setShowCreateLeaseButton] = useState(null);

  return (
    <div className="main-dashboard">
      <div className="main-dashboard__top-block">
        <div className="main-dashboard__top-text">
          <p className="main-dashboard__headline">
            <span>{ I18n.t('providers.providers.show.greeting', { name: '' }) }</span><span>,</span> { contactName }
          </p>
          <p>{ I18n.t('providers.dashboard.greeting_text') }</p>
        </div>
        <img src={AssistanceBanner} alt="Get Assistance" width={303} height={216} />
      </div>

      { showCreateLeaseButton &&
        <div className="create-new-button alone">
          <Link to={`${currentLocale}/new_providers/lease/new/location`}>
            + { I18n.t('providers.locations.index.create_location') }
          </Link>
        </div>
      }

      <Clients
        asPreview={true}
        currencySymbolFormatter={currencySymbolFormatter}
        currentLocale={currentLocale}
        enquiryStates={enquiryStates}
        isNumber={isNumber}
        resourceNotFoundError={resourceNotFoundError}
      />

      <Locations
        asPreview={true}
        category="lease_properties"
        currentLocale={currentLocale}
        key="lease_properties"
        orderByFilter='updated_at_by_provider_only'
        orderDirectionFilter="desc"
        providerApproved={providerApproved}
        providerOnDealStrategy={providerOnDealStrategy}
        setShowCreateLeaseButton={setShowCreateLeaseButton}
        siteName={siteName}
        states={states}
      />

      <ContactsPage
        asPreview={true}
        currentLocale={currentLocale}
      />

    </div>
  );
}

export default MainDashboard;
