import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { LatestOrdersColumns } from '../Table/Columns';
import {GET_CLIENT_ORDERS, GET_CLIENT_ENQUIRIES, UPDATE_CLIENT_INFO } from '../ApolloClient/queries';
import ContactInfoForm from './ContactInfoForm';
import Notes from './Notes';
import Table from '../Table';
import ClientsTable from '../Clients/ClientsTable';
import InfoMessagePopup from '../Popups/InfoMessagePopup';
import Page404 from '../Errors/Page404';

import './ClientInfo.sass';


function ClientInfo({ currentLocale, isNumber, locale, resourceNotFoundError }) {

  const { id: currentId } = useParams();

  const [clientInfo, setClientInfo] = useState([]);
  const [clientOrders, setClientOrders] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [initialLoad, setInitialLoad] = useState(true);
  const [showClientInfoLoading, setShowClientInfoLoading] = useState(null);
  const [infoPopUp, setInfoPopUp] = useState({
    infoPopupMessage: '',
    infoMessagePopupIsOpen: false,
    infoPopupMessageError: null,
  });

  const [filters, setFilters] = useState({
    page: 1,
    perPage: 4,
    orderDirection: 'desc',
    orderBy: 'created_at',
    clientId: Number(currentId),
  });

  const perPageOptions = [
    { value: '4', label: '4' },
    { value: '8', label: '8' },
  ];

  const { page, perPage, orderDirection, orderBy } = filters;

  const {
    loading: clientInfoLoading,
    error,
    data: dataClientInfo,
    refetch: refetchClientInfo
  } = useQuery(GET_CLIENT_ENQUIRIES, {variables: { clientId: Number(currentId) }, errorPolicy: "all"});

  const { data: dataClientOrders, loading: clientOrdersLoading } = useQuery(GET_CLIENT_ORDERS, {variables: filters});

  const [updateContact] = useMutation(UPDATE_CLIENT_INFO);


  useEffect(() => {
    if (error) return;

    if (dataClientOrders) {
      setClientOrders(dataClientOrders.clientOrders.orders);
      setTotalCount(dataClientOrders.clientOrders.totalCount);
    }

    if (dataClientInfo) {
      setInitialLoad(false);
      setClientInfo(dataClientInfo.clientEnquiries.orders);
    }
  }, [dataClientOrders, dataClientInfo, error]);


  const handleFilters = (newFilters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));
  };


  const updateContactInfo = (values) => {
    const { companyName, name, email, phoneNumber } = values;
    const input = {
      id: Number(currentId),
      personName: name,
      companyName,
      email,
      phoneNumber,
    };

    updateContact({
      variables: { input },
      refetchQueries: ['getClientEnquiries'],
      onCompleted: () => {
        setInfoPopUp({
          infoPopupMessage: I18n.t('providers.contacts.edit.information_updated'),
          infoMessagePopupIsOpen: true,
        });
      },
      onError: () => {
        setInfoPopUp({
          infoPopupMessage: 'Some network error happened',
          infoMessagePopupIsOpen: true,
          infoPopupMessageError: true,
        });
      },
    });
  };


  const setOrderBy = (field) => {
    const newOrderDirection =
      field === orderBy && orderDirection === 'asc' ? 'desc' : 'asc';

    handleFilters({ orderBy: field, orderDirection: newOrderDirection });
  };


  const columns = LatestOrdersColumns(
    clientOrders,
    setOrderBy,
    orderDirection,
    orderBy
  );


  const clientOrOrderEmail = clientInfo.length
    ? clientInfo[0].clientOrOrderEmail
    : '';


  const closeInfoMessagePopup = () => {
    setInfoPopUp({ infoMessagePopupIsOpen: false });
  };


  if (resourceNotFoundError(error) || !isNumber(currentId)) return <Page404 currentLocale={currentLocale} />;


  const hiddenColumns = [
    'clientOrOrderCompanyName',
    'clientOrOrderPersonName',
    'clientOrOrderPhone',
    'clientOrOrderEmail',
    'clientNotesCount',
    'orderContextTranslated',
  ];

  const loading = showClientInfoLoading && clientInfoLoading;

  return (
    <div className="clients-info">
      <InfoMessagePopup
        handleClose={closeInfoMessagePopup}
        message={infoPopUp.infoPopupMessage}
        customClass={infoPopUp.infoPopupMessageError ? 'error' : null}
        visible={infoPopUp.infoMessagePopupIsOpen}
      />

      <p className="clients-info__main-title">
        {I18n.t('providers.clients.show.header', {client: clientOrOrderEmail})}{' '}
      </p>
      <p>
        {I18n.t('providers.clients.show.info', { email: clientOrOrderEmail })}:
      </p>

      <h3 className="clients-info__enquiries-title">
        {I18n.t('reports.providers_order_report.enquiries')}
      </h3>

      <ClientsTable
        hiddenColumnsList={hiddenColumns}
        currentLocale={currentLocale}
        initialLoad={initialLoad}
        loading={loading}
        locale={locale}
        orders={clientInfo}
        perPage={perPage}
        refetchOrders={refetchClientInfo}
        setShowLoading={setShowClientInfoLoading}
        setOrders={setClientInfo}
        disableSortBy
        hideBottomFilters
        noPagination
      />

      <p className="clients-info__main-title">
        {I18n.t('provider.clients.client_orders.title')}
      </p>

      <Table
        data={clientOrders}
        columns={columns}
        footerLoading={clientOrdersLoading}
        loading={clientOrdersLoading}
        perPage={perPage}
        setPage={(page) => handleFilters({ page })}
        setPerPage={(perPage) => handleFilters({ perPage, page: 1 })}
        currentPage={page}
        totalCount={totalCount}
        perPageOptions={perPageOptions}
        showFooter
        noScrollToTop
      />

      <div className="clients-info__form-wrapper">
        <p className="clients-info__main-title">
          {I18n.t('providers.clients.show.contact_info')}
        </p>

        <ContactInfoForm
          contactInfo={clientInfo[0]}
          updateContactInfo={updateContactInfo}
        />
      </div>

      <Notes currentId={currentId} orderNotes />
    </div>
  );
}

ClientInfo.propTypes = {
  locale: PropTypes.string,
};

ClientInfo.defaultProps = {
  locale: '',
};

export default ClientInfo;
