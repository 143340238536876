import React, { Fragment, useEffect, useState} from 'react';
import InlineChart from "../../SharedComponents/Charts/InlineChart";
import TooltipPopup from '../Popups/TooltipPopup';
import "./index.sass";


const LocationViewsChart = ({ createdAt,
                              locationId,
                              locationShownDatapoints,
                              offset,
                              switchPeriodCallback,
                              tickFontSize,
                              width,
                              xaxisHeight }) => {

  const [activeDaysInChart, setActiveDaysInChart] = useState(15);
  const [locationDaysActive, setLocationDaysActive] = useState(1);


  useEffect(() => {
    const today = new Date();
    const dayCreatedAt = new Date(Date.parse(createdAt));
    const timeInMilliseconds = today.getTime() - dayCreatedAt.getTime();
    const daysActive = Math.ceil(timeInMilliseconds / (1000 * 60 * 60 * 24));
    const defaultActiveDays = daysActive > 30 ? 90 : daysActive > 15 ? 30 : 15;

    setLocationDaysActive(daysActive);
    setActiveDaysInChart(defaultActiveDays);
    switchPeriodCallback && switchPeriodCallback(defaultActiveDays);
  }, []);


  const locationViewsData = () => {
    const periodsCount = 15;
    const daysForOnePeriod = activeDaysInChart / periodsCount;
    const isOneDayPeriod = daysForOnePeriod === 1;
    const dayCreatedAt = new Date(Date.parse(createdAt));
    const setDateToLocalDateString = (dateObj) => dateObj.toLocaleDateString('en-GB').split('/').join('.');
    let showAsLastPeriod = false;
    let increment = 0;
    let data = [];

    dayCreatedAt.setHours(0,0,0,0);

    const dayCreatedAtTime = dayCreatedAt.getTime();

    for (let i = 0; i < periodsCount; i++) {
      const date = new Date();
      let views = 0;

      date.setDate(date.getDate() - increment);

      const dateFrom = new Date(date);
      const dateTo = new Date(date);

      !isOneDayPeriod && dateFrom.setDate(dateFrom.getDate() - daysForOnePeriod);
      i !== 0 && !isOneDayPeriod && dateTo.setDate(dateTo.getDate() - 1);
      dateFrom.setHours(0,0,0,0);
      dateTo.setHours(23,59,59,59);

      const dateToTime = dateTo.getTime();
      const dateFromTime = dateFrom.getTime();

      Object.entries(locationShownDatapoints).map(
        ([key, value]) => {
          const shownDate = new Date(key.replace(/-/g, '/'));
          const showDateTime = shownDate.getTime();
          const isInTimePeriod = showDateTime <= dateToTime && showDateTime >= dateFromTime;

          return isInTimePeriod && (views = value + views);
        }
      );

      const pointData = { date: setDateToLocalDateString(date) };
      const createdAtDateIsInPeriod = (dayCreatedAtTime === dateFromTime && isOneDayPeriod) || showAsLastPeriod;

      if ((dateFromTime === dayCreatedAtTime) || (dayCreatedAtTime < dateToTime) || showAsLastPeriod) {
        const dateLabel = `${activeDaysInChart !== 15 ? dateFrom.getDate() + '-' : ''}${setDateToLocalDateString(dateTo)}`;

        pointData.value = views;
        pointData.label = dateLabel;
        createdAtDateIsInPeriod && (pointData.dotSize = 14);
        pointData.tooltip =
          <Tooltip
            dateCreated={createdAtDateIsInPeriod ? setDateToLocalDateString(dayCreatedAt) : null}
            dateLabel={dateLabel}
            viewsCount={views}
          />
      }

      data.unshift(pointData);
      increment = increment + daysForOnePeriod;
      showAsLastPeriod && (showAsLastPeriod = false);

      if (!isOneDayPeriod && dayCreatedAtTime < dateToTime && dateFromTime <= dayCreatedAtTime) {
        showAsLastPeriod = true;
      }
    }

    return data;
  };


  const switchPeriod = (daysPeriod) => {
    setActiveDaysInChart(daysPeriod);
    switchPeriodCallback && switchPeriodCallback(daysPeriod);
  };


  const renderDaysSwitchers = () => {
    const daysPeriods = [15, 30, 90];

    return daysPeriods.map((daysPeriod, index) =>
      (index === 0 || locationDaysActive >= daysPeriod || (locationDaysActive < daysPeriod && locationDaysActive > daysPeriods[index - 1])) &&
        <div key={`location-views-chart-${index}`} className={`location-views-chart__days-switcher ${daysPeriod === activeDaysInChart ? 'active' : ''}`}
             onClick={() => switchPeriod(daysPeriod)}>
          { daysPeriod } { I18n.t('provider.views_chart.days') }
        </div>
    )
  };


  const Tooltip = ({ dateCreated, dateLabel, viewsCount }) =>
    <Fragment>
      { dateCreated &&
        <Fragment>
          <div>{ I18n.t('provider.views_chart.date_created') }</div>
          <div className="custom-tooltip__headline custom-tooltip__headline--green">{ dateCreated }</div>
          { dateLabel !== dateCreated && !!viewsCount && <hr/> }
        </Fragment>
      }
      { !(dateCreated && !viewsCount) &&
        <Fragment>
          { !(dateCreated && dateLabel === dateCreated) &&
            <div className="custom-tooltip__headline">{ dateLabel }</div>
          }
          <div className="custom-tooltip__value">
            { I18n.t('activerecord.attributes.location.views_count') }: { viewsCount }
          </div>
        </Fragment>
      }
    </Fragment>


  return (
    <div className="location-views-chart">
      <div className="location-views-chart__top">
        <div className="location-views-chart__tooltip-wrap">
          <h3>{ I18n.t('activerecord.attributes.location.views_count') }</h3>
          <TooltipPopup
            content={I18n.t('providers.locations.edit.views.description')}
            icon="info"
            id={locationId ? locationId : "view-tooltip"}
            marginLeft={6}
            place="bottom"
            offset={offset ? offset : {}}
          />
        </div>

        <div className="location-views-chart__days-switchers">
          { renderDaysSwitchers() }
        </div>
      </div>

      <InlineChart
        data={locationViewsData(locationShownDatapoints || {})}
        lineKey="value"
        tickFontSize={tickFontSize}
        tooltipLabel={I18n.t('activerecord.attributes.location.views_count')}
        width={width}
        xaxisHeight={xaxisHeight}
        xKey="date"
      />
    </div>
  )
}

export default LocationViewsChart;
