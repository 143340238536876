import React from 'react';

import { SvgIco } from "../../SharedComponents/Icons";

import './PageInfoMessage.sass';


const PageInfoMessage = ({ message }) => {

  return (
    <div className="page-info-message">
      <SvgIco name="caution" size={51} />
      <div className="page-info-message__content" dangerouslySetInnerHTML={{ __html: message }} />
    </div>
  )
}

export default PageInfoMessage;
