import React, { Fragment } from 'react';
import ReactTooltip from 'react-tooltip';

import BasicDropdown from '../../../SharedComponents/Forms/BasicDropdown';
import SearchableDropdown from '../../../SharedComponents/Forms/SearchableDropdown';
import { SvgIco } from "../../../SharedComponents/Icons";


const DropdownGroup = ({ children,
                         label,
                         labelCol,
                         options,
                         handleChange,
                         name,
                         value,
                         tooltipClass,
                         tooltipChildren,
                         labelTooltip,
                         labelClass,
                         wrapperClass,
                         searchable,
                         disabled,
                         error,
                         required }) => {

  const errorMessage = error ? `${label} ${error}` : null;

  return (
    <div className={`form-group row ${wrapperClass || ''}${required ? ' required-form-group' : ''}`} id={name}>
      <div className={`form-label col-xl-${labelCol || 3} col-lg-${labelCol || 3} ${labelClass || ''}`}>

        <span className="label-text" style={labelTooltip ? { marginRight: '8px' } : null}>
          { label }{ required && '*' }
        </span>

        { labelTooltip &&
          <Fragment>

            <span
              className="provider-tooltip"
              data-tip={labelTooltip}
              data-class="provider-tooltip"
              data-for={`${name}-tooltip`}>
              <SvgIco name="info_outline" size={24} />
            </span>

            <ReactTooltip
              border={true}
              borderColor="black"
              effect='solid'
              id={`${name}-tooltip`}
              place='right'
              type="light" />

          </Fragment>
        }

      </div>

      { searchable
        ? <SearchableDropdown
            disabled={disabled}
            error={errorMessage}
            handleChange={handleChange}
            name={name}
            options={options}
            wrapperClass="col-xl-3 col-lg-4"
            value={value} />

        : <BasicDropdown
            disabled={disabled}
            error={errorMessage}
            handleChange={handleChange}
            name={name}
            options={options}
            value={value}
            wrapperClass="col-xl-3 col-lg-4" />
      }

      { children || null }

      { tooltipChildren &&
        <Fragment>
          <div className="col-xl-1 col-lg-1" />
          <div className="col-xl-5 col-lg-4">
            <div className={`form-group__tooltip ${ tooltipClass || '' }`}>
              { tooltipChildren }
            </div>
          </div>
        </Fragment>
      }
    </div>
  )
};


export default DropdownGroup;
