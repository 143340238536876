import React, { Fragment } from 'react';
import Modal from 'react-modal';

import { SvgIco } from '../../SharedComponents/Icons';

import './ProviderModal.sass';

const ProviderModal = ({ children,
                         handleClose,
                         hideCloseBtn,
                         modalClass,
                         showModal,
                         scrollable,
                      }) => {

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.6)',
      top: '0',
      overflow: 'auto',
      zIndex: '9999',
      // backdropFilter: 'blur(5px)',
      scrollBehavior: 'smooth',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      pointerEvents: 'visible',
      background: 'white',
      borderRadius: '4px',
      position: 'absolute',
    },
  };

  return (
    <Modal
      ariaHideApp={false}
      className={`provider-modal ${modalClass || ''}`}
      contentLabel={modalClass}
      isOpen={showModal}
      onRequestClose={handleClose || null}
      style={customStyles}
    >
      { scrollable
        ?
          <div className="provider-modal__wrapper">
            { !hideCloseBtn &&
              <div className="provider-modal__close-btn" onClick={handleClose}>
                <SvgIco name="close" size={24} />
              </div>
            }
            { children }
          </div>
        :
          <Fragment>
            { !hideCloseBtn &&
              <div className="provider-modal__close-btn" onClick={handleClose}>
                <SvgIco name="close" size={24} />
              </div>
            }
            { children }
          </Fragment>
      }
    </Modal>
  );
};

export default ProviderModal;
