import React from 'react';
import ReactTooltip from "react-tooltip";
import { NavLink } from "react-router-dom";

import { SvgIco } from "../../SharedComponents/Icons";


const SideBarLink = ({ disableTooltip, label, linkTo, newInfoLabel, svgName }) => {

  return (
    <li className="sidebar-item"
        data-tip={label}
        data-for={`nav-link-${svgName}-tooltip`}>
      <ReactTooltip
        border={true}
        borderColor="black"
        effect='solid'
        id={`nav-link-${svgName}-tooltip`}
        place="right"
        type="light"
        disable={disableTooltip}
        className="nav-link-tooltip"
      />
      <NavLink exact to={linkTo} activeClassName="active">
        <div>
          <SvgIco name={svgName} size={22} />
        </div>
        <span>{ label }</span>

        { newInfoLabel &&
          <div className="sidebar-item__new-info-icon-wrapper">
            { newInfoLabel }
          </div>
        }
      </NavLink>
    </li>
  )
}

export default SideBarLink;
