import React, { Fragment, useContext, useState, useEffect, useRef } from 'react';
import GooglePlacesAutocomplete, { geocodeByAddress, geocodeByPlaceId, geocodeByLatLng } from 'react-google-places-autocomplete';
import { useLazyQuery } from '@apollo/client';
import ReactTooltip from 'react-tooltip';

import { apiProvider } from "../../../../../api/core";
import { SvgIco } from "../../../../SharedComponents/Icons";
import AnonymousBanner from "../../../Banners/AnonymousBanner";
import { GET_PROVINCES, GET_POSTAL_DISTRICTS } from '../../../ApolloClient/queries';
import { Context } from '../../index';
import InputGroup from '../../FormGroups/InputGroup';
import DropdownGroup from '../../FormGroups/DropdownGroup';
import NavigationButtons from '../../NavigationButtons';
import Map from "./Map";

import './MapWrapper.sass';

// !!! README !!!
// STREET_NUMBER ========================================================================
// By the moment we rejected STREET_NUMBER functionality as market team requested
// for user to have possibility to save any location address (even without street_number);
// This functionality allows to prevent save location address without street_number.
// In case we receive incomplete address without street_number the search field will autofocus
// and a tooltip will appear saying "#street".
// ======================================================================================


const MapWrapper = () => {

  const {
    anonymous,
    countries,
    errors,
    handleChange,
    handleInputChange,
    owners,
    setErrors,
    setVisualsData,
    siteKey,
    validateFields,
    values,
    updateValues,
  } = useContext(Context);

  const formWrapper = useRef();
  const postalDistrictTooltip = useRef();
  const provinceTooltip = useRef();
  // const streetNumberPlaceholder = useRef();                                         <---------- STREET_NUMBER

  const [address, setAddress] = useState('');
  const [searchInputValue, setSearchInputValue] = useState('');
  // const [inputShift, setInputShift] = useState(0);                                  <---------- STREET_NUMBER
  const [suggestNewProvince, setSuggestNewProvince] = useState(false);
  const [suggestNewDistrict, setSuggestNewDistrict] = useState(false);
  const [showOnlySuggestedProvince, setShowOnlySuggestedProvince] = useState(false);
  const [showPinInfoMessage, setShowPinInfoMessage] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  // const [streetNumberRequired, setStreetNumberRequired] = useState(null);           <---------- STREET_NUMBER
  // const [streetPlaceholderPos, setStreetPlaceholderPos] = useState(null);           <---------- STREET_NUMBER
  const [restrictionBounds, setRestrictionBounds] = useState(null)
  const [placeId, setPlaceId] = useState(null);
  const [loading, setLoading] = useState(null);
  const [provincesOptions, setProvincesOptions] = useState([]);
  const [postalDistrictsOptions, setPostalDistrictsOptions] = useState([]);

  const [getProvinces, { data: provincesData }] = useLazyQuery(GET_PROVINCES);
  const [getPostalDistrict, { data: postalDistrictData }] = useLazyQuery(GET_POSTAL_DISTRICTS);

  const {
    addressLine1,
    addressLine2,
    countryAlpha2,
    latitude,
    longitude,
    postalCode,
    postalDistrictId,
    postalDistrictSuggestion,
    provinceId,
    provinceSuggestion,
  } = values;


  useEffect(() => {
    if (latitude && longitude) {
      geocodeByLatLng({ lat: latitude, lng: longitude }).then(results => {
        if (results && results.length) {
          setAddress({ label: addressLine1, value: { place_id: results[0].place_id }});
          setPlaceId(results[0].place_id);
        }
      });
    } else {
      siteKey !== 'global' && handleSelectCountry(siteKey, 'countryAlpha2')
    }

    // Commented as we have a bug with viewport coords on Address Line 1 copy-paste
    // In case it's local site - restrict bounds to local country
    // siteKey !== 'global' && geocodeByAddress(siteKey).then(results => {
    //   const geometryViewport = results[0].geometry.viewport;
    //
    //   setRestrictionBounds({
    //     north: geometryViewport.getNorthEast().lat(),
    //     south: geometryViewport.getSouthWest().lat(),
    //     west: geometryViewport.getSouthWest().lng(),
    //     east:geometryViewport.getNorthEast().lng(),
    //   });
    // });
  }, []);


  useEffect(() => {
    postalDistrictSuggestion && !suggestNewDistrict && setSuggestNewDistrict(true);
    provinceSuggestion && !suggestNewProvince && setSuggestNewProvince(true);
  }, [countryAlpha2]);


  useEffect(() => {
    if (provincesData) {

      if (provincesData.province.length) {
        const newProvincesOptions = provincesData.province.map(province => ({ value: Number(province.id), label: province.name }));

        setProvincesOptions(newProvincesOptions);
        setShowOnlySuggestedProvince(false);
      } else {
        provincesData !== undefined && setShowOnlySuggestedProvince(true);
      }
    }
  }, [provincesData]);


  useEffect(() => {
    if (postalDistrictData && postalDistrictData.postalDistrict.length) {
      const newPostalDistrictsOptions = postalDistrictData.postalDistrict.map(district => ({ value: Number(district.id), label: district.name }));

      setPostalDistrictsOptions(newPostalDistrictsOptions);
    }
  }, [postalDistrictData]);


  useEffect(() => {
    countryAlpha2 && getProvinces({ variables: { countryAlpha2 }});
  }, [countryAlpha2]);


  useEffect(() => {
    !!provinceId && !!provinceSuggestion && updateValues({ provinceSuggestion: '' });
  }, [provinceId]);


  useEffect(() => {
    !!provinceSuggestion && !!provinceId && updateValues({ provinceId: '' });
  }, [provinceSuggestion]);


  useEffect(() => {
    !!postalDistrictId && !!postalDistrictSuggestion && updateValues({ postalDistrictSuggestion: '' });
  }, [postalDistrictId]);


  useEffect(() => {
    !!postalDistrictSuggestion && postalDistrictId && updateValues({ postalDistrictId: '' });
  }, [postalDistrictSuggestion]);


  useEffect(() => {
    if(provinceId) {
      getPostalDistrict({ variables: { provinceId: String(provinceId) }});
      postalDistrictsOptions.find(option => option.value === postalDistrictId) && updateValues({ postalDistrictId: '' });
    }
  }, [provinceId]);


  useEffect(() => {
    if(provinceId) {
      getPostalDistrict({ variables: { provinceId: String(provinceId) }});
      postalDistrictsOptions.find(option => option.value === postalDistrictId) && updateValues({ postalDistrictId: '' });
    }
  }, [provinceId]);


  useEffect(() => {
    const postalDistrict = postalDistrictId
      ? postalDistrictsOptions.find(option => option.value === postalDistrictId)
      : postalDistrictSuggestion || '';
    const postalDistrictLabel = postalDistrict ? postalDistrict.label || postalDistrict  : '';
    const newAddress = `${addressLine1}, ${postalCode} ${postalDistrictLabel}`;

    setVisualsData({ address: newAddress });
  }, [addressLine1, postalCode, postalDistrictId, postalDistrictsOptions, postalDistrictSuggestion]);


  // STREET_NUMBER
  // ------------------------------------------------------------------------------------->
  // useEffect(() => {
  //   clearInputShift();
  // }, [searchInputValue]);


  // useEffect(() => {
  //   !loading && streetNumberRequired && setTimeout(handleStreetNumberPlaceholderPosition, 100);
  // }, [loading]);
  // <-------------------------------------------------------------------------------------


  const countriesOptions = countries.map(country => ({ label: country[0], value: country[1] }));
  const ownersOptions = owners.map(owner => ({ label: owner[0], value: String(owner[1]) }));

  // STREET_NUMBER
  // -------------------------------------------------------------------------------------->
  // const autocompleteStreetLabelStyles = {
  //   visibility: !!streetPlaceholderPos ? 'visible' : 'hidden',
  //   paddingLeft: `${streetPlaceholderPos + 16}px`
  // };


  // const clearInputShift = () => {
  //   streetPlaceholderPos && setStreetPlaceholderPos(null);
  //   inputShift && setInputShift(0);
  //   setStreetNumberRequired(false);
  // };
  // <--------------------------------------------------------------------------------------


  const handleSearchValue = (value, event) => {
    if (event.action === 'input-change') {
      !value && clearAddress();
      setSearchInputValue(value);
    }
  };


  const clearAddress = () => {
    setAddress('');
    countryAlpha2 && handleSelectCountry(countryAlpha2, 'countryAlpha2');
  };


  const setValue = (option) => {
    const { place_id } = option.value;

    setLoading(true);
    setPlaceId(place_id);
    geocodeByPlaceId(place_id)
      .then(results => results.length && handleGeocodedResults(results, place_id))
      .catch(error => console.error('error', error));
  };


  const handleSelectCountry = (value, field) => {
    handleChange(value, field);

    const countryName = countries.filter(country => country[1] === value)[0][0];

    geocodeByAddress(countryName).then(results => setPlaceId(results[0].place_id));
  };


  const handleSuggestNewProvince = () => {
    setSuggestNewDistrict(!suggestNewProvince);
    setSuggestNewProvince(prevState => !prevState);
    ReactTooltip.hide(provinceTooltip);
  };


  const handleSubmit = (nextStep) => validateFields(nextStep);


  const clearErrorsList = (list) => {
    const updatedErrors = { ...errors };

    list.map(field => delete updatedErrors[field]);
    setErrors(updatedErrors);
  };

  // STREET_NUMBER
  // ------------------------------------------------------------------------------------->
  // const handleStreetNumberPlaceholderPosition = () => {
  //   const autocompleteInput = document.getElementById('autocomplete-input');
  //   const streetNumberNodeWidth = streetNumberPlaceholder.current.offsetWidth;
  //   const inputTextWidth = autocompleteInput.nextSibling.offsetWidth;
  //   const parentWidth = autocompleteInput.offsetWidth;
  //   const inputWidth = streetNumberNodeWidth + inputTextWidth;
  //   const leftInputShift = inputWidth > parentWidth ? inputWidth - parentWidth : 0;
  //   const streetNumberNodePos = leftInputShift ? inputTextWidth - leftInputShift : inputTextWidth;
  //
  //   leftInputShift && setInputShift(leftInputShift);
  //   setStreetPlaceholderPos(streetNumberNodePos);
  // };
  // <--------------------------------------------------------------------------------------


  const handleGeocodedResults = (results, placeId) => {
    const locationInfo = results[0];
    const addressComponents = locationInfo.address_components;
    const streetNumberComponentIndex = addressComponents.findIndex(component => component.types[0] === 'street_number');
    const countryCodeObj = addressComponents.filter(res => res.types.includes('country'));
    const newCountryAlpha2 = countryCodeObj && countryCodeObj.length ? countryCodeObj[0].short_name.toLowerCase() : '';
    const inCountryBoundaries = siteKey === 'global' || newCountryAlpha2 === siteKey;
    const postalCodeObj = addressComponents.filter(res => res.types.includes('postal_code'))[0];
    const errorsList = [];
    let value = { label: '', value: { place_id: placeId } };

    let updatedValues = {
      latitude: locationInfo.geometry.location.lat(),
      longitude: locationInfo.geometry.location.lng(),
      countryAlpha2: siteKey === 'global' ? newCountryAlpha2 : countryAlpha2,
      postalCode: '',
    };

    /* TODO: find a way to format addresses for different countries
       TODO: In USA for example the address starts from street number */

    // IF WE HAVE FULL ADDRESS WITH STREET NUMBER
    if (streetNumberComponentIndex >= 0 && inCountryBoundaries) {
      const streetNumber = addressComponents[streetNumberComponentIndex].short_name;
      const streetName = addressComponents[streetNumberComponentIndex + 1].long_name;
      const administrativeLevel2 = addressComponents.filter(res => res.types.includes('administrative_area_level_2'))[0];
      const locality = addressComponents.filter(res => res.types.includes('locality'))[0];
      const autocompleteInput = document.getElementById('autocomplete-input');

      /* In case we can find province name in addressComponents from our provincesOptions list
         we can fill and request province and postal districts */
      const handleProvinceSelect = () => {
        if (provincesOptions && (administrativeLevel2 || locality)) {
          provincesOptions.map(option => {
            const administrativeIncludesProvince = administrativeLevel2 && administrativeLevel2.long_name === option.label;
            const localityIncludesProvince = locality && locality.long_name === option.label;

            (administrativeIncludesProvince || localityIncludesProvince) && handleChange(Number(option.value), 'provinceId');
            errorsList.push('provinceId', 'postalDistrictId', 'provinceSuggestion', 'postalDistrictSuggestion');
            setSuggestNewProvince(false);
            setSuggestNewDistrict(false);
            setShowOnlySuggestedProvince(false);
          });
        };
      }

      showPinInfoMessage && setShowPinInfoMessage(false);
      errorsList.push('addressLine1', 'countryAlpha2');

      if (postalCodeObj) {
        updatedValues.postalCode = postalCodeObj.long_name;
        errorsList.push('postalCode');

        // FILL PROVINCE AND POSTAL DISTRICT SELECTS
        apiProvider.getAll(`/lease/api/frontend/postal_districts/search_by_postal_code?postal_code=${postalCodeObj.long_name}&country_alpha2=${newCountryAlpha2}`)
          .then(response => {
            if (response) {
              updatedValues.provinceId = response.province.id;
              updatedValues.postalDistrictId = response.postal_district.id;
              setTimeout(() => {
                updateValues(updatedValues);
                clearErrorsList(['addressLine1', 'countryAlpha2', 'provinceId', 'postalDistrictId', 'provinceSuggestion', 'postalDistrictSuggestion', 'postalCode']);
              });
              setSuggestNewProvince(false);
              setSuggestNewDistrict(false);
              setShowOnlySuggestedProvince(false);
            } else {
              handleProvinceSelect();
            }
          });
      } else {
        handleProvinceSelect();
      }

      value.label = `${streetName} ${streetNumber}`;
      setSearchInputValue(`${streetName} ${streetNumber}`);
      setAddress(value);
      setMenuIsOpen(false);
      updatedValues.addressLine1 = value.label;
      autocompleteInput.blur();

      /* IF WE HAVE LOCATION WITHOUT STREET NUMBER
         we place street_name (google places will mark it as a route) + ', street #'
         to places input, so the user knows the street_number is required */
    } else if (placeId && inCountryBoundaries) {
      // Add  |`${locationInfo.address_components[0].short_name}, `| for both for STREET_NUMBER functionality
      value.label = `${locationInfo.address_components[0].short_name}`;
      setSearchInputValue(`${locationInfo.address_components[0].short_name}`);

      // STREET_NUMBER
      // ------------------------------------------------------------------------------------->
      // setStreetNumberRequired(true);
      // errorsList.push('addressLine1', 'countryAlpha2');
      // <-------------------------------------------------------------------------------------

      setShowPinInfoMessage(true);
      setAddress(value);                        // Remove for STREET_NUMBER functionality
      updatedValues.addressLine1 = value.label; // Remove for STREET_NUMBER functionality

      /* IF WE RECEIVED RESULTS WITH NO STREET NEITHER STREET NUMBER
         clear all fields and show Map Pin to inform user he should move marker to another position */
    } else {
      setAddress(value);
      updatedValues = {
        ...updatedValues,
        addressLine1: '',
        provinceId: '',
        postalDistrictId: '',
        postalCode: '',
      };
      setShowPinInfoMessage(true);
    }

    clearErrorsList(errorsList);
    updateValues(updatedValues);
    setLoading(false);
  };


  const onAutocompleteBlur = () => {
    // STREET_NUMBER
    // -------------------------------------------------------------------------------------->
    // clearInputShift();
    // <--------------------------------------------------------------------------------------

    setMenuIsOpen(false);
    setSearchInputValue(address.label || '');
  };

  const onAutocompleteFocus = () => {
    setMenuIsOpen(true);
    setSearchInputValue(address.label || '');
  };


  return (
    <div className="location__content" ref={formWrapper}>
      <div className="location__content-wrapper">
        <div className="location__inputs-wrapper">
          { !anonymous &&
            <Fragment>
              <div className="form-group row required-form-group" id="addressLine1">

                <div className="form-label address">
                  <span>{ I18n.t('activerecord.attributes.location.address_line_1') }*</span>

                  <span
                    className="provider-tooltip"
                    data-class="provider-tooltip"
                    data-for={`addressLine1-tooltip`}
                    data-tip={`${I18n.t('providers.locations.form.set_address')} ${I18n.t('providers.locations.form.helper_2')}`}>
                    <SvgIco name="info_outline" size={24} />
                  </span>

                  <ReactTooltip
                    border={true}
                    borderColor="black"
                    effect='solid'
                    id={`addressLine1-tooltip`}
                    place='right'
                    type="light" />
                </div>

                <div className="location__places col-xl-3 col-lg-4">
                  <GooglePlacesAutocomplete
                    autocompletionRequest={{
                      // types: ['address'],
                      componentRestrictions: { country: countryAlpha2 && siteKey !== 'global' ? [countryAlpha2] : null },
                    }}
                    selectProps={{
                      loadingMessage: () => `${I18n.t('generic.loading')}...`,
                      noOptionsMessage: () => I18n.t('searches.show.no_results'),
                      onInputChange: (value, e) => handleSearchValue(value, e),
                      onChange: setValue,
                      onBlur: onAutocompleteBlur,
                      onFocus: onAutocompleteFocus,
                      blurInputOnSelect: false,
                      classNamePrefix: 'places',
                      className: 'places',
                      closeMenuOnSelect: false,
                      isLoading: loading,
                      inputId: 'autocomplete-input',
                      inputValue: loading ? `${I18n.t('generic.loading')}...` : searchInputValue,
                      loadingIndicator: { isDisabled: true },
                      menuIsOpen: menuIsOpen,
                      name: 'addressLine1',
                      placeholder: I18n.t('providers.locations.form.address_line_1_placeholder'),
                      value: address,
                      styles: {
                        placeholder: (provided) => ({
                          ...provided,
                          display: `${menuIsOpen ? 'none' : 'flex'}`,
                        }),
                        control: (provided) => ({
                          ...provided,
                          border: `1px solid ${errors.addressLine1 ? '#FF5A5A' : '#E0E2E2'}`,
                          ['&:hover']: { border: `1px solid ${errors.addressLine1 ? '#FF5A5A' : '#E0E2E2'}`, },
                          // ['input']: { left: `-${inputShift}px` },  <---------- STREET_NUMBER
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          display: `${menuIsOpen ? 'none' : 'flex'}`,
                        }),
                      }
                    }} />

                  {/* STREET_NUMBER ----------------------------------------------------> */}
                  {/*<div className="location__street-placeholder"*/}
                  {/*     ref={streetNumberPlaceholder}*/}
                  {/*     style={autocompleteStreetLabelStyles}>*/}
                  {/*  { I18n.t('provider.form.location.street_number_placeholder') }*/}
                  {/*</div>*/}
                  {/* <---------------------------------------------------- STREET_NUMBER */}

                  { errors.addressLine1 &&
                    <div className="error-tooltip">
                      { I18n.t('activerecord.attributes.location.address_line_1') } { errors.addressLine1 }
                    </div>
                  }
                </div>
              </div>

              <InputGroup
                handleChange={handleInputChange}
                label={I18n.t('activerecord.attributes.location.address_line_2')}
                labelTooltip={I18n.t('provider.form.location.address_line2.tooltip')}
                name="addressLine2"
                value={addressLine2} />

            </Fragment>
          }


          { siteKey === 'global' &&
            <DropdownGroup
              error={errors.countryAlpha2}
              handleChange={handleSelectCountry}
              label={I18n.t('providers.locations.form.country')}
              name="countryAlpha2"
              options={countriesOptions}
              required={true}
              searchable={true}
              value={countryAlpha2} />
          }

          { suggestNewProvince || showOnlySuggestedProvince
            ? <InputGroup
                handleChange={handleInputChange}
                label={I18n.t('providers.locations.form.province')}
                name="provinceSuggestion"
                required={true}
                value={provinceSuggestion} />

            : <DropdownGroup
                disabled={!provincesOptions.length}
                error={errors.provinceId}
                handleChange={(value, field) => handleChange(Number(value), field)}
                label={I18n.t('providers.locations.form.province')}
                name="provinceId"
                options={provincesOptions}
                required={true}
                searchable={true}
                value={provinceId} />
          }

          { !showOnlySuggestedProvince &&
            <div className="form-group row">

              { anonymous && <div className="col-xl-3 col-lg-3" /> }

              <div className="location__suggestion-link" onClick={handleSuggestNewProvince}>

                { suggestNewProvince ? <SvgIco name="arrow-back" size={18} /> : '+' }

                <div className="location__suggestion-link-text provider-tooltip"
                     data-tip={!suggestNewProvince ? I18n.t('providers.locations.form.suggestion_text') : null}
                     data-class="provider-tooltip"
                     ref={provinceTooltip}
                     data-for={`province-tooltip`}>
                  { suggestNewProvince
                    ? I18n.t('provider.form.location.districts_back_button')
                    : I18n.t('providers.locations.form.suggest_province')
                  }
                </div>

                { !suggestNewProvince &&
                  <ReactTooltip
                    place='right'
                    effect='solid'
                    id="province-tooltip"
                    type="light"
                    border={true}
                    borderColor="black"/>
                }
              </div>
            </div>
          }

          { suggestNewDistrict || showOnlySuggestedProvince
            ? <InputGroup
                handleChange={handleInputChange}
                label={I18n.t('providers.locations.form.postal_district')}
                name="postalDistrictSuggestion"
                required={true}
                value={postalDistrictSuggestion} />

            : <DropdownGroup
                disabled={!postalDistrictsOptions.length}
                error={errors.postalDistrictId}
                handleChange={(value, field) => handleChange(Number(value), field)}
                label={I18n.t('providers.locations.form.postal_district')}
                name="postalDistrictId"
                options={postalDistrictsOptions}
                required={true}
                searchable={true}
                value={postalDistrictId} />
          }

          { !suggestNewProvince && !showOnlySuggestedProvince &&
            <div className="form-group row">

              { anonymous && <div className="col-xl-3 col-lg-3" /> }

              <div className="location__suggestion-link" onClick={() => setSuggestNewDistrict(prevState => !prevState)}>
                { suggestNewDistrict ? <SvgIco name="arrow-back" size={18} /> : '+' }
                <div
                  className="location__suggestion-link-text provider-tooltip"
                  data-class="provider-tooltip"
                  data-for={`district-tooltip`}
                  data-tip={!suggestNewProvince ? I18n.t('providers.locations.form.suggestion_text') : null}
                  ref={postalDistrictTooltip}>

                  { suggestNewDistrict
                    ? I18n.t('provider.form.location.districts_back_button')
                    : I18n.t('providers.locations.form.suggest_postal_district')
                  }

                  { !suggestNewDistrict &&
                    <ReactTooltip
                      border={true}
                      borderColor="black"
                      effect='solid'
                      id={`district-tooltip`}
                      place='right'
                      type="light" />
                  }
                </div>
              </div>
            </div>
          }

          <InputGroup
            handleChange={handleInputChange}
            label={I18n.t('signup.new_form.register.postal_code.placeholder')}
            name="postalCode"
            required={true}
            value={postalCode}
            wrapperClass={!(siteKey === 'dk' && !!ownersOptions.length) ? 'no-padding' : ''} />
        </div>

        { !anonymous &&
          <Map
            { ...{ placeId, restrictionBounds, handleGeocodedResults, showPinInfoMessage }}
            containerElement={<div style={{ height: `auto`, width: '100%', paddingLeft: '24px' }} />}
            mapElement={<div style={{ height: `100%`, borderRadius: '4px' }} />} />
        }

        { anonymous && <AnonymousBanner />}
      </div>

      <NavigationButtons handleNext={() => handleSubmit('economy')} />

    </div>
  );
};


export default MapWrapper;
