import React, { useEffect, useState, useRef } from 'react';
import Select, { components } from 'react-select';
import 'react-dropdown/style.css';

import { SvgIco } from '../../Icons';

import './index.sass';



const SearchableDropdown = ({ options, handleChange, value, name, wrapperClass, required, disabled, error, placeholder, classNamePrefix }) => {

  const [isOpen, setIsOpen] = useState(false);
  const dropdownContainer = useRef();
  const selectedOption = value && options.length ? options.filter(option => option.value === value)[0] : null;


  useEffect(() => {
    const container = dropdownContainer.current;
    const closeDropdown = (e) => !container.contains(e.target) && setIsOpen(false);

    isOpen
      ? window.addEventListener('click', closeDropdown)
      : window.removeEventListener('click', closeDropdown);
  }, [isOpen]);


  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <SvgIco className="search-icon" name="search" size={24} />
      </components.DropdownIndicator>
    );
  };


  return (
    <div className={`searchable-dropdown ${wrapperClass || ''}`} ref={dropdownContainer}>

      <div className={`searchable-dropdown__trigger-area${disabled ? ' disabled' : ''}${ error ? ' error' : '' }`}
           onClick={() => !disabled && setIsOpen(prevState => !prevState)}>

        <div className={`searchable-dropdown__trigger-label${!value ? ' placeholder' : ''}`}>
          { selectedOption ? selectedOption.label : placeholder || I18n.t('helpers.select.prompt') }
        </div>

        { isOpen
          ? <SvgIco name="expand-less" size={24} />
          : <SvgIco name="expand-more" size={24} />
        }

      </div>

      { error && <div className="error-tooltip">{ error }</div> }

      <Select
        isSearchable
        components={{ DropdownIndicator }}
        options={options}
        className={`${classNamePrefix}${!isOpen ? ' hidden' : ''}`}
        menuIsOpen={true}
        onSelect={() => setIsOpen(false)}
        autoFocus={true}
        isFocused={true}
        value={{ value }}
        name={name}
        styles={customStyles}
        classNamePrefix={classNamePrefix}
        noOptionsMessage={() => I18n.t('searches.show.no_results')}
        onChange={(option) => {
          setIsOpen(false)
          handleChange(option.value, name)
        }}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#F6F6F6',
            primary: '#F6F6F6',
          },
        })}
      />

      { required && <span className="required-icon">*</span> }
    </div>

  )
}

export default SearchableDropdown;


const customStyles = {
  indicatorContainer: (provided) => ({
    ...provided,
    color: '#E0E2E2',
    ['&:hover']: () => ({
      color: '#E0E2E2',
    })
  }),
  option: (provided, state) => ({
    ...provided,
    lineHeight: '20px',
    padding: '10px 16px',
    display: 'flex',
    alignItems: 'center',
    color: 'black',
    borderRadius: '2px',
    cursor: 'pointer',
  }),
  placeholder: () => ({
    display: 'none',
  }),
  control: () => ({
    minHeight: '32px',
    maxHeight: '32px',
    background: 'white',
    border: '1px solid #E0E2E2',
    borderRadius: '4px',
    margin: '16px 16px 10px',
    display: 'flex',
  }),
  input: () => ({
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '4px',
    paddingLeft: '5px',
    marginLeft: '-3px',
    ['input']: () => ({
      width: '100%',
    })
  }),
  menu: () => ({
    margin: 0,
    maxHeight: '500px',
    overflow: 'hidden',
    background: 'white',
    paddingTop: '0px',
    fontFamily: 'MuseoSans, Helvetica, Arial, sans-serif',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '208px',
  }),
  singleValue: () => ({
    top: '-2px',
    position: 'relative',
  }),
  container: (provided) => ({
    ...provided,
    border: '1px solid #E0E2E2',
    paddingTop: '10px',
    borderRadius: '4px',
    position: 'absolute',
    width: 'calc(100% - 16px)',
    zIndex: 1,
    background: 'white',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};
