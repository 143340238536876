import React from 'react';
import { parseMd } from '../../../utils/helpers';
import './index.sass';

const HelpPage = ({ content }) => {

  return (
    <div className="help-page">
      <div className="help-page__content" dangerouslySetInnerHTML={{__html: parseMd(content)}} />
    </div>
  );
};


export default HelpPage;
