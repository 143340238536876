import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import ReactTooltip from "react-tooltip";

import { Portal } from "../../SharedComponents/hooks";
import { SvgIco } from "../../SharedComponents/Icons";
import TooltipPopup from "../Popups/TooltipPopup";
import { EllipsisLoader } from "../Loaders";


// COMPONENTS =========================================================================================================>

export const TableFilterHeader = ({ additionalClass, handleOrderBy, headline, isActive, style, orderDirection }) => {

  const iconName = orderDirection === 'desc' && isActive ? 'arrow_upward' : 'arrow_downward';

  return (
    <div className={`table-header ${additionalClass || ''}`} onClick={handleOrderBy}>
      <span style={style || null}>{ headline }</span>
      <SvgIco
        className={isActive ? ' active' : ''}
        name={iconName}
        size={16} />
    </div>
  )
};


export const TableHashLink = ({ link, value, offset, additionalClass, nowrap }) => {

  const handleScroll = (elem) => {
    const targetTop = elem.getBoundingClientRect().top;
    const targetCoords = Math.floor(targetTop + window.scrollY - offset);

    window.scroll(0, targetCoords);
  };

  return (
    <HashLink
      scroll={(el) => handleScroll(el)} className={additionalClass || ''}
      style={nowrap ? { whiteSpace: "nowrap" } : {}}
      to={link}>
      { value }
    </HashLink>
  )
};


export const InfoIconTooltip = ({ id, tooltip, position }) =>
    <div className="table-tooltip-wrapper">
      <div data-tip={tooltip}
         data-class="table-tooltip"
         data-for={`${id}-tooltip`}>
        <SvgIco name="info_outline" size={21} />
      </div>

      <ReactTooltip
        border={true}
        borderColor="black"
        effect='solid'
        id={`${id}-tooltip`}
        place={position || 'top'}
        type="light"
      />
    </div>;


export const CellDropdown = ({ options, handleChange, value, id, matchPrevColumn, withCallback, afterOptionChange, onShow, loading }) => {
  const [activeOption, setActiveOption] = useState(options.filter(option => option.value === value)[0]);
  const [menuTop, setMenuTop] = useState(null);
  const [menuLeft, setMenuLeft] = useState(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [wrapperHeight, setWrapperHeight] = useState('auto');
  const dropdown = useRef();

  const menuStyles = { left: `${menuLeft}px`, top: `${menuTop}px` };

  useEffect(() => {
    handleWrapperHeight();
    window.addEventListener('click', handleClickOutside);

    return () => window.removeEventListener('click', handleClickOutside);
  }, []);

  useEffect(() => {
    afterOptionChange && afterOptionChange(activeOption.value);
  }, [activeOption]);

  const setMenuState = () => {
    if (menuIsOpen) {
      setMenuIsOpen(false);
    } else {
      const dropdownRef = dropdown.current;
      const dropdownBottomCoords = dropdownRef && dropdownRef.getBoundingClientRect().top + window.scrollY + dropdownRef.clientHeight + 1;
      const dropdownLeftCoords = dropdownRef && dropdownRef.getBoundingClientRect().left;

      setMenuTop(dropdownBottomCoords);
      setMenuLeft(dropdownLeftCoords);
      setMenuIsOpen(true);
      onShow && onShow(() => setMenuIsOpen(false));
    }
  };

  const handleOptionClick = (option) => {
    !withCallback && setActiveOption(option);
    handleChange(option.value, () => setActiveOption(option));
  };

  const handleClickOutside = (e) =>
    !dropdown.current.contains(e.target) && setMenuIsOpen(false);


  const handleWrapperHeight = () => {
    if (!matchPrevColumn) return;

    const prevColumnElement = document.getElementById(id);
    const prevColumnElementHeight = prevColumnElement && prevColumnElement.clientHeight;

    setWrapperHeight(`${prevColumnElementHeight}px`);

    // setTimeout(() => {
    //   const prevColumnElement = document.getElementById(id);
    //   const prevColumnElementHeight = prevColumnElement && prevColumnElement.clientHeight;
    //
    //   setWrapperHeight(`${prevColumnElementHeight}px`);
    // }, 200);
  };


  return (
    <div className="cell-dropdown-wrapper" style={{ height: wrapperHeight }}>
      <div className="cell-dropdown" onClick={setMenuState} ref={dropdown}>
        { menuIsOpen &&
          <Portal>
            <div className="cell-dropdown__menu" style={menuStyles}>
              { options.map(option =>
                <div className={`cell-dropdown__option ${option.value === value ? 'active' : ''}`}
                     onClick={() => handleOptionClick(option)}
                     key={`${option.label}${id}`}>
                  { option.label }
                </div>
              )}
            </div>
          </Portal>
        }

        { loading ? <EllipsisLoader /> : activeOption.label }

        { menuIsOpen
          ? <SvgIco name="expand-less" size={18} />
          : <SvgIco name="expand-more" size={18} />
        }

      </div>
    </div>
  )
};


export const EditDropDown = ({ data, modalClick }) => {

  const clickOnDropDown = (data, editModal) => {
    modalClick(data, editModal)
  };

  return (
    <Fragment>
      <p className="table-action-link" onClick={() => clickOnDropDown(data.row.original, true)}>
        <SvgIco name="edit" size={24} />
        { I18n.t('generic.edit') }
      </p>
      { data.row.original.isDestroyable && (
        <p className="table-action-link" onClick={() => clickOnDropDown(data.row.original)}>
          <SvgIco name="delete_outline" size={24} />
          { I18n.t("generic.delete") }
        </p>
      )}
    </Fragment>
  )
};


export const handleHorizontalScroll = (name, handleClose) => {
  const tableWrapper = document.getElementById(`${name}TableWrapper`);

  const handleScroll = () => {
    handleClose();
    tableWrapper.removeEventListener('scroll', handleScroll);
  }

  tableWrapper.addEventListener('scroll', handleScroll);
};


// <========================================================================================================= COMPONENTS


//=======================================================================================================> LATEST ORDERS


export const LatestOrdersColumns = (clientOrders, setOrderBy, orderDirection, orderBy) => React.useMemo(
  () => [
    {
      Header:<TableFilterHeader headline={I18n.t("reports.providers_order_report.created_at")}
                                orderDirection={orderDirection}
                                handleOrderBy={() => setOrderBy('created_at')}
                                isActive={orderBy === 'created_at'} />,
      accessor: 'createdAtFormatted',
    },
    {
      Header: ({column}) => {
        return (
          <div className={`table-header`}>
            <span>{ I18n.t("generic.providers.sidebar.leases") }</span>
            <SvgIco
              className={column.isSortedDesc ? ' active' : ''}
              name={column.isSortedDesc ? 'arrow_upward' : 'arrow_downward'}
              size={16} />
          </div>
        )
      },
      accessor: 'locationAddress',
      Cell: ({cell}) => {
        return (
          <a href={cell.row.original.locationPath}>{cell.row.original.locationAddress}</a>
        )
      }
    },
    {
      Header: ({column}) => {
        return (
          <div className={`table-header`}>
            <span>{ I18n.t("activerecord.attributes.location.area_interval") }</span>
            <SvgIco
              className={column.isSortedDesc ? ' active' : ''}
              name={column.isSortedDesc ? 'arrow_upward' : 'arrow_downward'}
              size={16} />
          </div>
        )
      },
      accessor: 'locationAreaFormatted',
      Cell: data => <div className="google-translate-helper">{ data.value }</div>,
    },
    {
      Header: ({column}) => {
        return (
          <div className={`table-header`}>
            <span>{ I18n.t("activerecord.attributes.location.yearly_rent_per_m2_from") }</span>
            <SvgIco
              className={column.isSortedDesc ? ' active' : ''}
              name={column.isSortedDesc ? 'arrow_upward' : 'arrow_downward'}
              size={16} />
          </div>
        )
      },
      accessor: 'locationYearlyRentPerM2FromFormatted',
      Cell: data => <div className="google-translate-helper">{ data.value }</div>,
    },
    {
      Header: ({column}) => {
        return (
          <div className={`table-header`}>
            <span>{ I18n.t("providers.statistics.index.kind") }</span>
            <SvgIco
              className={column.isSortedDesc ? ' active' : ''}
              name={column.isSortedDesc ? 'arrow_upward' : 'arrow_downward'}
              size={16} />
          </div>
        )
      },
      accessor: 'locationKindTranslated',
      Cell: data => <div className="google-translate-helper">{ data.value }</div>,
    },
  ],
  [clientOrders]
);


//=============================================================================================> REPORT A DEAL LOCATIONS


export const ReportDealLocation = (locations, locationId, handleSelect) => React.useMemo(
  () => [
    {
      Header: I18n.t('providers.statistics.index.address'),
      accessor: 'addressLine1',
      Cell: data => {
        return(
          <div className="check-button">
            <input
              type="radio"
              name="address"
              id={data.cell.row.original.id}
              value={data.cell.row.original.id}
              checked={locationId == data.cell.row.original.id}
              onChange={(e) => handleSelect(e.target.value, data.cell.row.original.state)}
              className="checkoption-input"
            />
            <label htmlFor={data.cell.row.original.id} className="select-option">
              <span className="option-checkmark" />
              <div className="google-translate-helper">{ data.value }</div>
            </label>
          </div>)}
    },
    {
      Header: I18n.t("providers.locations.form.zip_and_city"),
      accessor: 'postalCode',
      Cell: data => <div className="google-translate-helper">{ data.value }</div>,
    },
    {
      Header: I18n.t("generic.type"),
      accessor: 'kindTranslated',
      Cell: data => <div className="google-translate-helper">{ data.value }</div>,
    },
    {
      Header: `${I18n.t("activerecord.attributes.location.area_interval")}`,
      accessor: 'areaFrom',
      Cell: data => {
        if (data.value) {
          const areaLabel = `${data.value}${data.cell.row.original.areaTo ? ` - ${data.cell.row.original.areaTo}` : '' } ${data.cell.row.original.areaUnit || ''}`

          return <div className="google-translate-helper">{ areaLabel }</div>
        } else {
          return '';
        }
      },
    },
    {
      Header: I18n.t("providers.statistics.index.status"),
      accessor: "stateTranslated",
      Cell: data => <div className="google-translate-helper">{ data.value }</div>,
    },
    {
      Header: I18n.t('activerecord.attributes.location.internal_identifier'),
      accessor: "internalIdentifier",
      Cell: data => <div className="google-translate-helper">{ data.value }</div>,
    },
  ], [locations, locationId]
);


//================================================================================================> REPORT A DEAL ORDERS


export const ReportDealOrders = (orders, setClientId, orderId, handleCloseNotification) => React.useMemo(
  () => [
    {
      Header: I18n.t("generic.company_name"),
      accessor: 'companyName',
      Cell: data => {
        return(
        <div className="check-button">
          <input
            type="radio"
            name="company-name"
            id={data.cell.row.original.clientId}
            value={data.cell.row.original.clientId}
            checked={orderId == data.cell.row.original.clientId}
            onChange={(e) => { setClientId(e.target.value); handleCloseNotification()}}
            className="checkoption-input"
          />
          <label htmlFor={data.cell.row.original.clientId} className="select-option">
            <span className="option-checkmark" />
            <div className="google-translate-helper">{ data.value }</div>
          </label>
        </div>)}
    },
    {
      Header: I18n.t('generic.name'),
      accessor: 'personName',
      Cell: data => <div className="google-translate-helper">{ data.value }</div>,
    },
    {
      Header: I18n.t("generic.email"),
      accessor: 'email',
      Cell: data => <div className="google-translate-helper">{ data.value }</div>,
    },
  ], [orders, orderId]
);


//============================================================================================================> CONTACTS


export const ContactsColumns = (dataTable, clickOnDropDown, orderDirection, setOrderBy, activeFilter) => React.useMemo(
  () => [
    {
      Header: I18n.t("generic.actions"),
      accessor: 'action',
      Cell: data => (
        <TooltipPopup id={`${data.cell.row.original.id}-actions-popup`}
                      place="right"
                      className="table-actions-popup"
                      height={28}
                      offset={{ top: -20 }}
                      hideBorder={true}
                      onShow={(handleClose) => handleHorizontalScroll('contacts', handleClose)}
                      icon="more_vert"
                      children={
                        <div className="table-actions-wrapper">
                          <EditDropDown
                            data={data}
                            modalClick={clickOnDropDown}
                          />
                        </div>}/>
      )
    },

    {
      Header: 'ID',
      accessor: 'id',
      Cell: data => <div className="google-translate-helper">{ data.value }</div>,
    },

    {
      Header:
        <TableFilterHeader headline={I18n.t('generic.name')}
                           orderDirection={orderDirection}
                           handleOrderBy={() => setOrderBy('name')}
                           isActive={activeFilter === 'name'} />,
      accessor: 'name',
      Cell: data => <div className="google-translate-helper">{ data.value }</div>,
    },

    {
      Header: I18n.t("generic.email"),
      accessor: 'email',
      Cell: e => <a href={`mailto:${e.value}`}> {e.value} </a>
    },

    {
      Header: I18n.t('generic.phone'),
      accessor: 'phoneNumber',
      Cell: data =>
        <div>
          <a href={`tel://${data.row.original.phoneNumber}`}> {data.row.original.phoneNumber} </a>
        </div>
    },
  ],
  [dataTable, activeFilter]
);


//====================================================================================================> DELETED CONTACTS


export const DeletedContactsColumns = (dataTable, handleDeleteLinkClick, activeDeleteContact, orderDirection, setOrderBy, activeFilter ) => React.useMemo(
  () => [

    {
      Header: 'ID',
      accessor: 'id',
      Cell: data => <div className="google-translate-helper">{ data.value }</div>,
    },

    {
      Header:
        <TableFilterHeader headline={I18n.t('generic.name')}
                           orderDirection={orderDirection}
                           handleOrderBy={() => setOrderBy('name')}
                           isActive={activeFilter === 'name'} />,
      accessor: 'name',
      Cell: data => <div className="google-translate-helper">{ data.value }</div>,
    },

    {
      Header: I18n.t("generic.email"),
      accessor: 'email',
      Cell: e => <a href={`mailto:${e.value}`}> {e.value} </a>
    },

    {
      Header: I18n.t('generic.status'),
      accessor: '',
      Cell: data =>
        <div>
          <p className="contacts-table__active-button" onClick={() => activeDeleteContact(data.row.original.id)}>
            { I18n.t("providers.contacts.deleted_list.activate") }
          </p>
        </div>
    },
    {
      Header: I18n.t('providers.contacts.deleted_list.permanent_removal'),
      accessor: '',
      Cell: data =>
        <div>
          <p className="contacts-table__delete-forever-button" onClick={() => handleDeleteLinkClick(data.row.original)}>
            { I18n.t('providers.contacts.deleted_list.delete_permanently') }
          </p>
        </div>
    },
  ],
  [dataTable, activeFilter]
);


//===================================================================================================> CONTACT LOCATIONS


export const ContactLocationsColumns = (contactLocation, currentLocale) => React.useMemo(
  () => [
    {
      Header: ({column}) => {
        return (
          <div className={`table-header`}>
            <span>{ I18n.t("generic.name") }</span>
            <SvgIco
              className={column.isSortedDesc ? ' active' : ''}
              name={column.isSortedDesc ? 'arrow_upward' : 'arrow_downward'}
              size={16} />
          </div>
        )
      },
      accessor: 'name',
      Cell: data =>
        <Link to={`${currentLocale}/new_providers/locations/edit/${data.row.original.location.id}/location`}>
          {data.row.original.location.address}
        </Link>
    },
    {
      Header: ({column}) => {
        return (
          <div className={`table-header`}>
            <span>{ I18n.t("providers.contacts.edit.role") }</span>
            <SvgIco
              className={column.isSortedDesc ? ' active' : ''}
              name={column.isSortedDesc ? 'arrow_upward' : 'arrow_downward'}
              size={16} />
          </div>
        )
      },

      accessor: 'roleTranslated',
      Cell: data =>
        <Link to={`${currentLocale}/new_providers/locations/edit/${data.row.original.location.id}/contacts`}>
          { data.value }
        </Link>,
    },
  ], [contactLocation]
);
