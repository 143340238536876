import React from 'react';
import classNames from "classnames";
import { useRouteMatch } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import { SvgIco } from "../../../SharedComponents/Icons";
import MutableLink from '../../MutableLink';
import { ActionIcon } from "../../ScoreWidget";

import './index.sass';


const ProgressBar = ({ activeSection,
                       editPage,
                       lastAvailableStep,
                       qualityScore,
                       showFacts,
                       stepsData,
                       stepsRefs,
                       validateFields
                     }) => {

  const { url } = useRouteMatch();
  const currentStepRef = stepsRefs[activeSection] || null;
  const validateFunction = currentStepRef ? () => currentStepRef.current.validateStep() : validateFields;

  const scoreIconActive = (step) => {
    if (qualityScore) {
      switch (step) {
        case 'economy':
          return qualityScore.price.score;
        case 'facts':
          return qualityScore.facilities.score && qualityScore.energyRating.score;
        case 'description':
          return qualityScore.description.score;
        case 'visuals':
          return qualityScore
            && qualityScore.floorPlan.score
            && qualityScore.photos.score
            && qualityScore.prospectus.score
            && qualityScore.video.score
            && qualityScore.virtualTour.score;
        default:
          return false
      }
    } else {
      return false;
    }
  };

  const ScoreIcon = ({ stepLink }) =>
    <div className="provider-tooltip score-icon-wrapper"
         data-tip={"This step includes items that can raise the rating of your lease"}
         data-class="provider-tooltip"
         data-for={`score-progress-${stepLink}-tooltip`}>
      <ActionIcon color={scoreIconActive(stepLink) ? '#77BC1F' : ''} />
      <ReactTooltip
        border={true}
        borderColor="black"
        effect='solid'
        id={`score-progress-${stepLink}-tooltip`}
        place='left'
        type="light" />
    </div>;


  return (
    <div className='progress-bar'>
      <div className="progress-bar__background-line" />

      { Object.keys(stepsData).map((item, index) => {

        const step = stepsData[item];
        const isActive = activeSection === step.link;
        const showAsLink = (step.passed || lastAvailableStep === step.link || editPage) && !isActive;
        const wrapperClasses = classNames(`progress-bar__step-wrapper`, {
          'active': isActive,
          'first': index === 0,
          'last': index === Object.keys(stepsData).length - 1,
        });

        if (step.link === 'facts' && !showFacts) return;

        return (
          <MutableLink
            currentStepRef={currentStepRef}
            handleClick={validateFunction}
            key={step.name}
            linkClass="progress-bar__step-link"
            showAsLink={showAsLink}
            stepLink={step.link}
            url={`${url}/${step.link}`}
          >
            <div className={wrapperClasses}>
              <div className="progress-bar__step-number-wrapper">
                { step.passed || editPage
                  ?
                    <div className="progress-bar__step-passed">
                      <SvgIco name={step.starRating ? 'star' : 'check'} size={16} />
                      { step.showScoreIcon && <ScoreIcon stepLink={step.link} /> }
                    </div>
                  :
                    <div className="progress-bar__step-number">
                      <div className="google-translate-helper">
                        { index + 1 }
                      </div>
                      { step.showScoreIcon && <ScoreIcon stepLink={step.link} /> }
                    </div>
                }
              </div>
              <div className="progress-bar__step-name">
                { step.name }
              </div>
            </div>
          </MutableLink>
        )
      })}
    </div>
  )
};


export default ProgressBar;
