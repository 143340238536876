import React, { useEffect, useState } from 'react';

import { SvgIco } from "../../SharedComponents/Icons";

import './InfoMessagePopup.sass';

let timer;


const InfoMessagePopup = ({ children, customClass, customDuration, handleClose, message, toggle, toggleCallback, visible }) => {

  const [animationClass, setAnimationClass] = useState('');

  useEffect(() => {
    setAnimationClass(visible ? 'fadein' : 'fadeout');
    visible && (timer = setTimeout(() => handleClose(), customDuration || 3000));
  }, [visible]);


  useEffect(() => {
    if (toggle) {
      clearTimeout(timer);
      timer = setTimeout(() => handleClose(), customDuration || 3000);
      toggleCallback();
    }
  }, [toggle]);


  const closePopup = () => {
    clearTimeout(timer);
    handleClose();
  };


  return (
    <div className={`notification-popup ${animationClass} ${customClass || ''}`} role="button">
      <div className="notification-popup__close" onClick={closePopup} role="button">
        <SvgIco name="close" size={20} />
      </div>
      { children || <div dangerouslySetInnerHTML={{ __html: message }} /> }
    </div>
  )
}

export default InfoMessagePopup;
