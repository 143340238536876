import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';


import mapPin from '../../src/images/details-page-pin.svg';
import mapStyles from "../../src/stylesheets/map_styles.json";
import { SvgIco } from "../SharedComponents/Icons";


function removeStyles() {
  const gm = document.querySelector('.bottom-map-container .gm-style');
  if (gm) {
    gm.classList.remove('gm-style');
  }
};


const BaseMap = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={props.defaultZoom || 15}
      defaultCenter={props.coordinates}
      onLoaded={removeStyles()}
      options={{
        gestureHandling: 'cooperative',
        fullscreenControl: !props.isFullscreenMobile && !props.isGalleryMobileComponent,
        mapTypeControl: !props.isFullscreenMobile || !props.isGalleryMobileComponent,
        streetViewControl: !props.isFullscreenMobile && !props.isMobile,
        styles: mapStyles,
      }}
    >
      <div className={`dv-gallery-map-fullscreen-header${props.isFullscreenMobile && !props.orientationHorizontalMobile ? ' active' : ''}`}>
        <span>
          { I18n.t('lb_showroom.detailed.asset_box.new_map_button') }
        </span>
        { props.isGalleryMobileComponent &&
          <div className="map-mobile-fullscreen-btn" onClick={props.handleFullscreenMobile}>
            { props.isFullscreenMobile
              ? <SvgIco name="close" size={24} />
              : <SvgIco name="crop_free" size={24} />
            }
          </div>
        }
      </div>
      <Marker icon={{ url: mapPin, scaledSize: { width: 56, height: 56} }} position={props.coordinates} />
      { props.markers }
    </GoogleMap>
  ))
);

export default BaseMap;
