import React, { Component } from 'react';
import BaseMap from './BaseMap';
import { isMobile, isTablet } from "react-device-detect";

function loadingElement() {
  return <div style={{ height: `100%` }} />;
}

class Map extends Component {
  state = {
    isGalleryMobileComponent: false,
    isFullscreenMobile: false,
    orientationHorizontalMobile: false,
  };

  componentDidMount() {
    this.props.isGalleryComponent && isMobile && this.setState({ isGalleryMobileComponent: true });
  }

  handleFullscreenMobile = () => this.setState({ isFullscreenMobile: !this.state.isFullscreenMobile });

  render() {
    const { latitude, longitude , googleApiKey, orientationHorizontalMobile, oldVariant } = this.props;
    const { isFullscreenMobile, isGalleryMobileComponent } = this.state;
    const coordinatesObj = { lat: latitude, lng: longitude };
    const containerClass = `map-container${isFullscreenMobile ? ' fullscreen-mobile' : ''}`;


    return (
      <BaseMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`}
        loadingElement={loadingElement()}
        containerElement={<div className={containerClass} />}
        mapElement={<div className="map-element" />}
        coordinates={coordinatesObj}
        isGalleryMobileComponent={isGalleryMobileComponent}
        isMobile={isMobile && !isTablet}
        handleFullscreenMobile={this.handleFullscreenMobile}
        isFullscreenMobile={isFullscreenMobile}
        orientationHorizontalMobile={orientationHorizontalMobile}
        oldVariant={oldVariant}
      />
    );
  }
}

export default Map;
