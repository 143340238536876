import { gql } from "@apollo/client";

export const GET_CONTACTS = gql`
  query getContacts(
      $orderBy: String
      $orderDirection: String
      $page: Int
      $perPage: Int) {
      contacts(
        page: $page,
        perPage: $perPage,
        orderBy: $orderBy,
        orderDirection: $orderDirection) {
        contacts {
          id
          email,
          login,
          name,
          phoneNumber,
          isDestroyable,
        }
        totalCount
      }
    }
`;


export const GET_LOCATIONS_OPTIONS_LIST = gql`
  query getLocations(
    $perPage: Int=3000,
    $category: String="lease_properties",
    $query: String="") {
    locations(
      category: $category,
      perPage: $perPage,
      query: $query) {
      locations {
        address,
        areaFrom,
        areaUnit,
        id,
      }
    }
  }
`;


export const GET_LOCATIONS_XLS_TOKEN = gql`
  query getLocationsXlsFileToken(
    $perPage: Int,
    $category: String,
    $query: String,
    $locationState: String,
    $orderBy: String,
    $showOnlyContactLocations: Boolean,
    $orderDirection: String,
    $page:  Int,
    $xls: Boolean) {
    locations(
      category: $category,
      perPage: $perPage,
      query: $query,
      locationState: $locationState,
      orderBy: $orderBy,
      showOnlyContactLocations: $showOnlyContactLocations,
      orderDirection: $orderDirection,
      page: $page
      xls: $xls) {
      xlsFileToken
    }
  }
`;


export const GET_ORDERS_XLS_TOKEN = gql`
  query getOrdersXlsFileToken(
    $query: String,
    $enquiryState: String,
    $showOnlyContactOrders: Boolean,
    $perPage: Int,
    $orderBy: String,
    $orderDirection: String,
    $locationId: Int,
    $page:  Int,
    $xls: Boolean) {
    orders(
      query: $query,
      enquiryState: $enquiryState,
      showOnlyContactOrders: $showOnlyContactOrders,
      perPage: $perPage,
      orderBy: $orderBy,
      locationId: $locationId,
      orderDirection: $orderDirection,
      page: $page
      xls: $xls) {
      xlsFileToken
    }
  }
`;


export const GET_STATISTIC_FILE = gql`
  query statisticFile(
    $timeFrom: String!,
    $timeTo: String!,
    $kind: String,
    $type: String,
    ) {
    statisticFile (
      timeFrom: $timeFrom,
      timeTo: $timeTo,
      kind: $kind,
      type: $type)
      {
        downloadFileToken
    }
  }
`;



export const GET_LOCATIONS = gql`
  query getLocations(
      $perPage: Int=20,
      $category: String="lease_properties",
      $query: String="",
      $locationState: String="",
      $orderBy: String="address_line_1",
      $showOnlyContactLocations: Boolean = false,
      $orderDirection: String="asc",
      $page:  Int = 1) {
    locations(
      category: $category,
      perPage: $perPage,
      query: $query,
      locationState: $locationState,
      orderBy: $orderBy,
      showOnlyContactLocations: $showOnlyContactLocations,
      orderDirection: $orderDirection,
      page: $page) {
        locations {
          address,
          areaUnit,
          avaliableEnquiryStates,
          id,
          infoMessage,
          category,
          kind,
          kindTranslated,
          areaFrom,
          fullArea,
          updatedAt,
          primaryContactName,
          photosCount,
          floorPlansCount,
          prospectusCount,
          enquiriesCountInteger,
          notesCount,
          scoreInPercents,
          section,
          state,
          stateInfo,
          stateTranslated,
          providersLocationPhotosUri,
          providersLocationFloorPlansUri,
          providersLocationProspectusUri,
          updatedAtByProviderOnlyFormatted,
        }
        totalCount
    }
  }
`;


export const GET_LOCATIONS_STATISTIC = gql`
  query getLocationsStatistic {
    locations {
      address
      avaliableEnquiryStates
      id
      kind
      areaFrom
      updatedAt
      primaryContactName
      photosCount
      floorPlansCount
      prospectusCount
      enquiriesCountInteger
      notesCount
      scoreInPercents
      state
      providersLocationPhotosUri
      providersLocationFloorPlansUri
      providersLocationProspectusUri
    }
  }
`;


export const GET_ORDERS = gql`
  query getOrders($query: String,
                  $enquiryState: String,
                  $showOnlyContactOrders: Boolean = false,
                  $perPage: Int=20,
                  $orderBy: String="created_at",
                  $orderDirection: String="desc",
                  $locationId: Int=null
                  $page:  Int = 1
                  $xls: Boolean=false){
     orders(query: $query,
            enquiryState: $enquiryState,
            showOnlyContactOrders: $showOnlyContactOrders,
            perPage: $perPage,
            orderBy: $orderBy,
            locationId: $locationId,
            orderDirection: $orderDirection,
            page: $page
            xls: $xls) {
            orders {
              id,
              createdAtFormatted,
              companyName,
              personName,
              phoneNumber,
              email,
              comment,
              section,
              userToken,
              clientNotesCount,
              orderContextTranslated,
              orderContextMessage,
              enquiries,
              clientId,
              clientOrOrderCompanyName,
              clientOrOrderPersonName,
              clientOrOrderPhone,
              clientOrOrderEmail,
             },
           totalCount
    }
  }
`;


export const GET_ORDER = gql`
  query getOrder($id: ID!) {
    order(id: $id) {
      id,
      createdAtFormatted,
      companyName,
      personName,
      phoneNumber,
      email,
      comment,
      section,
      userToken,
      clientNotesCount,
      orderContextTranslated,
      orderContextMessage,
      enquiries,
      clientId,
      clientOrOrderCompanyName,
      clientOrOrderPersonName,
      clientOrOrderPhone,
      clientOrOrderEmail,
    },
  }
`;


export const GET_CLIENT_INFO = gql`
  query getClientInfo($id: ID!){
    order(id: $id) {
      id,
      createdAtFormatted,
      companyName,
      personName,
      phoneNumber,
      email,
      comment,
      userToken,
      clientNotesCount,
      enquiries
    }
  }
`;


export const GET_CLIENT_ORDERS = gql`
  query getClientOrders(
    $clientId: ID!
    $orderBy: String="created_at"
    $orderDirection: String="asc"
    $page: Int= 1
    $perPage: Int=8){
    clientOrders(
      clientId: $clientId
      page: $page,
      perPage: $perPage,
      orderBy: $orderBy,
      orderDirection: $orderDirection) {
      orders {
        id,
        createdAtFormatted,
        locationAddress,
        locationPath,
        locationAreaFormatted,
        locationYearlyRentPerM2FromFormatted,
        locationKindTranslated
      }
      totalCount
    }
  }
`;


export const GET_CLIENT_ENQUIRIES = gql`
  query getClientEnquiries(
    $clientId: ID!
    $orderBy: String="created_at"
    $orderDirection: String="asc"
    $page: Int= 1
    $perPage: Int=20) {
    clientEnquiries(
      page: $page,
      perPage: $perPage,
      orderBy: $orderBy,
      orderDirection: $orderDirection
      clientId: $clientId) {
      orders {
        id
        createdAtFormatted
        companyName
        personName
        phoneNumber
        email
        comment
        section
        userToken
        clientNotesCount
        orderContextTranslated
        orderContextMessage
        enquiries
        clientId
        clientOrOrderCompanyName
        clientOrOrderPersonName
        clientOrOrderPhone
        clientOrOrderEmail
        contextTranslated
      }
      totalCount
    }
  }
`;


export const GET_PROVINCES = gql`
  query getProvinces($countryAlpha2: String!){
    province(countryAlpha2: $countryAlpha2) {
      id,
      name,
    }
  }
`;


export const GET_POSTAL_DISTRICTS = gql`
  query getPostalDistricts($provinceId: ID!){
    postalDistrict(provinceId: $provinceId) {
      id,
      name,
    }
  }
`;


export const GET_LOCATION_INFO = gql`
  query getLocationInfo($id: ID!){
    location(id: $id) {
      address
      annualElectricityCostWithCentsFrom
      annualElectricityCostWithCentsTo
      annualHeatingCostWithCentsFrom
      annualHeatingCostWithCentsTo
      annualOperationalCostWithCentsFrom
      annualOperationalCostWithCentsTo
      annualRentWithCentsFrom
      annualRentWithCentsTo
      annualTotalCostWithCentsFrom
      annualTotalCostWithCentsTo
      annualWaterCostWithCentsFrom
      annualWaterCostWithCentsTo
      areaFrom
      areaTo
      areaUnit
      createdAt
      currencyId
      depositAndPrepaidRent
      description
      disposalPrice
      energyRating
      enquiries
      enquiriesCountInteger
      exemptedFromEnergyRating
      explainLocationIncomplete
      facilityIds
      fixedAnnualOperationalCost
      floorPlans { thumbnailW600 }
      floorPlansCount
      hourlyRentWithCentsFrom
      hourlyRentWithCentsTo
      id
      infoMessage
      initialState
      isComplete
      kind
      latitude
      locationShownDatapoints
      locationUrl
      longitude
      monthlyRentPerSpaceWithCentsFrom
      monthlyRentPerSpaceWithCentsTo
      photos { thumbnailW600 }
      photosCount
      prospectus {
        id
        prospectusPath
        createAssetPath
      }
      prospectusCount
      salePrice
      scoreInPercents
      scoreItemsList
      section
      sectionTranslated
      state
      stateInfo
      stateTranslated
      vimeoId
      virtualTour
      workstationCostPerMonthFrom
      workstationCostPerMonthTo
      yearlyRentPerM2WithCentsFrom
      yearlyRentPerM2WithCentsTo
      yearlyElectricityCostPerM2WithCentsFrom
      yearlyElectricityCostPerM2WithCentsTo
      yearlyHeatingCostPerM2WithCentsFrom
      yearlyHeatingCostPerM2WithCentsTo
      yearlyOperationalCostPerM2WithCentsFrom
      yearlyOperationalCostPerM2WithCentsTo
      yearlyRentalIncome
      yearlyWaterCostPerM2WithCentsFrom
      yearlyWaterCostPerM2WithCentsTo
    }
  }
`;


export const GET_LOCATION_VISUALS = gql`
  query getLocationVisuals($id: ID!){
    location(id: $id) {
      id
      address
      floorPlans {
        id
        thumbnailW120
      }
      photos {
        id
        thumbnailW120
      }
      prospectus {
        id
        prospectusPath
        createAssetPath
        #         thumbnailW120
      }
      prospectusCount
      providerWebsiteLink
      vimeoId
      virtualTour
    }
  }
`;


export const GET_EXTRA_LEAD_PREVIEW = gql`
  query getLocation($id: ID!){
    location(id: $id) {
      address
      areaFrom
      areaTo
      areaUnit
      annualRentWithCentsFrom
      annualRentWithCentsTo
      annualOperationalCostWithCentsFrom
      annualOperationalCostWithCentsTo
      currencyId
      id
      locationUrl
      photos {
        id
        thumbnailW120
        thumbnailW400
      }
      title
    }
  }
`;


export const GET_LOCATION_VALUES = gql`
  query getLocationValues($id: ID!){
    location(id: $id) {
      id
      addressLine1
      addressLine2
      amountOfSpacesFrom
      amountOfSpacesTo
      annualElectricityCostWithCentsFrom
      annualElectricityCostWithCentsTo
      annualHeatingCostWithCentsFrom
      annualHeatingCostWithCentsTo
      annualOperationalCostWithCentsFrom
      annualOperationalCostWithCentsTo
      annualRentWithCentsFrom
      annualRentWithCentsTo
      annualTotalCostWithCentsFrom
      annualTotalCostWithCentsTo
      annualWaterCostWithCentsFrom
      annualWaterCostWithCentsTo
      anonymous
      areaFrom
      areaTo
      auctionNumber
      combinedLocationsAttributes {
        area
        id
        kind
        locationId
        yearlyPricePerM2
      }
      countryAlpha2
      currencyId
      dateOfAuction
      depositAndPrepaidRent
      description
      disposalPrice
      employeesCountFrom
      employeesCountTo
      employeesCountVisible
      enableCombinedLocations
      energyRating
      exemptedFromEnergyRating
      energyCertificateLevelsObject
      existingPrimaryContact { id }
      facilityIds
      fixedAnnualOperationalCost
      hasEnergyCertificateLevels
      hourlyRentWithCentsFrom
      hourlyRentWithCentsTo
      internalIdentifier
      isBuilt
      isRentIncludeOperationalCost
      kind
      latitude
      longitude
      lotArea
      monthlyRentPerSpaceWithCentsFrom
      monthlyRentPerSpaceWithCentsTo
      ownerId
      parkingType
      postalCode
      postalDistrictId
      providerWebsiteLink
      provinceId
      returnRate
      salePrice
      secondaryKinds
      section
      subscriberContactIds
      suitableForKinds
      title
      vimeoId
      virtualTour
      workstationCostPerMonthFrom
      workstationCostPerMonthTo
      workstationsCountFrom
      workstationsCountTo
      yearBuilt
      yearlyElectricityCostPerM2Visible
      yearlyElectricityCostPerM2WithCentsFrom
      yearlyElectricityCostPerM2WithCentsTo
      yearlyHeatingCostPerM2Visible
      yearlyHeatingCostPerM2WithCentsFrom
      yearlyHeatingCostPerM2WithCentsTo
      yearlyOperationalCostPerM2Visible
      yearlyOperationalCostPerM2WithCentsFrom
      yearlyOperationalCostPerM2WithCentsTo
      yearlyRentPerM2AmountFlexible
      yearlyRentPerM2WithCentsFrom
      yearlyRentPerM2WithCentsTo
      yearlyRentalIncome
      yearlyWaterCostPerM2Visible
      yearlyWaterCostPerM2WithCentsFrom
      yearlyWaterCostPerM2WithCentsTo
    }
  }
`;


export const ADD_NEW_CONTACT = gql`
  mutation addContact($input: AddContactMutationInput!) {
    addContact(input: $input) {
      contact {
        name,
        email,
        phoneNumber,
      }
      errors
    }
   }
`;


export const UPDATE_CONTACT = gql`
  mutation updateContact($input: UpdateContactMutationInput!) {
    updateContact(input: $input) {
      contact {
        name,
        email,
        phoneNumber,
      }
      errors
    }
   }
`;


export const GET_DELETED_CONTACTS = gql`
  query getDeleteContact(
    $orderBy: String="name"
    $orderDirection: String="asc"
    $page: Int= 1
    $perPage: Int=20
    $onlyDeleted: Boolean!) {
    contacts(
      orderBy: $orderBy,
      orderDirection: $orderDirection,
      page: $page,
      perPage: $perPage
      onlyDeleted: $onlyDeleted) {
      contacts {
        id,
        name,
        email,
        phoneNumber
      }
      totalCount
    }
  }
`;


export const ACTIVATE_DELETED_CONTACT = gql`
  mutation recoverContact($input: RecoverContactMutationInput!) {
    recoverContact(input: $input) {
      contact {
        id,
        name,
        email
      }
    }
  }
`;


export const HIDE_DELETED_CONTACT = gql`
  mutation hideDeletedContact($input: HideDeletedContactMutationInput!) {
    hideDeletedContact(input: $input) {
      contact {
        id
      }
    }
  }
`;



export const UPDATE_PROVIDER = gql`
  mutation updateProvider($input: UpdateProviderMutationInput!) {
    updateProvider(input: $input) {
      provider {
        city,
        companyName,
        phoneNumber,
      }
    }
  }
`;



export const GET_PROVIDER = gql`
  query getProvider($id: ID!){
    provider(id: $id) {
      id,
      logoUrl,
      companyName,
      vatId,
      ean,
      phoneNumber,
      city,
      line1,
      postalCode,
      line2,
      isMailSubjectIncludeInternalIdentifier,
      smsOrderNotification,
      emailsLocale,
      interfaceLocale,
      countryAlpha2,
      invoicingEmail
    }
  }
`;

export const UPDATE_CLIENT_INFO = gql`
  mutation updateClientData($input: UpdateClientDataMutationInput!) {
    updateClientData(input: $input) {
      clientData {
        personName,
        email,
        phoneNumber,
        companyName,
      }
    }
  }
`;

export const GET_CLIENT_NOTES = gql`
 query clientNotes($clientId: ID!){
    clientNotes(clientId: $clientId) {
      id,
      note,
      createdAtFormatted,
      authorId,
      noteableId,
      author {
        name
      }
    }
  }
`;

export const GET_LOCATION_NOTES = gql`
 query locationNotes($locationId: ID!){
    locationNotes(locationId: $locationId) {
      id,
      note,
      createdAtFormatted,
      authorId,
      noteableId,
      author {
        name
      }
    }
  }
`;

export const ADD_CLIENT_NOTE = gql`
  mutation addClientNote($input: AddClientNoteMutationInput!) {
    addClientNote(input: $input) {
      clientNote {
        note
      }
    }
  }
`;


export const ADD_LOCATION_NOTE = gql`
  mutation addLocationNote($input: AddLocationNoteMutationInput!) {
    addLocationNote(input: $input) {
      locationNote {
        note
      }
    }
  }
`;

export const UPDATE_CLIENT_NOTE = gql`
  mutation updateClientNote($input: UpdateClientNoteMutationInput!) {
    updateClientNote(input: $input) {
      clientNote {
        id,
        note
      }
    }
  }
`;


export const DELETE_CLIENT_NOTE = gql`
  mutation deleteClientNote($input: DeleteClientNoteMutationInput!) {
    deleteClientNote(input: $input) {
        id,
        error
      }
    }
`;


export const GET_STATISTICS = gql`
  query getStatistics($timeFrom: String!, $timeTo: String!, $kind: String = null, $page: Int = 1, $perPage: Int = 10, $query: String=""){
    statistic(timeFrom: $timeFrom, timeTo: $timeTo, kind: $kind, page: $page, perPage: $perPage, query: $query) {
      statisticData {
        countClientEnquiries
        countExtraEnquiries
        countProviderMessages
        countShowcaseOrders
        createdAt
        daysInPeriod
        locationAddress
        locationAreaFrom
        locationFullArea
        locationAreaUnit
        locationCountDaysActive
        locationEventsContactDetailsShownCount
        locationEventsProviderAdvertsShownCount
        locationEventsShownCount
        locationKind
        locationState
        locationId
        locationShownDatapoints
        locationYearlyRentPerM2
        showContactDetailsInFrontend
        currencyId
      },
      statisticRowsCount
    }
  }
`;


export const GET_LOCATION_STATISTICS = gql`
  query getLocationStatistics($timeFrom: String!, $timeTo: String!, $id: ID!){
    statisticForLocation(timeFrom: $timeFrom, timeTo: $timeTo, id: $id) {
        countClientEnquiries,
        countClientEnquiriesPerWeek,
        countExtraEnquiries,
        countExtraEnquiriesPerWeek,
        countProviderMessages,
        countShowcaseOrders,
        locationEventsContactDetailsShownCount,
        locationEventsProviderAdvertsShownCount,
        locationEventsShownCount,
        locationEventsShownCountPerWeek,
    }
  }
`;


export const GET_STATISTICS_TOTAL = gql`
  query getStatisticsTotal($timeFrom: String!, $timeTo: String!, $kind: String = null, $page: Int = 1, $perPage: Int = 20, $query: String=""){
    statisticTotal(timeFrom: $timeFrom, timeTo: $timeTo, kind: $kind, page: $page, perPage: $perPage, query: $query) {
        countClientEnquiries
        countExtraEnquiries
        countProviderMessages
        countShowcaseOrders
        locationEventsContactDetailsShownCount
        locationEventsProviderAdvertsShownCount
        locationEventsShownCount
    }
  }
`;


export const GET_CONTACT_INFO = gql`
  query contact($id: ID!) {
      contact(id: $id) {
        name,
        email,
        phoneNumber,
        photoUrl,
        isDestroyable,
      }
    }
`;


export const GET_LOCATIONS_REPORT_DEAL = gql`
  query getLocations(
      $query: String="",
      $category: String="lease_properties",
      $locationState: String="",
      $perPage: Int!
      $page: Int!
      $orderBy: String="address_line_1") {
    locations(
      query: $query,
      category: $category,
      locationState: $locationState,
      perPage: $perPage,
      page: $page,
      orderBy: $orderBy) {
        locations {
          id,
          addressLine1,
          addressLine2,
          address,
          areaUnit,
          areaFrom,
          areaTo,
          internalIdentifier,
          kind,
          kindTranslated,
          oldFullAddress,
          postalCode,
          state,
          stateTranslated,
        }
        totalCount
    }
  }
`;


export const GET_LOCATION_REPORT_DEAL = gql`
  query getLocation($id: ID!){
    location(id: $id) {
      id,
      addressLine1,
      addressLine2,
      address,
      areaUnit,
      postalCode,
      kind,
      kindTranslated,
      areaFrom,
      areaTo,
      state,
      stateTranslated,
      oldFullAddress,
    }
  }
`;


export const GET_CURRENCY = gql`
  query getCurrency($locationId: ID) {
    currencies(locationId: $locationId) {
      id,
      name
    }
  }
`;


export const GET_ORDERS_REPORT_DEAL = gql`
  query getOrdersReportDeal($query: String, $perPage: Int!, $page: Int!) {
    orders(query: $query, showOnlySent: false, showOnlyUniqClients: true, enquiryState: "all", orderBy: "created_at", orderDirection: "desc", page: $page, perPage: $perPage) {
      orders{
        id,
        companyName
        personName,
        email,
        clientId
      }
      totalCount
    }
  }
`;


export const GET_CONTACT_LOCATIONS = gql`
  query getContactLocations($orderDirection: String = "asc", $orderBy: String="address_line_1") {
   contactLocations(orderDirection: $orderDirection, orderBy: $orderBy) {
    location {
        id,
        address
      },
      roleTranslated
    }
  }
`;


export const GET_TOKENIZED_FILE = gql`
  query tokenizedFile($token: String!) {
    tokenizedFile(token: $token) {
      file
      fileUrl
    }
  }
`;


export const GET_EXTRA_LEAD_LOCATION_ID = gql`
  query getOrder($id: ID!) {
    order(id: $id) {
      location { id }
    }
  }
`;


export const GET_LOCATION_COPY_JOB = gql`
  query locationCopyJob($id: ID!) {
    locationCopyJob(id: $id) {
      duplicateLocationId
      state
    }
  }
`;


export const GET_UNRESOLVED_PROVIDER_DEALS = gql`
  query getUnresolvedProviderDeals {
    unresolvedProviderDeals {
      address
      clientEmail
      clientId
      companyName
      createdAtFormatted
      id
      locationId
      personName
      phoneNumber
      orderId
    }
  }
`;


export const GET_SERVER_SIDE_SESSIONS = gql`
  query getServerSideSessions {
    serverSideSessions {
      browserName
      city
      country
      createdAt
      data
      id
      isCurrent
      osName
      osVersion
      remoteIp
      updatedAt
    }
  }
`;


export const GET_STRIPE_INVOICES = gql`
  query getStripeInvoices {
    stripeInvoices {
      amountInCents
      currency { name }
      description
      id
      invoiceFile
      invoiceFileUploadedIn
      invoicePdfShowPath
      status
    }
  }
`;


export const GET_STRIPE_INTENT = gql`
  query getStripeIntent($id: ID!) {
    stripeIntent(id: $id) {
      clientSecret
      stripeInvoice {
        amountInCents
        currency { name }
      }
    }
  }
`;


export const GENERATE_LOCATION_DESCRIPTION = gql`
  query generateLocationDescription($locationId: ID!, $locale: String!){
    locationChatGptDescription(locationId: $locationId, locale: $locale) {
      id,
      text,
    }
  }
`;


export const GENERATE_LOCATION_TITLE = gql`
  query generateLocationTitle($locationId: ID!, $locale: String!){
    locationChatGptTitle(locationId: $locationId, locale: $locale) {
      id,
      text,
    }
  }
`;



export const GENERATE_NEW_LOCATION_DESCRIPTION = gql`
  query generateNewLocationDescription($attributes: LocationAttributes!, $locale: String!){
    newLocationChatGptDescription(attributes: $attributes, locale: $locale) {
      id, text
    }
  }
`;


export const GENERATE_NEW_LOCATION_TITLE = gql`
  query generateNewLocationTitle($attributes: LocationAttributes!, $locale: String!){
    newLocationChatGptTitle(attributes: $attributes, locale: $locale) {
      id, text
    }
  }
`;

