import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ADD_NEW_CONTACT, ACTIVATE_DELETED_CONTACT, UPDATE_CONTACT } from '../ApolloClient/queries';
import { ContactsColumns, DeletedContactsColumns } from '../Table/Columns';
import CreateNewContactModal from '../Modals/CreateNewContactModal';
import DeleteContactModal from '../Modals/DeleteContactModal';
import DeleteContactForeverModal from "../Modals/DeleteContactForeverModal";
import InfoMessagePopup from '../Popups/InfoMessagePopup';
import Table from '../Table';

import '../Table/TablePage.sass';
import './ContactsTable.sass';


function ContactsTable({
  currentLocale,
  dataTable,
  deletedTotalCount,
  initialLoad,
  asPreview,
  contactsLoading,
  contactsTotalCount,
  currentPage,
  perPage,
  setPage,
  setPerPage,
  showDeletedContacts,
  totalCount,
  orderDirection,
  setOrderBy,
  activeFilter,
  changeActiveTab,
}) {

  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteContactForeverModal, setShowDeleteContactForeverModal] = useState(false);
  const [modalData, setDataForModal] = useState({});
  const [infoMessagePopupIsOpen, setInfoMessagePopupIsOpen] = useState(false);
  const [infoPopupMessage, setInfoPopupMessage] = useState('');
  const [showNotification, setSnowNotification] = useState({
    show: false,
    message: '',
    color: 'green',
  });

  const [updateContact] = useMutation(UPDATE_CONTACT);
  const [addNewContact] = useMutation(ADD_NEW_CONTACT);
  const [activeDeletedContact] = useMutation(ACTIVATE_DELETED_CONTACT);

  const activeDeleteContact = (currentId) => {
    const input = { id: parseInt(currentId) };

    activeDeletedContact({
      variables: { input },
      refetchQueries: ['getContacts', 'getDeleteContact'],
    });
  };

  const editModal = Object.keys(modalData).length !== 0;

  const contactFormSubmit = (values) => {
    const { name, email, phoneNumber, password, passwordConfirmation } = values;
    const id = editModal ? parseInt(modalData.id) : null;
    const input = editModal
      ? { name, email, phoneNumber, id }
      : { name, email, phoneNumber, password, passwordConfirmation };

    editModal
      ? updateContact({
          variables: { input },
          refetchQueries: ['getContacts'],
          onCompleted: ({ updateContact }) => {
            const { errors } = updateContact;
            const successEdit = I18n.t('providers.contacts.list.contact_edit_successfully');

            showNotifications(errors, successEdit);
          },
        })
      : addNewContact({
          variables: { input },
          refetchQueries: ['getContacts'],
          onCompleted: ({ addContact }) => {
            const { errors } = addContact;
            const successCreate = I18n.t('providers.contacts.list.created_new_contact');

            showNotifications(errors, successCreate);
          },
        });
  };

  const showNotifications = (errors, successMessage) => {
    if (errors && errors.length) {
      setSnowNotification({
        show: true,
        message: I18n.t('providers.contacts.list.contact_email_already_exists'),
        color: 'red',
      });
    } else {
      setShowModal(false);
      setDataForModal({});
      setInfoPopupMessage(successMessage);
      setInfoMessagePopupIsOpen(true);
    }
  };

  const closeModal = () => {
    handleCloseNotification();
    setShowModal(false);
    setDataForModal({});
  };

  const handleCloseNotification = () => {
    setSnowNotification({ show: false, message: '' });
  };

  const closeInfoMessagePopup = () => {
    setInfoMessagePopupIsOpen(false);
  };

  const clickOnDropDown = (data, editModal) => {
    setDataForModal(data);
    editModal ? setShowModal(true) : setShowDeleteModal(true);
  };

  const clickOnDeleteForever = (data) => {
    setDataForModal(data);
    setShowDeleteContactForeverModal(true);
  };

  const columns = showDeletedContacts
    ?
      DeletedContactsColumns(
        dataTable,
        clickOnDeleteForever,
        activeDeleteContact,
        orderDirection,
        setOrderBy,
        activeFilter)
    :
      ContactsColumns(
        dataTable,
        clickOnDropDown,
        orderDirection,
        setOrderBy,
        activeFilter);


  const perPageOptions = [
    { value: '20', label: '20' },
    { value: '30', label: '30' },
  ];


  return (
    <div className={`contacts-table ${asPreview ? 'preview' : ''}`}>
      <div className="contacts-table__button-wrapper">
        <div
          className="table-page__title"
          style={{ justifyContent: asPreview ? 'flex-start' : 'space-between' }}
        >
          <div className="contacts-table__tabs">
            <p
              onClick={() => changeActiveTab(false)}
              className={`${!showDeletedContacts ? 'active' : ''}`}
            >
              {I18n.t('layouts.providers.navigation.contacts')} (
              {contactsTotalCount})
            </p>
            <p
              onClick={() => changeActiveTab(true)}
              className={`${showDeletedContacts ? 'active' : ''}`}
            >
              {I18n.t('providers.contacts.deleted_list.deleted_contacts')} (
              {deletedTotalCount})
            </p>
          </div>
          <div
            className="contacts-table__new-contact-button"
            onClick={() => setShowModal(true)}
          >
            <p>+ {I18n.t('providers.contacts.list.new_contact')}</p>
          </div>
        </div>
        {asPreview && (
          <Link
            to={`${currentLocale}/new_providers/contacts`}
            className="table-page__see-all-link"
          >
            <span>{I18n.t('provider.tables.see_all_link')}</span>
          </Link>
        )}
      </div>

      <InfoMessagePopup
        handleClose={closeInfoMessagePopup}
        message={infoPopupMessage}
        visible={infoMessagePopupIsOpen}
      />

      { modalData &&
        <DeleteContactModal
          callback={() => setDataForModal({})}
          data={modalData}
          handleClose={() => setShowDeleteModal(false)}
          showModal={showDeleteModal}
        />
      }

      { showDeleteContactForeverModal &&
        <DeleteContactForeverModal
          callback={() => setDataForModal({})}
          data={modalData}
          handleClose={() => setShowDeleteContactForeverModal(false)}
          showModal={showDeleteContactForeverModal}
        />
      }

      <CreateNewContactModal
        closeModal={closeModal}
        showModal={showModal}
        submitForm={contactFormSubmit}
        showNotification={showNotification}
        handleCloseNotification={handleCloseNotification}
        editContactData={modalData}
        editModal={editModal}
      />

      <Table
        data={dataTable}
        name="contacts"
        columns={columns}
        perPage={perPage}
        setPage={setPage}
        setPerPage={setPerPage}
        currentPage={currentPage}
        perPageOptions={perPageOptions}
        initialLoad={initialLoad}
        hideBottomFilters={!!asPreview}
        loading={contactsLoading}
        totalCount={totalCount}
      />
    </div>
  );
}

ContactsTable.propTypes = {
  dataTable: PropTypes.oneOfType([PropTypes.object]),
  deletedTotalCount: PropTypes.string,
  initialLoad: PropTypes.bool,
  asPreview: PropTypes.bool,
  contactsLoading: PropTypes.bool,
  contactsTotalCount: PropTypes.string,
  currentPage: PropTypes.string,
  perPage: PropTypes.string,
  setPage: PropTypes.func,
  setPerPage: PropTypes.func,
  showDeletedContacts: PropTypes.bool,
  totalCount: PropTypes.string,
  orderDirection: PropTypes.string,
  setOrderBy: PropTypes.func,
  activeFilter: PropTypes.string,
  changeActiveTab: PropTypes.func,
};

ContactsTable.defaultProps = {
  dataTable: {},
  deletedTotalCount: '',
  initialLoad: false,
  asPreview: false,
  contactsLoading: false,
  contactsTotalCount: '',
  currentPage: '',
  perPage: '',
  setPage: () => true,
  setPerPage: () => true,
  showDeletedContacts: false,
  totalCount: '',
  orderDirection: '',
  setOrderBy: () => true,
  activeFilter: '',
  changeActiveTab: () => true,
};

export default ContactsTable;
