import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import NavigationButtons from '../LocationForm/NavigationButtons';
import ReportSuccessModal from '../Modals/ReportADealSuccess';
import { GET_CURRENCY } from '../ApolloClient/queries';
import DetailsReportForm from './DetailsReportForm';

import './Details.sass';


function Details({ step,
                   title,
                   description,
                   backButton,
                   prevStep,
                   submitHandler,
                   showModal,
                   siteName,
                   locationId,
                   schemaName,
                   selectedLocationStatus,
                   siteKey,
                   providerId,
                   providerOnAdvertisingStrategy,
                   currentLocale }) {

  const [currencyList, setCurrencyList] = useState([]);

  const defaultCurrency = [
    { value: '3', label: 'EUR' },
    { value: '77', label: 'USD' },
  ];

  const { data } = useQuery(GET_CURRENCY, {
    variables: { locationId }
  });

  useEffect(() => {
    if (data) {
      const currencyFormat = data.currencies.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setCurrencyList(currencyFormat);
    } else {
      setCurrencyList(defaultCurrency);
    }
  }, [data]);


  return (
    <div className="details-block">
      <ReportSuccessModal
        currentLocale={currentLocale}
        showModal={showModal}
        siteKey={siteKey} />

      <DetailsReportForm
        currencyList={currencyList}
        description={description}
        locationId={locationId}
        providerId={providerId}
        providerOnAdvertisingStrategy={providerOnAdvertisingStrategy}
        schemaName={schemaName}
        selectedLocationStatus={selectedLocationStatus}
        siteName={siteName}
        step={step}
        submitHandler={submitHandler}
        title={title}
      />

      <div>
        <NavigationButtons handleBack={backButton ? () => prevStep() : false} />
      </div>
    </div>
  );
}

Details.propTypes = {
  step: PropTypes.number,
  prevStep: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  backButton: PropTypes.bool,
  siteKey: PropTypes.string,
  providerOnAdvertisingStrategy: PropTypes.bool,
  siteName: PropTypes.string,
  showModal: PropTypes.bool,
  submitHandler: PropTypes.func,
  locationId: PropTypes.string,
};

Details.defaultProps = {
  step: 1,
  prevStep: () => true,
  title: '',
  description: '',
  backButton: false,
  siteKey: '',
  providerOnAdvertisingStrategy: false,
  siteName: '',
  showModal: false,
  submitHandler: () => true,
  locationId: '',
};

export default Details;
