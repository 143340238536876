import React from 'react';

import ProviderModal from "./ProviderModal";

import './BaseModal.sass';


const BaseModal = ({ confirmButtonText,
                     handleClose,
                     hideReject,
                     modalIsOpen,
                     onConfirmClick,
                     onRejectClick,
                     rejectButtonText,
                     textContent }) => {

  return (
    <ProviderModal modalClass="base-modal"
                   handleClose={handleClose}
                   showModal={modalIsOpen}>

      <p>{ textContent }</p>

      <div className="base-modal__buttons-wrapper">
        { !hideReject &&
          <div className="base-modal__reject-button" onClick={onRejectClick}>
            { rejectButtonText }
          </div>
        }

        <div className="base-modal__confirm-button" onClick={onConfirmClick}>
          { confirmButtonText }
        </div>

      </div>
    </ProviderModal>
    )
};


export default BaseModal;
