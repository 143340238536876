import React from 'react';

import SslSecureLogo from '../../../src/images/logos/ssl-secure-encryption.svg';

import './index.sass'


const Footer = ({ footer, providerInfo, currentContactInfo }) => {

  const { addressLine,
          copyrightUrl,
          privacyUrl,
          supportUrl,
          sslLink,
          postalCode,
          contactInfoPhone,
          contactInfoMail,
          logoUrl,
          hotline,
          showCvrCode,
          cvrCode,
          root,
          socialLinks } = footer;

  const {
    companyName,
    line1: providerLine1,
    line2: providerLine2,
    city: providerCity,
    vatId: providerVatId,
    ean: providerEan,
    phoneNumber: providerPhone,
  } = providerInfo;

  const {
    name: currentContactName,
    email: currentPhoneNumber,
    phoneNumber: currentContactEmail
  } = currentContactInfo


  return (
    <footer className="provider-footer">
      <div className="container">
        <div className="provider-footer__info row">

          <div className="provider-footer__contacts col-xl-3 col-lg-3">
            <a href={root} className="provider-footer__logo">
              <img src={logoUrl} alt="Logo" className="lazyload" id="logo-image" data-sizes="auto" width={140} />
            </a>
            <p>{ addressLine }</p>
            <p>{ postalCode }</p>
            <p>{ I18n.t('generic.email') }: <a href={"mailTo:" + contactInfoMail} >{ contactInfoMail }</a></p>
            { contactInfoPhone && <p>{ I18n.t('generic.phone') } { contactInfoPhone }</p> }
            { hotline && <p>{ I18n.t('generic.hotline') } { hotline }</p> }
            { showCvrCode && <p>{ cvrCode }</p> }
          </div>

          <div className="provider-footer__col col-xl-3 col-lg-3">
            <h3>{I18n.t('layouts.providers.bottom.your_company')}</h3>
            <p>{ companyName }</p>
            <p>{ providerLine1 }</p>
            { providerLine2 && <p>{ providerLine2 }</p> }
            <p>{ providerInfo.postalCode } { providerCity }</p>
            { providerVatId && <p>{ I18n.t('generic.vat') }: { providerVatId }</p> }
            { providerEan && <p>{ I18n.t('activerecord.attributes.provider.ean') }: { providerEan }</p> }
            <p>{ I18n.t('layouts.providers.bottom.phone_number') }: { providerPhone }</p>
          </div>

          <div className="provider-footer__col col-xl-2 col-lg-2 offset-1">
            <h3>{ I18n.t('layouts.providers.bottom.logged_in_as') }</h3>
            <p>{ currentContactName }</p>
            <p>{ currentPhoneNumber }</p>
            <p>{ currentContactEmail }</p>
          </div>

          <div className="col-xl-3 col-lg-3">
            <div className="provider-footer__social-links">
              <a href={sslLink}>
                <img src={SslSecureLogo} alt="SSL secured" className="lazyload global-footer__ssl-img" height={44} width={134}/>
              </a>
              <ul className="provider-footer__social-buttons-list">
                { socialLinks.map(link =>
                  <li className="provider-footer__social-link" key={link[0]}>
                    <a href={link[1]} className={`provider-footer__social-link--${link[0]}`}/>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="provider-footer__bottom-links">
        <div className="container">
          <a href={supportUrl}>{ I18n.t('layouts.responsive.bottom.help_link') }</a>
          <a href={copyrightUrl} className="global-footer__copyright-link">
            Copyright { new Date().getFullYear() }
          </a>
          <a href={privacyUrl} className="provider-footer__privacy-link">
            <img src={require('../../../src/images/icons/cookie-badge.svg')} alt="cookies" width="15px" height="13.5px" />
            { I18n.t('cookie_consent.privacy_policy') }
          </a>
        </div>
      </div>
    </footer>
  );
}


export default Footer;
