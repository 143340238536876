import React from 'react';

import AnonymousBannerImg from '../../../src/images/provider/anonymous-banner.svg';

import './AnonymousBanner.sass';


const AnonymousBanner = () => {

  return (
    <div className="anonymous-banner">
      <img src={AnonymousBannerImg} alt="anonymous" height={78} width={139} />
      <div className="anonymous-banner__content">
        <div className="anonymous-banner__headline">
          { I18n.t('layouts.providers.navigation.anonymous') }
        </div>
        <p>{ I18n.t('views.providers.locations.anonymous.on_deal_notification') }</p>
      </div>
    </div>
  )
}

export default AnonymousBanner;
