import React from 'react';

import './InputBanner.sass';


const InputBanner = ({ additionalClass, buyNowLink, content, headline, readMoreLink }) => {
  return (
    <div className={`input-banner ${additionalClass || ''}`}>
      <div className="input-banner__content-wrapper">
        <div className="input-banner__headline">
          { headline }
        </div>
        <div className="input-banner__content">
          { content }
        </div>
      </div>

        <div className="input-banner__buttons">
          { readMoreLink &&
            <a className="input-banner__read-more" href={readMoreLink} target='_blank'>
              { I18n.t('generic.read_more') }
            </a>
          }

          { readMoreLink && buyNowLink &&
            <div className="input-banner__buttons-separator">
              { I18n.t('generic.or') }
            </div>
          }

          { buyNowLink }
        </div>
    </div>
  )
}

export default InputBanner;
