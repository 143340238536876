import React, { useState, useMemo } from 'react';
import { useMutation } from "@apollo/client";
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import Dropdown from 'react-dropdown';
import Yup from 'yup';

import { requiredFieldError } from '../../SharedComponents/Forms/ErrorMessages';
import { SvgIco } from '../../SharedComponents/Icons';
import { ADD_INCOMPLETE_DEAL_NOTIFICATION } from "../ApolloClient/mutations";


function DetailsReportFormWrapper({ currencyList,
                                    description,
                                    errors,
                                    handleBlur,
                                    handleChange: formikHandleChange,
                                    handleSubmit,
                                    locationId,
                                    providerId,
                                    schemaName,
                                    selectedLocationStatus,
                                    setFieldValue,
                                    siteName,
                                    step,
                                    title,
                                    touched,
                                    values,
                                  }) {

  const [isSubmited, setIsSubmited] = useState(false);
  const [incompleteDealSent, setIncompleteDealSent] = useState(false);


  const errorsArray = useMemo(
    () => Object.keys(errors).filter((item) => touched[item] || isSubmited),
    [errors, isSubmited, touched]
  );

  const [addIncompleteDealNotification] = useMutation(ADD_INCOMPLETE_DEAL_NOTIFICATION);


  const handleChange = (e) => {
    formikHandleChange(e);

    if (locationId && !incompleteDealSent) {
      setIncompleteDealSent(true);
      addIncompleteDealNotification({
        variables: { providerId: Number(providerId), locationId: Number(locationId) },
      }).catch(error => console.log('Error', error));
    }
  };


  return (
    <div>
      { errorsArray.length > 0 &&
        <div className="validation-message-wrapper">
          <p>{ I18n.t('reports.providers_order_report.fix_these_errors') }</p>
          <ul>
            { errorsArray.map(item => <li className="validation-message">{errors[item]}</li>)}
          </ul>
        </div>
      }

      <h3 className="details-block__title">
        { step }. {title}{' '}
        <span className="details-block__title-description">{ description }</span>
      </h3>

      <div className="deal-report">
        <form name="deal-report" onSubmit={handleSubmit} noValidate>

          { (schemaName === 'dk' || schemaName === 'default') &&
            <div className="deal-report__line-wrapper">

              <p className="deal-report__label">
                { I18n.t('activerecord.attributes.lease.area_rented') }:{' '}
              </p>

              <div className="deal-report__input-wrapper">
                <input
                  name="areaRented"
                  value={values.areaRented}
                  type="number"
                  id="areaRented"
                  className={`deal-report__input ${ errorsArray.includes('areaRented') ? 'error' : ''} `}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>

              { schemaName === 'dk' &&
                <div>
                  <div className="deal-report__checkbox-wrapper">
                    <label className="checkbox-label" htmlFor="areaRentedDontKnow">
                      <input
                        name="areaRentedDontKnow"
                        id="areaRentedDontKnow"
                        type="checkbox"
                        checked={values.areaRentedDontKnow}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />

                      <span className="checkmark">
                    <SvgIco name="check" size={17} />
                  </span>

                      <div className="checkbox-label">
                        { I18n.t('activerecord.attributes.lease.currently_unknown') }
                      </div>
                    </label>
                  </div>
                </div>
              }
            </div>
          }


          { schemaName === 'default' &&
            <div className="deal-report__line-wrapper">

              <p className="deal-report__label">
                { I18n.t('generic.number_of_desks') }:{' '}
              </p>

              <div className="deal-report__input-wrapper">
                <input
                  name="numberOfDesks"
                  value={values.numberOfDesks}
                  type="number"
                  id="numberOfDesks"
                  className={`deal-report__input ${ errorsArray.includes('areaRented') ? 'error' : ''} `}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          }


          <div className="deal-report__line-wrapper">

            <p className="deal-report__label">
              { schemaName === 'dk'
                ? I18n.t('activerecord.attributes.deal.occupied_at')
                : schemaName === 'meetingRoom'
                ? I18n.t('report_deal.booking_date')
                : I18n.t('report_deal.start_date')
              }:{' '}
            </p>

            <div>
              <input
                name="occupiedAt"
                value={values.occupiedAt}
                type="date"
                id="occupiedAt"
                className={`deal-report__input ${ errorsArray.includes('occupiedAt') ? 'error' : ''} `}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              />
            </div>

            { schemaName === 'dk' &&
              <div>
                <div className="deal-report__checkbox-wrapper">
                  <label className="checkbox-label" htmlFor="occupiedAtDontKnow">
                    <input
                      name="occupiedAtDontKnow"
                      id="occupiedAtDontKnow"
                      type="checkbox"
                      checked={values.occupiedAtDontKnow}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <span className="checkmark">
                    <SvgIco name="check" size={17} />
                  </span>

                    <div className="checkbox-label">
                      { I18n.t('activerecord.attributes.lease.currently_unknown') }
                    </div>
                  </label>
                </div>
              </div>
            }
          </div>


          { (schemaName === 'default' || schemaName === 'virtualOffice') &&
            <div className="deal-report__line-wrapper">

              <p className="deal-report__label">
                { I18n.t('report_deal.months_duration') }:{' '}
              </p>

              <div className="deal-report__input-wrapper">
                <input
                  name="monthsCount"
                  value={values.monthsCount}
                  type="number"
                  id="monthsCount"
                  className={`deal-report__input ${ errorsArray.includes('monthsCount') ? 'error' : ''} `}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          }


          { schemaName === 'dk' &&
            <div className="deal-report__line-wrapper">

              <p className="deal-report__label">
                { I18n.t('activerecord.attributes.lease.expected_end') }:{' '}
              </p>

              <div>
                <input
                  name="expectedEnd"
                  type="date"
                  value={values.expectedEnd}
                  id="expectedEnd"
                  className="deal-report__input"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
              </div>

              <div>
                <div className="deal-report__checkbox-wrapper">
                  <label className="checkbox-label" htmlFor="expectedEndDontKnow">
                    <input
                      name="expectedEndDontKnow"
                      id="expectedEndDontKnow"
                      type="checkbox"
                      checked={values.expectedEndDontKnow}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <span className="checkmark">
                    <SvgIco name="check" size={17} />
                  </span>

                    <div className="checkbox-label">
                      { I18n.t('activerecord.attributes.lease.currently_unknown') }
                    </div>
                  </label>
                </div>
              </div>
            </div>
          }


          <div className="deal-report__line-wrapper">

            { schemaName === 'meetingRoom'
              ?
                <p className="deal-report__label">
                  { I18n.t('generic.price') }:{' '}
                </p>
              :
                <p className="deal-report__label">
                  { schemaName === 'dk'
                    ? I18n.t('activerecord.attributes.deal.total_amount_rent_first_part')
                    : I18n.t('generic.monthly_rent')
                  }
                  <br />{' '}
                  <span className="deal-report__label-description">
                    { I18n.t('activerecord.attributes.deal.total_amount_rent_second_part') }:
                  </span>
                </p>
            }

            <div>
              {schemaName === 'dk' || schemaName === 'meetingRoom'
                ?
                <input
                  name="totalAmount"
                  value={values.totalAmount}
                  id="totalAmount"
                  type="number"
                  className={`deal-report__input ${errorsArray.includes('totalAmount') ? 'error' : ''} `}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required={schemaName === 'dk'}
                />
                :
                <input
                  name="monthlyRent"
                  value={values.monthlyRent}
                  id="monthlyRent"
                  type="number"
                  className={`deal-report__input ${errorsArray.includes('monthlyRent') ? 'error' : ''} `}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              }
            </div>

            { schemaName === 'dk' &&
              <div>
                <div className="deal-report__checkbox-wrapper">
                  <label className="checkbox-label" htmlFor="totalAmountDontKnow">
                    <input
                      name="totalAmountDontKnow"
                      id="totalAmountDontKnow"
                      type="checkbox"
                      checked={values.totalAmountDontKnow}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    <span className="checkmark">
                    <SvgIco name="check" size={17} />
                  </span>

                    <div className="checkbox-label">
                      { I18n.t('activerecord.attributes.lease.currently_unknown') }
                    </div>
                  </label>
                </div>
              </div>
            }

            { schemaName !== 'dk' &&
              <div>
                <Dropdown
                  options={currencyList}
                  onChange={(value) => setFieldValue('currencyId', value)}
                  value={values.currencyId}
                  arrowClosed={<SvgIco name="expand-more" size={24} />}
                  arrowOpen={<SvgIco name="expand-less" size={24} />}
                  controlClassName="currency-wrapper"
                />
              </div>
            }
          </div>

          { schemaName === 'dk' &&
            <div className="deal-report__line-wrapper">
              <p className="deal-report__label">
                { I18n.t('layouts.lokalebasen_layout.localization_picker.choose_currency') }
                :{' '}
              </p>

              <div>
                <Dropdown
                  options={currencyList}
                  onChange={(value) => setFieldValue('currencyId', value)}
                  value={values.currencyId}
                  arrowClosed={<SvgIco name="expand-more" size={24} />}
                  arrowOpen={<SvgIco name="expand-less" size={24} />}
                  controlClassName="currency-wrapper"
                />
              </div>
            </div>
          }


          { (!selectedLocationStatus || selectedLocationStatus !== 'closed') &&
            <div className="deal-report__line-wrapper">
              <div className="deal-report__checkbox-wrapper">
                <label className="checkbox-label" htmlFor="deactivateLocation">
                  <input
                    name="deactivateLocation"
                    type="checkbox"
                    checked={values.deactivateLocation}
                    id="deactivateLocation"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />

                  <span className="checkmark">
                    <SvgIco name="check" size={17} />
                  </span>

                  <div className="checkbox-label deactivate-text">
                    { I18n.t('activerecord.attributes.lease.deactivate_location', { site_name: siteName })}
                  </div>
                </label>
              </div>
            </div>
          }

          <div className="row">
            <div className="col-md-6">
              <h3 className="deal-report__comment-title">
                4. {I18n.t('generic.comment')}
              </h3>
              <textarea
                id="comment"
                name="comment"
                value={values.comment}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={
                  schemaName === 'dk'
                  ? I18n.t('reports.providers_order_report.add_comment_placeholder')
                  : schemaName === 'meetingRoom'
                  ? I18n.t('reports.providers_order_report.add_comment_meeting_room')
                  : I18n.t('reports.providers_order_report.add_comment_default')
                }
              />

              <div>
                <button
                  type="submit"
                  className="deal-report__approve"
                  onClick={() => setIsSubmited(true)}>
                  { I18n.t('admin.provider_deals.provider_deal.approve') }
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};


const DetailsReportForm = withFormik(
  {
  enableReinitialize: true,
  mapPropsToValues: ({ currencyList, providerOnAdvertisingStrategy, schemaName }) => ({
    comment: '',
    areaRentedDontKnow: false,
    occupiedAtDontKnow: false,
    expectedEndDontKnow: true,
    totalAmountDontKnow: false,
    deactivateLocation: false,
    isNotDk: schemaName !== 'dk',
    isDefault: schemaName === 'default',
    currencyId: currencyList[0],
    providerStrategy: providerOnAdvertisingStrategy,
  }),

  validationSchema: Yup.object().shape({
    areaRentedDontKnow: Yup.boolean(),
    occupiedAtDontKnow: Yup.boolean(),
    expectedEndDontKnow: Yup.boolean(),
    totalAmountDontKnow: Yup.boolean(),
    deactivateLocation: Yup.boolean(),
    providerStrategy: Yup.boolean(),
    isNotDk: Yup.boolean(),
    isDefault: Yup.boolean(),
    areaRented: Yup.string()
      .when(['areaRentedDontKnow', 'isNotDk', 'providerStrategy'], {
        is: false,
        then: Yup.string().required(requiredFieldError(I18n.t('providers.leases.new.area_rented_error'))),
      })
      .test('test', I18n.t('report_deal.error_required_fields_one_of'), function(value){
        return !this.parent.isDefault || (!!this.parent.numberOfDesks || value);
      }),
    occupiedAt: Yup.date()
      .when(['occupiedAtDontKnow', 'isNotDk', 'providerStrategy'], {
        is: false,
        then: Yup.date().required(requiredFieldError(I18n.t('providers.leases.new.occupied_rented_error'))),
      }),
    expectedEnd: Yup.date(),
    totalAmount: Yup.string()
      .when(['totalAmountDontKnow', 'isNotDk', 'providerStrategy'], {
        is: false,
        then: Yup.string().required(requiredFieldError(I18n.t('providers.leases.new.totalamout_rented_error'))),
      }
    ),
    monthsCount: Yup.string(),
    monthlyRent: Yup.string(),
    numberOfDesks: Yup.string(),
  }),

  handleSubmit: (values, bag) => {
    bag.props.submitHandler(values);
  },

  displayName: 'Report Deal Details',
}
)(DetailsReportFormWrapper);




DetailsReportFormWrapper.propTypes = {
  handleSubmit: PropTypes.func,
  values: PropTypes.oneOfType([PropTypes.object]),
  handleChange: PropTypes.func,
  touched: PropTypes.bool,
  handleBlur: PropTypes.func,
  errors: PropTypes.oneOfType([PropTypes.array]),
  title: PropTypes.string,
  description: PropTypes.string,
  siteName: PropTypes.string,
  currencyList: PropTypes.oneOfType([PropTypes.array]),
  setFieldValue: PropTypes.func,
  step: PropTypes.number,
};

DetailsReportFormWrapper.defaultProps = {
  handleSubmit: () => true,
  values: {},
  handleChange: () => true,
  touched: false,
  handleBlur: () => true,
  errors: [],
  title: '',
  description: '',
  siteName: '',
  currencyList: [],
  setFieldValue: () => true,
  step: 1,
};

export default DetailsReportForm;
