import React from 'react';

import { Area, Tooltip, XAxis, YAxis, AreaChart, ResponsiveContainer } from "recharts";

import './InlineChart.sass'


const CustomizedAxisTick = (props) => {
  const { fontSize, x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={5}
            textAnchor="end"
            fill="black"
            transform="rotate(-70)"
            fontSize={fontSize || 12}>
        { payload.value }
      </text>
    </g>
  );
};


const CustomTooltip = ({ active, payload }) => {

  if (active && payload && payload.length && payload[0].payload.tooltip) {
    return (
      <div className="custom-tooltip">
        { payload[0].payload.tooltip }
      </div>
    );
  }

  return null;
};


const CustomizedDot = (props) => {
  const { cx, cy, payload, color } = props;
  const { dotSize, label } = payload;

  if (payload && label) {
    return (
      <svg x={cx - (dotSize ? dotSize / 2 : 4)} y={cy - (dotSize ? dotSize / 2 : 4)} width={dotSize || 8} height={dotSize || 8} fill="white">
        <g transform={`translate(${dotSize ? dotSize / 2 : 4} ${dotSize ? dotSize / 2 : 4})`}>
          <circle r={dotSize ? dotSize / 2 : 4} fill={color || "#77bc1f"} />
          <circle r={dotSize ? dotSize / 4 : 2} fill="white" />
        </g>
      </svg>
    );
  }

  return null;
};


const InlineChart = ({ data, tickFontSize, height, lineKey, xKey, width, xaxisHeight }) => {

  const dataMax = data.reduce((previousValue, current) => previousValue + current[lineKey], 0) > 0 ? 'dataMax' : 1;

  return (
    <div className="inline-chart">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={width || 503}
          height={height || 336}
          data={data}
          margin={{ top: 20, right: 8, left: -20, bottom: 10}}>

          <XAxis interval={0}
                 dataKey={xKey}
                 tick={<CustomizedAxisTick fontSize={tickFontSize} />}
                 height={xaxisHeight || 80}
                 stroke="black" />

          <YAxis stroke="black"
                 domain={['dataMin', dataMax]}
                 allowDecimals={false} />

          <Tooltip content={<CustomTooltip />}/>

          <Area dot={<CustomizedDot/>}
                key={Math.random()}
                type="monotone"
                strokeWidth={2}
                dataKey={lineKey}
                stroke="#77bc1f"
                fill="rgba(119, 188, 31, 0.1)"
          />

        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}

export default InlineChart;
