import React, { useEffect, useState } from 'react';
import { useLazyQuery } from "@apollo/client";

import PaymentModal from "../Modals/PaymentModal/PaymentModal";
import { GET_STRIPE_INVOICES } from "../ApolloClient/queries";
import Table from "../Table";
import { InvoicesColumns } from "./Columns";

import './index.sass';


const Invoices = ({ locale, stripeInvoicesApiKey }) => {

  const [activeInvoiceId, setActiveInvoiceId] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [paymentModalIsOpen, setPaymentModalIsOpen] = useState(false);

  const [getStripedInvoices, { loading }] = useLazyQuery(GET_STRIPE_INVOICES, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data && data.stripeInvoices) {
        setInvoices(data.stripeInvoices);
        initialLoad && setInitialLoad(false);
      }
    },
  });

  useEffect(() => {
    getStripedInvoices().catch(error => console.log('error', error));
  }, []);

  useEffect(() => {
    activeInvoiceId && setPaymentModalIsOpen(true);
  }, [activeInvoiceId]);

  const openPaymentModal = (id) => {
    setActiveInvoiceId(id);
  }

  const closePaymentModal = () => {
    setPaymentModalIsOpen(false);
    setActiveInvoiceId(null);
  };

  const handlePaymentSuccess = () => {
    const updatedInvoices = [ ...invoices ];
    const index = invoices.findIndex(invoice => invoice.id === activeInvoiceId);

    updatedInvoices.splice(index, 1, { ...updatedInvoices[index], status: 'paid' });
    setInvoices(updatedInvoices);
    closePaymentModal();
    // getStripedInvoices().catch(error => console.log(error));
  };

  const columns = InvoicesColumns(invoices, openPaymentModal);


  return (
    <div className="invoices">

      <Table
        columns={columns}
        data={invoices}
        disableSortBy={true}
        initialLoad={initialLoad}
        extraClass="no-wrap"
        loading={initialLoad && loading}
        name="invoices"
        noPagination={true}
        hideBottomFilters={true}
        perPage={!!invoices.length ? 100 : 5}
      />

      { paymentModalIsOpen &&
        <PaymentModal
          handleClose={closePaymentModal}
          handlePaymentSuccess={handlePaymentSuccess}
          invoiceId={activeInvoiceId}
          locale={locale}
          showModal={paymentModalIsOpen}
          stripeInvoicesApiKey={stripeInvoicesApiKey}
        />
      }

    </div>
  )
}

export default Invoices;
