import React, {
  useState,
  useContext,
  Fragment,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
  useMemo
} from 'react';
import ReactTooltip from "react-tooltip";
import { useLazyQuery } from "@apollo/client";
import Truncate from 'react-truncate';
import SunEditor from 'suneditor-react';
require("suneditor/dist/css/suneditor.min.css")
// import 'suneditor/dist/css/suneditor.min.css';
require('react-quill/dist/quill.snow.css');

import GoEditImg from '../../../../src/images/provider/goedit.png';
import { SvgIco } from "../../../SharedComponents/Icons";
import Loader from "../../../SharedComponents/Loader";
import InfoMessagePopup from "../../Popups/InfoMessagePopup";
import BaseModal from "../../Modals/BaseModal";
import {
  GENERATE_LOCATION_DESCRIPTION,
  GENERATE_NEW_LOCATION_DESCRIPTION,
  GENERATE_LOCATION_TITLE,
  GENERATE_NEW_LOCATION_TITLE,
} from "../../ApolloClient/queries";
import { ScoreActionTooltip } from "../../ScoreWidget";
import NavigationButtons from '../NavigationButtons';
import DropdownGroup from '../FormGroups/DropdownGroup';
import InputGroup from '../FormGroups/InputGroup';
import { Context } from '../index';

import './Description.sass';



// We need to wrap component in `forwardRef` in order to gain
// access to the ref object that is assigned using the `ref` prop.
// This ref is passed as the second parameter to the function component.
const Description = forwardRef((props, ref) => {

  const { defaultLocale,
          editPage,
          errors,
          goEditUrl,
          handleFormSubmit,
          locationId,
          prepareData,
          qualityScore,
          scrollToTarget,
          setErrors,
          setInfoMessagePopupIsOpen,
          setInfoPopupMessageError,
          showFacts,
          showLocationInternalDetailsText,
          siteKey,
          translationsLocales,
          updateLocation,
          updateValues,
          validateFields,
          values,
        } = useContext(Context);

  const filterObjects = (data) =>
    data ? Object.fromEntries(Object.entries(data).filter(([key, value]) => !!value)) : {};

  const { internalIdentifier, providerWebsiteLink, countryAlpha2 } = values;
  const localeObj = translationsLocales.find(locale => locale.id === countryAlpha2);
  const countryLocale = (localeObj && localeObj.name) || defaultLocale;

  const [errorMessagePopupIsOpen, setErrorMessagePopupIsOpen] = useState(null);
  const [descriptionLanguage, setDescriptionLanguage] = useState(countryLocale);
  const [description, setDescriptionsList] = useState(filterObjects(values.description));
  const [title, setTitlesList] = useState(filterObjects(values.title));
  const [showModal, setShowModal] = useState(false);
  const [descriptionCounter, setDescriptionCounter] = useState();
  const [callback, setCallback] = useState(null);
  // We need this control variable as the description that we receive from backend
  // is not always a valid option for Sun Editor(it has special characters, extra spaces ...).
  // After skipping original description through Sun Editor(in case of "Edit location")
  // it automatically edits HTML that causes failures for description changes check.
  // In this case user sees save changes dialog when there wasn't actually any changes
  const [controlDescription, setControlDescription] = useState(undefined);

  const editor = useRef();

  const requiredValuesFilled = useMemo(() => {
      const descriptionFilled = Object.keys(filterObjects(description)).length;
      const titleFilled = Object.keys(filterObjects(title)).length;

      return descriptionFilled && titleFilled;
  },[ title, description ]);

  const currentDescriptionName = `description_${descriptionLanguage}`;
  const currentHeadlineName = `title_${descriptionLanguage}`;
  const bodyRequiredMessage = `${I18n.t('activerecord.attributes.location.description')} ${I18n.t('apps.lb_showroom.detailed.order_form.validation.field_required', { field: '' })}`;
  const titleRequiredMessage = `${I18n.t('activerecord.attributes.location.title')} ${I18n.t('apps.lb_showroom.detailed.order_form.validation.field_required', { field: '' })}`;

  const languageOptions =
    translationsLocales.map(locale =>
      ({ value: locale.name,
         label: <Fragment>
                  <i className={`description-step__locale-icon fi fi-${locale.id}`} />
                  <div className="description-step__locale-name">{ locale.name }</div>
                </Fragment>
      })
    );


  // Description has a difficult logic, and additional checks on validation
  // this is why here we have a separate validate function,
  // and we need to use 'forwardRef' to use this function in ProgressBar component.

  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument.
  // We need this functionality as some of the steps require additional checks.
  // And we need to pass this functions and checks to ProgressBar component
  // in order to validate step changes on ProgressBar link click.
  // This will allow us to call function from this component in a parent component
  // or any of it's child / example => [ref].current.validateStep()
  // KEEP "args" to have opportunity to update nextStep!!!
  useImperativeHandle(ref, (args) => ({
    nextStep: null,
    description,
    title,
    controlDescription,
    validateStep: () => {
      ref.current.nextStep && handleSubmit(ref.current.nextStep);
    },
  }));


  useEffect(() => {
    handleCounter();
    setTimeout(handleControlProp, 100);
  }, [descriptionLanguage]);


  useEffect(() => {
    callback && callback(values);
    setCallback(null);
  }, [values]);


  // REMOVE ERROR ON DESCRIPTION CHANGE
  // INVESTIGATE - any function call in handleDescriptionChange
  // causes bugs while working with props (props doesn't change).
  // Probably caused by SunEditor.
  useEffect(() => {
    description[descriptionLanguage] && handleErrors(['description']);
    handleErrors([currentDescriptionName]);
  }, [description]);


  const [generateLocationDescription, { error: generateDescriptionError, loading: generatingDescription }] = useLazyQuery(GENERATE_LOCATION_DESCRIPTION, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data) {
        setDescriptionsList(prevValues => ({
          ...prevValues,
          [descriptionLanguage]: data.locationChatGptDescription.text,
        }));
      }
    },
  });


  const [generateLocationTitle, { error: generateTitleError, loading: generatingTitle }] = useLazyQuery(GENERATE_LOCATION_TITLE, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data) {
        setTitlesList(prevValues => ({
          ...prevValues,
          [descriptionLanguage]: data.locationChatGptTitle.text,
        }))
      }
    },
  });


  const [generateNewLocationDescription, { error: generateNewDescriptionError, loading: generatingNewLocationDescription }] = useLazyQuery(GENERATE_NEW_LOCATION_DESCRIPTION, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data) {
        setDescriptionsList(prevValues => ({
          ...prevValues,
          [descriptionLanguage]: data.newLocationChatGptDescription.text,
        }));
      }
    },
  });


  const [generateNewLocationTitle, { error: generateNewTitleError, loading: generatingNewLocationTitle }] = useLazyQuery(GENERATE_NEW_LOCATION_TITLE, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data) {
        setTitlesList(prevValues => ({
          ...prevValues,
          [descriptionLanguage]: data.newLocationChatGptTitle.text,
        }))
      }
    },
  });


  useEffect(() => {
    (generateDescriptionError || generateTitleError || generateNewDescriptionError || generateNewTitleError) && setErrorMessagePopupIsOpen(true);
  }, [generateDescriptionError, generateTitleError, generateNewDescriptionError, generateNewTitleError]);


  const handleControlProp = () => ref.current && ref.current.description && setControlDescription({ ...ref.current.description });


  const handleCounter = () => {
    setDescriptionCounter(
      description[descriptionLanguage]
      ? description[descriptionLanguage].replace(/<[^>]+>/g, '').length
      : 0
    );
  };


  const setInitialValues = (locale) => {
    setDescriptionsList(prevValues => ({
      ...prevValues,
      [locale]: (values.description && values.description[locale]) || '',
    }));

    setTitlesList(prevValues => ({
      ...prevValues,
      [locale]: (values.title && values.title[locale]) || '',
    }));

    callback && callback();
    setCallback(null);
  };


  const handleSelectLanguage = (value) => {
    if (handleUnsavedChanges(() => setDescriptionLanguage(value))) {
      if (errors[currentDescriptionName] || errors[currentHeadlineName]) {
        const updatedErrors = { ...errors };

        errors[currentDescriptionName] && delete updatedErrors[currentDescriptionName];
        errors[currentHeadlineName] && delete updatedErrors[currentHeadlineName];
        setErrors({ ...updatedErrors });
      }
    }
  };


  const handleHeadlineChange = (e) => {
    const field = e.target.name.split('_')[1];
    const value = e.target.value;

    handleErrors([e.target.name, 'title']);

    setTitlesList(prevValues => ({
      ...prevValues,
      [field]: value,
    }));
  };


  const handleDescriptionChange = (text) => {
    // we need this check as ReactQuill will return <p><br/></p> if field is empty
    const currentTextLength = editor.current.getText().length;
    const formattedText = text.replace(/<\s*(\w+).*?>/g, '<$1>')
                              .replace(/<(?!br)[^/>][^>]*><\/[^>]+>/gim, "");

    setDescriptionsList(prevValues => ({
      ...prevValues,
      [descriptionLanguage]: currentTextLength ? formattedText : '',
    }));

    ref.current.controlDescription === undefined && setControlDescription({
      ...description,
      [descriptionLanguage]: currentTextLength ? formattedText : '',
    });

    setDescriptionCounter(editor.current.getText().length);
  };


  const handleErrors = (fields) => {
    const updatedErrors = { ...errors };

    fields.map(field => {
      if (updatedErrors[field]) {
        delete updatedErrors[field];
        setErrors({ ...updatedErrors });
      }
    });
  };


  const handleSave = () => {
    const currentTitleFilled = !!title[descriptionLanguage];
    const currentDescriptionFilled = !!description[descriptionLanguage];

    if (currentDescriptionFilled !== currentTitleFilled) {
      const fieldsError = { ...errors };

      !currentTitleFilled && (fieldsError[currentHeadlineName] = titleRequiredMessage)
      !currentDescriptionFilled && (fieldsError[currentDescriptionName] = bodyRequiredMessage)

      setErrors({ ...fieldsError });
      scrollToTarget(document.getElementById(!currentTitleFilled ? currentHeadlineName : currentDescriptionName));

    } else {
      updateValues({ description, title });
      setControlDescription({ ...description });
      // selectNextLocale();
      editPage && validateRequiredFields() && updateDescription({ description, title });
    }
  };


  const selectNextLocale = () => {
    const unsetDefaultLocale = !description[countryLocale] ? countryLocale : !description[defaultLocale] ? defaultLocale : null;

    if (unsetDefaultLocale) {
      setDescriptionLanguage(unsetDefaultLocale);
    } else {
      const unfilledLocale = translationsLocales.find(locale => locale.name !== descriptionLanguage && !description[locale.name]);

      unfilledLocale && setDescriptionLanguage(unfilledLocale.name);
    }
  };


  const handleClear = () => {
    setDescriptionsList(prevValues => ({
      ...prevValues,
      [descriptionLanguage]: '',
    }));

    setTitlesList(prevValues => ({
      ...prevValues,
      [descriptionLanguage]: '',
    }));

    const updatedErrors = { ...errors };

    updatedErrors[currentDescriptionName] && (delete updatedErrors[currentDescriptionName]);
    updatedErrors[currentHeadlineName] && (delete updatedErrors[currentHeadlineName]);
    setErrors(updatedErrors);
  };


  const handleDelete = (locale) => {

    const updatedDescriptionsList = { ...description, [locale]: '' };
    const updatedTitlesList = { ...title, [locale]: '' };

    setDescriptionsList(updatedDescriptionsList);
    setTitlesList(updatedTitlesList);
    updateValues({
      description: { ...updatedDescriptionsList },
      title: { ...updatedTitlesList },
    });
  };


  const handleUnsavedChanges = (callbackFunction) => {

    // TODO refactor
    const comparatorError = (objectName) => {
      const prevValue = objectName === 'description'
        ? (controlDescription && controlDescription[descriptionLanguage]) || ''
        : (values[objectName] && values[objectName][descriptionLanguage]) || '';
      const currentValue = ref.current[objectName][descriptionLanguage];

      return !prevValue ? !!prevValue !== !!currentValue : prevValue !== currentValue;
    };

    if (comparatorError('description') || comparatorError('title')) {
      setShowModal(true);
      setCallback(() => callbackFunction)
      return false;
    } else {
      callbackFunction && callbackFunction();
      return true;
    }
  };


  const validateRequiredFields = () => {
    const currentErrors = { ...errors };
    const descriptionFilled = Object.keys(filterObjects(description)).length;
    const titleFilled = Object.keys(filterObjects(title)).length;

    !requiredValuesFilled && setDescriptionLanguage(countryLocale);
    !descriptionFilled && (currentErrors[`description_${countryLocale}`] = bodyRequiredMessage);
    !titleFilled && (currentErrors[`title_${countryLocale}`] = titleRequiredMessage);
    setErrors({ ...currentErrors });

    const errorField = !titleFilled ? `title_${countryLocale}` : !descriptionFilled ? `description_${countryLocale}` : null;

    if (errorField) {
      // setDescriptionLanguage(countryLocale);
      scrollToTarget(document.getElementById(errorField));
    }

    return !Object.keys(currentErrors).length;
  };


  const handleSubmit = (nextStep, callback) =>
    handleUnsavedChanges((data) => validateRequiredFields() && validateFields(nextStep, callback && callback(data)));


  const handleModal = (save) => {
    save ? handleSave() : setInitialValues(descriptionLanguage);
    setShowModal(false);
  };


  // The sunEditor parameter will be set to the core suneditor instance when this function is called
  const getSunEditorInstance = (sunEditor) =>
    editor.current = sunEditor;


  const handleEditorLanguage = () => {
    const languageId = descriptionLanguage.split('-')[0];

    switch (languageId) {
      case 'uk':
        return 'ua';
      case 'fi':
        return 'en';
      case 'nb':
        return 'en';
      case 'tr':
        return 'en';
      case 'sv':
        return 'se';
      default:
        return languageId;
    }
  };


  const handleInput = () =>
    setDescriptionCounter(editor.current.getText().length);


  const updateDescription = (editedValues) => {
    updateLocation({
      variables: {
        attributes: { ...prepareData(editedValues), countryAlpha2: values.countryAlpha2 },
        id: Number(locationId),
      },
    }).catch((error) => {
      console.log('error', error);
      setInfoPopupMessageError(true);
      setInfoMessagePopupIsOpen(true);
    });
  };


  const generateDescription = () => {
    if (!editPage) {
      generateNewLocationDescription({ variables: { attributes: prepareData(values), locale: descriptionLanguage } });
      generateNewLocationTitle({ variables: { attributes: prepareData(values), locale: descriptionLanguage } });
    } else {
      generateLocationDescription({ variables: { locationId, locale: descriptionLanguage } });
      generateLocationTitle({ variables: { locationId, locale: descriptionLanguage } });
    }
  };


  const generatingDescriptionAndTitle =
    generatingDescription ||
    generatingTitle ||
    generatingNewLocationDescription ||
    generatingNewLocationTitle;


  const generateDescriptionButton =
    <div className="description-step__generate-button-wrapper col-xl-4 col-lg-3">
      <div className={`description-step__generate-button provider-tooltip ${generatingDescriptionAndTitle ? 'disabled' : ''}`}
           data-tip={I18n.t('providers.locations.form.description_generate_tooltip')}
           data-class="provider-tooltip"
           data-for={'generate-description-tooltip'}
           onClick={() => generatingDescriptionAndTitle ? null : generateDescription()}>
        { I18n.t('providers.locations.form.description_generate_button') }
        { generatingDescriptionAndTitle && <Loader size={18} left={0} top={0}/> }
      </div>
      <ReactTooltip
        border={true}
        borderColor="#FFA300"
        effect='solid'
        id={'generate-description-tooltip'}
        place='bottom'
        type="light" />
    </div>;


  return (
    <div className="description-step notranslate" content="notranslate" translate="no">

      <InfoMessagePopup
        customClass={'error'}
        handleClose={() => setErrorMessagePopupIsOpen(false)}
        message={I18n.t('providers.locations.form.description_limit_exceeded_error')}
        visible={errorMessagePopupIsOpen}
      />

      <div className="location-form__section-wrapper">
        <h3>
          { I18n.t('providers.locations.form.description') }

          <ScoreActionTooltip
            filled={qualityScore && qualityScore.description.score}
            label={qualityScore && I18n.t(`provider.form.quality_score.hint_label.description`)}
          />
        </h3>

        { values.description && translationsLocales.map(locale =>
          values.description[locale.name] &&
          <div className="description-step__preview-card" key={`preview${locale.name}`}>

            <div className="description-step__preview-locale-wrapper">
              <i className={`description-step__locale-icon fi fi-${locale.id}`} />
              <div className="description-step__locale-name">{ locale.name }</div>
            </div>

            <div className="description-step__preview-headline">
              { values.title[locale.name] }
            </div>

            <div className="description-step__preview-description">
              <Truncate
                lines={2}
                ellipsis="..."
                children={<p dangerouslySetInnerHTML={{__html: values.description[locale.name].replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '')}}/>}
              />
            </div>

            <div className="description-step__edit-button">
              <SvgIco
                className="description-step__preview-delete"
                name="delete_forever"
                onClick={() => handleDelete(locale.name)}
                size={22} />
              <SvgIco
                className="description-step__preview-edit"
                name="border_color"
                onClick={() => setDescriptionLanguage(locale.name)}
                size={18} />
            </div>
          </div>
        )}

        <div className="description-step__wrapper">

          <DropdownGroup
            children={generateDescriptionButton}
            disabled={languageOptions.length === 1}
            handleChange={handleSelectLanguage}
            label={I18n.t('providers.form.description_language')}
            name="descriptionLanguage"
            options={languageOptions}
            value={descriptionLanguage}
            wrapperClass={`form-group--lang ${generatingDescriptionAndTitle ? 'disabled' : ''}`} />

          <div id="title" />
          <div id={`title_${countryLocale}`} className={`description-step__title-wrapper${(generatingTitle || generatingNewLocationTitle) ? ' disabled' : ''}`}>
            <InputGroup
              error={errors[currentHeadlineName] || (errors.title && `${I18n.t('activerecord.attributes.location.title')} ${errors.title}`)}
              inputWrapperClass="notranslate col-xl-7 col-lg-7"
              handleChange={handleHeadlineChange}
              label={`${I18n.t('activerecord.attributes.location.title')}${!requiredValuesFilled ? '*' : ''}`}
              name={currentHeadlineName}
              value={title[descriptionLanguage] || ''} />
          </div>

          <div className="form-group row">

            <div className="form-label col-xl-3 col-lg-3">
            <span>
              { I18n.t('activerecord.attributes.location.description') }{!requiredValuesFilled && '*'}
            </span>
            </div>

            <div className="col-xl-7 col-lg-7">
              <div className="sectionScrollTarget" id="description" />
              <div className={`notranslate description-step__formatter-wrapper${errors[currentDescriptionName] || errors.description ? ' error' : ''}${(generatingDescription || generatingNewLocationDescription) ? ' disabled' : ''}`}
                   id={`description_${countryLocale}`}
                   content="notranslate"
                   translate="no"
                   key={`wrapper-${descriptionLanguage}`}>

                <SunEditor
                  getSunEditorInstance={getSunEditorInstance}
                  value={description[descriptionLanguage] || ''}
                  setContents={description[descriptionLanguage] || ''}
                  onChange={handleDescriptionChange}
                  lang={handleEditorLanguage()}
                  resizingBar={false}
                  onInput={handleInput}
                  options={{ pasteTagsWhitelist: 'p|ul|ol|li|sup|br|strong' }}
                  name={currentDescriptionName}
                  historyStackDelayTime={10}
                  setOptions={{
                    pasteTagsWhitelist: 'p|ul|ol|li|sup|br|strong',
                    buttonList: [
                      ['list', 'superscript', 'removeFormat', 'bold'],
                      ['preview', 'undo', 'redo'],
                    ],
                  }} />
              </div>

              <div className={`description-step__description-counter${(generatingDescription || generatingNewLocationDescription) ? ' disabled' : ''}`}>
                { I18n.t('providers.form.description_text_length') }: { descriptionCounter }
              </div>

              { errors[currentDescriptionName] &&
                <div className="description-step__description-error-tooltip">
                  { errors[currentDescriptionName] }
                </div>
              }

              { !errors[currentDescriptionName] && errors.description &&
                <div className="description-step__description-error-tooltip">
                  { I18n.t('activerecord.attributes.location.description') } { errors.description }
                </div>
              }

              <p className="description-step__description-notice"
                 dangerouslySetInnerHTML={{__html: I18n.t('providers.locations.form.notice') }} />

              <div className="description-step__description-buttons">
                <div className="description-step__button-clear" onClick={handleClear}>
                  { I18n.t('providers.form.description_clear_button') }
                </div>

                <div className="description-step__button-save" onClick={handleSave}>
                  { I18n.t('generic.save') }
                </div>
              </div>
            </div>

            { siteKey === 'dk' &&
              <div className="col-xl-10 col-lg-10 offset-1">
                <div className="description-step__go-edit">

                  <a href={goEditUrl} className="description-step__go-edit-img">
                    <img src={GoEditImg} alt="GoEdit" />
                  </a>

                  <div className="description-step__go-edit-headline">
                    { I18n.t('providers.goedit_banner.headline') }
                  </div>

                  <p className="description-step__go-edit-description">
                    { I18n.t('providers.locations.form.go_edit_part_above_image',
                      { type: values.section === 'lease' || values.anonymous ? I18n.t('global.terms.lease_property') : I18n.t('global.terms.sale_property') })
                    }
                    <a href={goEditUrl} className="description-step__go-edit-link" target="_blank">
                      { I18n.t('providers.goedit_banner.button_text') }
                    </a>
                  </p>

                </div>
              </div>
            }
          </div>
        </div>
      </div>


      <div className="location-form__section-wrapper" style={{ marginTop: '16px', minHeight: '259px' }}>
        <div className="row">
          <div className="col-xl-7 col-lg-8">
            <h3>{ I18n.t('providers.locations.form.internal_header_text_new') }</h3>
          </div>

          { showLocationInternalDetailsText &&
            <div className="col-xl-5 col-lg-4">
              <div className="form-group__tooltip description-step__internal-details-tooltip"
                   dangerouslySetInnerHTML={{ __html: I18n.t('activerecord.attributes.property.provider_website_link_tooltip') }} />
            </div>
          }
        </div>

        <InputGroup
          label={I18n.t('activerecord.attributes.location.internal_identifier')}
          name="internalIdentifier"
          value={internalIdentifier || ''} />

        <InputGroup
          label={I18n.t('activerecord.attributes.property.provider_website_link_new')}
          name="providerWebsiteLink"
          value={providerWebsiteLink} />
      </div>

      <NavigationButtons
        handleBack={() => handleSubmit(showFacts ? 'facts' : 'contacts')}
        handleNext={() => handleSubmit(editPage ? 'visuals' : null, editPage ? null : handleFormSubmit)}
        isSubmit={!editPage && siteKey !== 'dk'}
        requiredFieldsValid={() => handleUnsavedChanges() && validateRequiredFields()} />

      <BaseModal
        confirmButtonText={I18n.t('generic.save')}
        handleClose={() => setShowModal(false)}
        modalIsOpen={showModal}
        onConfirmClick={() => handleModal(true)}
        onRejectClick={() => handleModal(false)}
        rejectButtonText={I18n.t('providers.form.unsaved_changes_modal.decline_button')}
        textContent={I18n.t('providers.form.unsaved_changes_modal.text')} />

    </div>
  )
});


export default Description;
