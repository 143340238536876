import React, { Fragment, useContext } from 'react';
import ReactTooltip from 'react-tooltip';

import BasicInput from '../../../../SharedComponents/Forms/BasicInput';
import { SvgIco } from "../../../../SharedComponents/Icons";
import { ScoreActionTooltip } from "../../../ScoreWidget";
import { Context } from '../../index';
import FileInput from './FileInput';
import InputBanner from './InputBanner';

import './VisualsBlock.sass';

const VisualsBlock = ({ bannerClass,
                        bannerContent,
                        bannerHeadline,
                        buyNowLink,
                        defaultFileList,
                        fileTypes,
                        handleChange,
                        inputHeadline,
                        inputLabel,
                        inputType,
                        isPdf,
                        linkFile,
                        name,
                        qualityScore,
                        qualityScoreName,
                        readMoreLink,
                        refetchValues,
                        setVisualsData,
                        showBanner,
                        showFileAsLink,
                        singleFileInput,
                        tooltip, }) => {

  const { editedValuesFields,
          errors,
          formAuthToken,
          handleEdit,
          handleInputChange,
          locationId,
          validateFields,
          values } = useContext(Context);

  const isFileInput = inputType === 'file';
  const value = values[name];


  return (
    <div className="visuals-block">
      <div className="visuals-block__wrapper">
        <div className={`form-group form-group--visuals${ isFileInput ? ' file' : '' }`}>
          <div className="visuals-block__input-headline">
            { inputHeadline }

            <span className="provider-tooltip"
                  data-tip={tooltip}
                  data-class="provider-tooltip__body"
                  data-html={tooltip}
                  data-for={`${name}-tooltip`}>
              <SvgIco name="info_outline" size={22} />
            </span>

            <ReactTooltip
              border={true}
              borderColor="black"
              effect='solid'
              id={`${name}-tooltip`}
              place='right'
              type="light"
            />

            <ScoreActionTooltip
              filled={(qualityScore && qualityScore[qualityScoreName].score) || value}
              label={qualityScore && I18n.t(`provider.form.quality_score.hint_label.${qualityScore[qualityScoreName].id}`)}
            />
          </div>

          <div className="visuals-block__input-label">
            { inputLabel }
          </div>

          <div className="visuals-block__input-wrapper">
            { isFileInput
              ?
                <FileInput
                  defaultFileList={defaultFileList}
                  fileTypes={fileTypes}
                  formAuthToken={formAuthToken}
                  linkFile={linkFile}
                  locationId={locationId}
                  name={name}
                  refetchValues={refetchValues}
                  setVisualsData={setVisualsData}
                  singleFileInput={singleFileInput}
                  isPdf={isPdf}
                  showFileAsLink={showFileAsLink} />
              :
                <Fragment>
                  <BasicInput
                    error={errors[name]}
                    handleChange={handleChange || handleInputChange}
                    name={name}
                    type={inputType}
                    value={value || ''}/>

                  <div className={`visuals-block__input-submit-btn ${editedValuesFields.includes(name) ? 'active' : ''}`}
                       onClick={() => validateFields(false, handleEdit)}>
                    { I18n.t('generic.update_button') }
                  </div>
                </Fragment>
            }
          </div>
        </div>
      </div>

      { showBanner &&
        <InputBanner
          additionalClass={bannerClass}
          buyNowLink={buyNowLink}
          content={bannerContent}
          headline={bannerHeadline}
          readMoreLink={readMoreLink}
        />
      }

    </div>
  )
}


export default VisualsBlock;
