import React from 'react';
import ErrorImg from '../../../src/images/provider/error.svg';
import { Link } from 'react-router-dom';


const Page404 = ({ currentLocale }) => {
  return (
    <div className="errors-page">
      <h3>{ I18n.t('provider.error.404') } !</h3>
      <img src={ErrorImg} height={243} width={345} alt="Error" />
      <p className="page-not-found">{ I18n.t('error_pages.404_page.header')}</p>
      <p >{I18n.t("error_pages.404_page.description")}</p>

      <Link to={`${currentLocale}/new_providers`} clasName="return-dashboard-link"> {I18n.t("providers.leases.success.review.text_dashboard_button")}</Link>

    </div>
  );
}

export default Page404;
