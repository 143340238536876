import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useLocation } from "react-router-dom";

import { useRouterQuery } from "../../SharedComponents/hooks";
import { GET_UNRESOLVED_PROVIDER_DEALS } from '../ApolloClient/queries';
import RejectAgreementModal from '../Modals/RejectAgreementModal';
import Table from '../Table';
import { UnresolvedAgreementColumns } from './Columns';

import './index.sass';


const UnresolvedAgreement = ({ currentLocale }) => {

  const query = useRouterQuery();

  const [rejectModalIsOpen, setRejectModalIsOpen] = useState(false);
  const [selectedProviderDeal, setSelectedProviderDeal] = useState({});
  const [unresolvedProviderDeals, setUnresolvedProviderDeals] = useState([]);

  const {} = useQuery(GET_UNRESOLVED_PROVIDER_DEALS, {
    onCompleted: (data) => {
      const rejectDealId = parseInt(query.get('reject_deal_id'));
      const { unresolvedProviderDeals } = data;
      const rejectedDeal = rejectDealId && unresolvedProviderDeals.find(deal => Number(deal.id) === Number(rejectDealId));

      data && unresolvedProviderDeals && setUnresolvedProviderDeals(unresolvedProviderDeals);

      if (rejectedDeal) {
        setSelectedProviderDeal(rejectedDeal);
        setRejectModalIsOpen(true);
      }
    },
  });

  const handleReject = (data) => {
    setSelectedProviderDeal(data);
    setRejectModalIsOpen(true);
  };

  const columns = UnresolvedAgreementColumns(unresolvedProviderDeals, currentLocale, handleReject);
  const isReportADealPage = useLocation().pathname.includes('report_deal');

  if (!!unresolvedProviderDeals.length && !isReportADealPage) {
    return (
      <div className="unresolved-agreement">
        <RejectAgreementModal
          data={selectedProviderDeal}
          handleClose={() => setRejectModalIsOpen(false)}
          showModal={rejectModalIsOpen}
        />

        <h3>{ I18n.t('providers.providers.provider_deals_notifications.heading') }</h3>

        <p className="unresolved-agreement__explanation">
          { I18n.t('providers.providers.provider_deals_notifications.description') }
        </p>

        <Table
          columns={columns}
          data={unresolvedProviderDeals}
          disableSortBy={true}
          extraClass="no-wrap"
          name="unresolvedAgreements"
          noPagination={true}
          hideBottomFilters={true}
          perPage={10}
        />
      </div>
    );
  } else {
    return null;
  }
};

export default UnresolvedAgreement;
