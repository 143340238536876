import React, { useContext, Fragment } from 'react';

import Checkbox from '../../../SharedComponents/Forms/CheckBox';
import EnergyRatingImg from '../../../../src/images/provider/energy-rating.svg';
import RadioFormGroup from '../FormGroups/RadioFormGroup';
import DropdownGroup from '../FormGroups/DropdownGroup';
import { ScoreActionTooltip } from "../../ScoreWidget";
import NavigationButtons from '../NavigationButtons';
import { Context } from '../index';

import './FactsAndFacilities.sass';


const FactsAndFacilities = () => {

  const {
    countryAlpha2,
    energyRatingList,
    energyRatingListDk,
    energyRatingUrl,
    energyCertificateLevelsList,
    facilitiesList,
    handleChange,
    handleCheckboxesSelect,
    qualityScore,
    siteKey,
    validateFields,
    values,
  } = useContext(Context);

  const { energyCertificateLevelsObject, energyRating, exemptedFromEnergyRating, facilityIds, hasEnergyCertificateLevels } = values;
  const isDk = siteKey === 'dk' || countryAlpha2 === 'dk' || (values.countryAlpha2 && values.countryAlpha2 === 'dk');
  const energyRatingOptions = (isDk ? energyRatingListDk : energyRatingList).map(item => ({ value: item, label: item }));
  const energyCertificatesOptions = energyCertificateLevelsList && Object.keys(energyCertificateLevelsList).map(certificate =>
    ({
      name: certificate.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`),
      label: I18n.t(`energy_certificate.name.${certificate.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)}`),
      options: [ { value: 0, label: I18n.t('helpers.select.prompt') }, ...energyCertificateLevelsList[certificate].map(option => ({ value: option.id, label: option.label })) ],
    })
  );


  const handleEnergyCertificates = (certificateId, certificateGroup) => {
    const newCertificates = { ...energyCertificateLevelsObject };

    certificateId === 0
      ? delete newCertificates[certificateGroup]
      : newCertificates[certificateGroup] = certificateId;

    handleChange(newCertificates, 'energyCertificateLevelsObject');
  };


  return (
    <div className="facts-step">

      <div className="location-form__section-wrapper">
        <div className="sectionScrollTarget" id="energyRating" />
        <div className="form-title">
          { I18n.t('providers.locations.form.energy_rating') }

          <ScoreActionTooltip
            filled={qualityScore && qualityScore.energyRating.score}
            label={qualityScore && I18n.t(`provider.form.quality_score.hint_label.energy_rating`)}
          />
        </div>

        <RadioFormGroup
          field="exemptedFromEnergyRating"
          label={I18n.t('activerecord.attributes.location.exempted_from_energy_rating')} />

        { !exemptedFromEnergyRating &&
          <DropdownGroup
            handleChange={handleChange}
            label={I18n.t('activerecord.attributes.location.energy_rating')}
            name="energyRating"
            options={energyRatingOptions}
            value={energyRating} />
        }
      </div>

      <div className="location-form__section-wrapper">
        <div className="sectionScrollTarget" id="facilities" />
        <div className="form-title">
          { I18n.t('apps.lb_showroom.detailed.tabs.facilities') }

          <ScoreActionTooltip
            filled={qualityScore && qualityScore.facilities.score}
            label={qualityScore && I18n.t(`provider.form.quality_score.hint_label.facilities`)}
          />
        </div>

        <div className="row">
          <div className="facts-step__facilities-wrapper col-xl-12 col-lg-12">
            { facilitiesList.map(facility =>
              <Checkbox
                field="facilityIds"
                handleChange={(e, value) => handleCheckboxesSelect(e, value, true)}
                key={facility.key}
                label={facility.name}
                name={`${facility.id}`}
                value={facilityIds && facilityIds.includes(facility.id)} />
            )}
          </div>
        </div>
      </div>


      <div className="location-form__section-wrapper" style={{ marginBottom: 0 }}>
        { isDk &&
          <Fragment>
            <div className="form-title">
              { I18n.t('generic.certifications') }
            </div>
            <RadioFormGroup
              field="hasEnergyCertificateLevels"
              label={I18n.t('provider.form.facts.certifications.label')}
            />

            { hasEnergyCertificateLevels && energyCertificatesOptions &&
              <div className="facts-step__energy-certificates-wrapper">
                { energyCertificatesOptions.map(certificate =>
                  <div className="facts-step__energy-certificates-dropdown-wrapper" key={`certificate-${certificate.name}`}>
                    <DropdownGroup
                      handleChange={handleEnergyCertificates}
                      label={certificate.label}
                      name={certificate.name}
                      options={certificate.options}
                      value={
                        ( energyCertificateLevelsObject
                          && Object.keys(energyCertificateLevelsObject).length
                          && certificate.options.find(option => option.value === energyCertificateLevelsObject[certificate.name])
                        )
                        || certificate.options[0]
                      }
                    />
                  </div>
                )}
              </div>
            }
          </Fragment>
        }

        <div className="form-title">
          { I18n.t('activerecord.attributes.location.is_built') }
        </div>

        <RadioFormGroup field="isBuilt" />
      </div>

      <NavigationButtons
        handleBack={() => validateFields('contacts')}
        handleNext={() => validateFields('description')} />

    </div>
  )
};


export default FactsAndFacilities;
