import React, { useState, useCallback, useEffect } from 'react';
import ReactTooltip from "react-tooltip";

import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded, SvgIco } from '../../SharedComponents/Icons';
import ShowMeBannerImg from "../../../src/images/provider-backend/Banner_1_Illustration@2x.png";
import FacebookLogo from '../../../src/images/icons/facebook-logo.svg';
import SideBarLink from "./SideBarLink";
import IframeModal from "../Modals/IframeModal";

import './index.sass';


const SideBar = ({ companyName,
                   currentContactEmail,
                   currentContactName,
                   currentLocale,
                   currentPhoneName,
                   newClientsCount,
                   links,
                   providerPartner,
                   siteKey,
                 }) => {

  const [activeSideBar, setActiveSideBar] = useState(() => {
    if(localStorage) {
      const storageMenuStatus = localStorage.getItem('my.provider_menu_open');

      if (storageMenuStatus) {
        return storageMenuStatus === 'true';
      } else {
        localStorage.setItem('my.provider_menu_open', JSON.stringify(true));
        return true;
      }
    } else {
      return true;
    }
  });

  const [modalIsActive, setModalIsActive] = useState(false);
  const [url, setUrl] = useState(false);

  const { qualityScorePageLink, partnerBadgePageLink } = links;
  const partnerLink = siteKey === 'dk' ? '/serviceydelser-til-udlejere/fremhevet/annoncering' : partnerBadgePageLink;

  const changeSidebar = useCallback(() => setActiveSideBar(!activeSideBar));


  useEffect(() => {
    if(localStorage) {
      const storageMenuStatus = localStorage.getItem('my.provider_menu_open');

      storageMenuStatus && localStorage.setItem('my.provider_menu_open', JSON.stringify(activeSideBar));
    }
  }, [activeSideBar]);


  const handleShowModal = (url) => {
    setUrl(url + `?raw=true&data[name]=${currentContactName}&data[phone]=${currentPhoneName}&data[email]=${currentContactEmail}&data[company_name]=${companyName}`);
    setModalIsActive(true);
  };


  const handleCloseModal = () => {
    setModalIsActive(false);
  };


  return (
    <div className={`sidebar ${activeSideBar ? '' : 'inactive'}`}>

      <IframeModal
        handleClose={handleCloseModal}
        modalClass="simple-cms-modal"
        showModal={modalIsActive}
        url={url}
      />

      <ul className="sidebar-items">

        <SideBarLink
          disableTooltip={activeSideBar}
          label={I18n.t('generic.providers.sidebar.dashboard')}
          linkTo={`${currentLocale}/new_providers`}
          svgName="dashboard-provider" />

        <SideBarLink
          disableTooltip={activeSideBar}
          label={I18n.t('generic.providers.sidebar.statistic')}
          linkTo={`${currentLocale}/new_providers/statistic`}
          svgName="statistic-provider" />

        <SideBarLink
          disableTooltip={activeSideBar}
          label={I18n.t('generic.providers.sidebar.leases')}
          linkTo={`${currentLocale}/new_providers/locations/lease_properties`}
          svgName="leases-provider" />

        { siteKey === 'dk' &&
          <SideBarLink
            disableTooltip={activeSideBar}
            label="Salgsejendomme"
            linkTo={`${currentLocale}/new_providers/locations/sales_properties`}
            svgName="houses" />
        }

        { siteKey === 'dk' &&
          <SideBarLink
            disableTooltip={activeSideBar}
            label="Anonymiserede lejemål"
            linkTo={`${currentLocale}/new_providers/locations/anonymous`}
            svgName="anonymous" />
        }

        <SideBarLink
          disableTooltip={activeSideBar}
          label={I18n.t('providers.providers.show.customers')}
          linkTo={`${currentLocale}/new_providers/orders`}
          newInfoLabel={newClientsCount}
          svgName="clients-provider" />

        <SideBarLink
          disableTooltip={activeSideBar}
          label={I18n.t('generic.providers.sidebar.contacts')}
          linkTo={`${currentLocale}/new_providers/contacts`}
          svgName="contacts-provider" />

        <hr className="horizontal-line" />

        <li className="sidebar-toggle" onClick={changeSidebar}>
          { activeSideBar ? <KeyboardArrowLeftRounded size={22} /> : <KeyboardArrowRightRounded size={22} /> }
        </li>

        <SideBarLink
          disableTooltip={activeSideBar}
          label={I18n.t('generic.providers.sidebar.provider-settings')}
          linkTo={`${currentLocale}/new_providers/settings/provider`}
          svgName="provider-settings" />

        <SideBarLink
          disableTooltip={activeSideBar}
          label={I18n.t('generic.providers.sidebar.contact-settings')}
          linkTo={`${currentLocale}/new_providers/settings/contact`}
          svgName="contact-settings" />

        <SideBarLink
          disableTooltip={activeSideBar}
          label={I18n.t('generic.providers.sidebar.help')}
          linkTo={`${currentLocale}/new_providers/help`}
          svgName="help_outline" />


        <li className="sidebar-item"
            data-tip={I18n.t('generic.providers.sidebar.sign-out')}
            data-for="nav-link-sign-out-tooltip">
          <ReactTooltip
            border={true}
            borderColor="black"
            effect='solid'
            id="nav-link-sign-out-tooltip"
            place="right"
            type="light"
            disable={activeSideBar}
            className="nav-link-tooltip"
          />
          <a href="/logout">
            <div>
              <SvgIco name="logout-provider" size={22} />
            </div>
            <span>{ I18n.t("generic.providers.sidebar.sign-out") }</span>
          </a>
        </li>
      </ul>

      <div className="sidebar-banners-wrapper">
        <img src={ShowMeBannerImg} alt="show-me-how-banner"/>
        <div>
          <p className="sidebar-banners-wrapper-text">
            { I18n.t("views.providers.banner.main-text") }
          </p>
        </div>
        <a onClick={() => handleShowModal(qualityScorePageLink)}>
          { I18n.t("views.providers.banner.cta-button") }
        </a>
      </div>

      {(!providerPartner || siteKey === 'dk') && (
        <div className="sidebar-banners-wrapper">
          <p className="banner-title">
            { I18n.t("views.providers.banner.partner-badge-title") }
          </p>
          <p className="banner-text"
             dangerouslySetInnerHTML={{ __html: I18n.t("generic.providers.sidebar.get-partner-badge-main-text") }} />
          <div>
            <a className="get-partner-button" onClick={() => handleShowModal(partnerLink)}>
              { I18n.t("views.providers.banner.partner-cta-button") }
            </a>
          </div>
        </div>
      )}

      { siteKey === 'dk' &&
        <div className="sidebar-banners-wrapper">
          <p className="banner-title">{ I18n.t("generic.providers.sidebar.facebook.title") }</p>
          <p className="banner-text">{ I18n.t("generic.providers.sidebar.facebook.content") }</p>
          <div>
            <a className="facebook-banner-button" onClick={() => handleShowModal("/serviceydelser-til-udlejere/annoncering/facebook")}>
              <img src={FacebookLogo} alt="Facebook" />
              { I18n.t("generic.read_more") }
            </a>
          </div>
        </div>
      }

    </div>
  );
}


export default SideBar;
