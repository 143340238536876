import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import NotesImg from "../../../src/images/provider/note.svg";
import Loader from "../../SharedComponents/Loader";
import { TableFilterHeader, InfoIconTooltip, CellDropdown, handleHorizontalScroll } from '../Table/Columns';


export const ClientsColumns = (data,
                               activeFilter,
                               orderDirection,
                               stateChangeLoadingId,
                               setOrderBy,
                               currentLocale,
                               openNotesModal,
                               openExtraLeadModal,
                               handleEnquiryStatus) => React.useMemo(
    () =>
      [
        //===========================================================================>

        { Header: <TableFilterHeader
                    headline={I18n.t("reports.providers_order_report.created_at")}
                    orderDirection={orderDirection}
                    handleOrderBy={() => setOrderBy && setOrderBy('created_at')}
                    isActive={activeFilter === 'created_at'} />,

          accessor: 'createdAtFormatted',

          Cell: data =>
            <Link to={`${currentLocale}/new_providers/clients/${data.cell.row.original.clientId}`}>
              { data.value }
            </Link>,
        },

        //===========================================================================>

        { Header: I18n.t("providers.clients.show.contact_info"),

          accessor: 'contactInfo',

          Cell: ({ data }) => {
            const { clientOrOrderCompanyName, clientOrOrderPersonName, clientOrOrderEmail, clientOrOrderPhone } = data[0];

            return (
              <div>
                <p  className="client-info-text">
                  { clientOrOrderCompanyName }<br />
                  { clientOrOrderPersonName }<br />
                  <a href={`mailto: ${clientOrOrderEmail}`}>{clientOrOrderEmail}</a><br />
                  { clientOrOrderPhone }
                </p>
              </div>
            )
          }
        },

        //===========================================================================>

        { Header: <TableFilterHeader
                    headline={I18n.t('generic.company_name')}
                    orderDirection={orderDirection}
                    handleOrderBy={() => setOrderBy && setOrderBy('company_name')}
                    isActive={activeFilter === 'company_name'} />,

          accessor: 'clientOrOrderCompanyName',

          Cell: data =>
            <Link to={`${currentLocale}/new_providers/clients/${data.cell.row.original.clientId}`}>
              { data.value }
            </Link>,
        },

        //===========================================================================>

        { Header: <TableFilterHeader
                    headline={I18n.t('generic.name')}
                    orderDirection={orderDirection}
                    handleOrderBy={() => setOrderBy && setOrderBy('person_name')}
                    isActive={activeFilter === 'person_name'} />,

          accessor: 'clientOrOrderPersonName',

          Cell: data =>
            <Link to={`${currentLocale}/new_providers/clients/${data.cell.row.original.clientId}`}>
              { data.value }
            </Link>,
        },

        //===========================================================================>

        { Header: <TableFilterHeader
                    headline={I18n.t('generic.phone')}
                    orderDirection={orderDirection}
                    handleOrderBy={() => setOrderBy && setOrderBy('phone_number')}
                    isActive={activeFilter === 'phone_number'} />,

          accessor: 'clientOrOrderPhone',

          Cell: data => <a href={`tel://${data.value}`}>{ data.value }</a>,
        },

        //===========================================================================>

        { Header: I18n.t("generic.email"),
          accessor: 'clientOrOrderEmail',
          Cell: data => <a href={`mailto:${data.value}`}>{ data.value }</a>,
        },

        //===========================================================================>

        { Header: I18n.t("generic.comment"),
          style: { maxWidth: '340px', minWidth: '340px' },
          accessor: 'comment',
          Cell: data => <div className="google-translate-helper">{ data.value }</div>,
        },

        //===========================================================================>

        { Header:
            <div>{ I18n.t('providers.notes.list.title') }
              <div id="orders-scroll-end-target" className="scroll-end-target" />
            </div>,
          accessor: 'clientNotesCount',

          Cell: data =>
            <div onClick={() => openNotesModal(data.cell.row.original.clientId)} className="table-notes-cell">
              <img src={NotesImg} width={15} height={19} alt="Note"/>
              <p>({ data.value })</p>
            </div>
        },

        //===========================================================================>

        { Header:
            <div>{ I18n.t("generic.type") }
              <div id="orders-sticky-start-target" className="sticky-start-target" />
            </div>,
          accessor: 'orderContextTranslated',

          Cell: data => {

            const { id, enquiries } = data.cell.row.original;

            return enquiries ?
              <div className="with-icon">
                <div className="sticky-start-target" />
                { data.value }
                { enquiries[0].is_extra_lead && <InfoIconTooltip tooltip={I18n.t('helpers.ui_helper.type_popup_text')} id={id} /> }
              </div>
              : '';

          },
          sticky: 'right',
        },

        //===========================================================================>

        { Header: I18n.t("generic.providers.sidebar.leases"),
          accessor: "Leases",
          width: 290,
          style: { maxWidth: '290px', minWidth: '290px' },

          Cell: data => {

            const { enquiries, id } = data.cell.row.original;
            const isAlreadyKnown = enquiries && enquiries.find(enquiry => enquiry.already_known);

            if (isAlreadyKnown) return I18n.t('providers.orders.enquiries_with_state.already_known');

            return enquiries ?
              <div className="table-list">
                { enquiries.map(item => {

                  const { location_id, enquiry_id, address, is_extra_lead } = item;

                  return (
                    <div className="order-leases-cell"
                         key={'leases-cell' + location_id}
                         id={`${enquiry_id}${location_id}`}>

                      <Link to={`${currentLocale}/new_providers/locations/info/${location_id}`} key={location_id}>
                        { address }
                      </Link>

                      { is_extra_lead &&
                        <Fragment>
                          <div className="extra-lead-tooltip"
                               onClick={() => openExtraLeadModal(id)}
                               data-tip={I18n.t('helpers.ui_helper.vip_alt_title')}
                               data-class="table-tooltip"
                               data-for={`extra-lead-tooltip`}>
                            { I18n.t('providers.orders.list.see_extra_order') }
                          </div>
                          <ReactTooltip
                            place='top'
                            effect='solid'
                            id="extra-lead-tooltip"
                            type="light"
                            border={true}
                            borderColor="black"/>
                        </Fragment>
                      }
                    </div>
                  )}
                )}
              </div>
              : '';
          },
          sticky: 'right',
        },

        //===========================================================================>

        { Header: I18n.t("providers.statistics.index.status"),
          accessor: 'locationStates',
          width: 155,

          Cell: data => {

            const { enquiries, clientId, id } = data.cell.row.original;
            const isAlreadyKnown = enquiries && enquiries.find(enquiry => enquiry.already_known);

            if (isAlreadyKnown) {

              const { enquiry_id, enquiry_state } = isAlreadyKnown;

              return (
                <div className="table-button" onClick={() => handleEnquiryStatus(isAlreadyKnown, clientId, enquiry_state)}>
                  <span>
                    { stateChangeLoadingId === enquiry_id && <Loader size={14} left={-22} top={-2} /> }
                    { I18n.t('generic.undo') }
                  </span>
                </div>
              )
            }

            if (enquiries) {
              return (
                <div className="table-list">
                  { enquiries.map(enquiry => {

                    const { enquiry_id, location_id, enquiry_state, available_enquiry_states } = enquiry;
                    const enquiryStatesOptions = Object.keys(available_enquiry_states).map(state => ({ value: state, label: available_enquiry_states[state] }));
                    const isLoading = stateChangeLoadingId === enquiry_id;

                    return <CellDropdown
                             options={enquiryStatesOptions}
                             withCallback={true}
                             key={enquiry_id}
                             matchPrevColumn={true}
                             loading={isLoading}
                             onShow={(handleClose) => handleHorizontalScroll('orders', handleClose)}
                             id={`${enquiry_id}${location_id}`}
                             handleChange={(value) => handleEnquiryStatus(enquiry, clientId, value, id)}
                             value={enquiry_state === 'resolved' ? 'deal_confirmed' : enquiry_state} />
                  })}
                </div>
              )
            } else {
              return '';
            }
          },
          sticky: 'right',
        },

        //===========================================================================>
      ],
  [data, activeFilter, stateChangeLoadingId]);
