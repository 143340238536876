import React from 'react';

import ProviderModal from "./ProviderModal";
import Notes from "../ClientInfo/Notes";

import './NotesModal.sass';


const NotesModal = ({ handleClose, id, orderNotes, showModal }) => {

  return (
    <ProviderModal modalClass="notes-modal"
                   showModal={showModal}
                   handleClose={handleClose}>
      <Notes currentId={id} modal={true} orderNotes={orderNotes} />
    </ProviderModal>
  )
};


export default NotesModal;
