import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import ReaportDealTable from './ReaportDealTable';
import Details from './Details';
import { Context } from "./index";

function ReportSteps() {

  const {
    clientPage,
    currentLocale,
    handleOtherClient,
    handleOtherLocationAddress,
    loadingLocations,
    loadingOrders,
    locationId,
    locationsColumns,
    locationsList,
    locationsPage,
    locationsTotalCount,
    locationQuery,
    orders,
    ordersColumns,
    ordersTotalCount,
    orderQuery,
    otherClient,
    otherLocationAddress,
    providerId,
    providerOnAdvertisingStrategy,
    schemaName,
    showModal,
    selectedLocationStatus,
    setClientPage,
    setLocationsPage,
    siteKey,
    siteName,
    step,
    submitHandler,
    changeSearchInput,
    nextStep,
    prevStep,
  } = useContext(Context);

  const hideInternalIdentifierColumn = !locationsList.some(data => data.internalIdentifier);

  const steps = () => {
    switch (step) {
      case 1:
        return (
          <ReaportDealTable
            changeSearchInput={changeSearchInput}
            columns={ordersColumns}
            data={orders}
            description={I18n.t('providers.leases.new.step_1_heading')}
            filtersData={clientPage}
            handleFilters={setClientPage}
            handleInput={handleOtherClient}
            inputValue={otherClient}
            loading={loadingOrders}
            nextStep={nextStep}
            query={orderQuery}
            step={step}
            title={I18n.t('providers.providers.show.customers')}
            totalCount={ordersTotalCount}
          />
        );
      case 2:
        return (
          <ReaportDealTable
            backButton
            changeSearchInput={changeSearchInput}
            columns={locationsColumns}
            data={locationsList}
            description={I18n.t('providers.leases.new.step_2_heading')}
            filtersData={locationsPage}
            handleFilters={setLocationsPage}
            handleInput={handleOtherLocationAddress}
            hiddenColumns={hideInternalIdentifierColumn ? ['internalIdentifier'] : null}
            inputValue={otherLocationAddress}
            loading={loadingLocations}
            locations
            nextStep={nextStep}
            prevStep={prevStep}
            query={locationQuery}
            step={step}
            title={I18n.t('generic.providers.sidebar.leases')}
            totalCount={locationsTotalCount}
          />
        );
      case 3:
        return (
          <Details
            currentLocale={currentLocale}
            step={step}
            title={I18n.t('generic.details')}
            description={I18n.t('providers.leases.new.enter_information')}
            prevStep={prevStep}
            submitHandler={submitHandler}
            showModal={showModal}
            siteName={siteName}
            locationId={locationId}
            schemaName={schemaName}
            selectedLocationStatus={selectedLocationStatus}
            siteKey={siteKey}
            providerId={providerId}
            providerOnAdvertisingStrategy={providerOnAdvertisingStrategy}
            backButton
          />
        );
      default:
      // do nothing
    }
  };

  return <div>{ steps() }</div>;
}

ReportSteps.propTypes = {
  siteKey: PropTypes.string,
  siteName: PropTypes.string,
  providerOnAdvertisingStrategy: PropTypes.bool,
  step: PropTypes.number,
  orders: PropTypes.oneOfType([PropTypes.array]),
  locationsList: PropTypes.oneOfType([PropTypes.array]),
  nextStep: PropTypes.func,
  prevStep: PropTypes.func,
  submitHandler: PropTypes.func,
  changeSearchInput: PropTypes.func,
  handleOtherClient: PropTypes.func,
  clientPage: PropTypes.number,
  locationId: PropTypes.string,
  showModal: PropTypes.bool,
  otherClient: PropTypes.string,
  handleOtherLocationAddress: PropTypes.func,
  otherLocationAddress: PropTypes.string,
  orderQuery: PropTypes.string,
  locationQuery: PropTypes.string,
};

ReportSteps.defaultProps = {
  siteKey: '',
  siteName: '',
  providerOnAdvertisingStrategy: false,
  step: 1,
  orders: [],
  locationsList: [],
  nextStep: () => true,
  prevStep: () => true,
  submitHandler: () => true,
  changeSearchInput: () => true,
  handleOtherClient: () => true,
  clientPage: 1,
  locationId: '',
  showModal: false,
  otherClient: '',
  handleOtherLocationAddress: () => true,
  otherLocationAddress: '',
  orderQuery: '',
  locationQuery: '',
};

export default ReportSteps;
