import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { SvgIco } from "../../../SharedComponents/Icons";
import {
  ADD_CLIENT_NOTE,
  ADD_LOCATION_NOTE,
  GET_CLIENT_NOTES,
  GET_LOCATION_NOTES,
  UPDATE_CLIENT_NOTE,
} from '../../ApolloClient/queries';
import DeleteNoteModal from '../../Modals/DeleteNoteModal';

import './index.sass';

function Notes({ currentId, modal, orderNotes }) {
  const notesWrapperRef = useRef();

  const [note, setNotes] = useState('');
  const [editNoteId, setEditNoteId] = useState('');
  const [deleteNoteId, setDeleteNoteId] = useState(null);
  const [clientNotes, setClientNotes] = useState([]);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const { data: notes } = orderNotes
    ? useQuery(GET_CLIENT_NOTES, { variables: { clientId: Number(currentId) } })
    : useQuery(GET_LOCATION_NOTES, {
        variables: { locationId: Number(currentId) },
      });
  const [addNote] = orderNotes
    ? useMutation(ADD_CLIENT_NOTE)
    : useMutation(ADD_LOCATION_NOTE);

  const [updateClientNote] = useMutation(UPDATE_CLIENT_NOTE);

  useEffect(() => {
    if (notes) {
      const checkNotes = orderNotes ? 'clientNotes' : 'locationNotes';
      setClientNotes(notes[checkNotes]);
    }
  }, [notes]);

  const noteSubmit = () => {
    const updateNotesAfterRefetch = orderNotes
      ? ['clientNotes', 'getOrders']
      : ['locationNotes', 'getLocations'];
    const updateAfterEditNote = orderNotes
      ? ['clientNotes']
      : ['locationNotes'];
    const input = orderNotes
      ? { clientId: Number(currentId), note }
      : { locationId: Number(currentId), note };
    const updateInput = { id: parseInt(editNoteId), note };

    editNoteId
      ? updateClientNote({
          variables: { input: updateInput },
          refetchQueries: updateAfterEditNote,
          onCompleted: () => {
            setNotes('');
            setEditNoteId('');
          },
        })
      : addNote({
          variables: { input },
          refetchQueries: updateNotesAfterRefetch,
          onCompleted: () => {
            setNotes('');
          },
        });
  };

  const scrollToTextarea = () => {
    const notesWrapper = notesWrapperRef.current;

    if (modal) {
      notesWrapper.scrollTop = 0;
    } else {
      const targetTop = notesWrapper.getBoundingClientRect().top;
      const targetCoords = Math.floor(targetTop + window.scrollY - 24);

      window.scroll(0, targetCoords);
    }
  };

  const updateNote = (id, note) => {
    setNotes(note);
    setEditNoteId(id);
    scrollToTextarea();
  };

  const closeDeleteModal = () => {
    setNotes('');
    setEditNoteId('');
    setDeleteModalIsOpen(false);
  };

  const openDeleteModal = (id) => {
    setDeleteNoteId(id);
    setDeleteModalIsOpen(true);
  };

  return (
    <div className="notes-wrapper" ref={notesWrapperRef}>
      <DeleteNoteModal
        handleClose={closeDeleteModal}
        noteId={deleteNoteId}
        refetchQueries={
          orderNotes
            ? ['clientNotes', 'getOrders']
            : ['locationNotes', 'getLocations']
        }
        showModal={deleteModalIsOpen}
      />

      <p className="notes-wrapper__main-title">
        {I18n.t('providers.notes.list.title')}
      </p>

      <div className="row">
        <div className={`${modal ? 'col-md-12' : 'col-md-6'}`}>
          <textarea
            name="text"
            placeholder={I18n.t('providers.notes.list.add_notes_placeholder')}
            value={note}
            onChange={(e) => setNotes(e.target.value)}
          />

          <div>
            <button
              type="button"
              onClick={noteSubmit}
              className="notes-wrapper__add-notes-button"
            >
              {editNoteId
                ? I18n.t('generic.update_button')
                : I18n.t('providers.notes.new.add_new_note')}
            </button>
          </div>

          {clientNotes.map((noteItem) => {
            const { id, author, note, createdAtFormatted } = noteItem;

            return (
              <div className="notes-wrapper__notes" key={id}>
                <div className="notes-edit-block">
                  <div className="notes-author">{author.name}</div>
                  <div>
                    <SvgIco
                      className="edit"
                      name="edit"
                      onClick={() => updateNote(id, note)}
                      size={24}
                    />
                    &nbsp;{' '}
                    <SvgIco
                      className="delete"
                      name="delete_outline"
                      onClick={() => openDeleteModal(id)}
                      size={24}
                    />
                  </div>
                </div>
                <p className="notes-text">{note}</p>
                <div className="notes-date">{createdAtFormatted}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

Notes.propTypes = {
  currentId: PropTypes.string,
  modal: PropTypes.bool,
  orderNotes: PropTypes.oneOfType([PropTypes.array]),
};

Notes.defaultProps = {
  currentId: '',
  modal: false,
  orderNotes: [],
};

export default Notes;
