import React, { Fragment, useContext } from 'react';
import ReactTooltip from 'react-tooltip';

import RadioButtons from '../../../SharedComponents/Forms/RadioButtons';
import { SvgIco } from "../../../SharedComponents/Icons";
import { Context } from '../index';


const RadioFormGroup = ({
    label,
    field,
    tooltip,
    htmlTooltip,
    activeClass,
  }) => {

  const { handleChange, values, activeSection } = useContext(Context);

  const options = [
    { label: I18n.t('generic.yes'), value: true, checked: values[field] },
    { label: I18n.t('generic.no'), value: false, checked: !values[field] },
  ];

  return (
    <div className="form-group form-group--radio row">

      { label
        ?
          <div className="form-label form-label--align-top col-xl-3 col-lg-3">
            <span style={tooltip ? { marginRight: '8px' } : null}>{label}</span>

            { tooltip &&
              <Fragment>
              <span
                data-tip={tooltip}
                data-class="provider-tooltip"
                data-html={htmlTooltip}
                data-for={`${field}-tooltip`}
                className="provider-tooltip"
              >
                <SvgIco name="info_outline" size={24} />
              </span>
                <ReactTooltip
                  border={true}
                  borderColor="black"
                  effect="solid"
                  id={`${field}-tooltip`}
                  place="right"
                  type="light"
                />
              </Fragment>
            }
          </div>
        :
          <div className="form-label form-label--align-top col-lg-1" />
      }

      <RadioButtons
        activeClass={activeClass}
        handleChange={(value) => handleChange(value, field, activeSection)}
        name={field}
        options={options}
        wrapperClass="col-xl-3 col-lg-3"
      />
    </div>
  );
};

export default RadioFormGroup;
