import React, { useEffect, useState } from 'react';
import { useLazyQuery } from "@apollo/client";

import { localizedNumberFormatter } from "../../../utils/helpers";
import { SvgIco } from "../../SharedComponents/Icons";
import { GET_LOCATION_STATISTICS } from "../ApolloClient/queries";
import { SkeletonLoader } from "../Loaders";
import LocationViewsChart from "../LocationViewsChart";

import './index.sass';

let timeout;


const ExpandedStatistics = ({ data }) => {

  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedData, setExpandedData] = useState({});


  const {
    countClientEnquiries,
    countClientEnquiriesPerWeek,
    countExtraEnquiries,
    countExtraEnquiriesPerWeek,
    countProviderMessages,
    countShowcaseOrders,
    locationEventsContactDetailsShownCount,
    locationEventsProviderAdvertsShownCount,
    locationEventsShownCount,
    locationEventsShownCountPerWeek,
  } = expandedData;


  useEffect(() => {
    setIsExpanded(data.expanded);
  }, []);


  useEffect(() => {
    clearTimeout(timeout);

    if (data.expanded) {
      setIsExpanded(data.expanded);
    } else {
      timeout = setTimeout(() => setIsExpanded(data.expanded), 500);
    }
  }, [data.expanded]);


  const [getLocationStatistic, { loading }] = useLazyQuery(GET_LOCATION_STATISTICS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      data && data.statisticForLocation && setExpandedData(data.statisticForLocation);
    },
  });


  const getLocationStatisticsByPeriod = (daysPeriod) => {
    const timeFrom = new Date();
    const timeTo = new Date();

    timeFrom.setDate(timeFrom.getDate() - daysPeriod - 1);
    timeFrom.setHours(0,0,0,0);

    const timeFromUserTimezoneOffset = timeFrom.getTimezoneOffset() * 60000;
    const timeToUserTimezoneOffset = timeTo.getTimezoneOffset() * 60000;

    getLocationStatistic({ variables: {
        timeFrom: new Date(timeFrom.getTime() - timeFromUserTimezoneOffset),
        timeTo: new Date(timeTo.getTime() - timeToUserTimezoneOffset),
        id: data.locationId,
    }}).catch(error => console.log('error', error));
  };


  // We need to use useMemo to prevent charts SVG form rerender on data update
  const cachedViewsChart = React.useMemo(
    () =>
      <LocationViewsChart
        createdAt={data.createdAt}
        switchPeriodCallback={getLocationStatisticsByPeriod}
        locationId={data.locationId}
        locationShownDatapoints={data.locationShownDatapoints || {}}
        offset={{ left: -80 }}
        tickFontSize={9}
        width={321}
        xaxisHeight={43}
      />,
    [],
  );


  if (isExpanded) {
    return (
      <div className="statistics__expanded-sticky-wrapper">
        <div className="statistics__expanded-data">
          <div className="statistics__expanded-data-row">

            <div className="statistics__chart-block">
              { cachedViewsChart }
            </div>

            <div className={`statistics__expanded-boxes ${loading ? 'loading' : ''}`}>
              <div className="statistics__expanded-boxes-column">

                <div className="statistics__expanded-box">
                  <SvgIco name="eye" size={40} />

                  <div className="statistics__expanded-box-info">
                    <div className="statistics__expanded-box-headline">
                      { I18n.t('provider.statistic.expanded_info.page_views') }
                    </div>

                    <span>{ I18n.t('provider.table.total_footer') }: <strong>{ localizedNumberFormatter(locationEventsShownCount) }</strong>
                      <SkeletonLoader className="statistics__skeleton-loader" />
                    </span>

                    <span>{ I18n.t('generic.per_week') }: <strong>{ localizedNumberFormatter(locationEventsShownCountPerWeek) }</strong>
                      <SkeletonLoader className="statistics__skeleton-loader" />
                    </span>
                  </div>
                </div>

                <div className="statistics__expanded-box">
                  <SvgIco name="person_send" size={40} />

                  <div className="statistics__expanded-box-info">
                    <div className="statistics__expanded-box-headline">
                      { I18n.t('providers.statistics.index.client_enquiries') }
                    </div>

                    <span>{ I18n.t('provider.table.total_footer') }: <strong>{ localizedNumberFormatter(Number(countClientEnquiries) + Number(countProviderMessages) + Number(countShowcaseOrders)) }</strong>
                      <SkeletonLoader className="statistics__skeleton-loader" />
                    </span>

                    <span>{ I18n.t('generic.per_week') }: <strong>{ localizedNumberFormatter(countClientEnquiriesPerWeek) }</strong>
                      <SkeletonLoader className="statistics__skeleton-loader" />
                    </span>
                  </div>
                </div>
              </div>

              <div className="statistics__expanded-boxes-column">
                <div className="statistics__expanded-box">
                  <SvgIco name="click" size={40} />

                  <div className="statistics__expanded-box-info">
                    <div className="statistics__expanded-box-headline">
                      { I18n.t('generic.clicks') }
                    </div>

                    <span>{ I18n.t('provider.statistic.expanded_info.personal_website') }: <strong>{ localizedNumberFormatter(locationEventsProviderAdvertsShownCount) }</strong>
                      <SkeletonLoader className="statistics__skeleton-loader" />
                    </span>

                    <span>{ I18n.t('providers.statistics.index.contact_info') }: <strong>{ localizedNumberFormatter(locationEventsContactDetailsShownCount) }</strong>
                      <SkeletonLoader className="statistics__skeleton-loader" />
                    </span>
                  </div>
                </div>

                <div className="statistics__expanded-box">
                  <SvgIco name="person_add" size={40} />

                  <div className="statistics__expanded-box-info">
                    <div className="statistics__expanded-box-headline">
                      { I18n.t('providers.statistics.index.extra_enquiries') }
                    </div>

                    <span>{ I18n.t('provider.table.total_footer') }: <strong>{ localizedNumberFormatter(countExtraEnquiries) }</strong>
                      <SkeletonLoader className="statistics__skeleton-loader" />
                    </span>

                    <span>{ I18n.t('generic.per_week') }: <strong>{ localizedNumberFormatter(countExtraEnquiriesPerWeek) }</strong>
                      <SkeletonLoader className="statistics__skeleton-loader" />
                    </span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return null;
  }
}

export default ExpandedStatistics;
