export const nameError = I18n.t('apps.lb_showroom.misc.scoped_form.name.invalid');
export const emailError = I18n.t('apps.lb_showroom.detailed.order_form.validation.wrong_email');
export const companyError =  I18n.t('apps.lb_showroom.misc.scoped_form.company.invalid');
export const phoneError = I18n.t('apps.lb_showroom.misc.scoped_form.phone.invalid');
export const postalCodeError = I18n.t('activerecord.errors.models.postal_code.attributes.postal_code.invalid');
export const eanError = I18n.t('apps.lb_showroom.detailed.order_form.validation.ean_error');
export const vatIdError = I18n.t('apps.lb_showroom.misc.scoped_form.vat_id.invalid');
export const addressError = I18n.t('apps.lb_showroom.misc.scoped_form.address.invalid');
export const cityError = I18n.t('apps.lb_showroom.misc.scoped_form.city.invalid');
export const manySimilarSymbolsError = I18n.t('apps.lb_showroom.detailed.order_form.validation.too_many_similar_symbols');
export const atLeastOneLetterError = I18n.t('apps.lb_showroom.detailed.order_form.validation.at_least_one_letter');
export const atLeastEightDigitsError = I18n.t('apps.lb_showroom.detailed.order_form.validation.at_least_8_digits');

export const symbolsLimitError = (symbols_limit) => I18n.t('apps.lb_showroom.misc.scoped_form.max_symbols_limit', { symbols_limit });
export const requiredFieldError = (field) => I18n.t('apps.lb_showroom.detailed.order_form.validation.field_required', { field });
export const minNumberError = (field) => I18n.t('apps.lb_showroom.detailed.order_form.validation.min_number', { field });

