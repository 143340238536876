import React, {useContext} from 'react';
import classNames from 'classnames';

import { SvgIco } from "../../../SharedComponents/Icons";
import { Context } from "../index";

import './index.sass';


const NavigationButtons = ({ handleNext, isSubmit, handleBack, requiredFieldsValid }) => {

  const { activeSection,
          addLocationLoading,
          editPage,
          editedValuesFields,
          handleEdit,
          validateFields,
          validateLocationLoading,
          updateLocationValuesLoading,
        } = useContext(Context);

  const nextButtonClasses = classNames('navigation-buttons__next', { 'submit': isSubmit }, { 'disabled': addLocationLoading || validateLocationLoading });
  const updateButtonClasses = classNames('navigation-buttons__edit', {
    'active': editPage && editedValuesFields.length,
    'loading': updateLocationValuesLoading });

  const handleEditValues = () =>
    requiredFieldsValid
      ? requiredFieldsValid() && validateFields(false, handleEdit)
      : validateFields(false, handleEdit);


  return (
    <div className="navigation-buttons">

      { handleBack &&
        <div className="navigation-buttons__back-link" onClick={handleBack}>
          <SvgIco name="arrow-back" size={24} />
          <div className="navigation-buttons__back-text">
            <span>{ I18n.t('generic.back') }</span>
          </div>
        </div>
      }

      <div className="navigation-buttons__right-block">
        { editPage && activeSection !== 'visuals' &&
          <div className={updateButtonClasses} onClick={handleEditValues}>
            { I18n.t('generic.save_changes') }
          </div>
        }

        { handleNext &&
          <div className={nextButtonClasses} onClick={handleNext}>
            { isSubmit ? I18n.t('provider.form.complete_button') : I18n.t('generic.next') }
            { !isSubmit && <SvgIco name="arrow_forward" size={24} /> }
          </div>
        }
      </div>
    </div>
  )
}

export default NavigationButtons;
