import React from "react";
import { Link } from "react-router-dom";

import { localizedNumberFormatter } from "../../../utils/helpers";
import { SvgIco } from "../../SharedComponents/Icons";


export const StatisticColumns = (statisticData, statisticDataTotal, currentLocale, handleExpand, currencySymbolFormatter) => React.useMemo(
  () =>
      [

        //===========================================================================>

        { Header: I18n.t('providers.statistics.index.address'),
          accessor: 'locationAddress',
          style: { maxWidth: '266px', minWidth: '266px' },
          Footer: I18n.t('provider.table.total_footer'),
          Cell: data =>
            <div className="statistics__address-cell">
              { data.cell.row.original.expanded
                ? <SvgIco name="expand-less" size={18} onClick={() => handleExpand(data.cell.row.original.locationId)} />
                : <SvgIco name="expand-more" size={18} onClick={() => handleExpand(data.cell.row.original.locationId)} />
              }

              <Link to={`${currentLocale}/new_providers/locations/info/${data.cell.row.original.locationId}`}>
                { data.value }
              </Link>
            </div>,
        },

        //===========================================================================>

        { Header: I18n.t('providers.statistics.index.kind'),
          Footer: '',
          accessor: 'locationKind',
          style: { whiteSpace: 'nowrap' },
          Cell: data => <div className="google-translate-helper">{ data.value }</div>
        },

        //===========================================================================>

        { Header: I18n.t('providers.statistics.index.status'),
          Footer: '',
          accessor: 'locationState',
          Cell: data => <div className="google-translate-helper">{ data.value }</div>
        },

        //===========================================================================>

        { Header: <div className="align-right">{ I18n.t('activerecord.attributes.location.area_interval') }</div>,
          Footer: '',
          accessor: 'locationFullArea',
          style: { textAlign: 'right' },
          Cell: data =>
            <div style={{ whiteSpace: "nowrap" }}>
              { data.value
                ? `${data.value} ${data.cell.row.original.locationAreaUnit === 'ft2' ? 'ft²' : 'm²'}`
                : ''
              }
            </div>,
        },

        //===========================================================================>

        { Header: <div className="align-right">{ I18n.t('providers.statistics.index.yearly_rent_per_m2_min') }</div>,
          Footer: '',
          accessor: 'locationYearlyRentPerM2',
          style: { textAlign: 'right' },
          Cell: data =>
            <div className="google-translate-helper">
              { data.value
                ? localizedNumberFormatter(Number(data.value.replaceAll(' ', '')), currencySymbolFormatter(data.cell.row.original.currencyId, true))
                : ''
              }
            </div>,
        },

        //===========================================================================>

        { Header:
            <div className="align-right">
              { I18n.t('providers.statistics.index.days_active', { days_in_period: statisticData.length ? statisticData[0].daysInPeriod : 0 }) }
            </div>
            ,
          Footer: '',
          style: { textAlign: 'right' },
          accessor: 'locationCountDaysActive',
          Cell: data => <div className="google-translate-helper">{ data.value ? localizedNumberFormatter(Number(data.value)) : 0 }</div>
        },

        //===========================================================================>

        { Header: <div className="align-right">{ I18n.t('providers.statistics.index.shown_count') }</div>,
          Footer: statisticDataTotal.locationEventsShownCount ? localizedNumberFormatter(Number(statisticDataTotal.locationEventsShownCount)) : '0',
          style: { textAlign: 'right' },
          accessor: 'locationEventsShownCount',
          Cell: data => <div className="google-translate-helper">{ data.value ? localizedNumberFormatter(Number(data.value)) : 0 }</div>
        },

        //===========================================================================>

        { Header: <div className="align-right">{ I18n.t('providers.statistics.index.client_enquiries') }</div>,
          Footer: (statisticDataTotal && Object.keys(statisticDataTotal).length && localizedNumberFormatter(Number(statisticDataTotal.countClientEnquiries) + Number(statisticDataTotal.countProviderMessages) + Number(statisticDataTotal.countShowcaseOrders))) || 0,
          accessor: 'countClientEnquiries',
          style: { textAlign: 'right' },
          Cell: data =>
            <div className="google-translate-helper">
              { localizedNumberFormatter(Number(data.value) + Number(data.cell.row.original.countProviderMessages) + Number(data.cell.row.original.countShowcaseOrders)) || 0 }
            </div> ,
        },

        //===========================================================================>

        // { Header: I18n.t('providers.statistics.index.provider_message_enquiries'),
        //   Footer: statisticDataTotal.countProviderMessages || '0',
        //   accessor: 'countProviderMessages',
        // },

        //===========================================================================>

        // { Header: I18n.t('providers.statistics.index.book_showcase_enquiries'),
        //   Footer: statisticDataTotal.countShowcaseOrders || '0',
        //   accessor: 'countShowcaseOrders',
        // },

        //===========================================================================>

        { Header: <div className="align-right">{ I18n.t('providers.statistics.index.extra_enquiries') }</div>,
          Footer: statisticDataTotal.countExtraEnquiries ? localizedNumberFormatter(statisticDataTotal.countExtraEnquiries) : '0',
          style: { textAlign: 'right' },
          accessor: 'countExtraEnquiries',
          Cell: data => <div className="google-translate-helper">{ data.value ? localizedNumberFormatter(Number(data.value)) : 0 }</div>,
        },

        //===========================================================================>

        { Header: <div className="align-right">{ I18n.t('providers.statistics.index.contact_info') }</div>,
          Footer: statisticDataTotal.locationEventsContactDetailsShownCount ? localizedNumberFormatter(statisticDataTotal.locationEventsContactDetailsShownCount) : '0',
          style: { textAlign: 'right' },
          accessor: 'locationEventsContactDetailsShownCount',
          Cell: data => <div className="google-translate-helper">{ data.value ? localizedNumberFormatter(Number(data.value)) : 0 }</div>
        },

        //===========================================================================>

        { Header: <div className="align-right">{ I18n.t('providers.statistics.index.provider_info') }</div>,
          Footer: statisticDataTotal.locationEventsProviderAdvertsShownCount ? localizedNumberFormatter(statisticDataTotal.locationEventsProviderAdvertsShownCount) : '0',
          style: { textAlign: 'right', paddingRight: '24px' },
          accessor: 'locationEventsProviderAdvertsShownCount',
          Cell: data => <div className="google-translate-helper">{ data.value ? localizedNumberFormatter(Number(data.value)) : 0 }</div>
        },

        //===========================================================================>

      ],
  [statisticData, statisticDataTotal]
);
