import React from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import NotesImg from "../../../src/images/provider/note.svg";
import { SvgIco } from "../../SharedComponents/Icons";
import TooltipPopup from "../Popups/TooltipPopup";
import {
  CellDropdown,
  handleHorizontalScroll,
  InfoIconTooltip,
  TableFilterHeader,
  TableHashLink
} from "../Table/Columns";


export const LocationsColumns = (currentLocale,
                                 data,
                                 providerApproved,
                                 activeFilter,
                                 orderDirection,
                                 stateUpdateSuccess,
                                 stateChangeCallback,
                                 stateChangeLoadingId,
                                 setOrderBy,
                                 handleLocationState,
                                 openNotesModal) => React.useMemo(
    () =>
      [
        //===========================================================================>

        { Header: I18n.t('generic.actions'),
          accessor: 'manage-icons',

          Cell: data =>
            <div className="table-actions-wrapper">
              <ReactTooltip
                border={true}
                borderColor="black"
                effect='solid'
                id="location-show-tooltip"
                place='top'
                type="light" />

              <Link to={`${currentLocale}/new_providers/locations/info/${data.cell.row.original.id}`}
                    className="table-action-link"
                    data-for="location-show-tooltip"
                    data-tip={I18n.t('provider.show_location')}>
                <SvgIco name="visibility" size={20} />
              </Link>

              <ReactTooltip
                border={true}
                borderColor="black"
                effect='solid'
                id="location-edit-tooltip"
                place='top'
                type="light" />

              <Link to={`${currentLocale}/new_providers/locations/edit/${data.cell.row.original.id}/location`}
                    className="table-action-link"
                    data-for="location-edit-tooltip"
                    data-tip={I18n.t('provider.locations.edit_location')}>
                <SvgIco name="edit" size={20} />
              </Link>
            </div>
        },

        //===========================================================================>

        { Header:
            <TableFilterHeader
              handleOrderBy={() => setOrderBy('address_line_1')}
              headline={I18n.t('providers.statistics.index.address')}
              isActive={activeFilter === 'address_line_1'}
              orderDirection={orderDirection} />,

          accessor: 'address',
          style: { maxWidth: '266px', minWidth: '266px' },

          Cell: data =>
            <Link to={`${currentLocale}/new_providers/locations/info/${data.cell.row.original.id}`}>
              { data.value }
            </Link>,
        },

        //===========================================================================>

        { Header:
            <TableFilterHeader
              handleOrderBy={() => setOrderBy('area_from')}
              headline={I18n.t('activerecord.attributes.location.area_interval')}
              isActive={activeFilter === 'area_from'}
              orderDirection={orderDirection}
              style={{ whiteSpace: 'nowrap' }} />,

          accessor: 'fullArea',

          Cell: data =>
            <TableHashLink
              link={`${currentLocale}/new_providers/locations/edit/${data.cell.row.original.id}/economy#areaFrom`}
              offset={102}
              value={data.value ? `${data.value} ${data.cell.row.original.areaUnit || ''}` : ''}
              nowrap
            />
        },

        //===========================================================================>

        { Header: I18n.t('activerecord.attributes.property.category'),
          style: { whiteSpace: 'nowrap' },

          Cell: data => {
            const { id, section, kindTranslated } = data.cell.row.original;
            const translatedSection = I18n.t(`generic.models.property.category.${section}`);

            return (
              <TableHashLink
                additionalClass="capitalize"
                link={`${currentLocale}/new_providers/locations/edit/${id}/economy#kind`}
                offset={102}
                value={`${ section === 'investment' ? translatedSection.one : translatedSection } / ${kindTranslated}`} />
            )
          }
        },

        //===========================================================================>

        { Header:
            <TableFilterHeader
              handleOrderBy={() => setOrderBy('updated_at_by_provider_only')}
              headline={I18n.t('activerecord.attributes.location.updated_at')}
              isActive={activeFilter === 'updated_at_by_provider_only'}
              orderDirection={orderDirection} />,

          accessor: 'updatedAtByProviderOnlyFormatted',

          Cell: data =>
            <Link to={`${currentLocale}/new_providers/locations/edit/${data.cell.row.original.id}/location`}>
              { data.value }
            </Link>
        },

        //===========================================================================>

        { Header: I18n.t('activerecord.attributes.location.primary_contact'),
          accessor: 'primaryContactName',
          Cell: data => <div className="google-translate-helper">{ data.value }</div>,
        },

        //===========================================================================>

        { Header:
            <TableFilterHeader
              handleOrderBy={() => setOrderBy('photo_assets')}
              headline={I18n.t('lb_showroom.detailed.asset_box.new_photos_button')}
              isActive={activeFilter === 'photo_assets'}
              orderDirection={orderDirection} />,

          accessor: 'photosCount',

          Cell: data =>
            <TableHashLink
              link={`${currentLocale}/new_providers/locations/edit/${data.cell.row.original.id}/visuals#photo`}
              offset={160}
              value={data.value} />
        },

        //===========================================================================>

        { Header:
            <TableFilterHeader
              handleOrderBy={() => setOrderBy('floor_plan_assets')}
              headline={I18n.t('providers.floor_plans.form.description')}
              isActive={activeFilter === 'floor_plan_assets'}
              orderDirection={orderDirection}
              style={{ whiteSpace: 'nowrap' }} />,

          accessor: 'floorPlansCount',

          Cell: data =>
            <TableHashLink
              link={`${currentLocale}/new_providers/locations/edit/${data.cell.row.original.id}/visuals#floor-plans`}
              offset={160}
              value={data.value} />
        },

        //===========================================================================>

        { Header:
            <TableFilterHeader
              additionalClass="capitalize"
              handleOrderBy={() => setOrderBy('prospectus_assets')}
              headline={I18n.t('reports.providers_order_report.prospectuses')}
              isActive={activeFilter === 'prospectus_assets'}
              orderDirection={orderDirection} />,

          accessor: 'prospectusCount',

          Cell: data =>
            <TableHashLink
              link={`${currentLocale}/new_providers/locations/edit/${data.cell.row.original.id}/visuals#prospectuses`}
              offset={160}
              value={data.value} />
        },

        //===========================================================================>

        {
          Header:
            <TableFilterHeader
              handleOrderBy={() => setOrderBy('enquiries_count')}
              headline={I18n.t('reports.providers_order_report.enquiries')}
              isActive={activeFilter === 'enquiries_count'}
              orderDirection={orderDirection} />,

          accessor: 'enquiriesCountInteger',
          Cell: data => <div className="google-translate-helper">{ data.value }</div>,
        },

        //===========================================================================>

        { Header:
            <div className="leases__quality-score-table-header">
              { I18n.t('providers.locations.edit.scores.title') }
              <TooltipPopup
                content={I18n.t('providers.locations.edit.scores.description')}
                icon="info"
                id="score-tooltip"
                marginLeft={8}
                onShow={(handleClose) => handleHorizontalScroll('locations', handleClose)} />
            </div>,

          accessor: 'scoreInPercents',

          Cell: data =>
            <div className="leases__quality-score-table-cell">
              <progress className="location-score-progress" value={data.value} max="100">
                { data.value } / 100
              </progress>
              { `${data.value} / 100` }
            </div>
        },

        //===========================================================================>

        { Header:
            <div>{ I18n.t('providers.notes.list.title') }
              <div id="locations-scroll-end-target" className="scroll-end-target" />
            </div>,
          accessor: 'notesCount',

          Cell: data =>
            <div className="table-notes-cell"
                 onClick={() => openNotesModal(data.cell.row.original.id)}>
              <img
                alt="Note"
                height={19}
                src={NotesImg}
                width={15} />
              <p>({ data.value })</p>
            </div>
        },

        //===========================================================================>

        { Header:
            <div>{ I18n.t('providers.statistics.index.status') }
              <div id="locations-sticky-start-target" className="sticky-start-target" />
            </div>,
          accessor: 'avaliableEnquiryStates',
          sticky: 'right',

          Cell: data => {
            const { id, state, stateInfo, stateTranslated } = data.cell.row.original;
            const statusOptions = data.value && providerApproved && Object.entries(data.value).map(([key, value]) => ({ value: key, label: value }));

            return (
              <div className="leases__status-cell">
                <div className="sticky-start-target" />
                { statusOptions
                  ?
                    <CellDropdown
                      handleChange={(value) => handleLocationState(value, data.row.id)}
                      loading={stateChangeLoadingId === id}
                      onShow={(handleClose) => handleHorizontalScroll('locations', handleClose)}
                      options={statusOptions}
                      value={state}
                      withCallback={true}/>
                  :
                    stateTranslated
                }

                <InfoIconTooltip id={`status-${id}-tooltip`} position="left" tooltip={stateInfo} />
              </div>
            )
          },
        },

        //===========================================================================>

      ], [data, activeFilter, stateChangeLoadingId]
  )
;
