import React from 'react';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import Yup from 'yup';

import Input from '../../SharedComponents/Forms/Input';
import {
  phoneValidation,
  nameValidation,
  emailValidation,
  passwordValidation,
  passwordConfirmationValidation,
} from '../../SharedComponents/Forms/ValidationSchemaPattern';

function CreateNewContactWrapper({
  handleSubmit,
  values,
  handleChange,
  touched,
  handleBlur,
  errors,
  closeModal,
  editContactData,
  editModal,
}) {
  return (
    <div className="create-new">
      <form
        name="createNewContact"
        className="new-contact-form"
        onSubmit={handleSubmit}
        noValidate
      >
        <Input
          name="name"
          value={values.name}
          handleChange={handleChange}
          handleBlur={handleBlur}
          label={I18n.t('generic.name')}
          error={touched.name && errors.name}
          required
        />
        <Input
          name="email"
          value={values.email}
          handleChange={handleChange}
          handleBlur={handleBlur}
          label={I18n.t('generic.email')}
          error={touched.email && errors.email}
          required
        />

        <Input
          name="phoneNumber"
          value={values.phoneNumber}
          handleChange={handleChange}
          handleBlur={handleBlur}
          label={I18n.t('signup.new_form.register.phone_number.placeholder')}
          error={touched.phoneNumber && errors.phoneNumber}
          required
        />

        {!editModal && (
          <>
            <Input
              name="password"
              type="password"
              value={values.password}
              handleChange={handleChange}
              handleBlur={handleBlur}
              label={I18n.t('generic.password')}
              error={touched.password && errors.password}
              required
            />

            <Input
              name="passwordConfirmation"
              type="password"
              value={values.passwordConfirmation}
              handleChange={handleChange}
              handleBlur={handleBlur}
              label={I18n.t('activerecord.attributes.contact.password_confirmation')}
              error={
                touched.passwordConfirmation && errors.passwordConfirmation
              }
              required
            />
          </>
        )}

        <div className="create-new__button-wrapper">
          <button
            type="button"
            onClick={() => closeModal()}
            className="cancel-button b-button"
          >
            {I18n.t('search_agent.modal.cancel')}
          </button>

          <button type="submit" className="save-button b-button">
            {I18n.t('generic.save')}
          </button>
        </div>
      </form>
    </div>
  );
}

const CreateNewContact = withFormik({
  mapPropsToValues: ({ editContactData, editModal }) => ({
    name: editContactData ? editContactData.name : '',
    email: editContactData ? editContactData.email : '',
    phoneNumber: editContactData ? editContactData.phoneNumber : '',
    createNewContact: !editModal,
    password: '',
    passwordConfirmation: '',
  }),
  validationSchema: Yup.object().shape({
    name: nameValidation,
    email: emailValidation,
    phoneNumber: phoneValidation,
    createNewContact: Yup.boolean(),
    password: Yup.string().when('createNewContact', {
      is: true,
      then: passwordValidation,
    }),
    passwordConfirmation: Yup.string().when('createNewContact', {
      is: true,
      then: passwordConfirmationValidation,
    }),
  }),

  handleSubmit: (values, bag) => {
    bag.props.submitForm(values);
  },

  displayName: 'CreateNewContactForm',
})(CreateNewContactWrapper);

CreateNewContactWrapper.propTypes = {
  handleSubmit: PropTypes.func,
  values: PropTypes.oneOfType([PropTypes.object]),
  handleChange: PropTypes.func,
  touched: PropTypes.bool,
  handleBlur: PropTypes.func,
  errors: PropTypes.oneOfType([PropTypes.array]),
  closeModal: PropTypes.bool,
  editContactData: PropTypes.oneOfType([PropTypes.object]),
};

CreateNewContactWrapper.defaultProps = {
  handleSubmit: () => true,
  values: {},
  handleChange: () => true,
  touched: false,
  handleBlur: () => true,
  errors: [],
  closeModal: false,
  editContactData: {},
};

export default CreateNewContact;
