import React, { Fragment, useEffect, useState } from 'react';
import { useLazyQuery } from "@apollo/client";

import ReportImg from "../../../src/images/provider/report.svg";
import { SvgIco } from "../../SharedComponents/Icons";
import ProviderModal from "../Modals/ProviderModal";
import ModalNetworkError from "../Errors/ModalNetworkError";
import { GET_TOKENIZED_FILE } from "../ApolloClient/queries";
import { FileLoader } from "../Loaders";

import './TokenizedFileModalLink.sass';


const TokenizedFileModalLink = ({ dataName, fileName, linkLabel, query, variables }) => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [tokenizedFileUrl, setTokenizedFileUrl] = useState(null);
  const [pollInterval, setPollInterval] = useState(0);
  const [xlsFileToken, setXlsFileToken] = useState(null);

  const [getTokenizedFile, { loading, error }] = useLazyQuery(GET_TOKENIZED_FILE, {
    pollInterval,
    onCompleted: data => {
      if (data.tokenizedFile && data.tokenizedFile.fileUrl) {
        setTokenizedFileUrl(data.tokenizedFile.fileUrl);
        setPollInterval(0);
      }
    },
  });

  const [getXlsFileToken, { error: xlsFileTokenError, loading: xlsFileTokenLoading }] = useLazyQuery(query, {
    fetchPolicy: 'no-cache',
    onCompleted: data => setXlsFileToken(data[dataName][fileName || 'xlsFileToken']),
  });

  useEffect(() => {
    xlsFileToken && getXls();
  }, [xlsFileToken]);


  const getXls = () => {
    setTokenizedFileUrl(null);
    setPollInterval(2000);
    getTokenizedFile({ variables: { token: xlsFileToken }})
      .catch(error => console.log(error));
  };

  const handleLinkClick = () => {
    getXlsFileToken({ variables });
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setPollInterval(0);
    setXlsFileToken(null);
    setTokenizedFileUrl(null);
  };


  return (
    <div className="tokenized-file-link-modal">

      <div onClick={handleLinkClick} className="tokenized-file-link-modal__link">
        <SvgIco name="download_file" size={18} />
        { linkLabel || I18n.t('providers.orders.list.download_xls') }
      </div>

      <ProviderModal modalClass="loading-file-modal"
                     handleClose={handleCloseModal}
                     showModal={modalIsOpen}>

        { (error || xlsFileTokenError) && <ModalNetworkError /> }

        <img src={ReportImg} alt="Load Excel" height={223} width={184} />

        { tokenizedFileUrl && !loading && !xlsFileTokenLoading && !error
          ?
            <Fragment>
              <div className="loading-file-modal__headline">
                { I18n.t('provider.tokenized_file.report_ready') }
              </div>
              <p>{ I18n.t('provider.tokenized_file.download') }</p>
              <a className="loading-file-modal__excel-link" href={tokenizedFileUrl}>
                { I18n.t('providers.tokenized_file.show.download_link') }
              </a>
            </Fragment>
          :
            <Fragment>
              <div className="loading-file-modal__headline">
                { I18n.t('provider.tokenized_file.preparing_report') }
              </div>
              <p>{ I18n.t('views.locations.shared.cart.please_wait') }</p>
              <FileLoader />
            </Fragment>
        }
      </ProviderModal>
    </div>
  )
}

export default TokenizedFileModalLink;
