import Yup from 'yup';
import postalCodes from 'postal-codes-js';

import { validationPatterns } from '../../../utils/validationPatterns';
import SignUp from '../../../utils/SignUp';
import { symbolsLimitError, requiredFieldError, minNumberError } from './ErrorMessages';
import {
  companyError,
  manySimilarSymbolsError,
  atLeastOneLetterError,
  eanError,
  vatIdError,
  addressError,
  postalCodeError,
  cityError,
  nameError,
  emailError,
  phoneError,
  atLeastEightDigitsError,
} from './ErrorMessages';

const {
        companyI18n,
        vat_idI18n,
        line_1I18n,
        postal_codeI18n,
        cityI18n,
        nameI18n,
        emailI18n,
        phoneI18n,
        passwordI18n,
        eanI18n,
      } = SignUp.getI18nAttrNames();


export const companyValidation =
  Yup.string()
     .matches(validationPatterns.symbolsLimit, symbolsLimitError(254))
     .matches(validationPatterns.company, companyError)
     .matches(validationPatterns.specialCharacktersNotMoreThan, manySimilarSymbolsError)
     .matches(validationPatterns.atLeastOneLetter, atLeastOneLetterError)
     .required(requiredFieldError(companyI18n));


export const companyValidationNotRequired =
  Yup.string()
    .matches(validationPatterns.symbolsLimit, symbolsLimitError(254))
    .matches(validationPatterns.company, companyError)
    .matches(validationPatterns.specialCharacktersNotMoreThan, manySimilarSymbolsError)
    .matches(validationPatterns.atLeastOneLetter, atLeastOneLetterError);


export const vatIdValidation =
  Yup.string()
    .matches(validationPatterns.symbolsLimit, symbolsLimitError(254))
    .matches(validationPatterns.company, vatIdError)
    .test('isRequired', requiredFieldError(vat_idI18n), function(value) {
      const { path, createError } = this;

      return this.parent.country_alpha2 !== 'dk' || value || createError({ path, message: requiredFieldError(vat_idI18n) });
    });


export const eanValidation =
  Yup.string()
    .matches(validationPatterns.symbolsLimit, symbolsLimitError(254))
    .matches(validationPatterns.ean, eanError);


export const eanValidationRequired =
  Yup.string()
     .matches(validationPatterns.symbolsLimit, symbolsLimitError(254))
     .matches(validationPatterns.ean, eanError)
     .required(requiredFieldError(eanI18n));


export const addressValidation =
  Yup.string()
     .matches(validationPatterns.symbolsLimit, symbolsLimitError(254))
     .matches(validationPatterns.company, addressError)
     .matches(validationPatterns.specialCharacktersNotMoreThan, manySimilarSymbolsError)
     .matches(validationPatterns.atLeastOneLetter, atLeastOneLetterError)
     .required(requiredFieldError(line_1I18n));


export const postalCodeValidation =
  Yup.string()
     .test('isPostalCode', postalCodeError, function(value) {
       const { path, createError } = this;
       const codeIsValid = postalCodes.validate(this.parent.countryAlpha2, value);

       return codeIsValid === true || !value || createError({ path, message: postalCodeError });
     })
     .required(requiredFieldError(postal_codeI18n));


export const postalCodeValidationProvider =
  Yup.string()
    .required(requiredFieldError(postal_codeI18n));


export const cityValidation =
  Yup.string()
     .matches(validationPatterns.symbolsLimit, symbolsLimitError(254))
     .matches(validationPatterns.name, cityError)
     .matches(validationPatterns.specialCharacktersNotMoreThan, manySimilarSymbolsError)
     .matches(validationPatterns.atLeastOneLetter, atLeastOneLetterError)
     .required(requiredFieldError(cityI18n));


export const nameValidation =
  Yup.string()
     .matches(validationPatterns.symbolsLimit, symbolsLimitError(254))
     .matches(validationPatterns.name, nameError)
     .matches(validationPatterns.specialCharacktersNotMoreThan, manySimilarSymbolsError)
     .matches(validationPatterns.atLeastOneLetter, atLeastOneLetterError)
     .required(requiredFieldError(nameI18n));


export const emailValidation =
  Yup.string()
     .matches(validationPatterns.symbolsEmailLimit, symbolsLimitError(100))
     .email(emailError)
     .required(requiredFieldError(emailI18n));


export const phoneValidation =
  Yup.string()
     .matches(validationPatterns.symbolsLimit, symbolsLimitError(254))
     .matches(validationPatterns.phone, phoneError)
     .matches(validationPatterns.phoneSpecialCharactersNotMoreThan, manySimilarSymbolsError)
     .test('minDigitsValidation', atLeastEightDigitsError, function(value) {
       const { path, createError } = this;
       const phoneIsValid = value && value.replace(/[+\-\s()]/g, '').length >= 8;

       return phoneIsValid || !value || createError({ path, message: atLeastEightDigitsError });
     })
     .required(requiredFieldError(phoneI18n));


export const passwordValidation =
  Yup.string()
     .matches(validationPatterns.symbolsLimit, symbolsLimitError(254))
     .min(6, minNumberError(passwordI18n))
     .required(requiredFieldError(passwordI18n));


export const passwordConfirmationValidation =
  Yup.string()
    .matches(validationPatterns.symbolsLimit, symbolsLimitError(254))
    .required(requiredFieldError(I18n.t("activerecord.attributes.contact.password_confirmation")))
    .test('passwords-match', I18n.t("generic.passwords_must_match"), function(value){
      return this.parent.password === value
    });


export const confirmPasswordValidation = Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match');

export const checkboxNotRequired = Yup.boolean();

