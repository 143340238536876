import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { ACTIVATE_ENQUIRY, GIVE_UP_ENQUIRY } from "../ApolloClient/mutations";
import AlreadyKnownLeadStatusModal from "../Modals/AlreadyKnownLeadStatusModal";
import ExtraOrderModal from "../Modals/ExtraOrderModal";
import NotesModal from "../Modals/NotesModal";
import InfoMessagePopup from "../Popups/InfoMessagePopup";
import Table from "../Table";
import { ClientsColumns } from "./ClientsColumns";


const ClientsTable = ({ currencySymbolFormatter,
                        currentPage,
                        currentLocale,
                        disableSortBy,
                        handleOrderBy,
                        hiddenColumnsList,
                        hideBottomFilters,
                        initialLoad,
                        loading,
                        locale,
                        noPagination,
                        orders,
                        orderBy,
                        orderDirection,
                        perPage,
                        perPageOptions,
                        refetchOrders,
                        setShowLoading,
                        setPage,
                        setPerPage,
                        totalCount }) => {

  const history = useHistory();

  const [clientId, setClientId] = useState('');
  const [infoPopupMessage, setInfoPopupMessage] = useState('');
  const [stateChangeLoadingId, setStateChangeLoadingId] = useState('');
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [extraLeadModalIsOpen, setExtraLeadModalIsOpen] = useState(false);
  const [extraLeadOrderId, setExtraLeadOrderId] = useState(false);
  const [infoMessagePopupIsOpen, setInfoMessagePopupIsOpen] = useState(false);
  const [alreadyKnownModalIsOpen, setAlreadyKnownModalIsOpen] = useState(false);
  const [infoPopupMessageError, setInfoPopupMessageError] = useState(null);
  const [hiddenColumns, setHiddenColumns] = useState(hiddenColumnsList || []);

  const [activateEnquiry] = useMutation(ACTIVATE_ENQUIRY, {
    onCompleted: data => !data.error && leadStateChangeCallback(),
  });

  const [giveUpEnquiry] = useMutation(GIVE_UP_ENQUIRY, {
    onCompleted: data => !data.error && leadStateChangeCallback(),
  });

  useEffect(() => {
    setShowLoading(!stateChangeLoadingId)
  }, [stateChangeLoadingId]);

  useEffect(() => {
    if (orders) {
      const showCommentColumn = orders.some(order => typeof order.comment === "string" ? order.comment.replaceAll(' ', '') : order.comment);

      !showCommentColumn && !hiddenColumns.includes('comment') && setHiddenColumns(prevColumns => ([ ...prevColumns, 'comment' ]));

      if (showCommentColumn && hiddenColumns.includes('comment')) {
        const newHiddenColumns = [ ...hiddenColumnsList];

        newHiddenColumns.splice(hiddenColumns.indexOf('comment'), 1);
        setHiddenColumns(newHiddenColumns);
      }
    }
  }, [orders]);


  const openNotesModal = (clientId) => {
    setClientId(clientId);
    setShowNoteModal(true);
  };


  const handleEnquiryStatus = (enquiry, clientId, status, id) => {
    const { location_id, enquiry_id } = enquiry;

    setInfoPopupMessage(`${I18n.t('provider.clients.status_change_message')} "${ I18n.t(`activerecord.attributes.enquiry.states/${status}`) }"`);

    switch (status) {

      case 'unresolved':
        setStateChangeLoadingId(enquiry_id);
        activateEnquiry({ variables: { id: enquiry_id }})
          .catch(error => handleStateChangeError(error));
        return;

      case 'lost':
        setStateChangeLoadingId(enquiry_id);
        giveUpEnquiry({ variables: { id: enquiry_id }})
          .catch(error => handleStateChangeError(error));
        return;

      case 'already_known':
        setClientId(clientId);
        setAlreadyKnownModalIsOpen(true);
        return;

      case 'deal_confirmed':
        history.push({
          pathname: `${currentLocale}/new_providers/report_deal`,
          search: `?client_id=${clientId}&location_id=${location_id}&enquiry_id=${enquiry_id}&order_id=${id}`,
        });
        return;
    }
  };


  const handleStateChangeError = (error) => {
    console.log('error', error);
    setStateChangeLoadingId('');
    setInfoPopupMessage(I18n.t('apps.lb_showroom.detailed.order_form.error.message'));
    setInfoPopupMessageError(true);
    setInfoMessagePopupIsOpen(true);
  };


  const handleOpenExtraLeadModal = (orderId) => {
    setExtraLeadOrderId(orderId);
    setExtraLeadModalIsOpen(true);
  };


  const handleCloseExtraLeadModal = () => {
    setExtraLeadModalIsOpen(false);
  };


  const closeInfoMessagePopup = () => {
    setInfoMessagePopupIsOpen(false);
    infoPopupMessageError && setInfoPopupMessageError(false);
  };


  const leadStateChangeCallback = (closeModal) => {

    const handleClose = () => {
      closeModal && closeModal();
      stateChangeLoadingId && setStateChangeLoadingId('');
    };

    refetchOrders()
      .then(() => {
        handleClose();
        setInfoMessagePopupIsOpen(true);
      })
      .catch(error => {
        console.log('error', error);
        handleClose();
      });
  };


  const ordersColumns = ClientsColumns(
    orders,
    orderBy,
    orderDirection,
    stateChangeLoadingId,
    handleOrderBy,
    currentLocale,
    openNotesModal,
    handleOpenExtraLeadModal,
    handleEnquiryStatus);


  return (
    <div className="client-table">

      <AlreadyKnownLeadStatusModal
        callbackFunction={leadStateChangeCallback}
        clientId={clientId}
        handleClose={() => setAlreadyKnownModalIsOpen(false)}
        showModal={alreadyKnownModalIsOpen} />

      <InfoMessagePopup
        customClass={infoPopupMessageError ? 'error' : null}
        handleClose={closeInfoMessagePopup}
        message={infoPopupMessage}
        visible={infoMessagePopupIsOpen} />

      <ExtraOrderModal
        currencySymbolFormatter={currencySymbolFormatter}
        extraLeadModalOpen={extraLeadModalIsOpen}
        extraLeadOrderId={extraLeadOrderId}
        handleCloseExtraLeadModal={handleCloseExtraLeadModal}
        locale={locale} />

      <NotesModal
        id={clientId}
        orderNotes={true}
        handleClose={() => setShowNoteModal(false)}
        showModal={showNoteModal} />

      <Table
        columns={ordersColumns}
        currentPage={currentPage}
        data={orders}
        disableSortBy={disableSortBy}
        hiddenColumns={hiddenColumns}
        hideBottomFilters={hideBottomFilters}
        initialLoad={initialLoad}
        loading={loading}
        name="orders"
        noPagination={noPagination}
        perPage={perPage}
        perPageOptions={perPageOptions}
        setPage={setPage}
        setPerPage={setPerPage}
        totalCount={totalCount}
      />

    </div>
  )
}


export default ClientsTable;
