import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import { REJECT_PROVIDER_DEAL } from '../ApolloClient/mutations';
import { FileLoader } from '../Loaders';
import ProviderModal from './ProviderModal';

import './RejectAgreementModal.sass';

const RejectAgreementModal = ({ data, handleClose, showModal }) => {
  const [comment, setComment] = useState('');
  const [validComment, setValidComment] = useState(false);

  const changeTextArea = (e) => {
    setValidComment(false);
    setComment(e.target.value)
  };

  const { address, clientEmail, companyName, id, personName, phoneNumber } =
    data;

  const [rejectProviderDeal, { error, loading }] = useMutation(
    REJECT_PROVIDER_DEAL,
    {
      update(cache, result) {},
      onCompleted: (data) => !data.error && handleClose(),
      refetchQueries: ['getUnresolvedProviderDeals'],
    },
  );

  return (
    <ProviderModal
      modalClass="reject-agreement-modal"
      showModal={showModal}
      handleClose={handleClose}
    >
      <h3>{I18n.t('providers.provider_deals.edit.header')}</h3>

      <div className="reject-agreement-modal__subheader">
        {I18n.t('providers.provider_deals.edit.client_info')}:
      </div>

      <div className="reject-agreement-modal__client-info-wrapper">
        <div className="reject-agreement-modal__client-info-row">
          <div className="reject-agreement-modal__client-info-label">
            {I18n.t('generic.name')}:
          </div>
          <div className="reject-agreement-modal__client-info-value">
            {personName}
          </div>
        </div>

        <div className="reject-agreement-modal__client-info-row">
          <div className="reject-agreement-modal__client-info-label">
            {I18n.t('generic.company_name')}:
          </div>
          <div className="reject-agreement-modal__client-info-value">
            {companyName}
          </div>
        </div>

        <div className="reject-agreement-modal__client-info-row">
          <div className="reject-agreement-modal__client-info-label">
            {I18n.t('generic.phone')}:
          </div>
          <div className="reject-agreement-modal__client-info-value">
            {phoneNumber}
          </div>
        </div>

        <div className="reject-agreement-modal__client-info-row">
          <div className="reject-agreement-modal__client-info-label">
            {I18n.t('generic.email')}:
          </div>
          <div className="reject-agreement-modal__client-info-value">
            {clientEmail}
          </div>
        </div>
      </div>

      <div className="reject-agreement-modal__subheader">
        {I18n.t('views.locations.show.physical_location')}
      </div>

      <p>{address}</p>

      <div className="reject-agreement-modal__comment-wrap">
        <textarea
          className={`${validComment ? 'error' : ''}`}
          name="comment"
          value={comment}
          id="deactivationDealComment"
          onChange={(e) => changeTextArea(e)}
          placeholder={I18n.t('client_deal_feedbacks.new.comment_placeholder')}
        />
        {validComment &&
          < div className="reject-agreement-modal__valid-comment">
            {I18n.t('apps.lb_showroom.detailed.order_form.validation.field_required', { field: I18n.t('generic.message')})}
          </div>
        }
      </div>

      {loading && <FileLoader />}

      <div
        className={`provider-modal__button ${loading ? 'inactive' : ''}`}
        onClick={() => {
          if (comment.length === 0) {
            setValidComment(true)
          } else {
            rejectProviderDeal({
              variables: { id: Number(id), comment, state: 'denied' },
            })
          }}
        }
      >
        {I18n.t('providers.provider_deals.edit.deny_button')}
      </div>
    </ProviderModal>
  );
};

export default RejectAgreementModal;
