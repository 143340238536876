import React, { useState } from 'react';
import classNames from 'classnames';

import { SvgIco } from "../../Icons";

import './index.sass';



const Input = ({ name, type, value, handleChange, handleBlur, inputClass, label, error, required, placeholder, style }) => {

  const [passwordVisible, setPasswordVisibility] = useState(false);
  const inputClasses = classNames(inputClass, { 'error': error, 'filled': value || placeholder });
  const inputType = passwordVisible || !type ? 'text' : type;


  return (
    <div className="base-input form-group">
      <input
        name={name}
        type={inputType}
        value={value}
        id={name}
        onChange={handleChange}
        onBlur={handleBlur}
        className={inputClasses}
        placeholder={placeholder}
        style={style}
      />

      { type === 'password' &&
        ( passwordVisible
          ? <SvgIco className="password-visibility" name="visibility" onClick={() => setPasswordVisibility(false)} size={18} />
          : <SvgIco className="password-visibility" name="visibility_off" onClick={() => setPasswordVisibility(true)} size={18} />
        )
      }

      <span className={`floating-label ${(value || placeholder) && 'active'}`}>
        { label }{ required && '*' }
      </span>

      { error && <div className="error-tooltip"> { error }</div> }
    </div>
  )
};


export default Input;
