import React from 'react';

import './index.sass';


const ProviderInactive = ({ data }) => {

  const { addressLine, companyName, contactInfoMail, contactInfoPhone, hotline, postalCode } = data;

  return (
    <div className="provider-inactive">
      <h2 dangerouslySetInnerHTML={{__html: I18n.t('providers.inactive_page.headline')}}/>
      <p>{ I18n.t('providers.inactive_page.paragraph') }</p>
      <div className="provider-inactive__contacts">
        <div className="provider-inactive__contacts-headline">
          { I18n.t('views.shared.contact_info') }
        </div>
        <div className="provider-inactive__contacts-line">
          { companyName }
        </div>
        <div className="provider-inactive__contacts-line">
          { addressLine }
        </div>
        <div className="provider-inactive__contacts-line">
          { postalCode }
        </div>
        <div className="provider-inactive__contacts-line email">
          { I18n.t('generic.email') }: <a href={"mailTo:" + contactInfoMail} >{ contactInfoMail }</a>
        </div>
        { contactInfoPhone &&
          <div className="provider-inactive__contacts-line">
            { I18n.t('generic.phone') }: { contactInfoPhone }
          </div>
        }
        { hotline &&
          <div className="provider-inactive__contacts-line hotline">
            { I18n.t('generic.hotline') }: { hotline }
          </div>
        }
      </div>
    </div>
  )
}

export default ProviderInactive;
