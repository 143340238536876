import React, { useEffect, useMemo, useState, Fragment, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery} from '@apollo/client';
import ReactTooltip from 'react-tooltip';
import { HashLink } from 'react-router-hash-link';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';

import ActivateIcon from '../../../src/images/provider/activate-icon.svg';
import CopyIcon from '../../../src/images/provider/copy-icon.svg';
import ReportIcon from '../../../src/images/provider/report-icon.svg';
import DeactivationIcon from '../../../src/images/provider/eye-off-icon.svg';
import EditIcon from '../../../src/images/provider/edit.svg';
import facebookShare from '../../../src/images/provider/facebook-share-button.svg';
import linkinShare from '../../../src/images/provider/linkedin-share-button.svg';
import twitterShare from '../../../src/images/provider/twitter-share-button.svg';
import shareIcon from '../../../src/images/provider/share-icon.svg';
import { SvgIco } from '../../SharedComponents/Icons';
import Loader from '../../SharedComponents/Loader';
import { GET_LOCATION_INFO, GET_LOCATIONS } from '../ApolloClient/queries';
import { ACTIVATE_LOCATION } from '../ApolloClient/mutations';
import AdvertisementBlock from '../AdvertismentBlock';
import PartnerBadgeBanner from '../AdvertismentBlock/Banners';
import DeactivateLocationModal from '../Modals/DeactivateLocationModal';
import DuplicateLocationModal from '../Modals/DuplicateLocationModal';
import { PulsingLoader } from '../Loaders';
import RentedOutModal from '../Modals/RentedOutModal';
import Notes from '../ClientInfo/Notes';
import Page404 from '../Errors/Page404';
import PageInfoMessage from '../Errors/PageInfoMessage';
import NetworkError from '../Errors/NetworkError';
import LocationViewsChart from "../LocationViewsChart";
import { ActionIcon, scoreCalculator, ScoreChart, visibilityQuality } from "../ScoreWidget";
import AssetsBlock from './AssetsBlock';

import './index.sass';


const LocationInfo = ({ currentLocale,
                        currencySymbolFormatter,
                        googleApiKey,
                        isNumber,
                        partnerBadgeUrl,
                        providerAdvertisingStrategy,
                        providerApproved,
                        providerOnDealStrategy,
                        qualityScorePageLink,
                        resourceNotFoundError,
                        siteKey,
                        siteName,
                        providerPartner,
                      }) => {

  const { id: locationId } = useParams();

  const tooltipPopupRef = useRef();

  const [locationInfo, setLocationInfo] = useState({});
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [duplicateModalIsOpen, setDuplicateModalIsOpen] = useState(false);
  const [rentedOutModalIsOpen, setRentedOutModalIsOpen] = useState(false);
  const [locationActivationLoading, setLocationActivationLoading] = useState(null);
  const [locationState, setLocationState] = useState(null);
  const [showPartnerBadgeBanner, setShowPartnerBadgeBanner] = useState(false);


  const { error } = useQuery(GET_LOCATION_INFO, {
    variables: { id: locationId },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data.location) {
        setLocationInfo(data.location);
      }
    }
  });


  const [getLocations] = useLazyQuery(GET_LOCATIONS, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      data.locations && data.locations.totalCount >= 3 && setShowPartnerBadgeBanner(true);
    },
  });


  const [activateLocation, {}] = useMutation(ACTIVATE_LOCATION, {
    onCompleted: (data) => {
      const { error, location } = data.activateLocation;

      !error && locationStateChangeCallback(null, location);
    },
  });


  const {
    address,
    areaFrom,
    areaTo,
    areaUnit,
    createdAt,
    currencyId,
    enquiries,
    enquiriesCountInteger,
    explainLocationIncomplete,
    floorPlans,
    floorPlansCount,
    infoMessage,
    initialState,
    isComplete,
    latitude,
    locationShownDatapoints,
    locationUrl,
    longitude,
    photos,
    photosCount,
    prospectusCount,
    section,
    sectionTranslated,
    state,
    stateInfo,
    stateTranslated,
    vimeoId,
    virtualTour,
    yearlyRentPerM2WithCentsFrom,
    yearlyRentPerM2WithCentsTo,
  } = locationInfo;

  const assetsProps = {
    photos,
    floorPlans,
    latitude,
    longitude,
    vimeoId,
    virtualTour,
  };

  const showYearlyPrice = yearlyRentPerM2WithCentsFrom || yearlyRentPerM2WithCentsTo;
  const showMonthlyPrice = false;
  const showLocationInfo = Object.keys(locationInfo).length;
  const showReportADeal = providerOnDealStrategy && section === 'lease';


  useEffect(() => {
    getLocations({ variables: { category: 'lease_properties', perPage: 0 }})
      .catch((error) => console.log('error', error));
    window.scroll({ top: 1, behavior: 'smooth' });
  }, []);


  useEffect(() => {
    locationState && animateStatus();
    setLocationState(state);
  }, [state, stateInfo]);


  const pricesFormatter = (priceFrom, priceTo) => {
    return priceFrom && priceTo
      ? `${priceFrom}-${priceTo}`
      : priceFrom
      ? I18n.t('search_agents.create.price_from', { price: priceFrom })
      : priceTo
      ? I18n.t('search_agents.create.price_to', { price: priceTo })
      : null;
  };


  const animateStatus = () => {
    const statusWrapper = document.getElementById('statusWrapper');

    statusWrapper.classList.add('activated');
    ReactTooltip.show(tooltipPopupRef.current);
    setTimeout(() => ReactTooltip.hide(tooltipPopupRef.current), 4000);
    setTimeout(() => statusWrapper.classList.remove('activated'), 2500);
  };


  const areaFormatter = (areaFrom, areaTo) => {
    return areaFrom && areaTo
      ? `${areaFrom}-${areaTo}`
      : `${I18n.t('generic.from')} ${areaFrom}`;
  };


  const locationStateChangeCallback = (closeModal, location, infoMessage) => {
    closeModal && closeModal();

    if (infoMessage) {
      setLocationInfo({ ...locationInfo, stateInfo: infoMessage });
      animateStatus();
    }

    location && setLocationInfo(location);
    locationActivationLoading && setLocationActivationLoading(false);
  };


  const handleActivateLocation = () => {
    setLocationActivationLoading(true);
    activateLocation({ variables: { id: Number(locationId) } }).catch((error) =>
      console.log('error', error)
    );
  };


  const ListItem = ({ itemData }) => {
    const linkInnerContent =
      <Fragment>
        <div className={`location-info__score-list-item-label ${itemData.score ? 'done' : ''}`}>
          <div className="location-info__score-list-item-name">
            { itemData.name }
          </div>
          <div className="location-info__score-list-item-score">
            { !!itemData.score && <ActionIcon color="#77BC1F" icon="check" iconSize={12} /> }
            { itemData.score ? `+${itemData.score}` : itemData.availableScoreLabel }
          </div>
        </div>
        <SvgIco name="keyboard-arrow-right-rounded-ios" size={14} />
      </Fragment>;

    return itemData.id === 'partner_badge'
      ?
        <a className="location-info__score-list-item" href={itemData.infoLink} target="_blank">
          { linkInnerContent }
        </a>
      :
        <HashLink to={itemData.infoLink} className="location-info__score-list-item">
          { linkInnerContent }
        </HashLink>;
  };


  // We need to use useMemo to prevent charts SVG form rerender on data update
  const cachedViewsChart = React.useMemo(
    () => <LocationViewsChart
      createdAt={createdAt}
      locationShownDatapoints={locationShownDatapoints}
    />,
    [createdAt, locationShownDatapoints],
  );


  const scoreData = useMemo(
    () => scoreCalculator({ ...locationInfo, providerPartner }, currentLocale, partnerBadgeUrl),
    [locationInfo]
  );


  const scoreChart = useMemo(
    () =>
      <div>
        <div className="location-info__score-widget">
          <ScoreChart score={scoreData && scoreData.totalScore} stroke={15} width={278} />
        </div>
        <div className="location-info__chart-score-list">
          { Object.keys(scoreData).map(scoreSection =>
            (scoreSection !== 'totalScore'
              && !scoreData[scoreSection].score
              && !scoreData[scoreSection].hidden
              && <ListItem itemData={scoreData[scoreSection]} key={scoreData[scoreSection].name} />)
            || null
          )}

          { Object.keys(scoreData).map(scoreSection =>
            (scoreSection !== 'totalScore'
              && scoreData[scoreSection].score
              && !scoreData[scoreSection].hidden
              && <ListItem itemData={scoreData[scoreSection]} key={scoreData[scoreSection].name} />)
            || null
          )}
        </div>
      </div>,
    [scoreData]
  );


  if (resourceNotFoundError(error) || !isNumber(locationId)) return <Page404 currentLocale={currentLocale} />;
  if (error) return <NetworkError />;


  const visibilityQualityState = scoreData && visibilityQuality(scoreData.totalScore);


  return (
    <div className="location-info">

      { !!infoMessage &&
        <PageInfoMessage message={infoMessage} />
      }

      { !!deleteModalIsOpen &&
        <DeactivateLocationModal
          address={address}
          callbackFunction={locationStateChangeCallback}
          currentLocale={currentLocale}
          handleClose={() => setDeleteModalIsOpen(false)}
          locationId={locationId}
          showModal={deleteModalIsOpen}
          siteName={siteName}
          showReportADeal={showReportADeal}
        />
      }

      <DuplicateLocationModal
        currentLocale={currentLocale}
        handleClose={() => setDuplicateModalIsOpen(false)}
        locationId={locationId}
        showModal={duplicateModalIsOpen}
      />

      <RentedOutModal
        address={address}
        callbackFunction={locationStateChangeCallback}
        handleClose={() => setRentedOutModalIsOpen(false)}
        locationId={locationId}
        showModal={rentedOutModalIsOpen}
        siteName={siteName}
      />

      { !!(!providerPartner && showPartnerBadgeBanner) &&
        <AdvertisementBlock
          children={
            <PartnerBadgeBanner
              partnerBadgeUrl={partnerBadgeUrl}
              qualityScore={scoreData}
              siteKey={siteKey}
            />
          }
        />
      }

      { !showLocationInfo && <PulsingLoader /> }

      { !!showLocationInfo &&
        <Fragment>

          { state === 'active' &&

            <div className="location-info__share-button-wrap">

              <img className="share-icon" src={shareIcon} alt="share-button"/>

              <FacebookShareButton url={`${window.location.origin}${locationUrl}`}>
                <img src={facebookShare} alt="facebook-share"/>
              </FacebookShareButton>

              <LinkedinShareButton url={`${window.location.origin}${locationUrl}`}>
                <img src={linkinShare} alt="linkin-share"/>
              </LinkedinShareButton>

              <TwitterShareButton url={`${window.location.origin}${locationUrl}`}>
                <img src={twitterShare} alt="twitter-share"/>
              </TwitterShareButton>
            </div>
          }

          <div className="location-info__main-info-wrapper">
            <div className="location-info__main-info-block">

              <div className="location-info__main-info-headline-block">
                <div className="location-info__main-info-headline">
                  { sectionTranslated }:
                </div>

                { state === 'active' &&
                  <a className="location-url" href={locationUrl} target="_blank">
                    <SvgIco name="visibility" size={24} />
                    <span>{ I18n.t('provider.show_location') }</span>
                  </a>
                }

                { !providerApproved &&
                  <div className="location-info__pending-approval">
                    { I18n.t('providers.locations.edit.pending_provider_approval') }
                  </div>
                }
              </div>

              <div className="location-info__address">{ address }</div>

              <div className="location-info__quality-score-info-wrapper">
                <div className="location-info__quality-score-info">
                  <div className={`location-info__quality-score-state ${visibilityQualityState}`}>
                    { I18n.t('provider.location_info.quality_score.visibility_label') }:&nbsp; <strong>{ I18n.t(`provider.quality_score_chart.visibility_state.${visibilityQualityState}`) }</strong>
                  </div>
                  <div className="location-info__quality-score-points">
                    <ActionIcon iconSize={20} />
                    { I18n.t('provider.location_info.quality_score.points_label') }:&nbsp; <strong>{ scoreData ? scoreData.totalScore : 0 }</strong>
                  </div>
                </div>
                <div className="location-info__main-info-headline-block">
                  <HashLink to="#score-widget" className="location-info__quality-score-link">
                    { I18n.t('provider.location_info.quality_score.details_link') }
                  </HashLink>
                </div>
              </div>

              <div className={`location-info__status ${state}`} id="statusWrapper">
                { I18n.t('providers.statistics.index.status') }:
                <div
                  className="location-info__status-label"
                  data-tip={!isComplete ? explainLocationIncomplete : stateInfo}
                  data-class="provider-tooltip"
                  data-for="state-tooltip"
                  ref={tooltipPopupRef}
                >
                  <div className="location-info__status-dot" />
                  <div className="google-translate-helper">
                    { stateTranslated }
                  </div>
                  <ReactTooltip
                    border={true}
                    borderColor="black"
                    effect="solid"
                    id="state-tooltip"
                    place="right"
                    type="light"
                  />
                </div>
              </div>

              { areaFrom &&
                <div className="location-info__area">
                  { I18n.t('apps.lb_showroom.detailed.facts.area_label') }:
                  <SvgIco name={'area'} size={22} />
                  <span>{areaFormatter(areaFrom, areaTo)} {areaUnit || ''}</span>
                </div>
              }

              {(showYearlyPrice || showMonthlyPrice) && (
                <div className="location-info__prices">
                  <div className="location-info__prices-labels">
                    {showMonthlyPrice && (
                      <div className="location-info__prices-label">
                        <SvgIco name={'price'} size={22} />
                        { I18n.t('apps.lb_showroom.detailed.facts.monthly_price') }
                        :
                      </div>
                    )}

                    { showYearlyPrice &&
                      <div className="location-info__prices-label">
                        <SvgIco name={'price'} size={22} />
                        { I18n.t('apps.lb_showroom.detailed.economy.annual_prices_m2') }
                        :
                      </div>
                    }
                  </div>

                  <div className="location-info__prices-values">
                    { showMonthlyPrice &&
                      <div className="location-info__prices-value">
                        { pricesFormatter(yearlyRentPerM2WithCentsFrom, yearlyRentPerM2WithCentsTo)}{' '}
                        { currencySymbolFormatter(currencyId) }
                      </div>
                    }

                    { showYearlyPrice && (
                      <div className="location-info__prices-value">
                        { pricesFormatter(yearlyRentPerM2WithCentsFrom, yearlyRentPerM2WithCentsTo)}{' '}
                        { currencySymbolFormatter(currencyId) }
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="location-info__main-info-headline-block">
                <div className="location-info__main-info-headline">
                  { I18n.t('generic.visuals') }:
                </div>

                <Link to={`${currentLocale}/new_providers/locations/edit/${locationId}/visuals`}>
                  { I18n.t('provider.location_info.add_new') }
                </Link>
              </div>

              <div className="location-info__visuals-info-wrapper">
                <div className="location-info__visuals-info">
                  { I18n.t('lb_showroom.detailed.asset_box.new_photos_button') }:
                  <span>{photosCount}</span>
                </div>

                <div className="location-info__visuals-info">
                  { I18n.t('providers.floor_plans.form.description') }:
                  <span>{floorPlansCount}</span>
                </div>

                <div className="location-info__visuals-info prospectuses">
                  { I18n.t('activerecord.models.prospectus_asset').one }:
                  <span>{prospectusCount}</span>
                </div>
              </div>

              <div className="location-info__main-info-headline-block">
                <div className="location-info__main-info-headline">
                  { I18n.t('reports.providers_order_report.enquiries') }
                </div>

                {!!enquiriesCountInteger && (
                  <Link to={`${currentLocale}/new_providers/orders/${locationId}`}>
                    { I18n.t('providers.locations.edit.show_enquiries') }
                  </Link>
                )}
              </div>

              <div className="location-info__enquiries-list">
                { enquiries &&
                  !!Object.keys(enquiries).length &&
                  Object.keys(enquiries).map((type, key) => (
                    <div className="location-info__inquiry" key={key}>
                      { type }:<span>{ enquiries[type] }</span>
                    </div>
                  ))}
              </div>
            </div>

            <AssetsBlock {...assetsProps} googleApiKey={googleApiKey} />
          </div>

          <div className="location-info__manage-buttons">
            <Link
              to={`${currentLocale}/new_providers/locations/edit/${locationId}/location`}
              className="location-info__manage-button location-info__manage-button--green"
            >
              <img src={EditIcon} alt="Copy" height={24} width={24} />
              { I18n.t('generic.edit') }
            </Link>

            <div
              className="location-info__manage-button"
              onClick={() => setDuplicateModalIsOpen(true)}
            >
              <img src={CopyIcon} alt="Copy" height={24} width={24} />
              { I18n.t('providers.copy_location_job.new.copy_button') }
            </div>

            {initialState === 'active' && (
              <Fragment>
                { providerAdvertisingStrategy ?
                  (section === 'lease' ?
                    <div className="location-info__manage-button" onClick={() => setRentedOutModalIsOpen(true)}>
                      <img src={ReportIcon} alt="Report" height={24} width={24} />
                      { I18n.t('providers.locations.edit.mark_as_rented_out') }
                    </div>
                    :
                    null
                  )
                  :
                  <Link to={`${currentLocale}/new_providers/report_deal?location_id=${locationId}`} className="location-info__manage-button">
                    <img src={ReportIcon} alt="Report" height={24} width={24} />
                    { I18n.t('providers.locations.edit.report_deal') }
                  </Link>
                }

                <div className="location-info__manage-button" onClick={() => setDeleteModalIsOpen(true)}>
                  <img
                    src={DeactivationIcon}
                    alt="Deactivate"
                    height={24}
                    width={24}
                  />
                  { I18n.t('parking.parking_lot.deactivate') }
                </div>
              </Fragment>
            )}

            {(initialState === 'new' || initialState === 'closed') &&
              <Fragment>
                { isComplete && providerApproved &&
                  <div className={`location-info__manage-button location-info__manage-button--green ${locationActivationLoading ? 'disabled' : ''}`}
                       onClick={handleActivateLocation}>
                    { locationActivationLoading
                      ?
                        <Loader size={24} left={-8} />
                      :
                        <img
                          src={ActivateIcon}
                          alt="Activate"
                          height={24}
                          width={24}
                        />
                    }
                    <div>{ I18n.t('apps.lb_showroom.filters.activate') }</div>
                  </div>
                }
              </Fragment>
            }
          </div>

          <div className="location-info__charts-wrapper">
            <div className="location-info__chart-block">
              { cachedViewsChart }
            </div>

            <div className="location-info__chart-block score">
              <div className="sectionScrollTarget" id="score-widget" />
              <div className="location-info__chart-headline">
                { I18n.t('providers.locations.edit.scores.title') }
                <a href={qualityScorePageLink} target="_blank" className="location-info__score-read-more">
                  { I18n.t('provider_backend.quality_score.link') }
                  <SvgIco name="open_in_new" size={16} />
                </a>
              </div>

              <div className="location-info__chart-wrapper">
                { scoreChart }
              </div>
            </div>
          </div>

          <Notes currentId={locationId} orderNotes={false} />

        </Fragment>
      }
    </div>
  );
};


export default LocationInfo;
