import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';

import BasicDropdown from "../../SharedComponents/Forms/BasicDropdown";
import RadioButtons from "../../SharedComponents/Forms/RadioButtons";
import { useDebouncedEffect } from "../../SharedComponents/hooks";
import { SvgIco } from "../../SharedComponents/Icons";
import PageInfoMessage from "../Errors/PageInfoMessage";
import NetworkError from "../Errors/NetworkError";
import { GET_LOCATIONS, GET_LOCATIONS_XLS_TOKEN } from '../ApolloClient/queries';
import { ACTIVATE_LOCATION } from "../ApolloClient/mutations";
import Table from '../Table';
import TokenizedFileModalLink from "../Modals/TokenizedFileModalLink";
import NotesModal from '../Modals/NotesModal';
import DeactivateLocationModal from "../Modals/DeactivateLocationModal";
import RentedOutModal from "../Modals/RentedOutModal";
import InfoMessagePopup from "../Popups/InfoMessagePopup";
import Page404 from '../Errors/Page404';
import { LocationsColumns } from './LocaationsColumns';

import '../Table/TablePage.sass';
import './index.sass';


const Locations = ({ asPreview,
                     currentLocale,
                     orderByFilter,
                     orderDirectionFilter,
                     providerApproved,
                     providerOnDealStrategy,
                     setShowCreateLeaseButton,
                     siteName,
                     states }) => {

  const history = useHistory();
  const { category } = useParams();
  const match = useRouteMatch();
  const categories = ['lease_properties', 'sales_properties', undefined, 'anonymous'];

  const checkCategory = (category) => {
    return categories.includes(category)
  };

  const isValidCategory = checkCategory(match.params.category);


  if (!isValidCategory) {
    return <Page404 currentLocale={currentLocale} />
  }

  const [queryValue, setQueryValue] = useState('');
  const [locationId, setLocationId] = useState('');
  const [locationAddress, setLocationAddress] = useState('');
  const [infoMessage, setInfoMessage] = useState('');
  const [infoPopupMessage, setInfoPopupMessage] = useState('');
  const [stateChangeLoadingId, setStateChangeLoadingId] = useState('');
  const [locationsData, setLocationsData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [initialLoad, setInitialLoad] = useState(true);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [rentedOutModalIsOpen, setRentedOutModalIsOpen] = useState(false);
  const [stateUpdateSuccess, setStateUpdateSuccess] = useState(false);
  const [infoMessagePopupIsOpen, setInfoMessagePopupIsOpen] = useState(false);
  const [infoPopupMessageError, setInfoPopupMessageError] = useState(null);

  const [filters, setFilters] = useState({
    locationState: '',
    page: 1,
    perPage: asPreview ? 4 : 20,
    orderBy: orderByFilter || 'updated_at_by_provider_only',
    orderDirection: orderDirectionFilter || 'desc',
    query: '',
    showOnlyContactLocations: false,
  });

  const { locationState, page, perPage, orderBy, orderDirection, showOnlyContactLocations } = filters;

  const { loading: locationsLoading, error, refetch: refetchLocations } = useQuery(GET_LOCATIONS, {
    variables: { category, ...filters },
    onCompleted: data => {
      const { locations, totalCount }= data.locations;

      setLocationsData(locations);
      setTotalCount(totalCount);
      initialLoad && setInitialLoad(false);
      stateChangeLoadingId && setStateChangeLoadingId('');
    },
  });

  const [activateLocation] = useMutation(ACTIVATE_LOCATION, {
    onCompleted: data => !data.error && locationStateChangeCallback(),
  });

  const loading = !stateChangeLoadingId && locationsLoading;

  useEffect(() => {
    refetchLocations().catch(error => console.log(error));
  }, []);

  useEffect(() => {
    refetchLocations().catch(error => console.log(error));
  }, [filters]);

  useEffect(() => {
    loading && setLocationsData(Array(perPage).fill({}));
  }, [loading]);

  useEffect(() => {
    let locationInfoMessage = null;

    locationsData.map(location => location.infoMessage && (locationInfoMessage = location.infoMessage))
    setInfoMessage(locationInfoMessage);
  }, [locationsData]);

  useDebouncedEffect(() => handleFilters({ query: queryValue, page: 1 }), [queryValue], 500);


  const isLease = category !== 'sales_properties';
  const showReportADeal = providerOnDealStrategy && isLease;

  const perPageOptions = [
    { value: '10', label: '10' },
    { value: '20', label: '20' },
    { value: '40', label: '40' },
  ];

  const locationStateOptions = [
    {value: null, label: I18n.t('generic.all'), className: !locationState ? 'selected' : null},
    {value: "new", label: states.new},
    {value: "active", label: states.active},
    {value: "rented_out", label: states.rentedOut},
    {value: "closed", label: states.closed},
  ];

  const radioOptions = [
    { label: I18n.t('providers.locations.filter.my_locations'), value: true, checked: showOnlyContactLocations },
    { label: I18n.t('providers.orders.filter.all_locations'), value: false, checked: !showOnlyContactLocations },
  ];


  const handleFilters = (newFilters) =>
    setFilters(prevFilters => ({
      ...prevFilters,
      ...newFilters,
    }));


  const handleOrderBy = (field) => {
    const newOrderDirection = field === orderBy && orderDirection === 'asc' ? 'desc' : 'asc';

    handleFilters({ orderBy: field, orderDirection: newOrderDirection });
  };


  const openNotesModal = (locationId) => {
    setShowNoteModal(true);
    setLocationId(locationId);
  };


  const closeInfoMessagePopup = () => {
    setInfoMessagePopupIsOpen(false);
    infoPopupMessageError && setInfoPopupMessageError(false);
  };


  const handleLocationState = (state, locationIndex) => {

    const { address, id } = locationsData[locationIndex];

    setLocationId(id);
    setLocationAddress(address);
    setInfoPopupMessage(I18n.t(`providers.locations.state.${state}.information`));

    switch (state) {
      case 'active':
        setStateChangeLoadingId(id);
        activateLocation({ variables: { id: Number(id) }})
          .catch(error => {
            console.log('error', error);
            setStateChangeLoadingId('');
            setInfoPopupMessage(I18n.t('apps.lb_showroom.detailed.order_form.error.message'));
            setInfoPopupMessageError(true);
            setInfoMessagePopupIsOpen(true);
          });
        return;
      case 'closed':
        setDeleteModalIsOpen(true);
        return;
      case 'rented_out':
        showReportADeal
          ? history.push({
              pathname: `${currentLocale}/new_providers/report_deal`,
              search: `?location_id=${id}`,
            })
          : setRentedOutModalIsOpen(true);
        return;
    }
  };


  const locationStateChangeCallback = (closeModal, location, successMessage) =>
    refetchLocations()
      .then(() => {
        successMessage && setInfoPopupMessage(successMessage)
        closeModal && closeModal();
        setInfoMessagePopupIsOpen(true);
      })
      .catch(error => {
        console.log('error', error);
        closeModal && closeModal();
      });


  const locationsColumns = LocationsColumns(currentLocale,
                                            locationsData,
                                            providerApproved,
                                            orderBy,
                                            orderDirection,
                                            stateUpdateSuccess,
                                            setStateUpdateSuccess,
                                            stateChangeLoadingId,
                                            handleOrderBy,
                                            handleLocationState,
                                            openNotesModal);

  const tableHeadline =
    category === 'sales_properties'
      ? I18n.t('layouts.providers.navigation.sales_properties')
      : I18n.t('layouts.providers.navigation.locations');

  const locationsEmpty = totalCount === 0 && !loading;


  if (!asPreview && error) return <NetworkError />;

  asPreview && setShowCreateLeaseButton && setShowCreateLeaseButton(locationsEmpty);

  if (!(asPreview && locationsEmpty)) {
    return (
      <div className={`leases table-page ${ asPreview ? 'preview' : '' }`}>

        { !asPreview && infoMessage && !loading && <PageInfoMessage message={infoMessage} /> }

        <NotesModal
          handleClose={() => setShowNoteModal(false)}
          id={locationId}
          orderNotes={false}
          showModal={showNoteModal} />

        <InfoMessagePopup
          customClass={infoPopupMessageError ? 'error' : null}
          handleClose={closeInfoMessagePopup}
          message={infoPopupMessage}
          visible={infoMessagePopupIsOpen} />

        { deleteModalIsOpen &&
          <DeactivateLocationModal
            address={locationAddress}
            callbackFunction={locationStateChangeCallback}
            currentLocale={currentLocale}
            handleClose={() => setDeleteModalIsOpen(false)}
            locationId={locationId}
            showModal={deleteModalIsOpen}
            showReportADeal={showReportADeal}
            siteName={siteName}
          />
        }

        <RentedOutModal
          address={locationAddress}
          callbackFunction={locationStateChangeCallback}
          handleClose={() => setRentedOutModalIsOpen(false)}
          locationId={locationId}
          showModal={rentedOutModalIsOpen}
          siteName={siteName} />

        { !asPreview &&
          <div className="table-page__filters-wrapper">

            <div className="table-page__filters-left">
              <div className="search-input-wrapper">
                <input
                  className="search-input"
                  maxLength="256"
                  onChange={(e) => setQueryValue(e.target.value)}
                  placeholder={I18n.t('generic.search')}
                  type="text"
                  value={queryValue} />
                <SvgIco name="search" size={16} />
              </div>

              <BasicDropdown
                handleChange={(value) => handleFilters({ locationState: value || '', page: 1 })}
                options={locationStateOptions}
                placeholder={I18n.t('providers.statistics.index.status')}
                value={locationState || locationStateOptions[0].value}
                wrapperClass="leases__status-dropdown" />

              <RadioButtons
                handleChange={(value) => handleFilters({ showOnlyContactLocations: value, page: 1 })}
                options={radioOptions} />
            </div>

            { !asPreview && totalCount > 0 &&
              <TokenizedFileModalLink
                dataName="locations"
                linkLabel="Excel"
                query={GET_LOCATIONS_XLS_TOKEN}
                variables={{ ...filters, category, page: null, perPage: null, xls: true }}
              />
            }
          </div>
        }

        <div className="table-page__title-wrapper">
          <div className="table-page__title" style={{ justifyContent: asPreview ? 'flex-start' : 'space-between' }}>
            <h3>{ tableHeadline } ({ totalCount })</h3>
            <div className="create-new-button">
              <Link to={`${currentLocale}/new_providers/${category === 'sales_properties' ? 'sales' : category === 'anonymous' ? 'anonymous' : 'lease'}/new/location`}>
                + { isLease
                    ? I18n.t('providers.locations.index.create_location')
                    : I18n.t('providers.locations.create_sale_property')
                  }
              </Link>
            </div>
          </div>
          { asPreview &&
            <Link to={`${currentLocale}/new_providers/locations/lease_properties`} className="table-page__see-all-link">
              <span>{ I18n.t('provider.tables.see_all_link') }</span>
            </Link>
          }
        </div>

        <Table
          columns={locationsColumns}
          currentPage={page}
          data={locationsData}
          disableSortBy={true}
          extraClass="no-wrap"
          hideBottomFilters={!!asPreview}
          initialLoad={initialLoad}
          loading={loading}
          name="locations"
          perPage={perPage}
          perPageOptions={perPageOptions}
          setPage={(page) => handleFilters({ page })}
          setPerPage={(perPage) => handleFilters({ perPage, page: 1 })}
          totalCount={totalCount} />
      </div>
    );
  } else {
    return null;
  }
}

export default Locations;
