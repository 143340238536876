import React, { useEffect, useState, Fragment } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/scss/image-gallery.scss";

import RadioButtons from '../../SharedComponents/Forms/RadioButtons';
import Checkbox from "../../SharedComponents/Forms/CheckBox";
import { SvgIco } from "../../SharedComponents/Icons";
import { GET_CONTACT_INFO } from '../ApolloClient/queries';
import { ADD_PAID_SERVICE_ORDER } from "../ApolloClient/mutations";
import ModalNetworkError from "../Errors/ModalNetworkError";
import InfoMessagePopup from "../Popups/InfoMessagePopup";
import ProviderModal from './ProviderModal';

import './VisualsProposalsForm.sass';


const VisualsProposalsForm = ({ currentContactId,
                                handleClose,
                                locationAddress,
                                locationId,
                                showModal,
                              }) => {

  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [contactInfo, setContactInfo] = useState(null);
  const [formSent, setFormSent] = useState(false);
  const [toggleInfoPopup, setToggleInfoPopup] = useState(false);
  const [selectedProposals, setSelectedProposals] = useState({
    photo: [],
    floorPlan: 0,
    virtualTour: 0,
    video: 0,
    comment: '',
    locationId,
  });


  const [getContactInfo, { error: contactError }] = useLazyQuery(GET_CONTACT_INFO, {
    onCompleted: data => data && data.contact && setContactInfo(data.contact),
  });


  const [addPaidServiceOrder, { loading, error }] = useMutation(ADD_PAID_SERVICE_ORDER, {
    variables: {
      ...selectedProposals,
      floorPlan: [selectedProposals.floorPlan],
      virtualTour: [selectedProposals.virtualTour],
      video: [selectedProposals.video]
    },
    onCompleted: (data) => {
      const { errors } = data.addPaidServiceOrder;

      !errors && setFormSent(true);
    },
  });


  useEffect(() => {
    if (showModal) {
      !contactInfo && getContactInfo({ variables: { id: currentContactId } });
    } else {
      setSelectedProposals({
        photo: [],
        floorPlan: 0,
        virtualTour: 0,
        video: 0,
        comment: '',
        locationId
      });
      formSent && setFormSent(false);
    }
  }, [showModal]);


  useEffect(() => {
    error && window.scroll(0, 0);
  }, [error]);


  const generateOptions = (category) =>
    proposalsData[category]
      .map((option) => ({
        checked: selectedProposals[category] === option.id,
        label: (
          <span className="visuals-form__label">
            {option.label}
            <span className="visuals-form__price">{option.price}</span>
          </span>
        ),
        value: option.id,
      }))
      .concat({
        checked: selectedProposals[category] === 0,
        label: I18n.t('provider.form.visuals_form.proposals.refuse_option_label'),
        value: 0,
      });


  const handleOptionSelect = (value, category) => {
    setSelectedProposals((prevState) => ({
      ...prevState,
      [category]: value,
    }));

    resetInfoPopup();
  };


  const handleCheckboxSelect = (name, optionId) => {
    const value = Number(optionId);
    const selected = [ ...selectedProposals[name] ];
    const optionIndex = selected.indexOf(value);

    if (optionIndex >= 0) {
      selected.splice(optionIndex, 1);
      setShowInfoPopup(false);
    } else {
      selected.push(value);
      resetInfoPopup();
    }

    setSelectedProposals((prevState) => ({ ...prevState, [name]: selected }));
  };


  const resetInfoPopup = () => {
    if (showInfoPopup) {
      setToggleInfoPopup(true);
    } else {
      setShowInfoPopup(true);
    }
  }


  const setComment = (comment) =>
    setSelectedProposals((prevState) => ({
      ...prevState,
      comment,
    }));


  const RadioGroup = ({ category }) => (
    <div className="visuals-form__radio-group">
      <RadioButtons
        handleChange={(value) => handleOptionSelect(value, category)}
        name={`${category}Order`}
        options={generateOptions(category)}
      />
    </div>
  );


  const CheckboxGroup = ({ options, name }) => {
    return (
      <div className="visuals-form__checkbox-group">
        { options.map(option =>
          <div className="visuals-form__checkbox-wrapper" key={`option-${option.id}`}>
            <Checkbox
              field="photo"
              handleChange={(e) => handleCheckboxSelect(name, e.target.id)}
              label={
                <span className="visuals-form__label">
                  { option.label }
                  <span className="visuals-form__price">{ option.price }</span>
                </span>}
              name={`${option.id}`}
              value={selectedProposals[name] && selectedProposals[name].includes(option.id)} />
          </div>
        )}
      </div>
    )
  };


  const renderRightNav = (onClick) =>
    <div
      className='image-gallery-custom-right-nav'
      onClick={onClick} >
      <SvgIco name="arrow_forward" size={24} />
    </div>;


  const renderLeftNav = (onClick) =>
    <div
      className='image-gallery-custom-left-nav'
      onClick={onClick} >
      <SvgIco name="arrow-back" size={24} />
    </div>;


  const optionsSelected = React.useMemo(
    () => {
      if ((selectedProposals.photo && selectedProposals.photo.length) || (selectedProposals.floorPlan !== 0)) {
        return true;
      } else {
        return false;
      }
    },
    [selectedProposals.photo, selectedProposals.floorPlan],
  );


  const selectedOption = (name, id) => {
    const optionData = proposalsData[name].find(data => data.id === id);

    return optionData
      ?
        <div className="visuals-form__order-summary-option" key={`selected-floor-plan-${id}`}>
          <div className="visuals-form__order-summary-option-label">
            { optionData.label }
          </div>
          <div className="visuals-form__order-summary-option-value">
            { optionData.price }
          </div>
        </div>
      : null;
  };


  const infoPopupMessage =
    <div className="visuals-form__info-message-body">
      <div className="visuals-form__info-message-left">
        Vare tilføjet
      </div>
      <a className="visuals-form__info-message-right" href="#summary-wrapper" onClick={() => setShowInfoPopup(false)}>
        { I18n.t('generic.buy_now') }
        <SvgIco name="arrow_outward" size={24} />
      </a>
    </div>


  return (
    <div>

      <div className={`visuals-form__info-message-wrapper ${showInfoPopup && !formSent ? 'visible' : ''}`}>
        <InfoMessagePopup
          children={infoPopupMessage}
          customDuration={6000}
          handleClose={() => setShowInfoPopup(false)}
          toggle={toggleInfoPopup}
          toggleCallback={() => setToggleInfoPopup(false)}
          visible={showInfoPopup}
        />
      </div>

      <ProviderModal
        handleClose={handleClose}
        modalClass={`visuals-form ${ formSent ? 'form-sent' : '' }`}
        scrollable={true}
        showModal={showModal}
      >
        <div className="visuals-form__close-btn" onClick={handleClose}>
          <SvgIco name="close" size={24} />
        </div>

        <div className="visuals-form__wrapper">

          { error && <ModalNetworkError /> }

          { formSent
            ?
            <Fragment>
              <h3 className="visuals-form__headline">
                { I18n.t('signup.teaser.modal_close.thank-header') }
              </h3>

              <div className="visuals-form__thank-u-text">
                { I18n.t('signup.teaser.modal_close.thank-text') }
              </div>
            </Fragment>
            :
            <Fragment>
              <h3 className="visuals-form__headline">
                Inspirér lejere med en professionel fotopakke
              </h3>

              <p className="visuals-form__subheader">
                { I18n.t('provider.form.visuals_form.subheader') }
              </p>

              <h3>
                { I18n.t('provider.form.visuals_form.photo_headline') }
              </h3>

              <CheckboxGroup name="photo" options={proposalsData.photo}/>

              <div className="visuals-form__example-sub">
                Udvalgte eksempler
              </div>

              <div className="visuals-form__gallery-wrapper images">
                <ImageGallery
                  fullscreen={false}
                  infinite={true}
                  items={images.photos}
                  renderLeftNav={renderLeftNav}
                  renderRightNav={renderRightNav}
                  showFullscreenButton={false}
                  showPlayButton={false}
                  showThumbnails={true}
                />
              </div>

              <h3>
                { I18n.t('lb_showroom.detailed.asset_box.new_floor-plans_button') }
              </h3>

              <RadioGroup category="floorPlan"/>

              <div className="visuals-form__example-sub">
                Udvalgte eksempler
              </div>

              <div className="visuals-form__gallery-wrapper floor-plans">
                <ImageGallery
                  fullscreen={false}
                  infinite={true}
                  items={images.floorPlans}
                  renderLeftNav={renderLeftNav}
                  renderRightNav={renderRightNav}
                  showFullscreenButton={false}
                  showPlayButton={false}
                  showThumbnails={true}
                />
              </div>

              <div className="visuals-form__contact-info">

                <div className="visuals-form__contact-form-header">
                  { I18n.t('generic.providers.sidebar.contacts') }
                </div>

                <div className="visuals-form__contact-info-row">
                  <div className="visuals-form__contact-info-label">
                    { I18n.t('provider.form.visuals_form.address_label') }:
                  </div>
                  <div className="visuals-form__contact-info-value">
                    { locationAddress }
                  </div>
                </div>

                <div className="visuals-form__contact-info-row">
                  <div className="visuals-form__contact-info-label">
                    { I18n.t('lb_showroom.detailed.provider_box.contact') }:
                  </div>
                  <div className="visuals-form__contact-info-value">
                    { contactInfo && contactInfo.name }
                  </div>
                </div>

                <div className="visuals-form__contact-info-row">
                  <div className="visuals-form__contact-info-label">
                    { I18n.t('generic.email') }:
                  </div>
                  <div className="visuals-form__contact-info-value">
                    { contactInfo && contactInfo.email }
                  </div>
                </div>

                <div className="visuals-form__contact-info-row">
                  <div className="visuals-form__contact-info-label">
                    { I18n.t('activemodel.attributes.provider_service_request.phone_number_order_form') }
                    :
                  </div>
                  <div className="visuals-form__contact-info-value">
                    { contactInfo && contactInfo.phoneNumber }
                  </div>
                </div>
              </div>

              { optionsSelected &&
                <div className="visuals-form__order-summary-wrapper" id="summary-wrapper">
                  <div className="visuals-form__contact-form-header">
                    Min bestilling
                  </div>

                  <div className="visuals-form__order-summary-option" key="selected-floor-plan-default">
                    <div className="visuals-form__order-summary-option-label">
                      Opstartsgebyr pr. adresse
                    </div>
                    <div className="visuals-form__order-summary-option-value">
                      1.000 DKK
                    </div>
                  </div>

                  <div className="visuals-form__order-summary-options">
                    { !!(selectedProposals.photo && selectedProposals.photo.length) && selectedProposals.photo.map(option => selectedOption('photo', option))}
                    { !!(selectedProposals.floorPlan && selectedProposals.floorPlan !== 0) && selectedOption('floorPlan', selectedProposals.floorPlan) }
                  </div>

                  <div className="visuals-form__contact-info-row visuals-form__contact-info-row--column">
                    <div className="visuals-form__comment-headline">
                      { I18n.t('activemodel.attributes.service_request.comment') }
                    </div>

                    <textarea
                      id="comment"
                      name="comment"
                      onChange={e => setComment(e.target.value)}
                      value={selectedProposals.comment}
                    />
                  </div>

                  <div
                    className={`provider-modal__button ${loading ? 'inactive' : ''}`}
                    onClick={() => addPaidServiceOrder()}>
                    { I18n.t('generic.buy_now') }
                  </div>

                </div>
              }

              <div className="visuals-form__bottom-info">
                <div dangerouslySetInnerHTML={{__html: I18n.t('provider.form.visuals_form.bottom.paragraph_3')}}/>
                <p>{ I18n.t('provider.form.visuals_form.bottom.paragraph_1') }</p>
                <p>{ I18n.t('provider.form.visuals_form.bottom.paragraph_2') }</p>
              </div>

            </Fragment>
          }
        </div>
      </ProviderModal>
    </div>

  );
};

export default VisualsProposalsForm;

const proposalsData = {
  photo: [
    {
      id: 1,
      label: I18n.t('provider.form.visuals_form.proposals.photo.first_label'),
      price: I18n.t('provider.form.visuals_form.proposals.photo.first_price'),
    },
    {
      id: 2,
      label: I18n.t('provider.form.visuals_form.proposals.photo.second_label'),
      price: I18n.t('provider.form.visuals_form.proposals.photo.second_price'),
    },
    {
      id: 3,
      label: I18n.t('provider.form.visuals_form.proposals.photo.third_label'),
      price: I18n.t('provider.form.visuals_form.proposals.photo.third_price'),
    },
  ],
  floorPlan: [
    {
      id: 1,
      label: I18n.t('provider.form.visuals_form.proposals.floor_plan.first_label'),
      price: I18n.t('provider.form.visuals_form.proposals.floor_plan.first_price'),
    },
    {
      id: 2,
      label: I18n.t('provider.form.visuals_form.proposals.floor_plan.second_label'),
      price: I18n.t('provider.form.visuals_form.proposals.floor_plan.second_price'),
    },
    {
      id: 3,
      label: I18n.t('provider.form.visuals_form.proposals.floor_plan.third_label'),
      price: I18n.t('provider.form.visuals_form.proposals.floor_plan.third_price'),
    },
    {
      id: 4,
      label: I18n.t('provider.form.visuals_form.proposals.floor_plan.fourth_label'),
      price: I18n.t('provider.form.visuals_form.proposals.floor_plan.fourth_price'),
    },
  ],
  virtualTour: [
    {
      id: 1,
      label: I18n.t('provider.form.visuals_form.proposals.virtual_tour.first_label'),
      price: I18n.t('provider.form.visuals_form.proposals.virtual_tour.first_price'),
    },
    {
      id: 2,
      label: I18n.t('provider.form.visuals_form.proposals.virtual_tour.second_label'),
      price: I18n.t('provider.form.visuals_form.proposals.virtual_tour.second_price'),
    },
  ],
  video: [
    {
      id: 1,
      label: I18n.t('provider.form.visuals_form.proposals.video.first_label'),
      price: I18n.t('provider.form.visuals_form.proposals.video.first_price'),
    },
  ],
};

const images = {
  photos: [
    {
      original: require('../../../src/images/provider/visual_proposals/images/1.webp'),
      thumbnail: require('../../../src/images/provider/visual_proposals/images/1.webp'),
    },
    {
      original: require('../../../src/images/provider/visual_proposals/images/2.webp'),
      thumbnail: require('../../../src/images/provider/visual_proposals/images/2.webp'),
    },
    {
      original: require('../../../src/images/provider/visual_proposals/images/3.webp'),
      thumbnail: require('../../../src/images/provider/visual_proposals/images/3.webp'),
    },
    {
      original: require('../../../src/images/provider/visual_proposals/images/4.webp'),
      thumbnail: require('../../../src/images/provider/visual_proposals/images/4.webp'),
    },
    {
      original: require('../../../src/images/provider/visual_proposals/images/5.webp'),
      thumbnail: require('../../../src/images/provider/visual_proposals/images/5.webp'),
    },
    {
      original: require('../../../src/images/provider/visual_proposals/images/6.webp'),
      thumbnail: require('../../../src/images/provider/visual_proposals/images/6.webp'),
    },
    {
      original: require('../../../src/images/provider/visual_proposals/images/7.webp'),
      thumbnail: require('../../../src/images/provider/visual_proposals/images/7.webp'),
    },
    {
      original: require('../../../src/images/provider/visual_proposals/images/8.webp'),
      thumbnail: require('../../../src/images/provider/visual_proposals/images/8.webp'),
    },
  ],
  floorPlans: [
    {
      original: require('../../../src/images/provider/visual_proposals/floor_plans/1.webp'),
      thumbnail: require('../../../src/images/provider/visual_proposals/floor_plans/1.webp'),
    },
    {
      original: require('../../../src/images/provider/visual_proposals/floor_plans/2.webp'),
      thumbnail: require('../../../src/images/provider/visual_proposals/floor_plans/2.webp'),
    },
    {
      original: require('../../../src/images/provider/visual_proposals/floor_plans/3.webp'),
      thumbnail: require('../../../src/images/provider/visual_proposals/floor_plans/3.webp'),
    },
    {
      original: require('../../../src/images/provider/visual_proposals/floor_plans/4.webp'),
      thumbnail: require('../../../src/images/provider/visual_proposals/floor_plans/4.webp'),
    },
    {
      original: require('../../../src/images/provider/visual_proposals/floor_plans/5.webp'),
      thumbnail: require('../../../src/images/provider/visual_proposals/floor_plans/5.webp'),
    },
    {
      original: require('../../../src/images/provider/visual_proposals/floor_plans/6.webp'),
      thumbnail: require('../../../src/images/provider/visual_proposals/floor_plans/6.webp'),
    },
    {
      original: require('../../../src/images/provider/visual_proposals/floor_plans/7.webp'),
      thumbnail: require('../../../src/images/provider/visual_proposals/floor_plans/7.webp'),
    },
  ]
};
