import React, { useState } from 'react';
import { useMutation } from "@apollo/client";

import RentedOutImg from "../../../src/images/provider/rendted-out.svg";
import { MARK_LOCATION_AS_RENTED_OUT } from "../ApolloClient/mutations";
import ModalNetworkError from "../Errors/ModalNetworkError";
import { FileLoader } from "../Loaders";
import ProviderModal from "./ProviderModal";

import './DeactivateLocationModal.sass';


const RentedOutModal = ({ address,
                          callbackFunction,
                          handleClose,
                          locationId,
                          showModal,
                          siteName }) => {

  const [loading, setLoading] = useState(false);

  const [rentedOutLocation, { error }] = useMutation(MARK_LOCATION_AS_RENTED_OUT, {
    onCompleted: data => {
      const { error, location } = data.rentedOutLocation;

      !error && callbackFunction ? callbackFunction(closeModal, location) : closeModal();
    },
  });


  const closeModal = () => {
    setLoading(false);
    handleClose();
  };


  const markAsRentedOut = () => {
    setLoading(true);
    rentedOutLocation({ variables: { id: Number(locationId) }}).catch(error => console.log('error', error));
  };


  return (
    <ProviderModal modalClass="deactivate-location-modal"
                   showModal={showModal}
                   handleClose={handleClose}>

      { error && <ModalNetworkError /> }

      <img src={RentedOutImg} width={241} height={182} alt="Deactivate" />

      <div className="deactivate-location-modal__headline">
        { I18n.t('provider.location_deactivation.headline') }
      </div>

      <div className="deactivate-location-modal__explanation">
        { I18n.t('providers.locations.rented_out.when_rented_out', { site: siteName }) }
      </div>

      <div className="deactivate-location-modal__location-address">
        { address }
      </div>

      { loading && !error && <FileLoader /> }

      <div className={`provider-modal__button ${loading ? 'inactive' : ''}`}
           onClick={markAsRentedOut}>
        { I18n.t('parking.parking_lot.deactivate') }
      </div>

    </ProviderModal>
  )
}


export default RentedOutModal;
