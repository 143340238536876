import React, { useRef, useState } from 'react';
import ReactTooltip from "react-tooltip";

import { SvgIco } from "../../SharedComponents/Icons";

import './TooltipPopup.sass';


const TooltipPopup = ({ borderColor,
                        children,
                        className,
                        content,
                        height,
                        hideBorder,
                        icon,
                        id,
                        marginRight,
                        marginLeft,
                        onShow,
                        offset,
                        place }) => {

  const [tooltipVisible, setTooltipVisible] = useState(false);
  const tooltipPopupWrapperRef = useRef();
  const tooltipPopupRef = useRef();


  const handleTooltip = () => {
    tooltipVisible ? ReactTooltip.hide(tooltipPopupRef.current) : ReactTooltip.show(tooltipPopupRef.current);
    setTooltipVisible(!tooltipVisible);
  };


  const handleScoreTooltip = (tooltipVisible) => {
    tooltipVisible && window.addEventListener('click', handleClickOutsideTooltip);
    onShow && onShow(() => ReactTooltip.hide(tooltipPopupRef.current));
  };


  const handleClickOutsideTooltip = (e) => {
    if (tooltipPopupWrapperRef.current && !tooltipPopupWrapperRef.current.contains(e.target)) {
      ReactTooltip.hide(tooltipPopupRef.current);
      window.removeEventListener('click', handleClickOutsideTooltip);
    }
  };


  return (
    <div className={`tooltip-popup ${className}`}
         ref={tooltipPopupWrapperRef}
         style={{ height: height ? `${height}px` : '20px' }}>

      <div className="tooltip-popup__tooltip"
           data-tip={content || children}
           data-html={!!content}
           ref={tooltipPopupRef}
           data-class="provider-tooltip"
           data-for={id} />

      <div className="tooltip-popup__icon"
           onClick={handleTooltip}
           style={{
           marginRight: marginRight ? `${marginRight}px` : '0',
           marginLeft: marginLeft ? `${marginLeft}px` : '0',}}>
        <SvgIco name={icon} size={height || 20} />
      </div>

      <ReactTooltip
        afterHide={() => setTooltipVisible(false)}
        afterShow={() => handleScoreTooltip(true)}
        border={!hideBorder}
        borderColor={borderColor || 'black'}
        clickable={true}
        effect='solid'
        id={id}
        offset={offset}
        place={place || 'left'}
        scrollHide={true}
        type="light"
      >
        { children }
      </ReactTooltip>

    </div>
  )
}

export default TooltipPopup;
