import React from 'react';
import { useMutation } from '@apollo/client';

import DeactivationImg from '../../../src/images/provider/deactivate.svg';
import { DELETE_CLIENT_NOTE } from '../ApolloClient/queries';
import ModalNetworkError from "../Errors/ModalNetworkError";
import { FileLoader } from '../Loaders';
import ProviderModal from './ProviderModal';

import './DeleteNoteModal.sass';


const DeleteNoteModal = ({ handleClose, noteId, refetchQueries, showModal }) => {

  const [deleteClientNote, { loading, error }] = useMutation(DELETE_CLIENT_NOTE, {
    update(cache, result) {},
    refetchQueries: refetchQueries || [],
    onCompleted: () => {
      handleClose();
    },
  });

  const deleteNote =() => {
    const input = {id: parseInt(noteId)};

    deleteClientNote({ variables: { input } })
      .catch(error => console.log('error', error));
  };

  return (
    <ProviderModal modalClass="delete-note-modal"
                   showModal={showModal}
                   handleClose={handleClose}>

      { error && <ModalNetworkError /> }

      <img src={DeactivationImg} width={156} height={132} alt="Deactivate" />

      <p className="bold">{ I18n.t('providers.notes.delete_confirm') }</p>

      { loading && !error && <FileLoader /> }

      <div className={`provider-modal__button ${loading ? 'inactive' : ''}`}
           onClick={deleteNote}>
        { I18n.t('generic.delete') }
      </div>

    </ProviderModal>
  )
}

export default DeleteNoteModal;
