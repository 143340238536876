import React from 'react';
import { useMutation } from "@apollo/client";

import DeactivationImg from "../../../src/images/provider/deactivate.svg";
import { DELETE_CONTACT } from "../ApolloClient/mutations";
import ModalNetworkError from "../Errors/ModalNetworkError";
import { FileLoader } from "../Loaders";
import ProviderModal from "./ProviderModal";

import './DeleteNoteModal.sass';


const DeleteContactModal = ({ callback, data, handleClose, showModal, logout }) => {

  const { name, id } = data;

  const [ deleteContact, { loading, error } ] = useMutation(DELETE_CONTACT, {
    update(cache, result) {},
    refetchQueries: ["getContacts", "getDeleteContact"],
    onCompleted: () => {
      callback && callback();
      handleClose();
      if (logout) {
        window.location.assign('/logout')
      }
    }
  });

  const handleDeleteContact = () =>
    deleteContact({ variables: { input: { id: parseInt(id || 0) } }})
      .catch(error => console.log('error', error));


  return (
    <ProviderModal modalClass="delete-contact-modal"
                   showModal={showModal}
                   handleClose={handleClose}>

      { error && <ModalNetworkError /> }

      <img src={DeactivationImg} width={156} height={132} alt="Deactivate" />

      <p>{ I18n.t("providers.contacts.edit.config_delete_contact", { contact: name || '' }) }</p>

      { loading && !error && <FileLoader /> }

      <div className={`provider-modal__button ${loading ? 'inactive' : ''}`}
           onClick={handleDeleteContact}>
        { I18n.t('generic.delete') }
      </div>
    </ProviderModal>
  )
};


export default DeleteContactModal;
