import React, { Fragment, useState } from 'react';
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";

import RadioButtons from "../../SharedComponents/Forms/RadioButtons";
import { DEACTIVATE_LOCATION, DEACTIVATE_LOCATION_AT_THE_MONTH_END } from "../ApolloClient/mutations";
import ModalNetworkError from "../Errors/ModalNetworkError";
import { FileLoader } from "../Loaders";
import ProviderModal from "./ProviderModal";

import './DeactivateLocationModal.sass';


const DeactivateLocationModal = ({ address,
                                   callbackFunction,
                                   currentLocale,
                                   handleClose,
                                   locationId,
                                   showModal,
                                   showReportADeal,
                                   siteName }) => {

  const [leaseWasRentedOut, setLeaseWasRentedOut] = useState(true);
  const [loading, setLoading] = useState(false);
  const [deactivationReason, setDeactivationReason] = useState('');
  const [deactivateLocationNow, setDeactivateLocationNow] = useState(false);

  const history = useHistory();


  const [deactivateLocation, { error }] = useMutation(DEACTIVATE_LOCATION, {
    onCompleted: data => {
      const { error, location } = data.deactivateLocation;

      !error && callbackFunction ? callbackFunction(closeModal, location) : closeModal();
    },
  });


  const [deactivateLocationAtTheMonthEnd, { error: deactivateLocationAtTheMonthError }] = useMutation(DEACTIVATE_LOCATION_AT_THE_MONTH_END, {
    onCompleted: data => {
      const { error } = data.deactivateLocationAtTheMonthEnd;

      !error && callbackFunction ? callbackFunction(closeModal, false, I18n.t('providers.locations.deactivate.terms_option.month_end.success_message')) : closeModal();
    },
    refetchQueries: ['getLocationInfo'],
  });


  const deactivationOptions = [
    { label: I18n.t('providers.locations.deactivate.lease_rented_out_yes'), value: true, checked: leaseWasRentedOut },
    { label: I18n.t('providers.locations.deactivate.lease_rented_out_no'), value: false, checked: !leaseWasRentedOut },
  ];


  const deactivationTermsOptions = [
    { label: <div className="deactivate-location-modal__deactivation-term-option">
               <div className="deactivate-location-modal__deactivation-term-option-headline">
                 { I18n.t('providers.locations.deactivate.terms_option.month_end.headline') }
               </div>
               <div className="deactivate-location-modal__deactivation-term-option-description">
                 { I18n.t('providers.locations.deactivate.terms_option.month_end.description') }
               </div>
             </div>,
      value: false,
      checked: !deactivateLocationNow,
    },
    { label: <div className="deactivate-location-modal__deactivation-term-option">
               <div className="deactivate-location-modal__deactivation-term-option-headline">
                 { I18n.t('providers.locations.deactivate.terms_option.now.headline') }
               </div>
               <div className="deactivate-location-modal__deactivation-term-option-description">
                 { I18n.t('providers.locations.deactivate.terms_option.now.description') }
               </div>
             </div>,
      value: true,
      checked: deactivateLocationNow
    },
  ];


  const closeModal = () => {
    setLoading(false);
    handleClose();
  };


  const handleDelete = () => {
    if (leaseWasRentedOut && showReportADeal) {
      history.push(`${currentLocale}/new_providers/report_deal?location_id=${locationId}`);
    } else {
      setLoading(true);
      deactivateLocation({ variables: { id: Number(locationId), reason: deactivationReason }});
    }
  };


  const handleRadioButtonsChange = (value) => {
    setLeaseWasRentedOut(value);
    value && setDeactivationReason('');
  };


  const handleConfirm = () => {
    showReportADeal
      ? (leaseWasRentedOut || deactivationReason.length ? handleDelete() : null)
      : (deactivateLocationNow ? handleDelete() : deactivateLocationAtTheMonthEnd({ variables: { id: Number(locationId) }}));
  };


  return (
    <ProviderModal modalClass="deactivate-location-modal"
                   showModal={showModal}
                   handleClose={handleClose}>

      { (error || deactivateLocationAtTheMonthError) && <ModalNetworkError /> }

      <div className="deactivate-location-modal__headline">
        { I18n.t('provider.location_deactivation.headline') }
      </div>

      { showReportADeal
        ?
          <Fragment>
            <div className="deactivate-location-modal__explanation">
              { I18n.t('providers.locations.deactivate.when_deactivating', { site: siteName }) }
            </div>

            <div className="deactivate-location-modal__location-address">
              { address }
            </div>

            <div className="deactivate-location-modal__report-deal-block">
              <div className="deactivate-location-modal__report-deal-headline">
                { I18n.t('providers.locations.deactivate.deactivate_question_text') }
              </div>
              <div className="deactivate-location-modal__report-deal-question">
                { I18n.t('providers.locations.deactivate.lease_rented_out') }
              </div>

              <RadioButtons
                handleChange={value => handleRadioButtonsChange(value)}
                name="leaseWasRentedOut"
                options={deactivationOptions} />

              { !leaseWasRentedOut &&
                <textarea
                  id="deactivationReason"
                  name="deactivationReason"
                  onChange={e => setDeactivationReason(e.target.value)}
                  placeholder={I18n.t('providers.locations.deactivate.deactivation_reason')}
                  value={deactivationReason}
                />
              }
            </div>
          </Fragment>
        :
          <Fragment>
            <div className="deactivate-location-modal__explanation">
              { I18n.t('providers.locations.deactivate.terms_subheader') }:
            </div>

            <RadioButtons
              handleChange={value => setDeactivateLocationNow(value)}
              name="deactivationTerms"
              options={deactivationTermsOptions} />
          </Fragment>
      }

      { loading && !error && !deactivateLocationAtTheMonthError && <FileLoader /> }

      <div className={`deactivate-location-modal__buttons-wrapper ${loading ? 'inactive' : ''}`}>
        <div className="deactivate-location-modal__button close" onClick={handleClose}>
          { I18n.t('search_agent.modal.cancel') }
        </div>
        <div className={`deactivate-location-modal__button confirm ${loading ? 'inactive' : ''}`} onClick={handleConfirm}>
          { !showReportADeal ? I18n.t('parking.parking_lot.deactivate') : I18n.t('providers.providers.provider_deals_notifications.accept') }
        </div>
      </div>

    </ProviderModal>
  )
};


export default DeactivateLocationModal;
