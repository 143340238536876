import React, { useState, useContext, useEffect } from "react";
import { GoogleMap, InfoWindow, Marker, withGoogleMap } from "react-google-maps";
import { geocodeByLatLng, geocodeByPlaceId } from 'react-google-places-autocomplete';

import mapPin from '../../../../../src/images/provider/map-pin.svg';
import mapStyles from '../../../../../src/stylesheets/map_styles.json';
import { Context } from '../../index';


const Map = React.memo((props) => {
  const [center, setCenter] = useState();
  const [refMap, setMapRef] = useState(null);

  const { siteKey } = useContext(Context);

  const { placeId, handleGeocodedResults, restrictionBounds, showPinInfoMessage } = props;
  const worldCoords = { lat: 28.304380682962783, lng: 3.515625 };


  useEffect(() => {
    placeId && refMap && geocodeByPlaceId(placeId)
      .then(results => {
        const bounds = results[0].geometry.bounds || results[0].geometry.viewport;
        refMap.fitBounds(bounds);
      })
      .catch(error => console.error('error', error));
  }, [refMap, placeId]);


  const handleBoundsChanged = () => {
    const mapCenter = refMap.getCenter(); //get map center

    setCenter({ lat: mapCenter.lat(), lng: mapCenter.lng() });
  };


  const handleDragEnd = () =>
    geocodeByLatLng(center)
      .then(results => results.length && handleGeocodedResults(results))
      .catch(error => console.error('error',error));


  return (
    <GoogleMap
      ref={(map) => setMapRef(map)}
      defaultZoom={siteKey === 'global' ? 2 : 13}
      onBoundsChanged={handleBoundsChanged}
      defaultCenter={worldCoords}
      onDragEnd={handleDragEnd}
      options={
        restrictionBounds
          ?
            { restriction: {
                latLngBounds:restrictionBounds,
                strictBounds: false,
              },
              styles: mapStyles,
            }
          :
            { styles: mapStyles }
      }
      defaultOptions={{ mapTypeControl: false }}
    >
      <Marker
        icon={{ url: mapPin, scaledSize: { width: 56, height: 56} }}
        key="markerKey"
        noRedraw={true}
        place_id={placeId || null}
        position={center}
      >
        { showPinInfoMessage &&
          <InfoWindow options={{ disableAutoPan: true }}>
            <div>{ I18n.t('provider.map.move_cursor_tooltip') }</div>
          </InfoWindow>
        }
      </Marker>
    </GoogleMap>
  );
});


export default withGoogleMap(Map);
