import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const MutableLink = ({ showAsLink, url, children, linkClass, handleClick, stepLink, currentStepRef }) => {

  if (showAsLink) {
    return (
      <Link to={url}
            className={linkClass}
            onClick={
              handleClick
              ? (e) => {
                  e.preventDefault();
                  currentStepRef && (currentStepRef.current.nextStep = stepLink);   // FIXME: make reusable
                  handleClick(stepLink);
                }
              : null }
      >
        { children }
      </Link>
    )
  } else {
    return (
      <Fragment>{ children }</Fragment>
    )
  }
}

export default MutableLink;
