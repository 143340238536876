import React from 'react';
import { Link } from "react-router-dom";

import ReportSuccessImg from "../../../src/images/provider/report-success.svg";
import GoogleReview from "../../../src/images/provider/google_review.svg";
import ProviderModal from "./ProviderModal";

import './ReportSuccessModal.sass';


const ReportSuccessModal = ({ currentLocale, showModal, siteKey }) => {

  const dkLink = "https://g.page/r/CVM8OALD8rgjEBM/review";
  const globalLink = "https://www.google.com/maps/place/MatchOffice/@55.6986904,12.5340955,17z/data=!3m1!4b1!4m6!3m5!1s0x4652525ed39a61c9:0xc9ba564896d79bb6!8m2!3d55.6986904!4d12.5340955!16s%2Fg%2F1hhh_sq5_"
  const back_url = `${currentLocale}/new_providers`;


  const handleCloseInfoForm = () => window.location.href = back_url;

  return (
    <ProviderModal modalClass="report-success-modal"
                   showModal={showModal}
                   handleClose={handleCloseInfoForm}
                   hideCloseBtn={false}>

        <img src={ReportSuccessImg} alt="report-success" className="lazyload" data-sizes="auto" width={147} />

        <h2>
          { I18n.t("providers.leases.success.heading") }
        </h2>

        <p className="report-success-modal__thank-you-text">
          { I18n.t("providers.leases.success.text_first") }
        </p>

        <p className="report-success-modal__review-text">
          { I18n.t("providers.leases.success.review.text_first") }
        </p>

        <a href={siteKey === 'dk' ? dkLink : globalLink} target="_blank">
          { I18n.t("providers.leases.success.review.text_second") }
        </a>

        <a className="report-success-modal__logo-link" href={siteKey === 'dk' ? dkLink : globalLink} target="_blank">
          <img className="report-success-modal__logo-image" src={GoogleReview} alt="some-logo"/>
        </a>

        <Link to={back_url} className="report-success-modal__dashboard-btn">
          { I18n.t("providers.leases.success.review.text_dashboard_button") }
        </Link>

    </ProviderModal>
  )
};


export default ReportSuccessModal;
