import React, { Fragment, useContext } from 'react';
import classNames from 'classnames';

import BasicInput from '../../../SharedComponents/Forms/BasicInput';
import { Context } from '../index';

import './RangeInputs.sass';


const RangeInputs = ({ float,
                       handleChange,
                       hideSecondField,
                       formGroupClass,
                       label,
                       labelCol,
                       name,
                       requiredFrom,
                       requiredTo,
                       tooltip,
                       type,
                       value,
                       units,
                       unitsPosition,
                       useOriginName,
                     }) => {

  const { handleNumberInputChange, values, errors } = useContext(Context);
  const nameFrom = useOriginName ? name : `${name}From`;
  const nameTo = `${name}To`;
  const valueFrom = values[nameFrom];
  const valueTo = values[nameTo];
  const errorFrom = errors && errors[nameFrom];
  const errorTo = errors && errors[nameTo];
  const unitsClasses = classNames("range-inputs__units", { 'top': unitsPosition === 'top' });


  return (
    <div className={`form-group range-inputs row ${formGroupClass || ''}`}>

      <div className={`form-label col-xl-${labelCol || 3} col-lg-${labelCol || 3}`}>
        <span>{ label }</span>
      </div>

      <div className={`range-inputs__col col-lg-4 col-xl-${hideSecondField && !units ? '3 wide' : '4'}`}>
        <div className="range-inputs__col-wrapper">
          <div className="range-inputs__wrapper">
            <div className="range-inputs__input-wrapper">

              <BasicInput
                error={errorFrom}
                float={float}
                handleChange={(e) => handleChange ? handleChange(e, nameFrom) : handleNumberInputChange(e)}
                hideError={true}
                name={nameFrom}
                placeholder={I18n.t('generic.from')}
                required={requiredFrom}
                type={type || "number"}
                value={value || valueFrom || ''}
              />

              <span className="range-inputs__required-wrapper">
                { hideSecondField && units && <div className={unitsClasses}>{ units }</div> }
                { requiredFrom && <span className="range-inputs__required-icon">*</span> }
              </span>
            </div>

            { !hideSecondField &&
              <Fragment>
                <span className="range-inputs__separator">-</span>

                <div className="range-inputs__input-wrapper" id={nameTo}>
                  <BasicInput
                    error={errorTo}
                    float={float}
                    handleChange={(e) => handleChange ? handleChange(e, nameTo) : handleNumberInputChange(e)}
                    hideError={true}
                    name={nameTo}
                    placeholder={I18n.t('generic.to')}
                    required={requiredTo}
                    type={type || "number"}
                    value={valueTo || ''}
                  />
                </div>
              </Fragment>
            }
          </div>

          { !hideSecondField && units &&
            <div className="range-inputs__second-units">
              <span className={unitsClasses}>
                { units }{ requiredTo && <span className="range-inputs__required-icon">*</span> }
              </span>
            </div>
          }
        </div>

        { errorFrom &&
          <div className="error-tooltip">
            { `${label} "${I18n.t('generic.from').toLowerCase()}" ${errorFrom}` }
          </div>
        }

        { errorTo &&
          <div className="error-tooltip">
            { `${label} "${I18n.t('generic.to').toLowerCase()}" ${errorTo}` }
          </div>
        }

      </div>

      { tooltip &&
        <div className="range-inputs__tooltip">
          <div className="form-group__tooltip">
            { tooltip }
          </div>
        </div>
      }
    </div>
  )
}

export default RangeInputs;
