import React, { useState } from 'react';
import classNames from 'classnames';

import { SvgIco } from "../../Icons";

import './index.sass';


const BasicInput = ({ disabled,
                      error,
                      float,
                      handleChange,
                      hideError,
                      inputClass,
                      name,
                      placeholder,
                      required,
                      type,
                      value,
                      wrapperClass,
                    }) => {

  const [passwordVisible, setPasswordVisibility] = useState(false);
  const inputClasses = classNames(inputClass, { 'error': error, 'filled': value, 'required-form-group': required });
  const inputType = passwordVisible || !type || type === 'number' ? 'text' : type;


  // Input type 'number' doesn't work on Safari, Firefox and passes letter 'E' in Chrome
  // This is why we need to check it manually to prevent bugs
  const handleInputChange = (e) => {
    const value = e.target.value.replace(',', '.');
    e.target.value = value;

    type === 'number'
      ? ((float ? /^\d*\.?\d*$/ : /^\d+$/).test(e.target.value) || !e.target.value) && handleChange(e)
      : handleChange(e);
  };


  return (
    <div className={`input-wrapper ${ wrapperClass || '' }`}>
      <input
        disabled={disabled}
        name={name}
        type={inputType}
        value={value}
        id={name}
        onChange={handleInputChange}
        className={inputClasses}
        placeholder={placeholder || I18n.t('providers.input_placeholder')}
      />

      { type === 'password' &&
        ( passwordVisible
          ? <SvgIco className="password-visibility" name="visibility" onClick={() => setPasswordVisibility(false)} size={18} />
          : <SvgIco className="password-visibility" name="visibility_off" onClick={() => setPasswordVisibility(true)} size={18} />
        )
      }

      { required && <span className="required-icon">*</span> }
      { error && !hideError && <div className="error-tooltip">{ error }</div> }
    </div>
  )
}


export default BasicInput;
