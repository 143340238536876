import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";

import lokalebasen_dk from '../../../src/images/logos/lokalebasen_dk.svg';
import matchoffice_generic from '../../../src/images/logos/matchoffice_generic.svg';
import { GET_STRIPE_INVOICES } from "../ApolloClient/queries";
import IframeModal from "../Modals/IframeModal";

import './index.sass';


const ProviderHeader = ({ currentLocale, siteKey, contractUrl }) => {

  const [showInvoicesLink, setShowInvoicesLink] = useState(null);
  const [modalIsActive, setModalIsActive] = useState(false);
  const [url, setUrl] = useState(false);

  const getLogo = () => siteKey === 'dk' ? lokalebasen_dk : matchoffice_generic;

  const {} = useQuery(GET_STRIPE_INVOICES, {
    onCompleted: (data) => {
      if (data && data.stripeInvoices.length) {
        setShowInvoicesLink(true);
      }
    },
  });

  const handleShowModal = (url) => {
    setUrl(url);
    setModalIsActive(true);
  };

  const handleCloseModal = () => {
    setModalIsActive(false);
  };

  return (
    <div className="provider-header" id="provider-header">

      <IframeModal modalClass="simple-cms-modal"
                   showModal={modalIsActive}
                   handleClose={handleCloseModal}
                   url={url}></IframeModal>

      <a className="provider-header__logo-wrapper" href="/">
        <img
          className="logo lazyload"
          src={getLogo()}
          data-sizes="auto"
          title={I18n.t('apps.lokalebasen_header.top.to_the_frontpage')}
          alt={I18n.t('apps.lokalebasen_header.top.alt')}
        />
      </a>

      <div className="provider-header__buttons-wrapper">

        <Link to={`${currentLocale}/new_providers/report_deal`} className="provider-header__button">
          <span>{ I18n.t('layouts.providers.contract.report_a_deal') }</span>
        </Link>

        <a className="provider-header__button" onClick={() => handleShowModal(contractUrl)}>
          <span>{ I18n.t('layouts.providers.contract.contract') }</span>
        </a>

        <Link to={`${currentLocale}/new_providers/settings/contact`} className="provider-header__button">
          <span>{ I18n.t("generic.contact_details") }</span>
        </Link>

        { showInvoicesLink &&
          <Link to={`${currentLocale}/new_providers/invoices`} className="provider-header__button provider-header__button--orange">
            <span>{ I18n.t("layouts.providers.contract.invoice_payments") }</span>
          </Link>
        }

      </div>

    </div>
  );
}

export default ProviderHeader;
