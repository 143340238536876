import React from 'react';

const handleBackgroundColor = (status) =>
  status === 'paid' ? 'rgba(119, 188, 31, 0.05)' : 'rgba(238, 0, 0, 0.05)';



export const InvoicesColumns = (data, openPaymentModal) =>
  React.useMemo(
    () => [
      //===========================================================================>

      {
        Header: I18n.t('generic.status'),
        accessor: 'status',
        style: {maxWidth: '298px', minWidth: '298px', width: '298px' },
        Cell: (data) =>
          <div className="invoices__column" style={{ color: data.value === 'paid' ? '#539533' : '#EE0000', }}>
            <div className="invoices__column-bg" style={{ background: handleBackgroundColor(data.value) }} />
            { I18n.t(`generic.status_value.${data.value}`) }
          </div>
      },

      //===========================================================================>

      {
        Header: I18n.t('generic.amount'),
        accessor: 'amountInCents',
        style: {minWidth: '162px', width: '162px' },
        Cell: (data) =>
          <div className="invoices__column">
            <div className="invoices__column-bg" style={{ background: handleBackgroundColor(data.row.original.status) }} />
            { (data.value && parseFloat((data.value)/100).toFixed(2)) || '' }
          </div>,
      },

      //===========================================================================>

      {
        Header: I18n.t('activerecord.attributes.location.currency'),
        accessor: 'currency.name',
        style: {minWidth: '162px', width: '162px' },
        Cell: (data) => data.value
          ?
            <div className="invoices__column">
              <div className="invoices__column-bg" style={{ background: handleBackgroundColor(data.row.original.status) }} />
              { data.value }
            </div>
          : '',
      },

      //===========================================================================>

      {
        Header: I18n.t('generic.invoice') + '(pdf)',
        accessor: 'invoicePdfShowPath',
        style: {minWidth: '162px', width: '162px' },
        Cell: (data) => data.value
          ?
            <a href={data.value}>
              <div className="invoices__column-bg" style={{ background: handleBackgroundColor(data.row.original.status) }} />
              { I18n.t('generic.show') } { I18n.t('generic.invoice') }
            </a>
          : '',
      },

      //===========================================================================>

      {
        Header: '',
        accessor: 'payment',
        style: { background: 'white' },
        Cell: (data) =>
          data.row.original.status === 'waiting'
          ? <div onClick={() => openPaymentModal(data.row.original.id)} className="invoices__pay-link">
              { I18n.t('generic.pay') }
            </div>
          : null,
      },
    ],
    [data],
  );
