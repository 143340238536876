import React, { Fragment, useState, useContext, useMemo, useEffect } from 'react';
import DateTimePicker from 'react-datetime-picker';
import ReactTooltip from "react-tooltip";

import Checkbox from '../../../../SharedComponents/Forms/CheckBox';
import { SvgIco } from "../../../../SharedComponents/Icons";
import SecondaryKindsInfoModal from "../../../Modals/SecondaryKindsInfoModal";
import RangeInputs from '../../FormGroups/RangeInputs';
import RadioFormGroup from '../../FormGroups/RadioFormGroup';
import DropdownGroup from '../../FormGroups/DropdownGroup';
import NavigationButtons from '../../NavigationButtons';
import InputGroup from '../../FormGroups/InputGroup';
import { ScoreActionTooltip } from "../../../ScoreWidget";
import { Context } from '../../index';
import CombinedKindForm from "./CombinedKindForm";

import './index.sass';


const Economy = () => {

  const {
    capitalize,
    currencyList,
    currentStepRef,
    defaultCurrencyId,
    errors,
    handleChange,
    leaseKinds,
    leasePrices,
    handleCheckboxesSelect,
    parkingTypes,
    qualityScore,
    saleKinds,
    saleSections,
    scrollToTarget,
    setErrors,
    showSecondaryKindsMessage,
    siteKey,
    updateValues,
    validateFields,
    validateRequiredFields,
    values,
    secondaryKindsMapping,
    suitableForKindsMapping,
  } = useContext(Context);

  const {
    auctionNumber,
    combinedLocationsAttributes,
    currencyId,
    dateOfAuction,
    enableCombinedLocations,
    isRentIncludeOperationalCost,
    kind,
    parkingType,
    secondaryKinds,
    section,
    suitableForKinds,
  } = values;

  const [annualPricesOpen, setAnnualPricesOpen] = useState(false);
  const [annualPricesM2Open, setAnnualPricesM2Open] = useState(true);
  const [combinedKindsErrors, setCombinedKindsErrors] = useState([]);
  const [initialCombinedKinds, setInitialCombinedKinds] = useState([]);
  const [secondaryKindModalIsOpen, setSecondaryKindModalIsOpen] = useState(false);
  const [visibleCombinedKinds, setVisibleCombinedKinds] = useState([]);

  const countryAlpha2 = values.countryAlpha2 || null;
  const areaUnit = countryAlpha2 === 'us' ? 'ft²' : 'm²';
  const currencies = JSON.parse(currencyList);
  const sectionLease = section === 'lease' || section === 'anonymous';
  const locationKinds = sectionLease ? leaseKinds : saleKinds;
  const filterLocationKinds = values.countryAlpha2 !== 'dk' ? locationKinds.filter(kind => kind !== 'parking') : locationKinds;

  const kindsOptions =
          filterLocationKinds.map(kind =>
              ({ value: kind, label: I18n.t(`generic.${kind}`) })
          );

  const saleSectionsOptions =
          saleSections.map(section =>
            ({ value: section, label: capitalize(I18n.t(`generic.models.property.category.${section}`, { count: 1 }))})
          );

  const parkingTypesOptions =
          parkingTypes.map(type =>
            ({ value: type, label: I18n.t(`providers.locations.form.parking_types.${type}`) })
          );

  const currencyOptions =
          currencies.map(currency =>
            ({ value: `${currency.id}`, label: currency.name })
          );

  const combinedKinds =
    kind === 'office'
      ? ['office', 'warehouse', 'store', 'showroom']
      : kind === 'store'
      ? ['store', 'warehouse', 'office', 'showroom']
      : kind === 'warehouse'
      ? ['warehouse', 'office', 'showroom']
      : null;

  const formattedCurrency = currencies.filter(item => {
    const currentCurrency = (currencyId && Number(currencyId)) || defaultCurrencyId.id;
    return item.id === currentCurrency;
  })[0].formatted;

  const defaultSection = saleSectionsOptions.some(option => option.value === section) ? section : '';


  useEffect(() => {
    if (enableCombinedLocations) {
      const combinedKinds = [];
      const initialKinds = [];

      combinedLocationsAttributes && combinedLocationsAttributes.map(kind => {
        (kind.hasOwnProperty('area') || kind.hasOwnProperty('yearlyPricePerM2')) && combinedKinds.push({ ...kind });
        initialKinds.push(kind);
      });

      setVisibleCombinedKinds(checkCombinedKindsOrder(combinedKinds));
      setInitialCombinedKinds(initialKinds);
    }
  }, []);


  const additionalCombinedKindsAvailable = useMemo(
    () =>
      visibleCombinedKinds && visibleCombinedKinds.length && combinedKinds
        ? !combinedKinds.every(kind => visibleCombinedKinds.find(attr => attr.kind === kind))
        : true,
    [visibleCombinedKinds],
  );


  const checkCombinedKindsOrder = (initialKinds, newLocationKind) => {
    // Put main combined kind to the top in case location kind change
    if (enableCombinedLocations && visibleFor(['office', 'warehouse', 'store'])) {
      const updatedCombinedKinds = [];
      const combinedKinds = initialKinds || visibleCombinedKinds;
      const mainLocationKind = newLocationKind || kind;

      combinedKinds && combinedKinds.map(kind =>
        (kind.hasOwnProperty('area') || kind.hasOwnProperty('yearlyPricePerM2')) && updatedCombinedKinds.push({ ...kind }));

      const mainKindIndex = updatedCombinedKinds.findIndex(combinedKind => combinedKind.kind === mainLocationKind);
      const mainKind = (mainKindIndex >= 0 && updatedCombinedKinds[mainKindIndex]) || { mainLocationKind };

      mainKindIndex >= 0 && updatedCombinedKinds.splice(mainKindIndex, 1);
      updatedCombinedKinds.unshift(mainKind);

      return initialKinds ? updatedCombinedKinds : setVisibleCombinedKinds(updatedCombinedKinds);
    }
  };


  const toCamelCase = (value) =>
    value.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());


  const secondaryKindsList = () =>
    secondaryKindsMapping[toCamelCase(section)]?.[toCamelCase(kind)];


  const suitableForKindsList = () =>
    suitableForKindsMapping[toCamelCase(section)]?.[toCamelCase(kind)]


  const handleKindChange = (value, name) => {
    Object.keys(errors).length && setErrors({});
    secondaryKinds.length && handleChange([], 'secondaryKinds');
    parkingType && handleChange(null, 'parkingType');
    handleChange(value, name);
  };


  const handleSecondaryKindsChange = (e, name) => {
    showSecondaryKindsMessage
    && invisibleFor(['virtual_office', 'parking', 'coworking', 'meeting_room'])
    && (!secondaryKinds || secondaryKinds.length === 0)
    && setSecondaryKindModalIsOpen(true);

    handleCheckboxesSelect(e, name);
  };


  const addNewCombinedKind = () => {

    const newKind = (visibleCombinedKinds && visibleCombinedKinds.length)
      ? combinedKinds.find(kind => !visibleCombinedKinds.some(attr => attr.kind === kind)) || null
      : combinedKinds[0];

    if (newKind) {
      const updatedCombinedKinds = [ ...visibleCombinedKinds ];
      const currentKindsKindIndex = combinedLocationsAttributes && combinedLocationsAttributes.findIndex(attr => attr.kind === newKind);
      const newCombinedLocationAttributes = [];

      // We need to copy all objects through iteration as we receive frozen objects from backend.
      combinedLocationsAttributes && combinedLocationsAttributes.map(kind => newCombinedLocationAttributes.push({ ...kind }));
      updatedCombinedKinds.push({ kind: newKind });
      setVisibleCombinedKinds(updatedCombinedKinds);

      if (!currentKindsKindIndex || currentKindsKindIndex < 0) {
        newCombinedLocationAttributes.push({ kind: newKind });
        updateValues({ combinedLocationsAttributes: [ ...newCombinedLocationAttributes ] });
      } else {
        newCombinedLocationAttributes[currentKindsKindIndex]._destroy
          && delete newCombinedLocationAttributes[currentKindsKindIndex]._destroy;
        updateValues({ combinedLocationsAttributes: [ ...newCombinedLocationAttributes ] });
      }
    }
  };


  const errorsHandler = (errors) => {
    const errorField = document.getElementById(Object.keys(errors)[0]);
    const annualPricesWrapper = document.getElementById('annual-prices');
    const annualM2pricesWrapper = document.getElementById('annual-prices-m2');
    const openAnnualPrices = annualPricesWrapper && annualPricesWrapper.contains(errorField) && !annualPricesOpen;
    const openAnnualM2Prices = annualM2pricesWrapper && annualM2pricesWrapper.contains(errorField) && !annualPricesM2Open;

    if (openAnnualPrices || openAnnualM2Prices) {
      openAnnualPrices ? setAnnualPricesOpen(true) : setAnnualPricesM2Open(true);
      setTimeout(scrollToTarget(document.getElementById(Object.keys(errors)[0])), 500);
    } else {
      scrollToTarget(document.getElementById(Object.keys(errors)[0]))
    }
  };

  const validateCombinedLocations = () => {
    if (enableCombinedLocations) {
      const errors = [];

      visibleCombinedKinds && visibleCombinedKinds.length &&
      visibleCombinedKinds.map(combinedKind =>
        combinedKinds.includes(combinedKind.kind) && !combinedKind.area && !combinedKind.yearlyPricePerM2 && errors.push(combinedKind.kind)
      );

      setCombinedKindsErrors(errors);
      errors.length && setTimeout(scrollToTarget(document.getElementById(`combined-form-${errors[0]}`)), 500);

      return !errors.length;
    } else {
      return true;
    }
  };

  const handleValidate = (nextStep) => validateRequiredFields(errorsHandler) && validateCombinedLocations() && validateFields(nextStep, null, errorsHandler);

  const handleChangeTime = (date) => handleChange(date ? date.toISOString() : date, 'dateOfAuction');

  const visibleFor = (kinds) => kinds.indexOf(sectionLease ? kind : section) >= 0;

  const invisibleFor = (kinds) => kinds.indexOf(sectionLease ? kind : section) < 0;

  const showUptoField = (name) => !(values[`${name}To`] || values.areaTo);


  return (
    <div className="economy-step" ref={currentStepRef}>

      <SecondaryKindsInfoModal
        handleCancel={() => {
          handleChange([], 'secondaryKinds');
          setSecondaryKindModalIsOpen(false);
        }}
        handleClose={() => setSecondaryKindModalIsOpen(false)}
        showModal={secondaryKindModalIsOpen}
      />

      <div className="location-form__section-wrapper">
        { !sectionLease &&
          <DropdownGroup
            handleChange={handleChange}
            label={I18n.t('activerecord.attributes.property.category')}
            name="section"
            options={saleSectionsOptions}
            value={defaultSection} />
        }

        <DropdownGroup
          handleChange={(value, name) => {
            visibleCombinedKinds.length && checkCombinedKindsOrder(false, value);
            handleKindChange(value, name);
          }}
          label={I18n.t('activerecord.attributes.property.kind')}
          labelTooltip={I18n.t('provider.form.economy.kind_tooltip')}
          name="kind"
          options={kindsOptions}
          required={true}
          tooltipClass={showSecondaryKindsMessage && 'relative'}
          value={kind || kindsOptions[0]} />

        { kind === 'parking' &&
          <DropdownGroup
            error={errors.parkingType}
            handleChange={handleKindChange}
            label={I18n.t('activerecord.attributes.location.parking_type')}
            name="parkingType"
            options={parkingTypesOptions}
            required={true}
            value={parkingType} />
        }

        { secondaryKindsList() && secondaryKindsList().length > 0 &&
          <div className="form-group row">
            <div className="form-label form-label--align-top col-xl-3 col-lg-3">
              <span>{ I18n.t('activerecord.attributes.location.secondary_kinds') }</span>
            </div>

            <div className="checkboxes-wrapper col-xl-3 col-lg-4">
              { secondaryKindsList().map(kind =>
                <Checkbox
                  field="secondaryKinds"
                  handleChange={handleSecondaryKindsChange}
                  key={`secondary-${kind}`}
                  label={capitalize(I18n.t(`global.models.location.kinds.${kind}.short`, { count: 1 }))}
                  name={kind}
                  value={secondaryKinds && secondaryKinds.includes(kind)} />
              )}
            </div>
            { showSecondaryKindsMessage && invisibleFor(['virtual_office', 'parking', 'coworking', 'meeting_room']) &&
              <Fragment>
                <div className="col-xl-1 col-lg-1" />
                <div className="col-xl-5 col-lg-4">
                  <div className="form-group__tooltip secondary-kinds">
                    <h3>{ I18n.t('providers.locations.secondary_kind_payment.header_new') }</h3>
                    <p dangerouslySetInnerHTML={{ __html: I18n.t('providers.locations.secondary_kind_payment.body_new') }} />
                  </div>
                </div>
              </Fragment>
            }
          </div>
        }

        { suitableForKindsList() && suitableForKindsList().length > 0 &&
          <div className="form-group row">
            <div className="form-label form-label--align-top col-xl-3 col-lg-3">
              <span style={{ marginRight: '8px' }}>{ I18n.t('generic.suitable_for') }</span>
              <span
                className="provider-tooltip"
                data-tip={I18n.t('providers.locations.form.suitable_for_kinds.other.helper')}
                data-class="provider-tooltip"
                data-for="suitable-for-tooltip">
              <SvgIco name="info_outline" size={24} />
            </span>

              <ReactTooltip
                border={true}
                borderColor="black"
                effect='solid'
                id="suitable-for-tooltip"
                place='right'
                type="light" />
            </div>

            <div className="checkboxes-wrapper col-xl-3 col-lg-4">
              { suitableForKindsList().map(kind =>
                <Checkbox
                  field="suitableForKinds"
                  handleChange={handleCheckboxesSelect}
                  key={`suitable-${kind}`}
                  label={capitalize(I18n.t(`global.models.location.kinds.${kind}.short`, { count: 1 }))}
                  name={kind}
                  value={suitableForKinds && suitableForKinds.includes(kind)} />
              )}
            </div>
          </div>
        }
      </div>

      <div className="location-form__section-wrapper" style={{ marginBottom: 0, paddingBottom: '8px' }}>
        <div className="sectionScrollTarget" id="prices" />
        <div className="step-header">
          { I18n.t('providers.locations.form.economy') }

          <ScoreActionTooltip
            filled={qualityScore && qualityScore.price.score}
            label={qualityScore && I18n.t(`provider.form.quality_score.hint_label.price`)}
          />
        </div>

        { siteKey !== 'dk' &&
          <DropdownGroup
            handleChange={(value, field) => handleChange(Number(value), field)}
            label={I18n.t('activerecord.attributes.location.currency')}
            name="currencyId"
            options={currencyOptions}
            value={(currencyId && `${currencyId}`) || `${defaultCurrencyId.id}`} />
        }

        { invisibleFor(['virtual_office', 'parking']) &&
          <RangeInputs
            label={`${I18n.t('apps.lb_showroom.detailed.facts.area_label')}`}
            name="area"
            requiredFrom={true}
            unitsPosition="top"
            units={areaUnit} />
        }

        { leasePrices && kind === "store" &&
          <RangeInputs
            hideSecondField={true}
            label={I18n.t('activerecord.attributes.location.disposal_price')}
            name="disposalPrice"
            units={formattedCurrency}
            useOriginName={true} />
        }

        { visibleFor(['parking']) &&
          <RangeInputs
            hideSecondField={showUptoField('amountOfSpaces')}
            label={I18n.t('providers.locations.form.amount_of_spaces')}
            name="amountOfSpaces"
            requiredFrom={true} />
        }

        { visibleFor(['coworking', 'business_center']) &&
          <RangeInputs
            hideSecondField={showUptoField('workstationsCount')}
            label={I18n.t('providers.locations.form.workstations_count')}
            name="workstationsCount" />
        }

        { sectionLease && visibleFor(['office', 'coworking', 'meeting_room', 'business_center']) &&
          <Fragment>
            <RangeInputs
              hideSecondField={showUptoField('employeesCount')}
              label={I18n.t('views.shared.employee_count')}
              name="employeesCount" />

            <RadioFormGroup
              field="employeesCountVisible"
              htmlTooltip={true}
              label={I18n.t('generic.visible')}
              tooltip={I18n.t('providers.locations.popover.number_of_employees')} />
          </Fragment>
        }

        { invisibleFor(['virtual_office', 'parking']) &&
          <Fragment>
            <div className="form-group row">
              <div className="form-label form-label--bold step-subheader col-xl-3 col-lg-3">
                { !sectionLease &&
                  <span>{ I18n.t('apps.lb_showroom.detailed.economy.annual_prices_m2') }</span>
                }
                { sectionLease &&
                  <div className="economy-step__expand-btn-wrapper" onClick={() => setAnnualPricesM2Open(!annualPricesM2Open)}>
                    <span>{ kind === 'meeting_room' ? I18n.t('generic.price') : I18n.t('apps.lb_showroom.detailed.economy.annual_prices_m2') }</span>
                    { annualPricesM2Open
                      ? <SvgIco name="expand-less" size={18} />
                      : <SvgIco name="expand-more" size={18} />
                    }
                  </div>
                }
              </div>
            </div>

            <div id="annual-prices-m2" className={annualPricesM2Open ? 'uncollapsed' : ''}>
              { visibleFor(['coworking', 'business_center']) &&
                <RangeInputs
                  hideSecondField={showUptoField('workstationCostPerMonth')}
                  label={I18n.t('providers.locations.form.workstation_cost_per_month')}
                  name="workstationCostPerMonth" />
              }

              { sectionLease &&
                <Fragment>
                  { visibleFor(['meeting_room']) &&
                    <RangeInputs
                      hideSecondField={showUptoField('hourlyRentWithCents')}
                      label={I18n.t('providers.locations.form.rent_price_per_hour')}
                      name="hourlyRentWithCents"
                      units={`${ formattedCurrency }`} />
                  }

                  { invisibleFor(['meeting_room']) &&
                    <Fragment>
                      <RangeInputs
                        float={true}
                        handleChange={(e, name) => handleChange(e.target.value, name)}
                        hideSecondField={showUptoField('yearlyRentPerM2WithCents')}
                        label={ I18n.t('apps.lb_showroom.detailed.economy.price_per_year') }
                        name="yearlyRentPerM2WithCents"
                        tooltip={ I18n.t('providers.locations.form.money_row.yearly_rent_per_m2_help') }
                        units={ `${ formattedCurrency }/${areaUnit}` }
                      />

                      <RadioFormGroup
                        field="yearlyRentPerM2AmountFlexible"
                        label={ I18n.t('providers.locations.form.yearly_rent_per_m2_amount_flexible') }
                        tooltip={ I18n.t('providers.locations.popover.yearly_rent_per_m2_amount_flexible') } />

                      { siteKey === 'dk' &&
                        <RadioFormGroup
                          field="isRentIncludeOperationalCost"
                          label={ I18n.t('activerecord.attributes.location.is_rent_include_operational_cost') } />
                      }

                      { siteKey === 'dk' && sectionLease && visibleFor(['office', 'warehouse', 'store']) &&
                        <Fragment>
                          <RadioFormGroup
                            field="enableCombinedLocations"
                            label={I18n.t('activerecord.attributes.location.enable_combined_locations')} />

                          { enableCombinedLocations &&
                            <div className="economy-step__combined-locations">

                              { visibleCombinedKinds && visibleCombinedKinds.length
                                ? visibleCombinedKinds.map(combinedKind =>
                                  combinedKinds.includes(combinedKind.kind) &&
                                  <CombinedKindForm
                                    areaUnit={areaUnit}
                                    combinedKinds={combinedKinds}
                                    currentData={combinedLocationsAttributes}
                                    data={combinedKind}
                                    formattedCurrency={formattedCurrency}
                                    errors={combinedKindsErrors}
                                    handleErrors={setCombinedKindsErrors}
                                    initialData={initialCombinedKinds}
                                    key={`combined-kind-${combinedKind.kind}`}
                                    mainKind={kind}
                                    selectedKinds={visibleCombinedKinds}
                                    updateValues={updateValues}
                                    updateSelectedKinds={setVisibleCombinedKinds}
                                  />
                                )
                                : (!combinedLocationsAttributes || !combinedLocationsAttributes.length) && addNewCombinedKind()
                              }

                              { additionalCombinedKindsAvailable &&
                                <div className="economy-step__combined-locations-add-btn"
                                     onClick={() => validateCombinedLocations() && addNewCombinedKind()}>
                                  + { I18n.t('providers.locations.form.combined_locations_add_property') }
                                </div>
                              }

                            </div>
                          }
                        </Fragment>
                      }

                      { !(siteKey === 'dk' && isRentIncludeOperationalCost) &&
                        <RangeInputs
                          hideSecondField={showUptoField('yearlyOperationalCostPerM2WithCents')}
                          label={ I18n.t('apps.lb_showroom.detailed.economy.operational_per_m2_per_year') }
                          name="yearlyOperationalCostPerM2WithCents"
                          units={ `${ formattedCurrency }/${areaUnit}` } />
                      }

                      <RangeInputs
                        hideSecondField={showUptoField('yearlyHeatingCostPerM2WithCents')}
                        label={ I18n.t('apps.lb_showroom.detailed.economy.heating_per_m2_per_year') }
                        name="yearlyHeatingCostPerM2WithCents"
                        units={ `${ formattedCurrency }/${areaUnit}` } />

                      <RangeInputs
                        hideSecondField={showUptoField('yearlyWaterCostPerM2WithCents')}
                        label={ I18n.t('apps.lb_showroom.detailed.economy.water_per_m2_per_year') }
                        name="yearlyWaterCostPerM2WithCents"
                        units={ `${ formattedCurrency }/${areaUnit}` } />

                      <RangeInputs
                        hideSecondField={showUptoField('yearlyElectricityCostPerM2WithCents')}
                        label={ I18n.t('apps.lb_showroom.detailed.economy.electricity_per_m2_per_year') }
                        name="yearlyElectricityCostPerM2WithCents"
                        units={ `${ formattedCurrency }/${areaUnit}` } />
                    </Fragment>
                  }
                </Fragment>
              }
            </div>
          </Fragment>
        }

        { sectionLease && invisibleFor(['meeting_room']) &&
          <Fragment>
            <div className="form-group row">
              <div className="form-label form-label--bold step-subheader col-xl-3 col-lg-3">
                { visibleFor(['virtual_office', 'parking']) &&
                  <span>{ I18n.t('apps.lb_showroom.detailed.economy.annual_prices') }</span>
                }
                { invisibleFor(['virtual_office', 'parking']) &&
                  <div className="economy-step__expand-btn-wrapper" onClick={() => setAnnualPricesOpen(!annualPricesOpen)}>
                    <span>{ I18n.t('apps.lb_showroom.detailed.economy.annual_prices') }</span>
                    { annualPricesOpen
                      ? <SvgIco name="expand-less" size={18} />
                      : <SvgIco name="expand-more" size={18} />
                    }
                  </div>
                }
              </div>
            </div>

            { invisibleFor(['virtual_office', 'parking']) &&

              <div id="annual-prices" className={annualPricesOpen ? 'uncollapsed' : ''}>
                <RangeInputs
                  hideSecondField={showUptoField('annualRentWithCents')}
                  label={isRentIncludeOperationalCost ? I18n.t('apps.lb_showroom.detailed.economy.annual_prices_m2_including_operational') : I18n.t('apps.lb_showroom.detailed.economy.annual_rent')}
                  name="annualRentWithCents"
                  units={formattedCurrency} />

                { !(siteKey === 'dk' && isRentIncludeOperationalCost) &&
                  <RangeInputs
                    hideSecondField={showUptoField('annualOperationalCostWithCents')}
                    label={I18n.t('apps.lb_showroom.detailed.economy.annual_operational_cost')}
                    name="annualOperationalCostWithCents"
                    units={formattedCurrency} />
                }

                <RangeInputs
                  hideSecondField={showUptoField('annualHeatingCostWithCents')}
                  label={I18n.t('apps.lb_showroom.detailed.economy.annual_heating_cost')}
                  name="annualHeatingCostWithCents"
                  units={formattedCurrency} />

                <RangeInputs
                  hideSecondField={showUptoField('annualWaterCostWithCents')}
                  label={I18n.t('apps.lb_showroom.detailed.economy.annual_water_cost')}
                  name="annualWaterCostWithCents"
                  units={formattedCurrency} />

                <RangeInputs
                  hideSecondField={showUptoField('annualElectricityCostWithCents')}
                  label={I18n.t('apps.lb_showroom.detailed.economy.annual_electricity_cost')}
                  name="annualElectricityCostWithCents"
                  units={formattedCurrency} />

                <RangeInputs
                  hideSecondField={showUptoField('annualTotalCostWithCents')}
                  label={I18n.t('apps.lb_showroom.detailed.economy.annual_rent_and_service_cost')}
                  name="annualTotalCostWithCents"
                  units={formattedCurrency} />
              </div>
            }

            { visibleFor(['virtual_office', 'parking']) &&
              <RangeInputs
                hideSecondField={showUptoField('monthlyRentPerSpaceWithCents')}
                label={I18n.t('apps.lb_showroom.detailed.economy.monthly_rent_per_space')}
                name="monthlyRentPerSpaceWithCents"
                units={formattedCurrency} />
            }
          </Fragment>
        }

        { !sectionLease &&
          <Fragment>
            { invisibleFor(['foreclosure']) &&
              <RangeInputs
                hideSecondField={true}
                label={I18n.t('apps.lb_showroom.detailed.economy.sale_price')}
                name={`salePrice`}
                useOriginName={true} />
            }

            <RangeInputs
              hideSecondField={true}
              label={I18n.t('activerecord.attributes.location.lot_area')}
              name={`lotArea`}
              useOriginName={true} />

            <RangeInputs
              hideSecondField={true}
              label={I18n.t('activerecord.attributes.location.year_built')}
              name={`yearBuilt`}
              useOriginName={true} />

            <RangeInputs
              hideSecondField={true}
              label={I18n.t('apps.lb_showroom.detailed.economy.yearly_rental_income')}
              name={`yearlyRentalIncome`}
              useOriginName={true} />

            <RangeInputs
              hideSecondField={true}
              label={I18n.t('apps.lb_showroom.detailed.economy.fixed_annual_operational_cost')}
              name={`fixedAnnualOperationalCost`}
              useOriginName={true} />

            <RangeInputs
              hideSecondField={true}
              label={I18n.t('apps.lb_showroom.detailed.economy.deposit_and_prepaid_rent')}
              name={`depositAndPrepaidRent`}
              useOriginName={true} />

            { invisibleFor(['foreclosure']) &&
              <RangeInputs
                hideSecondField={true}
                label={I18n.t('apps.lb_showroom.detailed.economy.return_rate_new')}
                name={`returnRate`}
                useOriginName={true} />
            }

            { visibleFor(['foreclosure']) &&
              <Fragment>
                <div className="form-group row">
                  <div className="form-label form-label--align-top col-xl-3 col-lg-3">
                    <span>{ I18n.t('provider.form.economy.auction_date') }</span>
                  </div>

                  <div className="checkboxes-wrapper col-xl-3 col-lg-4">
                    <DateTimePicker
                      calendarIcon={<SvgIco name="calendar_today" size={20} />}
                      clearIcon={<SvgIco name="close" size={24} />}
                      minDate={new Date()}
                      onChange={handleChangeTime}
                      value={dateOfAuction ? new Date(dateOfAuction) : null} />
                  </div>
                </div>

                <InputGroup
                  label={I18n.t('provider.form.economy.auction_number')}
                  name={`auctionNumber`}
                  value={auctionNumber || ''} />

              </Fragment>
            }

          </Fragment>
        }
      </div>

      <NavigationButtons
        handleBack={() => handleValidate('location')}
        handleNext={() => handleValidate('contacts')}
        requiredFieldsValid={() => validateRequiredFields(errorsHandler) && validateCombinedLocations()} />

    </div>
  )
};


export default Economy;
