import React, { useState } from 'react';
import { useQuery } from "@apollo/client";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Loader from "../../../SharedComponents/Loader";
import { GET_STRIPE_INTENT } from "../../ApolloClient/queries";
import ProviderModal from "../ProviderModal";
import CheckoutForm from "./CheckoutForm";

import "./PaymentModal.sass";


const PaymentModal = ({ handleClose,
                        invoiceId,
                        handlePaymentSuccess,
                        locale,
                        showModal,
                        stripeInvoicesApiKey,
                      }) => {

  const stripePromise = loadStripe(stripeInvoicesApiKey, { locale: locale.split('-')[0] });

  const [amount, setAmount] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [paymentInProcess, setPaymentInProcess] = useState(false);

  const {} = useQuery(GET_STRIPE_INTENT, {
    variables: { id: invoiceId },
    onCompleted: (data) => {
      if (data) {
        const { clientSecret, stripeInvoice } = data.stripeIntent;
        setAmount(parseFloat((stripeInvoice.amountInCents)/100).toFixed(2));
        setClientSecret(clientSecret);
        setCurrency(stripeInvoice.currency.name);
      }
    }
  });

  const appearance = {
    theme: 'stripe',
    variables: {
      colorBackground: '#ffffff',
      colorText: '#000000',
      borderRadius: '4px',
    },
    rules: {
      '.Input': {
        borderColor: '#E0E2E2',
      }
    }
  };

  const options = { clientSecret, appearance };


  return (
    <ProviderModal
      modalClass="payment-modal"
      showModal={showModal}
      handleClose={paymentInProcess ? null : handleClose}>

      <h3 className="payment-modal__headline">{ I18n.t('generic.payment') }</h3>

      { clientSecret &&
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm
            amount={amount}
            currency={currency}
            handlePaymentSuccess={handlePaymentSuccess}
            setPaymentInProcess={setPaymentInProcess}
          />
        </Elements>
      }

      { !clientSecret &&  <Loader size={32} /> }

    </ProviderModal>
  )
}


export default PaymentModal;



