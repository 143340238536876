import React, { Fragment, Component } from 'react';
import { FileDrop } from 'react-file-drop';
import ReactTooltip from 'react-tooltip';

import { apiProvider } from '../../../../../api/core';
import { SvgIco } from "../../../../SharedComponents/Icons";
import InfoMessagePopup from "../../../Popups/InfoMessagePopup";

import './FileInput.sass';


class FileInput extends Component {

  state = {
    filesList: [],
    draggable: null,
    draggableIndex: null,
    infoMessagePopupActive: false,
    pdfFileLoading: false,
    pdfFileDeleting: false,
  };


  componentDidMount() {
    this.handleDefaultFileList();
  };


  componentDidUpdate(prevProps, prevState, snapshot) {
    const { defaultFileList, name, setVisualsData } = this.props;

    if (prevProps.defaultFileList !== defaultFileList) {
      this.handleDefaultFileList();
    }

    if (this.state.filesList !== prevState.filesList) {
      const currentFilesCount = this.state.filesList.filter(file => !file.error).length;
      setVisualsData(prevData => ({ ...prevData, [`${name}_current_count`]: currentFilesCount }));
    }
  };


  handleDefaultFileList = () => {
    const { defaultFileList } = this.props;
    const data = defaultFileList || [];
    const newList = [];

    data.map(item => newList.push({id: item.id, imgUrl: item.thumbnailW120 }));
    this.setState({ filesList: newList });
  };


  handleChange = (files) => {
    const filesToDownload = { ...files };
    const newFilesList = [ ...this.state.filesList ];
    const { locationId, name, isPdf, refetchValues } = this.props;

    for (const [key, value] of Object.entries(files)) {

      const id = Number(('' + Math.random()).split(".")[1]);
      const newFile = { loading: true, name: value.name, id };

      value.id = id;
      newFilesList.push(newFile);
    }

    this.setState({ filesList: newFilesList, pdfFileLoading: !!isPdf }, () => {
      for (const [key, value] of Object.entries(filesToDownload)) {

        const formData = new FormData();
        formData.append('asset[asset_file]', value);
        formData.append('asset[location_id]:', `${locationId}`);
        formData.append('authenticity_token', this.props.formAuthToken);
        formData.append('utf8', 'true');

        const apiOptions = {
          url: `/providers/locations/${locationId}/${name}`,
          method: 'post',
          data: formData,
          headers: { 'Content-Type': 'application/json; charset=utf-8', 'Accept': 'application/json' },
        };

        apiProvider.withOptions(apiOptions)
          .then(response => {
            const { filesList } = this.state;
            const newFileIndex = filesList.findIndex(file => file.id === value.id);
            const currentFile = filesList[newFileIndex];
            const newFilesList = [ ...filesList ];

            currentFile.loading = false;

            if (response) {
              const assetInfo = response.assets_info || [];
              const errorsPresent = response.errors && response.errors.length;

              if (response.status === 'success') {

                if (assetInfo.length === 0) {
                  refetchValues && refetchValues();
                } else {
                  const asset = assetInfo[0];

                  if (isPdf) {
                    this.setState({ pdfFileLoading: false });
                    refetchValues();
                  } else {
                    currentFile.imgUrl = asset.thumbnail;
                    currentFile.id = asset.id;
                  }
                }

              } else if (errorsPresent) {
                currentFile.error = response.errors;
                currentFile.id = Number(('' + Math.random()).split(".")[1]);
              }

              newFilesList.splice(newFileIndex, 1, currentFile);
              this.setState({ filesList: newFilesList, pdfFileLoading: false }, () => {
                newFilesList.length && !this.state.filesList.some(file => file.loading) && this.handleReorder();
              });

            } else {
              newFilesList.splice(newFileIndex, 1);
              this.setState({ filesList: newFilesList, infoMessagePopupActive: true });
            }
          })
          .catch(error => {
            console.log('Error--->', error);
          });
      }}
    );
  };


  handleInputChange = (e) => {
    this.handleChange(e.target.files);
    e.target.value = '';
  }


  handleDragStart = (e) => {
    const targetIndex = e.target.dataset.index;
    e.target.classList.add('dragging');

    this.setState({
      draggable: this.state.filesList[targetIndex],
      draggableIndex: targetIndex,
    });
  };


  handleDragEnd = (e) =>
    e.target.classList.remove('dragging');


  handleDragEnter = (e) =>
    e.preventDefault();


  handleDrop = (e) => {
    const dropzoneIndex = e.target.dataset.index;
    const reorderedFilesList = [ ...this.state.filesList ];

    reorderedFilesList.splice(Number(dropzoneIndex), 0, reorderedFilesList.splice(this.state.draggableIndex, 1)[0]);
    this.setState({ filesList: reorderedFilesList }, () => this.handleReorder());
  }


  handleReorder = () => {
    const { locationId, name } = this.props;
    const url = `/providers/locations/${locationId}/${name}/set_positions`;
    const formData = new FormData();

    this.state.filesList.map(file => !file.error && formData.append('assets[]', file.id));
    formData.append('authenticity_token', this.props.formAuthToken);

    apiProvider.put(url, formData).then(response => console.log('response reorder', response));
  };


  handleDelete = (fileId) => {
    const { filesList } = this.state;
    const { locationId, name, isPdf, formAuthToken, refetchValues } = this.props;
    const url = `/providers/locations/${locationId}/${name}${fileId ? `/${fileId}` : ''}`;
    const apiOptions = {
      url,
      method: 'delete',
      data: { authenticity_token: formAuthToken  },
      headers: {'Content-Type': 'application/json; charset=utf-8', 'Accept': 'application/json'}
    };

    if (isPdf) {

      this.setState({ pdfFileDeleting: true });

      apiProvider.withOptions(apiOptions)
        .then(response => {
          if (response.status && response.status === 'success') {
            refetchValues();
            this.setState({ pdfFileDeleting: false });
          }})
        .catch(error => {
          console.log('error', error);
          this.setState({ pdfFileDeleting: false });
        });

    } else {

      const removableFileIndex = filesList.findIndex(file => file.id === fileId);
      // In case we have file error file is absent in database
      const removeFromDatabase = !filesList[removableFileIndex].error;
      const removeFileFromState = () => {
        const newFilesList = [ ...filesList ];

        newFilesList.splice(removableFileIndex, 1);
        this.setState({ filesList: newFilesList });
      }

      if (removeFromDatabase) {
        const newFilesList = [ ...filesList ];
        const removableFile = newFilesList[removableFileIndex];

        removableFile.loading = true;
        newFilesList.splice(removableFileIndex, 1, removableFile);
        this.setState({ filesList: newFilesList });

        apiProvider.withOptions(apiOptions).then(response => {
          console.log('remove', response);
          removeFileFromState();
        });
      } else {
        removeFileFromState();
      }
    }
  };


  handleRotate = (fileId) => {
    const { formAuthToken, locationId, name } = this.props;
    const url = `/providers/locations/${locationId}/${name}/${fileId}`;
    const newFilesList = [ ...this.state.filesList ];
    const currentFileIndex = newFilesList.findIndex(file => file.id === fileId);
    const currentFile = newFilesList[currentFileIndex];

    currentFile.loading = true;
    newFilesList.splice(currentFileIndex, 1, currentFile);
    this.setState({ filesList: newFilesList });

    const apiOptions = {
      url,
      method: 'put',
      data: { authenticity_token: formAuthToken, rotation_angle: 90  },
      headers: {'Content-Type': 'application/json; charset=utf-8', 'Accept': 'application/json'}
    };

    apiProvider.withOptions(apiOptions).then(response => {
      if (response && response.status === 'success') {
        const rotationAngle = currentFile.rotationAngle;

        currentFile.rotationAngle = rotationAngle ? rotationAngle + 90 : 90;
        currentFile.loading = false;
        newFilesList.splice(currentFileIndex, 1, currentFile);
        this.setState({ filesList: newFilesList });
      }
    });
  };


  render() {

    const { name, fileTypes, isPdf, linkFile } = this.props;
    const { filesList, infoMessagePopupActive, pdfFileLoading, pdfFileDeleting } = this.state;
    const showFileLink = isPdf && (linkFile.url || pdfFileLoading);


    return (
      <div className="file-input">

        <InfoMessagePopup
          handleClose={() => this.setState({ infoMessagePopupActive: false })}
          message={I18n.t('apps.lb_showroom.detailed.order_form.error.message')}
          customClass={'error'}
          visible={infoMessagePopupActive}
        />

        <div className="file-input__preview-list">

          { !isPdf && filesList.length > 0 && filesList.map((file, index) =>
            <div className="file-input__dropzone"
                 key={index}
                 data-index={index}
                 onDrop={this.handleDrop}
                 onDragEnter={this.handleDragEnter}>

              <div draggable={!file.error}
                   onDragStart={this.handleDragStart}
                   onDragEnd={this.handleDragEnd}
                   data-index={index}
                   className={`file-input__preview-img ${file.error ? ' error' : ''}`}>

                <div className="file-input__background"
                     style={ file.imgUrl
                       ? { backgroundImage: `url('${file.imgUrl}')`, transform: `rotate(${file.rotationAngle || 0}deg)`, opacity: file.loading ? 0.5 : 1 }
                       : null }
                />

                { !file.loading &&
                  <div className="file-input__delete" onClick={() => this.handleDelete(file.id)}>
                    <SvgIco name="close" size={14} />
                  </div>
                }

                { !file.loading && !file.error &&
                  <div className="file-input__rotate" onClick={() => this.handleRotate(file.id)}>
                    <SvgIco name="sync" size={13} />
                  </div>
                }

                { file.loading &&
                  <div className={`lds-spinner${file.imgUrl ? ' black' : ''}`}>
                    <div /><div /><div /><div /><div /><div /><div /><div /><div /><div /><div /><div />
                  </div>
                }

                { (file.loading || file.error) &&
                  <div className="file-input__name">
                    { file.name }
                  </div>
                }

                { file.error &&
                  <Fragment>
                    <div className="file-input__error">
                      <div className="provider-tooltip"
                           data-tip={file.error[0]}
                           data-class="provider-tooltip__body"
                           data-for={`${file.name}-tooltip`}>

                        <div className="file-input__error-label">
                          { I18n.t('error_page.general.error') }
                          <SvgIco name="info_outline" size={18} />
                        </div>
                      </div>

                      <ReactTooltip
                        border={true}
                        borderColor="black"
                        effect='solid'
                        id={`${file.name}-tooltip`}
                        place='right'
                        type="light"
                      />
                    </div>
                  </Fragment>
                }

              </div>
            </div>
          )}

          <div className="file-input__drop-wrapper">
            <FileDrop onDrop={(files) => this.handleChange(files)}>

              <label htmlFor={name}>
                <SvgIco name="add_circle_outline" size={32} />
              </label>

              <input
                accept={fileTypes}
                className="file-input__input"
                id={name}
                multiple={!isPdf}
                name={name}
                onChange={this.handleInputChange}
                onDrop={this.handleDrop}
                type="file" />
            </FileDrop>
          </div>

        </div>

        { showFileLink &&
          <div className="file-input__url-file-wrapper">
            <a href={linkFile.url} target="_blank">

              { pdfFileLoading || pdfFileDeleting
                ? <div className={`lds-spinner ${pdfFileDeleting ? 'lds-spinner--red' : ''}`}>
                    <div /><div /><div /><div /><div /><div /><div /><div /><div /><div /><div /><div />
                  </div>
                : <SvgIco name="pdf" size={24} />
              }

              <div className="google-translate-helper">
                { linkFile.name }.pdf
              </div>
            </a>

            { !pdfFileLoading &&
              <SvgIco
                className={pdfFileDeleting ? 'inactive' : ''}
                name="delete_outline"
                onClick={() => this.handleDelete(false)}
                size={24} />
            }

          </div>
        }
      </div>
    )
  }
}


export default FileInput;
