import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { GET_CONTACTS, GET_DELETED_CONTACTS } from '../ApolloClient/queries';
import NetworkError from '../Errors/NetworkError';
import ContactsTable from './ContactsTable';

import './index.sass';


function ContactsPage({ asPreview, currentLocale }) {
  const [contacts, setContactsList] = useState([]);
  const [contactsTotalCount, setContactsTotalCount] = useState(0);
  const [deletedTotalCount, setDeletedTotalCount] = useState(0);
  const [deletedContactsList, setDeletedContactsList] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [showDeletedContacts, setShowDeletedContacts] = useState(false);

  const [filters, setFilters] = useState({
    page: 1,
    perPage: asPreview ? 4 : 20,
    orderDirection: 'desc',
    orderBy: 'is_main_contact',
  });

  const [deletedContactsFilters, setDeletedContactFilters] = useState({
    page: 1,
    perPage: asPreview ? 4 : 20,
    orderDirection: 'desc',
    orderBy: 'created_at',
    onlyDeleted: true,
  });

  const { page, perPage, orderDirection, orderBy } = showDeletedContacts
    ? deletedContactsFilters
    : filters;

  const {
    error: contactsError,
    data,
    loading: contactsLoading,
  } = useQuery(GET_CONTACTS, { variables: filters });

  const {
    error: deletedContactsError,
    data: deletedContacts,
    loading: deletedContactsLoading,
  } = useQuery(GET_DELETED_CONTACTS, { variables: deletedContactsFilters });


  const handleFilters = (newFilters) => {
    showDeletedContacts
      ? setDeletedContactFilters((prevFilters) => ({
          ...prevFilters,
          ...newFilters,
        }))
      : setFilters((prevFilters) => ({
          ...prevFilters,
          ...newFilters,
        }));
  };


  const setOrderBy = (field) => {
    const newOrderDirection =
      field === orderBy && orderDirection === 'asc' ? 'desc' : 'asc';

    handleFilters({ orderBy: field, orderDirection: newOrderDirection });
  };


  const changeActiveTab = (value) => setShowDeletedContacts(value);


  useEffect(() => {
    if (data) {
      setContactsList(data.contacts.contacts);
      setContactsTotalCount(data.contacts.totalCount);
      setInitialLoad(false);
    }
  }, [data]);


  useEffect(() => {
    if (deletedContacts) {
      setDeletedContactsList(deletedContacts.contacts.contacts);
      setDeletedTotalCount(deletedContacts.contacts.totalCount);
    }
  }, [deletedContacts]);

  if (contactsError || deletedContactsError) return <NetworkError />;


  const dataTable = showDeletedContacts ? deletedContactsList : contacts;

  const totalCount = showDeletedContacts
    ? deletedTotalCount
    : contactsTotalCount;

  return (
    <div className="contacts">
      <div className={`contacts-wrapper ${asPreview ? 'preview' : ''}`}>
        <ContactsTable
          currentLocale={currentLocale}
          currentPage={page}
          perPage={perPage}
          deletedTotalCount={deletedTotalCount}
          contactsTotalCount={contactsTotalCount}
          initialLoad={initialLoad}
          asPreview={asPreview}
          contactsLoading={contactsLoading || deletedContactsLoading}
          setPage={(page) => handleFilters({ page })}
          setPerPage={(perPage) => handleFilters({ perPage, page: 1 })}
          showDeletedContacts={showDeletedContacts}
          dataTable={dataTable}
          totalCount={totalCount}
          orderDirection={orderDirection}
          setOrderBy={setOrderBy}
          activeFilter={orderBy}
          changeActiveTab={changeActiveTab}
        />
      </div>
    </div>
  );
}

ContactsPage.propTypes = {
  asPreview: PropTypes.bool,
};

ContactsPage.defaultProps = {
  asPreview: false,
};

export default ContactsPage;
