import React from 'react';
import { withFormik } from 'formik';
import Yup from 'yup';
import PropTypes from 'prop-types';

import {
  companyValidation,
  eanValidationRequired,
  phoneValidation,
  cityValidation,
  addressValidation,
  postalCodeValidation,
  emailValidation,
} from '../../SharedComponents/Forms/ValidationSchemaPattern';
import Input from '../../SharedComponents/Forms/Input';
import BasicDropdown from '../../SharedComponents/Forms/BasicDropdown';
import { requiredFieldError } from '../../SharedComponents/Forms/ErrorMessages';
import { SvgIco } from "../../SharedComponents/Icons";
import {InfoIconTooltip} from "../Table/Columns";


function UpdateProviderWrapper({ changeEmailLanguage,
                                 changeInterFaceLanguage,
                                 checkDk,
                                 contractName,
                                 errors,
                                 formatEmailLocales,
                                 formatLocales,
                                 handleBlur,
                                 handleChange,
                                 handleSubmit,
                                 providerInfo,
                                 showSmsOrderNotifications,
                                 showChangeLanguageBlock,
                                 showEmailChangeLanguage,
                                 showInterfaceChangeLanguage,
                                 touched,
                                 values,
                               }) {


  return (
    <div className="update-provider__form-wrapper">
      <form name="UpdateProvider" onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-md-3">
            <Input
              name="companyName"
              value={values.companyName}
              handleChange={handleChange}
              handleBlur={handleBlur}
              label={I18n.t('generic.company_name')}
              error={touched.companyName && errors.companyName}
              required
            />
          </div>

          <div className="col-md-3">
            <Input
              name="vatId"
              value={values.vatId}
              handleChange={handleChange}
              handleBlur={handleBlur}
              label={I18n.t('signup.new_form.register.vat_id.placeholder')}
              error={touched.vatId && errors.vatId}
              required
            />
          </div>
        </div>

        <div className="row">
          { providerInfo.ean && (
            <div className="col-md-3">
              <Input
                name="ean"
                value={values.ean}
                handleChange={handleChange}
                handleBlur={handleBlur}
                label={I18n.t('activerecord.attributes.provider.ean')}
                error={touched.ean && errors.ean}
                required
              />
            </div>
          )}

          <div className={`${providerInfo.ean ? 'col-md-3' : 'col-md-6'}`}>
            <Input
              name="invoicingEmail"
              value={values.invoicingEmail}
              handleChange={handleChange}
              handleBlur={handleBlur}
              label={I18n.t('signup.new_form.register.invoice_email.placeholder')}
              error={touched.invoicingEmail && errors.invoicingEmail}
              required
            />

            <InfoIconTooltip
              id={'providerInvoicingEmailTooltip'}
              position={'bottom'}
              tooltip={I18n.t('signup.new_form.register.invoice_email.tooltip')}
            />
          </div>

        </div>

        <div className="row">
          <div className="col-md-6">
            <Input
              name="city"
              value={values.city}
              handleChange={handleChange}
              handleBlur={handleBlur}
              label={I18n.t('signup.new_form.register.city.placeholder')}
              error={touched.city && errors.city}
              required
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <Input
              name="address"
              value={values.address}
              handleChange={handleChange}
              handleBlur={handleBlur}
              label={I18n.t('signup.new_form.register.line_1.placeholder')}
              error={touched.address && errors.address}
              required
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-3">
            <Input
              name="phoneNumber"
              value={values.phoneNumber}
              handleChange={handleChange}
              handleBlur={handleBlur}
              label={I18n.t('signup.new_form.register.phone_number.placeholder')}
              error={touched.phoneNumber && errors.phoneNumber}
              required
            />
          </div>

          <div className="col-md-3">
            <Input
              name="postalCode"
              value={values.postalCode}
              handleChange={handleChange}
              handleBlur={handleBlur}
              label={I18n.t('signup.new_form.register.postal_code.placeholder')}
              error={touched.postalCode && errors.postalCode}
              required={!!checkDk}
            />
          </div>
        </div>

        <div>
          <p>
            {I18n.t('providers.providers.provider_info.payment_model')}:{' '}
            <span className="provider-settings__contact-name">
              {contractName}
            </span>
          </p>
        </div>

        <div className="provider-settings__checkbox-wrapper">
          <label
            className="checkbox-label"
            htmlFor="isMailSubjectIncludeInternalIdentifier"
          >
            <input
              name="isMailSubjectIncludeInternalIdentifier"
              type="checkbox"
              checked={!!values.isMailSubjectIncludeInternalIdentifier}
              id="isMailSubjectIncludeInternalIdentifier"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <span className="checkmark">
              <SvgIco name="check" size={17} />
            </span>
            <div className="checkbox-label">
              {I18n.t('activerecord.attributes.provider.is_mail_subject_include_internal_identifier')}
            </div>
          </label>
        </div>

        {showSmsOrderNotifications && (
          <div className="provider-settings__checkbox-wrapper">
            <label className="checkbox-label" htmlFor="smsOrderNotification">
              <input
                name="smsOrderNotification"
                type="checkbox"
                checked={!!values.smsOrderNotification}
                id="smsOrderNotification"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <span className="checkmark">
                <SvgIco name="check" size={17} />
              </span>
              <div className="checkbox-label">
                {I18n.t('activerecord.attributes.provider.sms_order_notification')}
              </div>
            </label>
          </div>
        )}

        {showChangeLanguageBlock && (
          <div className="provider-settings__language">
            <h3>{I18n.t('generic.choose_your_language')}</h3>

            <div className="provider-settings__language-dropdowns">
              <div className="row">
                {showEmailChangeLanguage && (
                  <div className="col-md-3">
                    <p>
                      {I18n.t('providers.contacts.edit.change_emails_locale')}:{' '}
                    </p>
                    <BasicDropdown
                      options={formatEmailLocales}
                      handleChange={(value) => changeEmailLanguage(value)}
                      value={values.emailsLocale}
                    />
                  </div>
                )}
                {showInterfaceChangeLanguage && (
                  <div className="col-md-3">
                    <p>
                      {I18n.t('providers.contacts.edit.change_interface_locale')}
                      :{' '}
                    </p>
                    <BasicDropdown
                      options={formatLocales}
                      handleChange={(value) => changeInterFaceLanguage(value)}
                      value={values.interfaceLocale}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        <div>
          <button className="save-button b-button" type="submit">
            { I18n.t('generic.save') }
          </button>
        </div>
      </form>
    </div>
  );
}

const UpdateProvider = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({
                       providerInfo,
                       emailsLocale,
                       interfaceLocale,
                       checkDk,
                       countryAlpha2
                     }) => ({
    companyName: providerInfo ? providerInfo.companyName : '',
    vatId: providerInfo ? providerInfo.vatId : '',
    phoneNumber: providerInfo ? providerInfo.phoneNumber : '',
    address: providerInfo ? providerInfo.line1 : '',
    postalCode: providerInfo ? providerInfo.postalCode : '',
    city: providerInfo ? providerInfo.city : '',
    isMailSubjectIncludeInternalIdentifier: providerInfo
      ? providerInfo.isMailSubjectIncludeInternalIdentifier
      : false,
    smsOrderNotification: providerInfo
      ? providerInfo.smsOrderNotification
      : false,
    ean: providerInfo.ean || '',
    emailsLocale,
    interfaceLocale,
    isEan: !!providerInfo.ean,
    checkDk: checkDk,
    countryAlpha2: countryAlpha2,
    invoicingEmail: providerInfo.invoicingEmail,
  }),

  validationSchema: Yup.object().shape({
    isEan: Yup.boolean(),
    checkDk: Yup.boolean(),
    companyName: companyValidation,
    invoicingEmail: emailValidation,
    phoneNumber: phoneValidation,
    ean: Yup.string().when('isEan', {
      is: true,
      then: eanValidationRequired,
    }),
    address: addressValidation,
    city: cityValidation,
    postalCode: postalCodeValidation,
    vatId: Yup.string()
      .required(requiredFieldError(I18n.t('signup.new_form.register.vat_id.placeholder'))),
  }),

  handleSubmit: (values, bag) => {
    bag.props.providerSubmit(values);
  },

  displayName: 'UpdateProvider',
})(UpdateProviderWrapper);


UpdateProviderWrapper.propTypes = {
  values: PropTypes.oneOfType([PropTypes.object]),
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  touched: PropTypes.bool,
  handleBlur: PropTypes.func,
  errors: PropTypes.oneOfType([PropTypes.array]),
  contractName: PropTypes.string,
  changeEmailLanguage: PropTypes.func,
  changeInterFaceLanguage: PropTypes.func,
  formatEmailLocales: PropTypes.oneOfType([PropTypes.array]),
  formatLocales: PropTypes.oneOfType([PropTypes.array]),
  showChangeLanguageBlock: PropTypes.bool,
  showEmailChangeLanguage: PropTypes.bool,
  showInterfaceChangeLanguage: PropTypes.bool,
  showSmsOrderNotifications: PropTypes.bool,
  providerInfo: PropTypes.oneOfType([PropTypes.object]),
};


UpdateProviderWrapper.defaultProps = {
  values: {},
  handleSubmit: () => true,
  handleChange: () => true,
  touched: false,
  handleBlur: () => true,
  errors: [],
  contractName: '',
  formatEmailLocales: [],
  formatLocales: [],
  showChangeLanguageBlock: false,
  showEmailChangeLanguage: false,
  showInterfaceChangeLanguage: false,
  showSmsOrderNotifications: false,
  providerInfo: {},
};

export default UpdateProvider;
