import React from 'react';

import ProviderModal from "./ProviderModal";

import './SecondaryKindsInfoModal.sass';


const SecondaryKindsInfoModal = ({ handleCancel, handleClose, showModal }) => {

  return (
    <ProviderModal modalClass="secondary-kinds-info-modal"
                   showModal={showModal}
                   handleClose={handleClose}
                   hideCloseBtn={false}>

      <h3>{ I18n.t('provider.provider.form.economy.extra_category_modal.title') }</h3>

      <p className="secondary-kinds-info-modal__description">
        { I18n.t('provider.provider.form.economy.extra_category_modal.description') }
      </p>

      <div className="secondary-kinds-info-modal__pricing-list">
        <div className="secondary-kinds-info-modal__pricing-list-headline">
          { I18n.t('provider.provider.form.economy.extra_category_modal.pricing_info') }:
        </div>

        <div className="secondary-kinds-info-modal__pricing-list-item">
          <div className="secondary-kinds-info-modal__pricing-list-item-category">
            1 { I18n.t('generic.extra_category').one }:
          </div>
          <div className="secondary-kinds-info-modal__pricing-list-item-price">
            + 40% { I18n.t('provider.provider.form.economy.extra_category_modal.extra_payment') }
          </div>
        </div>

        <div className="secondary-kinds-info-modal__pricing-list-item">
          <div className="secondary-kinds-info-modal__pricing-list-item-category">
            2 { I18n.t('generic.extra_category').other }:
          </div>
          <div className="secondary-kinds-info-modal__pricing-list-item-price">
            + 110% { I18n.t('provider.provider.form.economy.extra_category_modal.extra_payment') }
          </div>
        </div>

        <div className="secondary-kinds-info-modal__pricing-list-item">
          <div className="secondary-kinds-info-modal__pricing-list-item-category">
            3 { I18n.t('generic.extra_category').other }:
          </div>
          <div className="secondary-kinds-info-modal__pricing-list-item-price">
            + 180% { I18n.t('provider.provider.form.economy.extra_category_modal.extra_payment') }
          </div>
        </div>

        <div className="secondary-kinds-info-modal__pricing-list-item">
          <div className="secondary-kinds-info-modal__pricing-list-item-category">
            4 { I18n.t('generic.extra_category').other }:
          </div>
          <div className="secondary-kinds-info-modal__pricing-list-item-price">
            + 250% { I18n.t('provider.provider.form.economy.extra_category_modal.extra_payment') }
          </div>
        </div>
      </div>

      <div className="secondary-kinds-info-modal__buttons">
        <div className="secondary-kinds-info-modal__button secondary-kinds-info-modal__button--cancel" onClick={handleCancel}>
          { I18n.t('search_agent.modal.cancel') }
        </div>
        <div className="secondary-kinds-info-modal__button" onClick={handleClose}>
          { I18n.t('providers.providers.provider_deals_notifications.accept') }
        </div>
      </div>
    </ProviderModal>

  )
};


export default SecondaryKindsInfoModal;
