import React, { useContext, useMemo } from 'react';
import { withScriptjs, withGoogleMap } from 'react-google-maps';

import { Context } from '../../index';
import MapWrapper from './MapWrapper';


const Location = () => {

  const { googleApiKey, siteKey, stepsRefs, translationsLocales } = useContext(Context);
  const mapLanguage = siteKey === 'global' ? 'en' : translationsLocales.filter(locale => locale.id === siteKey)[0].name;
  const region = siteKey === 'global' ? '' : siteKey.toUpperCase();
  const MapLoader = useMemo(
    () =>
      withScriptjs(
        withGoogleMap(() => <MapWrapper ref={stepsRefs.location} />),
      ),
    [],
  );

  return (
    <div className="location">
      <MapLoader
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&region=${region}&language=${mapLanguage}&libraries=geometry,drawing,places`}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        loadingElement={<div style={{ height: `100%` }} />}
      />
    </div>
  );
};

export default Location;
