import React from 'react';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import Yup from 'yup';

import {
  phoneValidation,
  nameValidation,
  emailValidation,
  companyValidation,
} from '../../SharedComponents/Forms/ValidationSchemaPattern';
import Input from '../../SharedComponents/Forms/Input';

function ContactInfoFormWrapper({
  handleSubmit,
  values,
  handleChange,
  touched,
  handleBlur,
  errors,
}) {
  return (
    <div className="create-new contact">
      <form
        name="createNewContact"
        className="new-contact-form"
        onSubmit={handleSubmit}
        noValidate
      >
        <div className="row">
          <div className="col-md-3">
            <Input
              name="companyName"
              value={values.companyName}
              handleChange={handleChange}
              handleBlur={handleBlur}
              label={I18n.t('generic.company_name')}
              error={
                (touched.companyName && errors.companyName) ||
                errors.companyName
              }
              required
            />
          </div>
          <div className="col-md-3">
            <Input
              name="name"
              value={values.name}
              handleChange={handleChange}
              handleBlur={handleBlur}
              label={I18n.t('generic.name')}
              error={touched.name && errors.name}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Input
              name="email"
              value={values.email}
              handleChange={handleChange}
              handleBlur={handleBlur}
              label={I18n.t('generic.email')}
              error={touched.email && errors.email}
              required
            />
          </div>
          <div className="col-md-3">
            <Input
              name="phoneNumber"
              value={values.phoneNumber}
              handleChange={handleChange}
              handleBlur={handleBlur}
              label={I18n.t('signup.new_form.register.phone_number.placeholder')}
              error={touched.phoneNumber && errors.phoneNumber}
              required
            />
          </div>
        </div>

        <div className="client-info">
          <button type="submit" className="clients-info__save-button">
            {I18n.t('generic.save')}
          </button>
        </div>
      </form>
    </div>
  );
}

const ContactInfoForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ contactInfo }) => ({
    companyName: contactInfo ? contactInfo.clientOrOrderCompanyName : '',
    name: contactInfo ? contactInfo.clientOrOrderPersonName : '',
    email: contactInfo ? contactInfo.clientOrOrderEmail : '',
    phoneNumber: contactInfo ? contactInfo.clientOrOrderPhone : '',
  }),
  validationSchema: Yup.object().shape({
    companyName: companyValidation,
    name: nameValidation,
    email: emailValidation,
    phoneNumber: phoneValidation,
  }),

  handleSubmit: (values, bag) => {
    bag.props.updateContactInfo(values);
  },

  displayName: 'ContactInfoForm',
})(ContactInfoFormWrapper);

ContactInfoFormWrapper.propTypes = {
  handleSubmit: PropTypes.func,
  values: PropTypes.oneOfType([PropTypes.object]),
  handleChange: PropTypes.func,
  touched: PropTypes.bool,
  handleBlur: PropTypes.func,
  errors: PropTypes.oneOfType([PropTypes.array]),
};

ContactInfoFormWrapper.defaultProps = {
  handleSubmit: () => true,
  values: {},
  handleChange: () => true,
  touched: false,
  handleBlur: () => true,
  errors: [],
};

export default ContactInfoForm;
