import React from 'react';

import CreateNewContact from '../ContactsPage/CreateNewContact';
import FormMessageBlock from '../../SharedComponents/FormMessages/FormMessageBlock';
import ProviderModal from './ProviderModal';

import './CreateNewContactModal.sass';


const CreateNewContactModal = ({ editContactData,
                                 handleCloseNotification,
                                 closeModal,
                                 showModal,
                                 showNotification,
                                 editModal,
                                 submitForm,
                               }) => {


  return (
    <ProviderModal
      showModal={showModal}
      handleClose={closeModal}
      modalClass="contacts-create-modal"
    >
      <div className="modal-wrapper">
        <div className="modal-content-wrapper">
          <p className="contacts-create-modal__header">
            { editModal
              ? I18n.t('providers.contacts.list.edit_new_secondary_contact')
              : I18n.t('providers.contacts.list.add_new_secondary_contact')
            }
          </p>
        </div>

        <div className="notification-wrapper">
          <FormMessageBlock
            show={showNotification}
            closeNotification={handleCloseNotification}
          />
        </div>

        <CreateNewContact
          editContactData={editContactData}
          editModal={editModal}
          submitForm={submitForm}
          closeModal={closeModal}
        />
      </div>
    </ProviderModal>
  );
};

export default CreateNewContactModal;
