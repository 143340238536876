import React, { Fragment } from 'react';
import Modal from 'react-modal';

import './IframeModal.sass';
import {SvgIco} from "../../SharedComponents/Icons";

const IframeModal = ({ handleClose,
                         modalClass,
                         showModal,
                         url,
                      }) => {

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.6)',
      top: '0',
      overflow: 'auto',
      zIndex: '9999',
      backdropFilter: 'blur(5px)',
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      pointerEvents: 'visible',
      background: 'white',
      borderRadius: '4px',
      position: 'absolute',
    },
  };

  return (
    <Modal
      ariaHideApp={false}
      className={`simple-cms-modal ${modalClass || ''}`}
      contentLabel={modalClass}
      isOpen={showModal}
      onRequestClose={handleClose || null}
      style={customStyles}
    >
      <div className="simple-cms-modal__wrapper">
        <div className="simple-cms-modal__close-btn" onClick={handleClose}>
          <SvgIco name="close" size={24} />
        </div>
        <iframe src={url} frameBorder="0"></iframe>
      </div>
    </Modal>
  );
};

export default IframeModal;
