import React from 'react';
import PropTypes from 'prop-types';

function ReportDealProgress({ steps, activeStep }) {
  return (
    <div className="progress-bar report-deal-progress-bar">
      <div className="progress-bar__background-line" />

      {steps.map((item, index) => (
        <div
          className={`progress-bar__step-wrapper ${
            activeStep === index + 1 ? ' active' : ''
          }`}
          key={index}
        >
          <div className="progress-bar__step-number-wrapper">
            <div
              className={`progress-bar__step-number ${
                item.passed ? 'complete' : ''
              }`}
            >
              {index + 1}
            </div>
          </div>

          <div className="progress-bar__step-name">{item.name}</div>
        </div>
      ))}
    </div>
  );
}

ReportDealProgress.propTypes = {
  steps: PropTypes.oneOfType([PropTypes.array]),
  activeStep: PropTypes.number,
};

ReportDealProgress.defaultProps = {
  steps: [],
  activeStep: 1,
};

export default ReportDealProgress;
