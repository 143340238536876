import React from 'react';
import classNames from 'classnames';

import { SvgIco } from "../../Icons";

import './index.sass';


const Checkbox = ({ name, handleChange, handleBlur, inputClass, label, field, value }) => {

  const checkboxClasses = classNames("checkbox", { inputClass });


  return (
    <div className="form-group form-group--checkbox">
      <div className="checkbox-label-wrapper">
        <label htmlFor={name}>
          <input
            name={name}
            type="checkbox"
            value={value}
            id={name}
            onChange={(e) => handleChange(e, field || name)}
            onBlur={handleBlur}
            className={checkboxClasses}
            checked={value}
          />
          <span className="checkmark">
            <SvgIco name="check" size={17} />
          </span>
          <div className="checkbox-label">{ label }</div>
        </label>
      </div>
    </div>
  )
}

export default Checkbox;
