import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import 'scroll-behavior-polyfill';

import { preventResizeObserverError } from "../../utils/resizeObzerver";
import { loadTawkTo } from '../TawkTo/TawkTo';
import { client } from './ApolloClient/client.js';
import ProviderHeader from './ProviderHeader';
import SideBar from './SideBar';
import Statistic from './Statistic';
import Locations from './Locations';
import ProviderSettings from './Settings/ProviderSettings';
import HelpPage from './HelpPage';
import MainDashboard from './MainDashboard';
import Clients from './Clients/index';
import Footer from './Footer';
import ContactsPage from './ContactsPage';
import LocationForm from './LocationForm';
import ClientInfo from './ClientInfo/ClientInfo';
import ContactSettings from './Settings/ContactSettings';
import ReportDeal from './ReportDeal';
import LocationInfo from './LocationInfo';
import WelcomePage from './WelcomePage';
import Invoices from "./Invoices";
import UnresolvedAgreement from './UnresolvedAgreement';
import ProviderInactive from "./ProviderInactive";
import ScrollToTop from '../SharedComponents/ScrollToTop';
import Page404 from './Errors/Page404';

import './index.sass';


const Provider = ({
  createLease,
  footer,
  googleApiKey,
  helpContent,
  links,
  locale,
  locations,
  newClientsCount,
  partnerBadgeUrl,
  patternedProspectusEnabled,
  providerAdvertisingStrategy,
  providerApproved,
  providerInactive,
  providerOnDealStrategy,
  providerPartner,
  settings,
  siteKey,
  siteName,
  statistics,
  stripeInvoicesApiKey,
}) => {

  const [newClientsQuantity, setNewClientsQuantity] = useState(newClientsCount);
  const [updatedProviderInfo, setUpdatedProviderInfo] = useState(null);
  const [updatedContactInfo, setUpdatedContactInfo] = useState(null);

  const currentLocale = locale && (locale === 'fr-BE' || locale === 'fr-CH') ? `/${locale}` : '';
  const { qualityScorePageLink } = links;


  useEffect(() => {
    loadTawkTo(siteKey);
    preventResizeObserverError();
  }, []);


  const currencySymbolFormatter = (currencyId, asName) => {
    const { currencyList, defaultCurrencyId } = createLease;
    const currencies = JSON.parse(currencyList);
    const currency = currencies.filter((item) => {
      const currentCurrency =
        (currencyId && Number(currencyId)) || defaultCurrencyId.id;
      return item.id === currentCurrency;
    })[0];

    return asName ? currency.name : currency.formatted;
  };


  const resourceNotFoundError = (error) =>
    !!( error
        && error.graphQLErrors
        && error.graphQLErrors.length
        && error.graphQLErrors[0].extensions
        && error.graphQLErrors[0].extensions.code === 'RESOURCE_NOT_FOUND'
      );


  const isNumber = (value) => value ? /^\d+$/.test(value) : true;


  return (
    <div className="provider">
      <ApolloProvider client={client}>
        <BrowserRouter>

          <ProviderHeader
            currentLocale={currentLocale}
            siteKey={siteKey}
            contractUrl={settings.contractUrl}
          />

          <div className="main-provider-wrapper">
            <SideBar
              companyName={footer.providerInfo.companyName}
              currentContactEmail={footer.currentContactEmail}
              currentContactName={footer.currentContactName}
              currentLocale={currentLocale}
              currentPhoneName={footer.currentPhoneName}
              links={links}
              newClientsCount={newClientsQuantity}
              providerPartner={providerPartner}
              siteKey={siteKey}
            />

            <div className="content-section" id="providerContent">

              { !providerInactive && <UnresolvedAgreement currentLocale={currentLocale} /> }

              <ScrollToTop />

              { !providerInactive &&
                <Switch>
                  <Route exact path={'/:locale?/new_providers'}>
                    <MainDashboard
                      currencySymbolFormatter={currencySymbolFormatter}
                      contactName={footer.currentContactInfo.name}
                      currentLocale={currentLocale}
                      enquiryStates={settings.enquiryStates}
                      isNumber={isNumber}
                      providerApproved={providerApproved}
                      providerOnDealStrategy={providerOnDealStrategy}
                      resourceNotFoundError={resourceNotFoundError}
                      siteName={siteName}
                      states={locations.states}
                    />
                  </Route>

                  <Route exact path="/:locale?/new_providers/statistic">
                    <Statistic
                      {...statistics}
                      currencySymbolFormatter={currencySymbolFormatter}
                      currentLocale={currentLocale}
                      leaseKinds={createLease.leaseKinds}
                    />
                  </Route>

                  <Route exact path="/:locale?/invoices">
                    <Invoices stripeInvoicesApiKey={stripeInvoicesApiKey} locale={locale}/>
                  </Route>

                  <Route exact path="/:locale?/new_providers/locations/:category">
                    <Locations
                      currentLocale={currentLocale}
                      providerOnDealStrategy={providerOnDealStrategy}
                      siteName={siteName}
                      states={locations.states}
                      providerApproved={providerApproved}
                    />
                  </Route>

                  <Route exact path="/:locale?/new_providers/locations/info/:id">
                    <LocationInfo
                      currencySymbolFormatter={currencySymbolFormatter}
                      currentLocale={currentLocale}
                      googleApiKey={googleApiKey}
                      isNumber={isNumber}
                      partnerBadgeUrl={partnerBadgeUrl}
                      providerAdvertisingStrategy={providerAdvertisingStrategy}
                      providerApproved={providerApproved}
                      providerOnDealStrategy={providerOnDealStrategy}
                      providerPartner={providerPartner}
                      qualityScorePageLink={qualityScorePageLink}
                      resourceNotFoundError={resourceNotFoundError}
                      siteKey={siteKey}
                      siteName={siteName}
                    />
                  </Route>

                  <Route
                    path={[
                      '/:locale?/new_providers/:locationSection/new',
                      '/:locale?/new_providers/locations/:editPage/:id',
                    ]}
                  >
                    <LocationForm
                      {...createLease}
                      countryAlpha2={settings.countryAlpha2}
                      currentContactId={settings.currentContactId}
                      currentLocale={currentLocale}
                      googleApiKey={googleApiKey}
                      isNumber={isNumber}
                      partnerBadgeUrl={partnerBadgeUrl}
                      patternedProspectusEnabled={patternedProspectusEnabled}
                      providerPartner={providerPartner}
                      resourceNotFoundError={resourceNotFoundError}
                      siteKey={siteKey}
                      siteName={siteName}
                    />
                  </Route>

                  <Route
                    exact
                    path={['/:locale?/new_providers/orders', '/:locale?/new_providers/orders/:id']}
                  >
                    <Clients
                      {...settings}
                      currencySymbolFormatter={currencySymbolFormatter}
                      currentLocale={currentLocale}
                      defaultLocale={createLease.defaultLocale}
                      isNumber={isNumber}
                      locale={locale}
                      newClientsCount={newClientsCount}
                      providerId={settings.providerId}
                      resetNewClients={() => setNewClientsQuantity(null)}
                      resourceNotFoundError={resourceNotFoundError}
                    />
                  </Route>

                  <Route exact path="/:locale?/new_providers/clients/:id">
                    <ClientInfo
                      currentLocale={currentLocale}
                      isNumber={isNumber}
                      locale={locale}
                      resourceNotFoundError={resourceNotFoundError}
                    />
                  </Route>

                  <Route exact path="/:locale?/new_providers/help">
                    <HelpPage content={helpContent}/>
                  </Route>

                  <Route exact path="/:locale?/new_providers/settings/provider">
                    <ProviderSettings
                      {...settings}
                      currentLocale={currentLocale}
                      siteKey={siteKey}
                      updateProviderInfo={setUpdatedProviderInfo}/>
                  </Route>

                  <Route path="/:locale?/new_providers/settings/contact">
                    <ContactSettings
                      {...settings}
                      currentLocale={currentLocale}
                      updateContactInfo={setUpdatedContactInfo}/>
                  </Route>

                  <Route exact path={'/:locale?/new_providers/report_deal'}>
                    <ReportDeal
                      currentLocale={currentLocale}
                      siteName={siteName}
                      siteKey={siteKey}
                      providerId={settings.providerId}
                      providerOnAdvertisingStrategy={settings.providerOnAdvertisingStrategy}
                    />
                  </Route>

                  <Route exact path="/:locale?/new_providers/welcome">
                    <WelcomePage
                      siteName={siteName}
                      contactEmail={footer.currentContactInfo.email}
                      contactName={footer.currentContactInfo.name}
                      currentLocale={currentLocale}
                    />
                  </Route>

                  <Route exact path="/:locale?/new_providers/contacts">
                    <ContactsPage
                      currentLocale={currentLocale}
                      providerId={settings.providerId}/>
                  </Route>

                  <Route path="*">
                    <Page404 currentLocale={currentLocale}/>
                  </Route>
                </Switch>
              }

              { providerInactive &&
                <ProviderInactive
                  currentLocale={currentLocale}
                  data={footer}
                />
              }

            </div>
          </div>
        </BrowserRouter>
      </ApolloProvider>
      <Footer
        footer={footer}
        providerInfo={updatedProviderInfo || footer.providerInfo}
        currentContactInfo={updatedContactInfo || footer.currentContactInfo}
      />
    </div>
  );
};


export default Provider;
