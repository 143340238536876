import React from 'react';
import { useMutation } from "@apollo/client";

import { DELETE_CONTACT_FOREVER } from "../ApolloClient/mutations";
import ModalNetworkError from "../Errors/ModalNetworkError";
import ProviderModal from "./ProviderModal";

import './DeleteContactForeverModal.sass';


const DeleteContactForeverModal = ({ callback, data, handleClose, showModal }) => {

  const { id } = data;

  const [ hideDeletedContact, { loading, error } ] = useMutation(DELETE_CONTACT_FOREVER, {
    update(cache, result) {},
    refetchQueries: ["getContacts", "getDeleteContact"],
    onCompleted: () => {
      callback && callback();
      handleClose();
    },
  });


  const handleDeleteContactForever = () =>
    hideDeletedContact({ variables: { id: parseInt(id || 0) } })
      .catch(error => console.log('error', error));


  return (
    <ProviderModal modalClass="delete-contact-forever-modal"
                   showModal={showModal}
                   handleClose={handleClose}>

      { error && <ModalNetworkError /> }

      <div className="delete-contact-forever-modal__headline">
        { I18n.t('providers.contacts.deleted_list.delete_contact_forever_modal.headline') }
      </div>

      <div className="delete-contact-forever-modal__body">
        { I18n.t('providers.contacts.deleted_list.delete_contact_forever_modal.content') }
      </div>

      <div className="delete-contact-forever-modal__buttons-wrapper">
        <div className={`provider-modal__button green-border ${loading ? 'inactive' : ''}`} onClick={handleClose}>
          { I18n.t('search_agent.modal.cancel') }
        </div>
        <div className={`provider-modal__button red ${loading ? 'inactive' : ''}`} onClick={handleDeleteContactForever}>
          { I18n.t('generic.delete') }
        </div>
      </div>

    </ProviderModal>
  )
};


export default DeleteContactForeverModal;
