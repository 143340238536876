import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import Loader from "../../../SharedComponents/Loader";


const CheckoutForm = ({ amount, currency, handlePaymentSuccess, setPaymentInProcess }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleLoading = (state) => {
    setLoading(state);
    setPaymentInProcess(state);
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    handleLoading(true);

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
      redirect: 'if_required',
    });

    if (result.paymentIntent && result.paymentIntent.status && result.paymentIntent.status === 'succeeded') {
      errorMessage && setErrorMessage(null);
      handleLoading(false);
      handlePaymentSuccess();
    }

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      result.error.type !== 'validation_error' && setErrorMessage(result.error.message);
      console.log(result.error.message);
      handleLoading(false);
    }
  };


  return (
    <form onSubmit={handleSubmit}>

      { errorMessage &&
        <div className="payment-modal__error-message">
          { errorMessage }
        </div>
      }

      <div className="payment-modal__form-wrapper">
        <PaymentElement />
      </div>

      <div className="payment-modal__price-info">
        <div className="payment-modal__price-info-row">
          <div className="payment-modal__price-info-label">
            { I18n.t('generic.amount') }:
          </div>
          <div className="payment-modal__price-info-value">
            { amount }
          </div>
        </div>

        <div className="payment-modal__price-info-row">
          <div className="payment-modal__price-info-label">
            { I18n.t('activerecord.attributes.location.currency') }:
          </div>
          <div className="payment-modal__price-info-value">
            { currency }
          </div>
        </div>
      </div>

      <div className={`payment-modal__submit-btn ${loading ? 'inactive' : ''}`} onClick={handleSubmit}>
        { loading ? <Loader size={24} /> : I18n.t('generic.pay')  }
      </div>
    </form>
  )
};

export default CheckoutForm;
