import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ImageUploading from 'react-images-uploading';
import axios from 'axios';

import { apiProvider } from '../../../api/core';

import './PhotoUpload.sass';


function PhotoUpload({
  imageUrl,
  uploadPath,
  formAuthToken,
  removePath,
  provider,
  removeTranslation,
  handleRefetch,
}) {
  const [images, setImages] = useState([]);
  const [loadingImage, setLoadingImage] = useState(false);

  const addImage = (imageList) => {
    setLoadingImage(true);
    const formData = new FormData();
    provider
      ? formData.append('provider[logo]', imageList[0].file)
      : formData.append('contact[photo]', imageList[0].file);
    formData.append('authenticity_token', formAuthToken);

    const apiOptions = {
      url: uploadPath,
      method: 'put',
      data: formData,
    };

    apiProvider.withOptions(apiOptions)
      .then(res => {
        if (res.status === 'success') {
          setLoadingImage(false);
          handleRefetch()
        }
      });
  }

  const removeImage = () => {
    axios
      .put(
        removePath,
        { authenticity_token: formAuthToken },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
      )
      .then((response) => {
        if (response.status === 200) {
          handleRefetch();
        }
      });
  };

  const onChange = (imageList) => {
    setImages(imageList);
    const imageListLength = imageList.length;
    imageListLength ? addImage(imageList) : removeImage();
  };

  return (
    <div className="photo-upload">
      <ImageUploading
        multiple={false}
        value={images}
        onChange={onChange}
        dataURLKey="data_url"
      >
        {({ imageList, onImageUpload, onImageRemoveAll }) => (
          <div className="photo-upload__main-wrapper">
            {imageList.length === 0 && (
              <div className="photo-upload__inner-wrapper">
                <div
                  className={`photo-upload__photo-place ${
                    imageUrl ? 'active-photo' : ''
                  }`}
                >
                  {imageUrl ? (
                    <img
                      className="photo-upload__provider-logo"
                      src={imageUrl}
                      alt="provider-logo"
                    />
                  ) : (
                    <span>{ provider ? I18n.t('providers.contacts.edit.no_logo') : I18n.t('providers.contacts.edit.no_photo') }</span>
                  )}
                </div>
              </div>
            )}

            {imageList.map((image, index) => (
              <div key={index} className="image-item">
                <div className="upload-image">
                  <img
                    alt="profile"
                    className="image"
                    src={image.data_url}
                    height="80px"
                  />
                </div>
              </div>
            ))}

            <button
              type="button"
              onClick={onImageUpload}
              className="upload-button"
            >
              { I18n.t('generic.upload') }
            </button>

            <button
              type="button"
              onClick={onImageRemoveAll}
              disabled={!imageUrl || loadingImage}
              className={`remove-button ${ imageUrl ? 'remove-button-active' : '' }`}
            >
              { loadingImage && <div className="loading-spinner" /> }
              <span>{ removeTranslation }</span>
            </button>
          </div>
        )}
      </ImageUploading>
    </div>
  );
};

PhotoUpload.propTypes = {
  imageUrl: PropTypes.string,
  uploadPath: PropTypes.string,
  formAuthToken: PropTypes.string,
  removePath: PropTypes.string,
  provider: PropTypes.bool,
  removeTranslation: PropTypes.string,
  handleRefetch: PropTypes.func,
};

PhotoUpload.defaultProps = {
  imageUrl: '',
  uploadPath: '',
  formAuthToken: '',
  removePath: '',
  provider: false,
  removeTranslation: '',
  handleRefetch: () => true,
};

export default PhotoUpload;
