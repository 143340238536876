import React, { Fragment, useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import ImgPlaceholder from '../../../src/images/provider/img-placeholder.jpg';
import { localizedNumberFormatter } from "../../../utils/helpers";
import { SvgIco } from '../../SharedComponents/Icons';
import { GET_EXTRA_LEAD_LOCATION_ID, GET_EXTRA_LEAD_PREVIEW } from '../ApolloClient/queries';
import ModalNetworkError from "../Errors/ModalNetworkError";
import { SkeletonLoader } from "../Loaders";
import ProviderModal from './ProviderModal';

import "./ExtraOrderModal.sass"


const ExtraOrderModal = ({ currencySymbolFormatter, extraLeadModalOpen, extraLeadOrderId, locale, handleCloseExtraLeadModal }) => {

  const [extraLeadInfo, setExtraLeadInfo] = useState({});

  useEffect(() => {
    extraLeadModalOpen
      ? getExtraLeadLocationId({ variables: { id: extraLeadOrderId } }).catch(error => console.log("error", error))
      : setExtraLeadInfo({});
  }, [extraLeadModalOpen]);

  const [getExtraLeadInfo, { loading: extraLeadLoading, error }] = useLazyQuery(GET_EXTRA_LEAD_PREVIEW, {
    onCompleted: data => data.location && setExtraLeadInfo(data.location),
  });

  const [getExtraLeadLocationId, { loading: extraLeadLocationIdLoading, error: locationIdError }] = useLazyQuery(GET_EXTRA_LEAD_LOCATION_ID, {
    onCompleted: data => {
      const { id } = data.order.location;
      id && getExtraLeadInfo({ variables: { id }}).catch(error => console.log('error', error));
    },
  });

  const {
    address,
    annualOperationalCostWithCentsFrom,
    annualOperationalCostWithCentsTo,
    annualRentWithCentsFrom,
    annualRentWithCentsTo,
    areaFrom,
    areaTo,
    areaUnit,
    currencyId,
    locationUrl,
    photos,
    title,
  } = extraLeadInfo;


  const handleExtraLeadTitle = () => {
    let modalTitle = title[locale];
    !modalTitle && Object.entries(title).map(([key, value]) => !!value && (modalTitle = value));

    return modalTitle;
  };


  const handleRangeUnits = (from, to, unit) => {
    const range = from && to ? `${from} - ${to}` : from || to;

    return range ? `${range} ${unit}` : I18n.t('locations.index.not_available');
  };


  return (
    <ProviderModal modalClass="extra-lead-modal"
                   handleClose={handleCloseExtraLeadModal}
                   showModal={extraLeadModalOpen}>

      { (error || locationIdError) && <ModalNetworkError /> }

      { (extraLeadLoading || extraLeadLocationIdLoading) && !(error || locationIdError) &&
        <SkeletonLoader className="extra-lead-modal__loader" count={7} />
      }

      { !!Object.keys(extraLeadInfo).length &&
        <Fragment>
          <div className="extra-lead-modal__img-wrapper"
               style={{ backgroundImage: `url(${photos.length ? photos[0].thumbnailW400 : ImgPlaceholder})`}} />

          <div className="extra-lead-modal__title">
            { handleExtraLeadTitle() }
          </div>

          <div className="extra-lead-modal__address">
            { address }
          </div>

          <div className="extra-lead-modal__location-param">
            <div>
              <SvgIco name={'area'} size={22} />
              { I18n.t('apps.lb_showroom.detailed.area.area_label') }
            </div>
            <div>{ handleRangeUnits(areaFrom, areaTo, areaUnit || '') }</div>
          </div>

          <div className="extra-lead-modal__location-param">
            <div>{ I18n.t('activerecord.attributes.location.yearly_rent_per_m2') }</div>
            <div>
              { handleRangeUnits(
                annualRentWithCentsFrom && localizedNumberFormatter(annualRentWithCentsFrom),
                annualRentWithCentsTo && localizedNumberFormatter(annualRentWithCentsTo),
                currencySymbolFormatter(currencyId)
              )}
            </div>
          </div>

          <div className="extra-lead-modal__location-param">
            <div>{ I18n.t('activerecord.attributes.location.yearly_rent_and_operational_cost') }</div>
            <div>
              { handleRangeUnits(
                annualOperationalCostWithCentsFrom && localizedNumberFormatter(annualOperationalCostWithCentsFrom),
                annualOperationalCostWithCentsTo && localizedNumberFormatter(annualOperationalCostWithCentsTo),
                currencySymbolFormatter(currencyId)
              )}
            </div>
          </div>

          <a href={locationUrl} target="_blank">
            { I18n.t('provider.modals.go_to_location') }
          </a>

        </Fragment>
      }
    </ProviderModal>
  );
};

export default ExtraOrderModal;
