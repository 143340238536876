import React from 'react';
import { Link } from 'react-router-dom';

import WelcomeBanner from '../../../src/images/provider/welcome_banner.svg';

import "./index.sass"


const WelcomePage = ({ siteName, contactName, contactEmail, currentLocale }) => {

  const capitalize = (name) => name.charAt(0).toUpperCase() + name.slice(1);

  return (
    <div className="welcome">
      <img src={WelcomeBanner} alt="Get Assistance" width={303} height={216} />
      <h1 className="welcome__title">{ I18n.t('providers.pages.welcome.heading') } {contactName}</h1>
      <p className="welcome__subtitle">{ I18n.t('providers.pages.welcome.body_first_part', { site_name: siteName }) }</p>
      <p className="welcome__description">{ I18n.t('providers.pages.welcome.body_second_part', {email: contactEmail}) }</p>

      <p className="welcome__subtitle">{ I18n.t('providers.pages.welcome.body_third_part') }</p>

      <div className="welcome__buttons-wrapper">
        <Link
          to={`${currentLocale}/new_providers/lease/new/location`}
          className="welcome__button create-new-lease"
        >{ I18n.t("providers.pages.welcome.new_location_link") }</Link>

        <Link
          to={`${currentLocale}/new_providers/settings/provider`}
          className="welcome__button edit-information"
        >{ capitalize(I18n.t("providers.pages.welcome.edit_contact_link")) }</Link>

      </div>


    </div>
  )
};

export default WelcomePage;
