import { gql } from "@apollo/client";


export const VALIDATE_LOCATION_FIELDS = gql`

  mutation ValidateLocation($attributes: LocationAttributes!) {
      validateLocation(input: {
        attributes: $attributes
      }) {
        errors {
          attrName
          errorMessages
        }
      }
    }
`;


export const ADD_LOCATION = gql`

  mutation AddLocation($attributes: LocationAttributes!) {
      addLocation(input: {
        attributes: $attributes
      })
      {
        location {
          id
          kind
          addressLine1
        },
        errors
      }
    }
`;


export const UPDATE_LOCATION = gql`

  mutation UpdateLocation($attributes: LocationAttributes!, $id: Int!) {
      updateLocation(input: {
        attributes: $attributes
        id: $id
      })
      {
        location {
          id
          kind
          addressLine1
        },
        errors
      }
    }
`;


export const ACTIVATE_LOCATION = gql`

  mutation activateLocation($id: Int!) {
    activateLocation(input: { id: $id })
    {
      location {
        address
        annualElectricityCostWithCentsFrom
        annualElectricityCostWithCentsTo
        annualHeatingCostWithCentsFrom
        annualHeatingCostWithCentsTo
        annualOperationalCostWithCentsFrom
        annualOperationalCostWithCentsTo
        annualRentWithCentsFrom
        annualRentWithCentsTo
        annualTotalCostWithCentsFrom
        annualTotalCostWithCentsTo
        annualWaterCostWithCentsFrom
        annualWaterCostWithCentsTo
        areaFrom
        areaTo
        areaUnit
        createdAt
        currencyId
        depositAndPrepaidRent
        description
        disposalPrice
        energyRating
        enquiries
        enquiriesCountInteger
        exemptedFromEnergyRating
        explainLocationIncomplete
        facilityIds
        fixedAnnualOperationalCost
        floorPlans { thumbnailW600 }
        floorPlansCount
        hourlyRentWithCentsFrom
        hourlyRentWithCentsTo
        id
        infoMessage
        initialState
        isComplete
        kind
        latitude
        locationShownDatapoints
        locationUrl
        longitude
        monthlyRentPerSpaceWithCentsFrom
        monthlyRentPerSpaceWithCentsTo
        photos { thumbnailW600 }
        photosCount
        prospectus {
          id
          prospectusPath
          createAssetPath
        }
        prospectusCount
        salePrice
        scoreInPercents
        scoreItemsList
        section
        sectionTranslated
        state
        stateInfo
        stateTranslated
        vimeoId
        virtualTour
        workstationCostPerMonthFrom
        workstationCostPerMonthTo
        yearlyRentPerM2WithCentsFrom
        yearlyRentPerM2WithCentsTo
        yearlyElectricityCostPerM2WithCentsFrom
        yearlyElectricityCostPerM2WithCentsTo
        yearlyHeatingCostPerM2WithCentsFrom
        yearlyHeatingCostPerM2WithCentsTo
        yearlyOperationalCostPerM2WithCentsFrom
        yearlyOperationalCostPerM2WithCentsTo
        yearlyRentalIncome
        yearlyWaterCostPerM2WithCentsFrom
        yearlyWaterCostPerM2WithCentsTo
      }
      error
    }
  }
`;


export const COPY_LOCATION = gql`

  mutation copyLocation($id: Int!) {
    copyLocation(input: { id: $id })
    {
      copyJobId
    }
  }
`;


export const DEACTIVATE_LOCATION = gql`

  mutation deactivateLocation($id: Int!, $reason: String!) {
    deactivateLocation(input: { id: $id, reason: $reason })
    {
      location {
        address
        annualElectricityCostWithCentsFrom
        annualElectricityCostWithCentsTo
        annualHeatingCostWithCentsFrom
        annualHeatingCostWithCentsTo
        annualOperationalCostWithCentsFrom
        annualOperationalCostWithCentsTo
        annualRentWithCentsFrom
        annualRentWithCentsTo
        annualTotalCostWithCentsFrom
        annualTotalCostWithCentsTo
        annualWaterCostWithCentsFrom
        annualWaterCostWithCentsTo
        areaFrom
        areaTo
        areaUnit
        createdAt
        currencyId
        depositAndPrepaidRent
        description
        disposalPrice
        energyRating
        enquiries
        enquiriesCountInteger
        exemptedFromEnergyRating
        explainLocationIncomplete
        facilityIds
        fixedAnnualOperationalCost
        floorPlans { thumbnailW600 }
        floorPlansCount
        hourlyRentWithCentsFrom
        hourlyRentWithCentsTo
        id
        infoMessage
        initialState
        isComplete
        kind
        latitude
        locationShownDatapoints
        locationUrl
        longitude
        monthlyRentPerSpaceWithCentsFrom
        monthlyRentPerSpaceWithCentsTo
        photos { thumbnailW600 }
        photosCount
        prospectus {
          id
          prospectusPath
          createAssetPath
        }
        prospectusCount
        salePrice
        scoreInPercents
        scoreItemsList
        section
        sectionTranslated
        state
        stateInfo
        stateTranslated
        vimeoId
        virtualTour
        workstationCostPerMonthFrom
        workstationCostPerMonthTo
        yearlyRentPerM2WithCentsFrom
        yearlyRentPerM2WithCentsTo
        yearlyElectricityCostPerM2WithCentsFrom
        yearlyElectricityCostPerM2WithCentsTo
        yearlyHeatingCostPerM2WithCentsFrom
        yearlyHeatingCostPerM2WithCentsTo
        yearlyOperationalCostPerM2WithCentsFrom
        yearlyOperationalCostPerM2WithCentsTo
        yearlyRentalIncome
        yearlyWaterCostPerM2WithCentsFrom
        yearlyWaterCostPerM2WithCentsTo
      }
      error
    }
  }
`;


export const DEACTIVATE_LOCATION_AT_THE_MONTH_END = gql`
  mutation deactivateLocationAtTheMonthEnd($id: Int!) {
    deactivateLocationAtTheMonthEnd(input: { id: $id })
    {
      error
    }
  }
`;


export const MARK_LOCATION_AS_RENTED_OUT = gql`

  mutation rentedOutLocation($id: Int!) {
    rentedOutLocation(input: { id: $id })
    {
      location {
        address
        annualElectricityCostWithCentsFrom
        annualElectricityCostWithCentsTo
        annualHeatingCostWithCentsFrom
        annualHeatingCostWithCentsTo
        annualOperationalCostWithCentsFrom
        annualOperationalCostWithCentsTo
        annualRentWithCentsFrom
        annualRentWithCentsTo
        annualTotalCostWithCentsFrom
        annualTotalCostWithCentsTo
        annualWaterCostWithCentsFrom
        annualWaterCostWithCentsTo
        areaFrom
        areaTo
        areaUnit
        createdAt
        currencyId
        depositAndPrepaidRent
        description
        disposalPrice
        energyRating
        enquiries
        enquiriesCountInteger
        exemptedFromEnergyRating
        explainLocationIncomplete
        facilityIds
        fixedAnnualOperationalCost
        floorPlans { thumbnailW600 }
        floorPlansCount
        hourlyRentWithCentsFrom
        hourlyRentWithCentsTo
        id
        infoMessage
        initialState
        isComplete
        kind
        latitude
        locationShownDatapoints
        locationUrl
        longitude
        monthlyRentPerSpaceWithCentsFrom
        monthlyRentPerSpaceWithCentsTo
        photos { thumbnailW600 }
        photosCount
        prospectus {
          id
          prospectusPath
          createAssetPath
        }
        prospectusCount
        salePrice
        scoreInPercents
        scoreItemsList
        section
        sectionTranslated
        state
        stateInfo
        stateTranslated
        vimeoId
        virtualTour
        workstationCostPerMonthFrom
        workstationCostPerMonthTo
        yearlyRentPerM2WithCentsFrom
        yearlyRentPerM2WithCentsTo
        yearlyElectricityCostPerM2WithCentsFrom
        yearlyElectricityCostPerM2WithCentsTo
        yearlyHeatingCostPerM2WithCentsFrom
        yearlyHeatingCostPerM2WithCentsTo
        yearlyOperationalCostPerM2WithCentsFrom
        yearlyOperationalCostPerM2WithCentsTo
        yearlyRentalIncome
        yearlyWaterCostPerM2WithCentsFrom
        yearlyWaterCostPerM2WithCentsTo
      }
      error
    }
  }
`;


export const REPORT_DEAL = gql`
  mutation addLease($attributes: LeaseAttributes!) {
    addLease(input: {
        attributes: $attributes
      }) {
        lease {
          id
        }
      }
    }
`;


export const ACTIVATE_ENQUIRY = gql`

  mutation activateEnquiry($id: Int!) {
    activateEnquiry(input: { id: $id })
    {
      id
    }
  }
`;


export const GIVE_UP_ENQUIRY = gql`

  mutation giveUpEnquiry($id: Int!) {
    giveUpEnquiry(input: { id: $id })
    {
      id
    }
  }
`;


export const ADD_CLIENT_RELATION = gql`

  mutation addClientRelation($clientId: Int!) {
    addClientRelation(input: { clientId: $clientId })
    {
      clientRelationId
      error
    }
  }
`;


export const REJECT_PROVIDER_DEAL = gql`
  mutation rejectProviderDeal($id: Int!, $comment: String!, $state: String!) {
    updateProviderDeal(input: { id: $id, comment: $comment, state: $state }) {
      providerDeal {
        id
      }
      errors
    }
  }
`;


export const ADD_PAID_SERVICE_ORDER = gql`

  mutation addPaidServiceOrder(
    $photo: [Int!]!
    $floorPlan: [Int!]!
    $virtualTour: [Int!]!
    $video: [Int!]!
    $comment: String!
    $locationId: ID!
  ) {
    addPaidServiceOrder(input: {
      photo: $photo
      floorPlan: $floorPlan
      virtualTour: $virtualTour
      video: $video
      comment: $comment
      locationId: $locationId
    })
    {
      errors
    }
  }
`;


export const DELETE_SERVER_SIDE_SESSION = gql`

  mutation deleteServerSideSession($input: DeleteServerSideSessionMutationInput!) {
    deleteServerSideSession(input: $input) {
      id
    }
  }
`;


export const ADD_INCOMPLETE_DEAL_NOTIFICATION = gql`
  mutation addIncompleteDealNotification($providerId: Int!, $locationId: Int!) {
    addIncompleteDealNotification(input: { providerId: $providerId, locationId: $locationId }) {
      incompleteDealNotification {
        id
      },
      errors
    }
  }
`;


export const RESET_NEW_CLIENTS_COUNT = gql`

  mutation resetNewClientsCount($providerId: Int!) {
    resetNewClientsCount(input: { providerId: $providerId })
    {
      errors
    }
  }
`;


export const DELETE_CONTACT = gql`
  mutation deleteContact($input: DeleteContactMutationInput!) {
    deleteContact(input: $input) {
      id
    }
  }
`;


export const DELETE_CONTACT_FOREVER = gql`
  mutation hideDeletedContact($id: Int!) {
    hideDeletedContact(input: { id: $id }) {
      errors
    }
  }
`;