import React, { useEffect, Fragment } from 'react';
import ReactTooltip from "react-tooltip";

import { SvgIco } from "../../../../../SharedComponents/Icons";
import DropdownGroup from "../../../FormGroups/DropdownGroup";
import RangeInputs from "../../../FormGroups/RangeInputs";

import './index.sass';


const CombinedKindForm = ({ areaUnit,
                            combinedKinds,
                            currentData,
                            data,
                            formattedCurrency,
                            errors,
                            handleErrors,
                            initialData,
                            mainKind,
                            selectedKinds,
                            updateSelectedKinds,
                            updateValues }) => {


  useEffect(() => {
    (data.area || data.yearlyPricePerM2) && errors.includes(data.kind) && handleErrors(errors.filter(error => error !== data.kind));
  }, [data]);


  const capitalize = (name) => name.charAt(0).toUpperCase() + name.slice(1);


  const combinedKindsWithIndex = () => {
    const newCurrentKinds = [];
    const currentKindsIndex = currentData.findIndex(combinedKind => combinedKind.kind === data.kind);
    const selectedKindsIndex = selectedKinds.findIndex(combinedKind => combinedKind.kind === data.kind);
    // We need to copy all objects through iteration as we receive frozen objects from backend.
    currentData.map(location => newCurrentKinds.push({ ...location }));

    return { newCurrentKinds, currentKindsIndex, selectedKindsIndex };
  };


  const handleChange = (e) => {
    const field = e.target.name;
    const value = Number(e.target.value) || null;
    const { newCurrentKinds, currentKindsIndex, selectedKindsIndex } = combinedKindsWithIndex();
    const newSelectedKinds = [ ...selectedKinds ];


    newCurrentKinds[currentKindsIndex][field] = value;
    newSelectedKinds[selectedKindsIndex][field] = value;
    updateValues({ combinedLocationsAttributes: [ ...newCurrentKinds ] });
    updateSelectedKinds(newSelectedKinds);
  };


  const updateKind = (newKind) => {
    const { newCurrentKinds, currentKindsIndex, selectedKindsIndex } = combinedKindsWithIndex();
    const newSelectedKinds = [ ...selectedKinds ];
    const destroyKindIndex = initialData.findIndex(combinedKind => combinedKind.kind === data.kind);

    newSelectedKinds[selectedKindsIndex].kind = newKind;
    updateSelectedKinds(newSelectedKinds);

    if (destroyKindIndex >= 0) {
      newCurrentKinds[currentKindsIndex] = {
        ...newCurrentKinds[currentKindsIndex],
        area: null,
        yearlyPricePerM2: null,
        _destroy: 1,
      };
      newCurrentKinds.push(newSelectedKinds[selectedKindsIndex]);
    } else {
      newCurrentKinds[currentKindsIndex].kind = newKind;
      newCurrentKinds[currentKindsIndex]._destroy && delete newCurrentKinds[currentKindsIndex]._destroy;
    }

    updateValues({ combinedLocationsAttributes: [ ...newCurrentKinds ] });
  };


  const deleteKind = () => {
    const { newCurrentKinds, currentKindsIndex, selectedKindsIndex } = combinedKindsWithIndex();
    const newSelectedKinds = [ ...selectedKinds ];
    const destroyKindIndex = initialData.findIndex(combinedKind => combinedKind.kind === data.kind);

    selectedKindsIndex >= 0 && newSelectedKinds.splice(selectedKindsIndex, 1);
    updateSelectedKinds(newSelectedKinds);

    if (destroyKindIndex >= 0) {
      newCurrentKinds[currentKindsIndex] = {
        ...newCurrentKinds[currentKindsIndex],
        area: null,
        yearlyPricePerM2: null,
        _destroy: 1,
      };
    } else {
      currentKindsIndex >= 0 && newCurrentKinds.splice(currentKindsIndex, 1);
    }

    updateValues({ combinedLocationsAttributes: [ ...newCurrentKinds ] });
  };


  const currentKind = data.kind;
  const area = data.area || null;
  const yearlyPricePerM2 = data.yearlyPricePerM2 || null;
  const hasError = errors.includes(data.kind);
  const isMainKind = mainKind === currentKind;
  const kindsOptions = combinedKinds
    .filter(kind => !selectedKinds.some(selectedKind => selectedKind.kind === kind))
    .map(kind => ({ value: kind, label: capitalize(I18n.t(`global.models.combined_location.kinds.${ kind }.normal`)) }));


  return (
    <div className="combined-kind-form" id={`combined-form-${data.kind}`}>

      { !isMainKind &&

        <Fragment>
          <span className="provider-tooltip combined-kind-form__delete-btn"
                data-tip={I18n.t('generic.delete')}
                data-for="combined-delete-btn">
            <SvgIco
              name="delete_outline"
              size={24}
              onClick={deleteKind}
              className=""
            />
          </span>
          <ReactTooltip
            border={true}
            borderColor="black"
            effect='solid'
            id="combined-delete-btn"
            place='top'
            type="light"
          />
        </Fragment>
      }

      <div className="combined-kind-form__headline">
        { isMainKind ? I18n.t('providers.locations.form.combined_locations_main_property') : I18n.t('providers.locations.form.combined_locations_additional_property') }
      </div>

      { isMainKind || !kindsOptions.length
        ?
          <div className="combined-kind-form__kind row">
            <div className="col-lg-4">
              { I18n.t('activerecord.attributes.property.category') }
            </div>
            <div className="col-lg-4">
              { capitalize(I18n.t(`global.models.combined_location.kinds.${currentKind}.normal`)) }
            </div>
          </div>
        :
          <DropdownGroup
            handleChange={(option) => updateKind(option)}
            label={I18n.t('activerecord.attributes.property.category')}
            labelCol={4}
            name="section"
            options={kindsOptions}
            value={{ value: currentKind, label: capitalize(I18n.t(`global.models.combined_location.kinds.${currentKind}.normal`)) }}
          />
      }

      <RangeInputs
        functionAttr={currentKind}
        handleChange={(e) => handleChange(e)}
        hideSecondField={true}
        formGroupClass={hasError && 'error'}
        label={ I18n.t('apps.lb_showroom.detailed.facts.area_label') }
        labelCol={4}
        name={`area`}
        value={area}
        units={areaUnit}
        useOriginName={true} />

      <RangeInputs
        functionAttr={currentKind}
        handleChange={(e) => handleChange(e)}
        hideSecondField={true}
        formGroupClass={hasError && 'error'}
        label={ I18n.t('activerecord.attributes.combined_location.yearly_price_per_m2') }
        labelCol={4}
        name={`yearlyPricePerM2`}
        value={yearlyPricePerM2}
        units={`${ formattedCurrency }/${areaUnit}`}
        useOriginName={true} />


      { hasError &&
        <div className="row">
          <div className="combined-kind-form__error-message col-lg-8 offset-lg-4">
            { I18n.t('providers.locations.form.combined_locations_error') }
          </div>
        </div>
      }

    </div>
  )
};


export default CombinedKindForm;
