import React from 'react';
import { Link } from 'react-router-dom';

export const UnresolvedAgreementColumns = (agreementsData, currentLocale, handleReject) =>
  React.useMemo(
    () => [
      //===========================================================================>

      {
        Header: I18n.t('providers.providers.provider_deals_notifications.client'),
        accessor: 'personName',
        Cell: data => <div className="google-translate-helper">{ data.value }</div>,
      },

      //===========================================================================>

      {
        Header: I18n.t('generic.locations'),
        accessor: 'address',
        Cell: data => <div className="google-translate-helper">{ data.value || '' }</div>,
      },

      //===========================================================================>

      {
        Header: I18n.t('providers.providers.provider_deals_notifications.created_at'),
        accessor: 'createdAtFormatted',
        Cell: data => <div className="google-translate-helper">{ data.value || '' }</div>,
      },

      //===========================================================================>

      {
        Header: '',
        accessor: 'confirm',

        Cell: (data) => {
          const { clientId, locationId, id, orderId } = data.row.original;
          return (
            <Link
              className="unresolved-agreement__confirm-link"
              to={`${currentLocale}/new_providers/report_deal?client_id=${clientId}&location_id=${locationId}&provider_deal_id=${id}&order_id=${orderId}`}
            >
              { I18n.t('providers.providers.provider_deals_notifications.accept') }
            </Link>
          );
        },
      },

      //===========================================================================>

      {
        Header: '',
        accessor: 'reject',

        Cell: (data) => (
          <div onClick={() => handleReject(data.row.original)} className="unresolved-agreement__reject-link">
            { I18n.t('generic.reject') }
          </div>
        ),
      },
    ],
    [agreementsData],
  );
