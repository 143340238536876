import React from 'react';

import ErrorImg from '../../../src/images/provider/error.svg';

import './NetworkError.sass';


const NetworkError = ({}) => {

  return (
    <div className="errors-page">
      <h3>{ I18n.t('provider.error.oops') } !</h3>
      <img src={ErrorImg} height={243} width={345} alt="Error" />
      <div>{ I18n.t('provider.error.network_first_sentence') }</div>
      <div>
        { I18n.t('generic.server-error-listview-page') }
        <a href={window.location.href}>
          { I18n.t('general.refresh-page') }
        </a>
        .
      </div>
    </div>
  )
}

export default NetworkError;
