import React, { Fragment, useContext } from 'react';
import ReactTooltip from 'react-tooltip';

import BasicInput from '../../../SharedComponents/Forms/BasicInput';
import { SvgIco } from "../../../SharedComponents/Icons";
import { Context } from '../index';


const InputGroup = ({ disabled,
                      error,
                      handleChange,
                      inputWrapperClass,
                      label,
                      labelClass,
                      labelTooltip,
                      name,
                      placeholder,
                      required,
                      tooltip,
                      value,
                    }) => {

  const { errors, handleInputChange } = useContext(Context);
  const errorMessage = error || (errors[name] ? `${label} ${error || errors[name]}` : null);


  return (
    <div className={`form-group row ${required ? 'required-form-group' : ''}`} id={name}>
      <div className={`form-label col-xl-3 col-lg-3 ${labelClass || ''}`}>

        <span className="label-text" style={labelTooltip ? { marginRight: '8px' } : null}>
          { label }{ required && '*' }
        </span>

        { labelTooltip &&
          <Fragment>
            <span
              className="provider-tooltip"
              data-tip={labelTooltip}
              data-class="provider-tooltip"
              data-for={`${name}-tooltip`}>
              <SvgIco name="info_outline" size={24} />
            </span>
            <ReactTooltip
              border={true}
              borderColor="black"
              effect='solid'
              id={`${name}-tooltip`}
              place='right'
              type="light" />
          </Fragment>
        }
      </div>

      <BasicInput
        disabled={disabled}
        error={errorMessage}
        handleChange={handleChange || handleInputChange}
        name={name}
        placeholder={placeholder}
        value={value}
        wrapperClass={inputWrapperClass || 'col-xl-3 col-lg-4'} />

      { tooltip &&
        <Fragment>
          <div className="col-xl-1 col-lg-1" />
          <div className="col-xl-5 col-lg-4">
            <div className="form-group__tooltip">
              <div className="form-group__tooltip-content">
                { tooltip }
              </div>
            </div>
          </div>
        </Fragment>
      }
    </div>
  )
}

export default InputGroup;
