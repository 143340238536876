import React, { Fragment, useEffect, useState } from 'react';

import "./FormMessageBlock.sass";


const FormMessageBlock = ({ show, closeNotification}) => {
  const [showBlock, setShow] = useState(show.show);
  const color = show.color;

  useEffect(() => {
    setShow(show.show);
  }, [show.show]);


  useEffect(
    () => {
      let timer1 = setTimeout(() => {
        setShow(false);
        if (closeNotification) {
          closeNotification()
        }
      }, 3000);

      return () => {
        clearTimeout(timer1);
      };
    },

    [showBlock]
  );

  return (
    <Fragment>
      { showBlock &&
        <div className={`form-notification-block ${color === 'green' ? 'green' : 'red'}`}>
          <span>{show.message}</span>
        </div>
      }
    </Fragment>
  );
};

export default FormMessageBlock;
