import React from 'react';

import './index.sass';


export const SkeletonLoader = ({ className, count }) =>
  <div className={`skeleton-box-wrapper ${className}`}>
    { Array.apply('test', Array(count)).map((elem, index) =>
      <div className={`skeleton-box skeleton-box--${index + 1}`} key={className + index} />
    )}
  </div>;


export const PulsingLoader = () =>
  <div className="pulsing-loader-wrapper">
    <div className="pulsing-loader" />
  </div>;


export const FileLoader = () =>
  <div className="file-loader">
    <div className="progress" />
  </div>;


export const EllipsisLoader = () =>
  <div className="ellipsis-loader">
    <div>
      <span/>
      <span/>
      <span/>
    </div>
  </div>;
