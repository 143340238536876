import React, { useState } from 'react';
import { useMutation, useQuery } from "@apollo/client";

import { GET_SERVER_SIDE_SESSIONS } from "../ApolloClient/queries";
import { DELETE_SERVER_SIDE_SESSION } from "../ApolloClient/mutations";
import { SvgIco } from "../../SharedComponents/Icons";

import './index.sass';


const ActiveSessions = ({ showDeleteButton }) => {

  const [sessions, setSessions] = useState([]);

  const { getServerSideSessions } = useQuery(GET_SERVER_SIDE_SESSIONS, {
    onCompleted: (data) =>
      data && data.serverSideSessions && setSessions(data.serverSideSessions),
  });

  const [ deleteServerSideSession, { loading, error } ] = useMutation(DELETE_SERVER_SIDE_SESSION, {
    update(cache, result) {},
    refetchQueries: ["getServerSideSessions"],
    // onCompleted: () => {
    // }
  });

  const deleteSession = (id) =>
    deleteServerSideSession({ variables: { input: { id: Number(id) } }})
      .catch(error => console.log('error', error));

  const currentSession = sessions && sessions.find(session => session.isCurrent);


  if (sessions && sessions.length) {
    return (
      <div className="active-sessions" style={{ marginTop: showDeleteButton ? '24px' : 0 }}>
        <div className="active-sessions__headline">
          { I18n.t('provider.active_sessions.headline') }
        </div>

        <div className="active-sessions__wrapper">
          { currentSession &&
            <div className="active-sessions__sessions-list">
              <div className="active-sessions__sub-header">
                { I18n.t('provider.active_sessions.this_device') }
              </div>
              <div className="active-sessions__session">
                <div className="active-sessions__session-top">
                  <div className="active-sessions__browser-info">
                    { currentSession.browserName }, { currentSession.osName } { currentSession.osVersion }
                  </div>
                  <div className="active-sessions__time-info">
                    <div className="active-sessions__time current">
                      { new Date(currentSession.createdAt).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) }
                    </div>
                  </div>
                </div>
                <div className="active-sessions__session-bottom">
                  { currentSession.remoteIp } - { currentSession.city }, { currentSession.country }
                </div>
              </div>
            </div>
          }

          { currentSession &&  sessions.length > 1 &&
            <div className="active-sessions__sessions-list other-sessions">
              <div className="active-sessions__sub-header">
                { I18n.t('provider.active_sessions.other_sessions') }
              </div>
              { sessions.map(session => !session.isCurrent &&
                <div className="active-sessions__session">
                  <div className="active-sessions__session-top">
                    <div className="active-sessions__browser-info">
                      { session.browserName }, { session.osName } { session.osVersion }
                    </div>
                    <div className="active-sessions__time-info">
                      <div className="active-sessions__time">
                        { new Date(session.createdAt).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) }
                      </div>
                      <SvgIco name="delete_forever" size={24} onClick={() => deleteSession(session.id)} />
                    </div>
                  </div>
                  <div className="active-sessions__session-bottom">
                    { session.remoteIp } - { session.city }, { session.country }
                  </div>
                </div>
              )}
            </div>
          }
        </div>
      </div>
    )
  } else {
    return null;
  }
}

export default ActiveSessions;
