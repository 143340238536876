import React, { Fragment } from 'react';

import GenerateProspectusImg from "../../../src/images/provider/generate-prospectus.svg";
import ModalNetworkError from "../Errors/ModalNetworkError";
import { FileLoader } from "../Loaders";
import ProviderModal from "./ProviderModal";

import './GenerateProspectusModal.sass';


const GenerateProspectusModal = ({ error, generatedProspectusUrl, handleClose, loading, showModal }) => {


  return (
    <ProviderModal modalClass="generate-prospectus-modal"
                   showModal={showModal}
                   handleClose={handleClose}>

      { error && <ModalNetworkError /> }

      <img src={GenerateProspectusImg} width={223} height={184} alt="Generate Prospectus" />

      { loading && !error &&
        <Fragment>
          <h3 className="generate-prospectus-modal__headline">
            { I18n.t('providers.form.visuals.prospectus.preparing') }
          </h3>

          <p>{ I18n.t('views.locations.shared.cart.please_wait') }</p>

          <FileLoader />
        </Fragment>
      }

      { generatedProspectusUrl && !loading &&
        <Fragment>
          <h3 className="generate-prospectus-modal__headline">
            { I18n.t('providers.form.visuals.prospectus.prepared') }
          </h3>

          <a className="provider-modal__button" href={generatedProspectusUrl} target="_blank">
            { I18n.t('order_confirmations.show.download_prospectus') }
          </a>
        </Fragment>
      }

    </ProviderModal>
  )
}

export default GenerateProspectusModal;
