import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { GET_CONTACT_INFO, UPDATE_CONTACT, GET_CONTACT_LOCATIONS } from '../ApolloClient/queries';
import { ContactLocationsColumns } from '../Table/Columns';
import ContactForm from './ContactForm';
import ChangePasswordForm from './ChangePasswordForm';
import DeleteContactModal from '../Modals/DeleteContactModal';
import Table from '../Table';
import PhotoUpload from './PhotoUpload';
import FormMessageBlock from '../../SharedComponents/FormMessages/FormMessageBlock';
import ActiveSessions from "../ActiveSessions";

import './ContactSetttings.sass';


function ContactSettings(props) {
  const [contactInfo, setContactInfo] = useState('');
  const [contactLocation, setContactLocation] = useState([]);
  const [passwordForm, setPasswordForm] = useState(true);
  const [perPage, setPerPage] = useState(5);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modalData, setDataForModal] = useState({});
  const [showNotification, setSnowNotification] = useState({
    show: false,
    message: '',
    color: 'green',
  });
  const [pageContact, setPageContact] = useState({ page: 1 });
  const { page } = pageContact;

  const {
    currentContactId,
    currentLocale,
    formAuthToken,
    uploadContactPhotoPath,
    removeContactPhotoPath,
    updateContactInfo
  } = props;
  const { data: contact, refetch } = useQuery(GET_CONTACT_INFO, {
    variables: { id: currentContactId },
  });
  const { data: getContactLocations } = useQuery(GET_CONTACT_LOCATIONS);
  const [updateContact] = useMutation(UPDATE_CONTACT);

  useEffect(() => {
    if (contact) {
      setContactInfo(contact.contact);
      setShowDeleteButton(contact.contact.isDestroyable);
      updateContactInfo(contact.contact);
    }
    if (getContactLocations) {
      setContactLocation(getContactLocations.contactLocations);
    }
  }, [contact, getContactLocations]);

  const updateContactForm = (values) => {
    setSnowNotification({ show: false, message: '' });
    const { name, email, phoneNumber } = values;
    const input = {
      name,
      email,
      phoneNumber,
      id: currentContactId,
    };

    updateContact({
      variables: { input },
      refetchQueries: ["contact"],
      onCompleted: ({ errors }) => {
        if (errors && errors.length) {
          console.log(errors);
        } else {
          setSnowNotification({
            show: true,
            message: I18n.t('providers.contacts.edit.information_updated'),
            color: 'green',
          });
        }
      },
    });
  };

  const updatePassword = (values) => {
    const { password, passwordConfirmation, oldPassword } = values;
    const input = {
      id: currentContactId,
      password,
      passwordConfirmation,
      oldPassword,
    };

    updateContact({
      variables: { input },
      onCompleted: ({ updateContact }) => {
        const { errors } = updateContact;
        window.scrollTo(0, 0);
        if (errors && errors.length) {
          setSnowNotification({
            show: true,
            message: I18n.t('generic.old_password_not_match'),
            color: 'red',
          });
        } else {
          setSnowNotification({
            show: true,
            message: I18n.t('providers.contacts.edit.information_updated'),
            color: 'green',
          });
        }
      },
    });
  };

  const deleteAccount = () => {
    const data = { id: currentContactId, name: contactInfo.name };
    setDataForModal(data);
    setShowDeleteModal(true);
  };

  // const handlePasswordForm = () => {
  //   setPasswordForm(!passwordForm);
  // };

  const handleCloseNotification = () => {
    setSnowNotification({ show: false, message: '', color: '' });
  };

  const perPageOptions = [
    { value: '5', label: '5' },
    { value: '10', label: '10' },
  ];

  const handleFilters = (newPage) => {
    setPageContact({ page: newPage.page })
  };

  const columns = ContactLocationsColumns(contactLocation, currentLocale);

  return (
    <div className="contact-settings">
      <FormMessageBlock
        show={showNotification}
        closeNotification={handleCloseNotification}
      />

      <h3>{I18n.t('providers.contacts.edit.contact_info')}</h3>

      <PhotoUpload
        imageUrl={contactInfo.photoUrl}
        uploadPath={uploadContactPhotoPath}
        formAuthToken={formAuthToken}
        removeTranslation={I18n.t('providers.contacts.edit.remove_photo')}
        handleRefetch={refetch}
        removePath={removeContactPhotoPath}
      />

      <ContactForm
        contactInfo={contactInfo}
        submitHandler={updateContactForm}
      />

      <h3>{I18n.t('views.sessions.new.login.recovery_password_title')}</h3>

      {/*<p onClick={handlePasswordForm} className="settings__change-password">*/}
      {/*  {I18n.t('providers.contacts.edit.change_password')}*/}
      {/*</p>*/}

      <ChangePasswordForm
        submitHandler={updatePassword}
        passwordForm={passwordForm}
      />

      <h3>{I18n.t('providers.contacts.edit.owned_locations')}</h3>

      <Table
        data={contactLocation}
        columns={columns}
        setPerPage={setPerPage}
        perPage={perPage}
        currentPage={page}
        setPage={(page) => handleFilters({ page })}
        perPageOptions={perPageOptions}
        disableSort
        gotAllData
        noScrollToTop
      />

      {showDeleteButton && (
        <>
          <DeleteContactModal
            callback={() => setDataForModal({})}
            data={modalData}
            handleClose={() => setShowDeleteModal(false)}
            showModal={showDeleteModal}
            logout
          />

          <h3>Delete account</h3>
          <p>{I18n.t('providers.contacts.edit.delete_contact_help')}</p>

          <button
            type="button"
            className="contact-settings__delete-button"
            onClick={deleteAccount}
          >
            {I18n.t('generic.delete')}
          </button>
        </>
      )}

      <ActiveSessions showDeleteButton={showDeleteButton} />
    </div>
  );
}

ContactSettings.propTypes = {
  currentContactId: PropTypes.string,
  formAuthToken: PropTypes.string,
  uploadContactPhotoPath: PropTypes.string,
  removeContactPhotoPath: PropTypes.string,
};

ContactSettings.defaultProps = {
  currentContactId: '',
  formAuthToken: '',
  uploadContactPhotoPath: '',
  removeContactPhotoPath: '',
};

export default ContactSettings;
