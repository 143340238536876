import React, { useState, useContext, useEffect } from 'react';
import { useLazyQuery } from "@apollo/client";

import Checkbox from '../../../SharedComponents/Forms/CheckBox';
import { GET_CONTACTS } from "../../ApolloClient/queries";
import NavigationButtons from '../NavigationButtons';
import DropdownGroup from '../FormGroups/DropdownGroup';
import { Context } from '../index';

import './Contacts.sass';


const Contacts = () => {

  const [contacts, setContacts] = useState(null);

  const {
    currentContactId,
    handleCheckboxesSelect,
    showFacts,
    validateFields,
    values,
    updateValues,
  } = useContext(Context);

  const { existingPrimaryContact, subscriberContactIds } = values;

  const [getContacts] = useLazyQuery(GET_CONTACTS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data.contacts) {
        setContacts([ ...data.contacts.contacts ]);
      }
    },
  });

  useEffect(() => {
    getContacts({ variables: { perPage: 1000 } })
      .catch(error => console.log('error', error));
  }, []);

  const primaryContactOptions =
    contacts && contacts.map(contact =>
      ({ value: `${contact.id}`, label: `${contact.name} - ${contact.email}`})
    );


  const handlePrimaryContactChange = (value) =>
    updateValues({ existingPrimaryContact: { id: value } });


  return (
    <div className="contacts-step">
      <h3>{ I18n.t('generic.providers.sidebar.contacts') }</h3>

      { primaryContactOptions &&
        <DropdownGroup
          handleChange={handlePrimaryContactChange}
          label={I18n.t('activerecord.attributes.location.primary_contact')}
          name="existingPrimaryContact"
          options={primaryContactOptions}
          value={(existingPrimaryContact && existingPrimaryContact.id) || `${currentContactId}`}
          tooltipChildren={
            <p dangerouslySetInnerHTML={{ __html: I18n.t('providers.locations.form.primary_contact_help') }} />
          }
        />
      }

      <div className="form-group row">
        <div className="form-label form-label--bold form-label--left col-xl-12 col-lg-12" style={{ marginBottom: '24px' }}>
          <span>{ I18n.t('providers.locations.form.secondary_contacts') }</span>
        </div>

        <div className="col-xl-6 col-lg-6">
          { contacts && contacts.map(contact =>
            <Checkbox
              field="subscriberContactIds"
              handleChange={(e, field) => handleCheckboxesSelect(e, field, true)}
              key={contact.id}
              label={`${contact.name} - ${contact.email}`}
              name={contact.id}
              value={!!subscriberContactIds && subscriberContactIds.includes(Number(contact.id))} />
          )}
        </div>
      </div>

      <NavigationButtons
        handleBack={() => validateFields('economy')}
        handleNext={() => validateFields(showFacts ? 'facts' : 'description')}
      />

    </div>
  )
}


export default Contacts;
