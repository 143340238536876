import React from 'react';

import PartnerBadgeIcon from '../../../src/images/provider/partner-badge.svg';
import PartnerBadgeIconDk from '../../../src/images/provider/partner-badge-dk.svg';
import { ScoreActionTooltip } from "../ScoreWidget";

import './index.sass';


const PartnerBadgeBanner = ({ partnerBadgeUrl, qualityScore, siteKey }) => {

  return (
    <div className="partner-badge">
      <img
        alt="Partner Badge"
        src={siteKey === 'dk' ? PartnerBadgeIconDk : PartnerBadgeIcon}
        height={70}
        width={180}
      />
      <div className="partner-badge__content">
        <ScoreActionTooltip
          filled={qualityScore && qualityScore.providerPartner.score}
          label={qualityScore && I18n.t(`provider.form.quality_score.hint_label.partner_badge`)}
        />
        <div className="google-translate-helper">
          { I18n.t('provider.partner_badge.banner_text') }
        </div>
      </div>
      <a href={partnerBadgeUrl} target="_blank">
        { I18n.t('generic.providers.sidebar.get-partner-badge-button-text') }
      </a>
    </div>
  )
}

export default PartnerBadgeBanner;
