import React from 'react';
import { withFormik } from 'formik';
import Yup from 'yup';
import PropTypes from 'prop-types';

import { nameValidation, emailValidation, phoneValidation } from '../../../SharedComponents/Forms/ValidationSchemaPattern';
import Input from '../../../SharedComponents/Forms/Input';

import './index.sass';

function ContactFormWrapper({
  handleSubmit,
  values,
  handleChange,
  touched,
  handleBlur,
  errors,
}) {
  return (
    <div className="contact-form__wrapper">
      <form
        name="contact-form"
        className="contact-form"
        onSubmit={handleSubmit}
        noValidate
      >
        <div className="row">
          <div className="col-md-3">
            <Input
              name="name"
              value={values.name}
              handleChange={handleChange}
              handleBlur={handleBlur}
              label={I18n.t('generic.name')}
              error={touched.name && errors.name}
              required
            />
          </div>

          <div className="col-md-3">
            <Input
              name="email"
              value={values.email}
              handleChange={handleChange}
              handleBlur={handleBlur}
              label={I18n.t('generic.email')}
              error={touched.email && errors.email}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Input
              name="phoneNumber"
              value={values.phoneNumber}
              handleChange={handleChange}
              handleBlur={handleBlur}
              label={I18n.t('generic.phone')}
              error={touched.phoneNumber && errors.phoneNumber}
              required
            />
          </div>
        </div>

        <button type="submit" className="contact-form__save-button">
          {I18n.t('generic.save')}
        </button>
      </form>
    </div>
  );
}

const ContactForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ contactInfo }) => ({
    name: contactInfo ? contactInfo.name : '',
    email: contactInfo ? contactInfo.email : '',
    phoneNumber: contactInfo ? contactInfo.phoneNumber : '',
  }),

  validationSchema: Yup.object().shape({
    name: nameValidation,
    email: emailValidation,
    phoneNumber: phoneValidation,
  }),

  handleSubmit: (values, bag) => {
    bag.props.submitHandler(values);
  },

  displayName: 'Contact Form',
})(ContactFormWrapper);

ContactFormWrapper.propTypes = {
  values: PropTypes.oneOfType([PropTypes.object]),
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  touched: PropTypes.bool,
  handleBlur: PropTypes.func,
  errors: PropTypes.oneOfType([PropTypes.array]),
};

ContactFormWrapper.defaultProps = {
  values: {},
  handleSubmit: () => true,
  handleChange: () => true,
  touched: false,
  handleBlur: () => true,
  errors: [],
};

export default ContactForm;
