import React, { useEffect, useState, useRef, useMemo, Fragment } from 'react';
import { createContext } from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  useHistory,
  useParams,
} from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';

import { SvgIco } from "../../SharedComponents/Icons";
import { VALIDATE_LOCATION_FIELDS, ADD_LOCATION, UPDATE_LOCATION } from '../ApolloClient/mutations';
import { GET_LOCATION_VALUES, GET_LOCATION_VISUALS, GET_LOCATIONS } from '../ApolloClient/queries';
import NetworkError from '../Errors/NetworkError';
import InfoMessagePopup from '../Popups/InfoMessagePopup';
import Page404 from '../Errors/Page404';
import { QualityScoreBanner, scoreCalculator } from "../ScoreWidget";
import ProgressBar from './ProgressBar/index';
import StepsWrapper from './StepsWrapper';

import './index.sass';

export const Context = createContext({});


const LocationForm = (props) => {

  const {
    currentLocale,
    defaultCurrencyId,
    isNumber,
    partnerBadgeUrl,
    qualityScoreLink,
    resourceNotFoundError,
    siteName
  } = props;

  const { id: locationId, locationSection, editPage } = useParams();
  const history = useHistory();
  const match = useRouteMatch();
  const stepsRefs = { description: useRef() };
  const currentStepRef = useRef();

  const firstKind = (section) => {
    switch (section) {
      case 'lease':
        return props.leaseKinds[0];
      case 'sales':
        return props.saleKinds[0];
      case 'anonymous':
        return props.leaseKinds[0];
      default:
        return 'office';
    }
  };

  const [values, setValues] = useState({
    addressLine1: locationSection === 'anonymous' ? 'anonymous' : '',
    exemptedFromEnergyRating: false,
    isBuilt: true,
    facilityIds: [],
    section: locationSection === 'sales' ? 'own_use' : 'lease',
    kind: firstKind(locationSection),
    anonymous: locationSection === 'anonymous',
    currencyId: defaultCurrencyId.id,
    secondaryKinds: [],
    subscriberContactIds: [],
    suitableForKinds: [],
    employeesCountVisible: true,
    isRentIncludeOperationalCost: false,
    enableCombinedLocations: false,
    yearlyRentPerM2AmountFlexible: true,
  });

  const [stepsData, setStepsData] = useState({
    location: {
      link: 'location',
      passed: false,
      starRating: false,
      name: I18n.t('detailed.location.headline'),
    },
    economy: {
      link: 'economy',
      passed: false,
      starRating: false,
      name: I18n.t('apps.lb_showroom.detailed.tabs.economy'),
      showScoreIcon: true,
    },
    contacts: {
      link: 'contacts',
      passed: false,
      starRating: false,
      name: I18n.t('layouts.providers.navigation.contacts'),
    },
    facts: {
      link: 'facts',
      passed: false,
      starRating: false,
      name: I18n.t('apps.lb_showroom.detailed.tabs.facts_and_facilities'),
      hide: false,
      showScoreIcon: true,
    },
    description: {
      link: 'description',
      passed: false,
      starRating: false,
      name: I18n.t('activerecord.attributes.property.description'),
      showScoreIcon: true,
    },
    visuals: {
      link: 'visuals',
      passed: false,
      starRating: false,
      name: I18n.t('generic.visuals'),
      showScoreIcon: true,
    },
  });

  const [errors, setErrors] = useState({});
  const [editedValues, setEditedValues] = useState({});
  const [showStatusBanner, setStatusBanner] = useState(false);
  const [activeSection, setActiveSection] = useState('');
  const [lastAvailableStep, setLastAvailableStep] = useState('');
  const [showEditPage, setShowEditPage] = useState(false);
  const [infoMessagePopupIsOpen, setInfoMessagePopupIsOpen] = useState(false);
  const [infoPopupMessageError, setInfoPopupMessageError] = useState(null);
  const [showNotFoundPage, setShowNotFoundPage] = useState(false);
  const [showQualityScore, setShowQualityScore] = useState(false);
  const [visualsData, setVisualsData] = useState({});


  const [getLocationVisuals, { refetch: refetchVisualValues }] = useLazyQuery(
    GET_LOCATION_VISUALS,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) =>
        data.location && setVisualsData({ ...data.location }),
    },
  );


  const [addLocation, { loading: addLocationLoading }] = useMutation(ADD_LOCATION, {
    onCompleted: (data) => {
      const { errors, location } = data.addLocation;

      if (errors) {
        handleErrors(errors);
      } else {
        setStepPassed();
        setStatusBanner(true);
        setShowEditPage(true);
        setShowQualityScore(false);
        history.push(`${currentLocale}/new_providers/locations/edit/${location.id}/visuals`);
      }
    },
  });


  const [updateLocation, { loading: updateLocationValuesLoading }] =
    useMutation(UPDATE_LOCATION, {
      refetchQueries: ['getLocationValues'],
      onCompleted: () => {
        setEditedValues({});
        setInfoMessagePopupIsOpen(true);
        refetchVisualValues().catch(error => console.log('Error', error));
      },
    });


  const [validateLocation, { loading: validateLocationLoading }] = useMutation(VALIDATE_LOCATION_FIELDS);


  const [getLocationValues, { data: initialLocationValues, error }] =
    useLazyQuery(GET_LOCATION_VALUES, {
      onCompleted: (data) => {
        if (data.location) {
          setShowEditPage(true);
          setValues({ ...data.location });
        }
      },
    });


  const [getLocations] = useLazyQuery(GET_LOCATIONS, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      data.locations && data.locations.totalCount > 0 && setShowQualityScore(true);
    },
  });


  useEffect(() => {
    editPage && locationId && getLocationVisuals({ variables: { id: locationId } });
  }, []);


  useEffect(() => {
    const category = locationSection === 'anonymous' ? 'anonymous' : locationSection === 'sales' ? 'sales_properties' : 'lease_properties';

    getValues();
    getLocations({ variables: { category, perPage: 0 }})
      .catch((error) => console.log('error', error));
  }, []);


  useEffect(() => {
    getValues();
  }, [editPage, locationId]);


  useEffect(() => {
    activeSection &&
      !editPage &&
      !stepsData.location.passed &&
      activeSection !== 'location' &&
      history.replace(`${match.url}/location`);
    !editPage && window.scroll({ top: 1, behavior: 'smooth' });
  }, [activeSection]);


  const editedValuesFields = useMemo(
    () =>
      Object.entries(editedValues)
        .filter(
          ([key, value]) =>
            !(
              initialLocationValues.location[key] === value ||
              (!initialLocationValues.location[key] && !value)
            ),
        )
        .map(([key]) => key),
    [editedValues, initialLocationValues],
  );

  const qualityScore = useMemo(
    () => scoreCalculator({ ...values, ...visualsData, providerPartner: props.providerPartner, ...editedValues }, currentLocale, partnerBadgeUrl),
    [editedValues, values, visualsData],
  );


  const showFacts = values.kind !== 'parking';
  const anonymous = values.anonymous;

  const getValues = () => {
    editPage &&
    locationId &&
    getLocationValues({ variables: { id: locationId } });
  };

  const updateValues = (values) => {
    setValues((prevValues) => ({ ...prevValues, ...values }));
    editPage && setEditedValues((prevValues) => ({ ...prevValues, ...values }));
  };

  const prepareData = (data) => {
    const newData = { ...data };
    const { yearlyRentPerM2WithCentsFrom, yearlyRentPerM2WithCentsTo } = data;

    data.hasOwnProperty('yearlyRentPerM2WithCentsFrom') && (newData.yearlyRentPerM2WithCentsFrom = parseFloat(yearlyRentPerM2WithCentsFrom) || null);
    data.hasOwnProperty('yearlyRentPerM2WithCentsTo') && (newData.yearlyRentPerM2WithCentsTo = parseFloat(yearlyRentPerM2WithCentsTo) || null);
    data.hasOwnProperty('postalDistrictId') && !data.postalDistrictId && (newData.postalDistrictId = 0);
    data.hasOwnProperty('provinceId') && !data.provinceId && (newData.provinceId = 0);

    return newData;
  };

  const handleChange = (value, field) => {
    if (errors[field]) {
      const updatedErrors = { ...errors };

      delete updatedErrors[field];
      setErrors({ ...updatedErrors });
    }

    updateValues({ [field]: value });
  };

  const handleCheckboxesSelect = (e, field, asNumber) => {
    const value = asNumber ? Number(e.target.name) : e.target.name;
    const currentValues = values[field];
    const index = currentValues ? currentValues.indexOf(value) : -1;
    const newValues = [...currentValues];

    index >= 0 ? newValues.splice(index, 1) : newValues.push(value);
    handleChange(newValues, field);
  };

  const handleInputChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;

    handleChange(value, field);
  };

  const handleNumberInputChange = (e) => {
    const field = e.target.name;
    const inputValue = e.target.value;
    const value = inputValue ? Number(inputValue) : null;

    handleChange(value, field);
  };

  const setStepPassed = () =>
    setStepsData((prevData) => ({
      ...prevData,
      [activeSection]: {
        ...prevData[activeSection],
        passed: true,
      },
    }));

  const validateRequiredFields = (errorsHandler) => {
    const currentErrors = { ...errors };
    const requiredFormGroups = document.querySelectorAll('.required-form-group');

    Array.from(requiredFormGroups).map((group) => {
      const name = group.id;

      !values[name] &&
        (currentErrors[name] = I18n.t(
          'apps.lb_showroom.detailed.order_form.validation.field_required',
          { field: '' },
        ));
    });

    setErrors({ ...currentErrors });

    const errorsLength = Object.keys(currentErrors).length;

    if (errorsLength) {
      errorsHandler
        ? errorsHandler(currentErrors)
        : scrollToTarget(document.getElementById(Object.keys(currentErrors)[0]));
    }

    return !errorsLength;
  };

  const validateFields = (nextStepUrl, callbackFunction, errorsHandler) => {

    const dataValues = prepareData(values);

    validateRequiredFields(errorsHandler) &&
    validateLocation({
      variables: { attributes: dataValues },
      onCompleted: (data) => {
        const { errors } = data.validateLocation;

        if (errors.length) {
          handleErrors(errors, errorsHandler);
        } else {
          callbackFunction && callbackFunction();

          if (nextStepUrl) {
            setStepPassed();
            handleNext(nextStepUrl);
          }
        }
      },
    }).catch((error) => console.log('error', error));
  };


  const handleErrors = (errorsList, errorsHandler) => {
    const newErrors = { ...errors };

    errorsList.map((listError) => (newErrors[listError.attrName] = listError.errorMessages));

    const filteredErrors =
      Object.keys(Object.fromEntries(Object.entries(newErrors).filter(([key]) => !key.includes('description') && !key.includes('title')))).length;

    setErrors({ ...newErrors });

    ((newErrors.title || newErrors.description) && !filteredErrors)
      && activeSection !== 'description'
      && history.push(`${match.url}/description`);

    if (Object.keys(newErrors).length) {
      errorsHandler
        ? errorsHandler(newErrors)
        : scrollToTarget(document.getElementById(Object.keys(newErrors)[0]));
    }
  };

  const scrollToTarget = (target) => {
    if (target) {
      const targetTop = target.getBoundingClientRect().top;
      const targetCoords = Math.floor(targetTop + window.scrollY - 80);

      window.scroll(0, targetCoords);
    }
  };

  const handleFormSubmit = (data) => {
    const attrs = prepareData(data || values);

    addLocation({ variables: { attributes: { ...attrs } } })
      .catch(error => console.log('Error', error));
  };

  const handleEdit = () => {
    updateLocation({
      variables: {
        attributes: { ...prepareData(editedValues), countryAlpha2: values.countryAlpha2 },
        id: Number(locationId),
      },
    }).catch((error) => {
      console.log('error', error);
      setInfoPopupMessageError(true);
      setInfoMessagePopupIsOpen(true);
    });
  };

  const closeInfoMessagePopup = () => {
    setInfoMessagePopupIsOpen(false);
    infoPopupMessageError && setInfoPopupMessageError(false);
  };

  const handleNext = (section) =>
    section && history.push(`${match.url}/${section}`);

  const capitalize = (name) => name.charAt(0).toUpperCase() + name.slice(1);

  const value = {
    ...props,
    activeSection,
    addLocationLoading,
    anonymous,
    currentLocale,
    currentStepRef,
    editPage,
    editedValuesFields,
    errors,
    locationId,
    match,
    qualityScore,
    setErrors,
    setInfoMessagePopupIsOpen,
    setInfoPopupMessageError,
    setVisualsData,
    showFacts,
    showQualityScore,
    stepsRefs,
    validateLocation,
    validateLocationLoading,
    values,
    visualsData,
    updateLocation,
    updateLocationValuesLoading,
    capitalize,
    handleChange,
    handleCheckboxesSelect,
    handleEdit,
    handleFormSubmit,
    handleInputChange,
    handleNext,
    handleNumberInputChange,
    prepareData,
    refetchVisualValues,
    scrollToTarget,
    validateFields,
    validateRequiredFields,
    updateValues,
  };

  const pageNotFound = editPage ? (!isNumber(locationId) || showNotFoundPage) : false;
  const showQualityScoreBanner = qualityScore.totalScore !== 100 && (editPage || (activeSection && stepsData[activeSection].showScoreIcon));

  if (resourceNotFoundError(error) || pageNotFound) return <Page404 currentLocale={currentLocale} />;
  if (error) return <NetworkError />;


  return (
    <div className="location-form-wrapper">
      <InfoMessagePopup
        customClass={infoPopupMessageError ? 'error' : null}
        handleClose={closeInfoMessagePopup}
        message={
          infoPopupMessageError
            ? I18n.t('apps.lb_showroom.detailed.order_form.error.message')
            : I18n.t('providers.form.update_success')
        }
        visible={infoMessagePopupIsOpen}
      />

      { activeSection === 'visuals' && showStatusBanner &&
        <div className="status-banner-wrapper">
          <div className="status-banner">
            <div className="status-banner__icon">
              <SvgIco name="check" size={20}/>
            </div>
            <div className="status-banner__content">
              <div className="status-banner__headline">
                {I18n.t('providers.locations.form.location_created_headline')}
              </div>
              <p>
                <div className="status-banner__subheader">
                  {I18n.t('providers.locations.form.location_created_subheadline')}
                </div>
                {I18n.t('providers.locations.form.location_created_content')}
              </p>
            </div>
            <SvgIco
              className="status-banner__close"
              name="close"
              size={28}
              onClick={() => setStatusBanner(false)}/>
          </div>
        </div>
      }

      { activeSection === 'visuals' && (
        <div className="visuals-headline">
          { values.section === 'lease' || values.anonymous
            ? I18n.t('providers.locations.form.visuals_headline')
            : "Opret ny salgsejendom: Tilføj fotos m.m."
          }
        </div>
      )}

      { (showEditPage || !editPage) &&
        <div id="locationForm" className="location-form">
          <Context.Provider value={value}>
            <Switch>

              <Route path={`${match.url}/:section`}>
                <Fragment>
                  { showQualityScoreBanner &&
                    <QualityScoreBanner
                      data={qualityScore}
                      qualityScoreLink={qualityScoreLink}
                      siteName={siteName}
                      stickyTop={activeSection === 'visuals' ? 144 : 96}
                    />
                  }

                  <ProgressBar
                    activeSection={activeSection}
                    editPage={editPage}
                    lastAvailableStep={lastAvailableStep}
                    showFacts={showFacts}
                    stepsData={stepsData}
                    stepsRefs={stepsRefs}
                    qualityScore={qualityScore}
                    validateFields={validateFields}
                  />

                  <StepsWrapper
                    setActiveSection={setActiveSection}
                    stepsData={stepsData}
                    stepsRefs={stepsRefs}
                    showFacts={showFacts}
                    activeSection={activeSection}
                    setLastAvailableStep={setLastAvailableStep}
                    setShowNotFoundPage={setShowNotFoundPage}
                  />
                </Fragment>
              </Route>

              <Route path="*">
                <Page404 currentLocale={currentLocale} />
              </Route>

            </Switch>
          </Context.Provider>
        </div>
      }
    </div>
  );
};


export default LocationForm;
