import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import DateTimePicker from 'react-datetime-picker';

import BasicDropdown from '../../SharedComponents/Forms/BasicDropdown';
import { SvgIco } from "../../SharedComponents/Icons";
import { useDebouncedEffect } from "../../SharedComponents/hooks";
import {
  GET_STATISTIC_FILE,
  GET_STATISTICS,
  GET_STATISTICS_TOTAL
} from '../ApolloClient/queries';
import NetworkError from "../Errors/NetworkError";
import Table from '../Table';
import ExpandedStatistics from "./ExpandedStatistics";
import { StatisticColumns } from './StatisticColumns';

import './index.sass';
import TokenizedFileModalLink from "../Modals/TokenizedFileModalLink";


const Statistic = ({ currencySymbolFormatter, currentLocale, leaseKinds, dateFrom: initialDateFrom, dateTo: initialDateTo }) => {

  const [dateFrom, setDayFrom] = useState(new Date(initialDateFrom));
  const [dateTo, setDayTo] = useState(new Date(initialDateTo));
  const [statisticData, setStatisticData] = useState([]);
  const [statisticDataTotal, setStatisticDataTotal] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [initialLoad, setInitialLoad] = useState(true);
  const [queryValue, setQueryValue] = useState('');

  const [filters, setFilters] = useState({
    timeFrom: '',
    timeTo: '',
    kind: null,
    page: 1,
    perPage: 10,
    query: '',
  });

  const { loading, error } = useQuery(GET_STATISTICS,
    { variables: { ...filters },
      fetchPolicy: 'no-cache',
      onCompleted: results => {
        const data = results.statistic;
        const dataLength = data.statisticData && data.statisticData.length;

        if (dataLength >= 0) {
          const editedData = [];
          const newData = { ...data.statisticData[0], expanded: true };

          data.statisticData.map((data, index) => editedData.push(index === 0 ? newData : { ...data }));
          setStatisticData(editedData);

          setTotalCount(data.statisticRowsCount);
          !dateFrom && setDayFrom(new Date(new Date().setDate(new Date().getDate() - data.statisticData[0].daysInPeriod)));
        }

        setInitialLoad(false);

      },
    });

  const { loading: loadingTotal } = useQuery(GET_STATISTICS_TOTAL,
    { variables: { ...filters },
      fetchPolicy: 'no-cache',
      onCompleted: results => {
        results && results.statisticTotal && setStatisticDataTotal(results.statisticTotal);
      },
    });


  const { kind, page, perPage } = filters;
  const hideYearlyRentColumn = !statisticData.some(data => data.locationYearlyRentPerM2);
  const hideLocationEventsColumns = !(statisticData && statisticData.length && statisticData[0].showContactDetailsInFrontend);

  const hiddenColumns = [
    `${hideYearlyRentColumn && 'locationYearlyRentPerM2'}`,
    `${hideLocationEventsColumns && 'locationEventsContactDetailsShownCount'}`,
    `${hideLocationEventsColumns && 'locationEventsProviderAdvertsShownCount'}`,
  ];

  const perPageOptions = [
    { value: '10', label: '10' },
    { value: '20', label: '20' },
    { value: '40', label: '40' },
  ];

  const kindsOptions =
    [{ value: null, label: I18n.t('generic.all'), className: !kind ? 'selected' : null }]
      .concat(leaseKinds.map(kind => ({ value: kind, label: I18n.t(`generic.${kind}`) })
  ));


  const downloadUrl = (extension) => {
    const timeFromQuery = dateFrom ? `time_from=${dateFrom.toLocaleDateString('en-GB').replaceAll('/', '-')}` : '';
    const timeToQuery = dateTo ? `&time_to=${dateTo.toLocaleDateString('en-GB').replaceAll('/', '-')}` : '';
    const kindQuery = kind ? `&kind=${kind}` : '';

    return `/providers/statistics/download.${extension}?${timeFromQuery}${timeToQuery}${kindQuery}`
  };


  const handleFilters = (newFilters) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...newFilters,
    }));
    setStatisticData([]);
  };


  const handleDate = (date, field, setState) => {

    let newDate = new Date(date);

    // We need to set time and remove time zone offset to get a proper time request,
    // as DateTimePicker returns date with time 00:00:00 +/-timezone
    if (field === 'timeTo' && date) {
      newDate.setHours(23,59,59,59);
      const timeToUserTimezoneOffset = newDate.getTimezoneOffset() * 60000;
      newDate = new Date(newDate.getTime() - timeToUserTimezoneOffset);
    };

    handleFilters({ [field]: newDate || '' });
    setState(date);
  };


  useDebouncedEffect(() => {
    queryValue !== filters.query && handleFilters({query: queryValue, page: 1})
  }, [queryValue], 500);


  const handleExpand = (id) => {
    const editedData = [];
    const selectedIndex = statisticData.findIndex(data => data.locationId === id);
    const isExpanded = statisticData[selectedIndex].expanded ? false : true;
    const newData = { ...statisticData[selectedIndex], expanded: isExpanded };

    statisticData.map((data, index) => editedData.push(index === selectedIndex ? newData : { ...data }));
    setStatisticData(editedData);
  };


  const statisticColumns = StatisticColumns(statisticData, statisticDataTotal, currentLocale, handleExpand, currencySymbolFormatter);


  if (error) return <NetworkError />;


  return (
    <div className="statistics">
      <div className="statistics__filters-wrapper">
        <div className="statistics__filters">

          <div className="search-input-wrapper">
            <input
              className="search-input"
              maxLength="256"
              onChange={(e) => setQueryValue(e.target.value)}
              placeholder={I18n.t('generic.search')}
              type="text"
              value={queryValue} />
            <SvgIco name="search" size={16} />
          </div>

          <DateTimePicker
            calendarIcon={<SvgIco name="calendar_today" size={16} />}
            clearIcon={<SvgIco name="close" size={24} />}
            className={dateFrom ? '' : 'empty'}
            dayPlaceholder={I18n.t('providers.statistics.index.date_from')}
            disableClock={true}
            format="dd-MM-yyyy"
            maxDate={new Date}
            onChange={(date) => handleDate(date, 'timeFrom', setDayFrom)}
            value={dateFrom} />

          <DateTimePicker
            calendarIcon={<SvgIco name="calendar_today" size={16} />}
            clearIcon={<SvgIco name="close" size={24} />}
            className={dateTo ? '' : 'empty'}
            dayPlaceholder={I18n.t('providers.statistics.index.date_to')}
            disableClock={true}
            format="dd-MM-yyyy"
            minDate={dateFrom}
            onChange={(date) => handleDate(date, 'timeTo', setDayTo)}
            value={dateTo} />

          <BasicDropdown
            handleChange={(value) => handleFilters({ kind: value || '', page: 1 })}
            name="kind"
            options={kindsOptions}
            placeholder={I18n.t('apps.lb_showroom.showroom.list.property_kind')}
            value={kind}
            wrapperClass="kind-select" />

        </div>

        { !!statisticData.length &&
          <div className="statistics__file-buttons-wrapper">
              <TokenizedFileModalLink
                dataName="statisticFile"
                fileName="downloadFileToken"
                linkLabel="CSV"
                query={GET_STATISTIC_FILE}
                variables={{ timeFrom: filters.timeFrom, timeTo: filters.timeTo, kind: filters.kind || '', type: 'csv' }}
              />
              <TokenizedFileModalLink
                dataName="statisticFile"
                fileName="downloadFileToken"
                linkLabel="Excel"
                query={GET_STATISTIC_FILE}
                variables={{ timeFrom: filters.timeFrom, timeTo: filters.timeTo, kind: filters.kind || '', type: 'xls' }}
              />
          </div>
        }

      </div>

      <Table
        columns={statisticColumns}
        currentPage={page}
        data={statisticData}
        disableSortBy={true}
        expandable={true}
        footerLoading={loadingTotal}
        hiddenColumns={hiddenColumns}
        initialLoad={initialLoad}
        loading={loading}
        perPage={perPage}
        perPageOptions={perPageOptions}
        renderExpandableRow={(data) => <ExpandedStatistics data={data} key={data.locationId} />}
        setPage={(page) => handleFilters({ page })}
        setPerPage={(perPage) => handleFilters({ perPage, page: 1 })}
        showFooter={true}
        totalCount={totalCount} />

    </div>
  );
};

export default Statistic;
