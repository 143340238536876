import React from 'react';
import ReactTooltip from "react-tooltip";

import { SvgIco } from "../../SharedComponents/Icons";
import { ActionIcon, ScoreChart, visibilityQuality } from "../ScoreWidget";
import ProviderModal from './ProviderModal';

import './QualityScoreInfoModal.sass';


const QualityScoreInfoModal = ({ data, handleClose, qualityScoreLink, showModal, siteName }) => {

  const { totalScore } = data;
  const qualityState = visibilityQuality(totalScore);

  const ListItem = ({ itemData }) =>
    <div className="quality-score-info-modal__list-item">
      <div className="quality-score-info-modal__list-item-name">
        <div className="quality-score-info-modal__list-item-label">{ itemData.name }</div>
        <span
          className="provider-tooltip"
          data-tip={I18n.t(`provider.form.quality_score.extended_hint.${itemData.id}`, { siteName })}
          data-class="provider-tooltip"
          data-for={`score-${itemData.id}-tooltip`}>
          <SvgIco name="info_outline" size={20} />
        </span>
        <ReactTooltip
          border={true}
          borderColor="black"
          effect='solid'
          id={`score-${itemData.id}-tooltip`}
          place='bottom'
          type="light" />
      </div>
      <div className={`quality-score-info-modal__list-item-score ${itemData.score ? 'done' : ''}`}>
        { !!itemData.score && <ActionIcon color="#77BC1F" icon="check" iconSize={16} /> }
        { itemData.score ? `+${itemData.score}` : itemData.availableScoreLabel }
      </div>
    </div>;


  return (
    <ProviderModal
      modalClass="quality-score-info-modal"
      showModal={showModal}
      handleClose={handleClose}
    >
      <div className="quality-score-info-modal__headline">
        { I18n.t('providers.locations.edit.scores.title') }
      </div>

      <div className="quality-score-info-modal__content">

        <div className="quality-score-info-modal__left">
          <ScoreChart score={totalScore} width={186} />

          <SvgIco
            className="quality-score-info-modal__img"
            name={`quality_score_list_${qualityState === 'excellent' || qualityState === 'good' ? 'high' : qualityState }`}
            width={185}
            height={160}
          />

          <div className="quality-score-info-modal__status-description">
            { I18n.t(`provider.quality_score_chart.visibility_description.${qualityState}`) }
          </div>

          <a href={qualityScoreLink} target="_blank" className="quality-score-info-modal__read-more">
            { I18n.t('provider_backend.quality_score.link') }
            <SvgIco name="open_in_new" size={16} />
          </a>
        </div>

        <div className="quality-score-info-modal__right">
          <div className="quality-score-info-modal__list-description">
            { I18n.t('provider.quality_score.modal_description') }
          </div>

          { Object.keys(data).map(scoreSection =>
            (scoreSection !== 'totalScore'
              && !data[scoreSection].hidden
              && !data[scoreSection].score
              && <ListItem itemData={data[scoreSection]} key={data[scoreSection].name} />) || null
          )}

          { Object.keys(data).map(scoreSection =>
            (scoreSection !== 'totalScore'
              && data[scoreSection].score
              && !data[scoreSection].hidden
              && <ListItem itemData={data[scoreSection]} key={data[scoreSection].name} />) || null
          )}

        </div>
      </div>

    </ProviderModal>
  )
}

export default QualityScoreInfoModal;
