import React, { useEffect, useMemo, useState } from 'react';
import { createContext } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { GET_LOCATION_REPORT_DEAL, GET_LOCATIONS_REPORT_DEAL, GET_ORDER, GET_ORDERS_REPORT_DEAL } from '../ApolloClient/queries';
import { REPORT_DEAL } from '../ApolloClient/mutations';
import FormMessageBlock from '../../SharedComponents/FormMessages/FormMessageBlock';
import { useRouterQuery, useDebouncedEffect } from '../../SharedComponents/hooks';
import { ReportDealLocation, ReportDealOrders } from '../Table/Columns';
import ReportSteps from './ReportSteps';
import ReportDealProgress from './ReportDealProgress';

import './index.sass';

export const Context = createContext({});


function ReportDeal({ currentLocale, siteName, siteKey, providerId, providerOnAdvertisingStrategy }) {
  const query = useRouterQuery();

  const [locations, setLocations] = useState([]);
  const [orders, setOrdersList] = useState([]);
  const [step, setStep] = useState(1);
  const [clientId, setClientId] = useState(parseInt(query.get('client_id')) || null);
  const [locationId, setLocationId] = useState(query.get('location_id') || null);
  const [enquiryId, setEnquiryId] = useState(query.get('enquiry_id') || null);
  const [orderId, setOrderId] = useState(query.get('order_id') || null);
  const [orderQuery, setOrderQuery] = useState('');
  const [locationQuery, setLocationQuery] = useState('');
  const [formValues, setFormValues] = useState({});
  const [preselectedOrder, setPreselectedOrder] = useState(null);
  const [preselectedLocation, setPreselectedLocation] = useState(null);
  const [poppedOrder, setPoppedOrder] = useState(null);
  const [poppedLocation, setPoppedLocation] = useState(null);
  const [ordersTotalCount, setOrdersTotalCount] = useState(0);
  const [locationsTotalCount, setLocationsTotalCount] = useState(0);
  const [clientPage, setClientPage] = useState({ page: 1, perPage: 5, query: '' });
  const [locationsPage, setLocationsPage] = useState({ page: 1, perPage: 5, query: '' });
  const [otherClient, setOtherClient] = useState('');
  const [otherLocationAddress, setOtherLocationAddress] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedLocationStatus, setSelectedLocationStatus] = useState(null);
  const [showNotification, setSnowNotification] = useState({
    show: false,
    message: '',
    color: 'red',
  });


  const [steps, setSteps] = useState([
    { name: I18n.t('providers.providers.show.customers'), passed: false },
    { name: I18n.t('generic.providers.sidebar.leases'), passed: false },
    { name: I18n.t('generic.details'), passed: false },
  ]);


  const [getLocations, { loading: loadingLocations }] = useLazyQuery(GET_LOCATIONS_REPORT_DEAL, {
    onCompleted: (data) => {
      if (data.locations) {
        setLocationsTotalCount(data.locations.totalCount);

        handleDataResponse({
          data: data.locations.locations,
          filters: locationsPage,
          preselectedElem: preselectedLocation,
          poppedElem: poppedLocation,
          setData: setLocations,
          setPoppedElem: setPoppedLocation,
        });
      }
    },
  });


  const [getLocation, { loading: loadingLocation }] = useLazyQuery(GET_LOCATION_REPORT_DEAL, {
    onCompleted: (data) => {
      data.location && setPreselectedLocation(data.location);
      getLocations({ variables: locationsPage })
        .catch(error => console.log('Error', error))
    },
  });


  const [getOrders, { loading: loadingOrders }] = useLazyQuery(GET_ORDERS_REPORT_DEAL, {
    onCompleted: (data) => {
      if (data.orders) {
        setOrdersTotalCount(data.orders.totalCount);

        handleDataResponse({
          data: data.orders.orders,
          filters: clientPage,
          preselectedElem: preselectedOrder,
          poppedElem: poppedOrder,
          setData: setOrdersList,
          setPoppedElem: setPoppedOrder,
        });
      }
    },
  });


  const [getOrder, { loading: loadingOrder }] = useLazyQuery(GET_ORDER, {
    onCompleted: (data) => {
      data.order && setPreselectedOrder(data.order);
      getOrders({ variables: { ...clientPage } })
        .catch(error => console.log('Error', error))
    },
  });


  const [reportDeal] = useMutation(REPORT_DEAL);


  const schemaName = useMemo(
    () => {
      const locationKind = locationId && locations && locations.length && locations.find(location => location.id === locationId).kind;
      return siteKey === 'dk'
        ? 'dk'
        : locationKind === 'virtual_office'
        ? 'virtualOffice'
        : locationKind === 'meeting_room'
        ? 'meetingRoom'
        : 'default';
    },
    [locationId]
  );


  useEffect(() => {
    requestOrders();
    requestLocations();
  }, []);

  useEffect(() => {
    if (otherLocationAddress) {
      setLocationId(null);
      setSelectedLocationStatus(null);
    }
  }, [otherLocationAddress]);


  useEffect(() => {
    otherClient && setClientId(null);
  }, [otherClient]);


  useEffect(() => {
    getOrders({ variables: { ...clientPage } })
      .catch(error => console.log('Error', error));
  }, [clientPage]);


  useEffect(() => {
    getLocations({ variables: { ...locationsPage } })
      .catch(error => console.log('Error', error))
  }, [locationsPage]);


  useDebouncedEffect(() => setClientPage(prevFilters => ({ ...prevFilters, query: orderQuery, page: 1, test: 4 })), [orderQuery], 500);
  useDebouncedEffect(() => setLocationsPage(prevFilters => ({ ...prevFilters, query: locationQuery, page: 1, test: 2 })), [locationQuery], 500);


  const requestOrders = () => {
    orderId
      ? getOrder({ variables: { id: orderId } })
      : getOrders({ variables: clientPage });
  };


  const requestLocations = () => {
    locationId
      ? getLocation({ variables: { id: locationId } })
      : getLocations({ variables: locationsPage });
  };


  const handleDataResponse = ({ data, filters, preselectedElem, poppedElem, setData, setPoppedElem }) => {
    let currentData = [ ...data ];

    if (preselectedElem && !filters.query) {
      const preselectedElemInTheList = currentData.some(elem => elem.id === preselectedElem.id);

      if (filters.page === 1) {
        preselectedElemInTheList
          ? currentData = currentData.filter(elem => elem.id !== preselectedElem.id)
          : setPoppedElem(currentData.pop());

        currentData.unshift(preselectedElem);
      } else {
        preselectedElemInTheList
        && poppedElem
        && (currentData = currentData.map(elem => elem.id === preselectedElem.id ? poppedElem : elem));
      }
    }

    setData(currentData);
  };

  const handleSelectLocation = (id, state) => {
    setLocationId(id);
    setSelectedLocationStatus(state);
    otherLocationAddress && setOtherLocationAddress('');
  };


  const handleSelectClient = (id) => {
    setClientId(id);
    otherClient && setOtherClient('');
  };


  const handleCloseNotification = () =>
    setSnowNotification({ show: false, message: '' });


  const changeSearchInput = (searchValue, locations) =>
    locations ? setLocationQuery(searchValue) : setOrderQuery(searchValue);


  const ordersColumns = ReportDealOrders(
    orders,
    handleSelectClient,
    clientId,
    handleCloseNotification,
  );

  const locationsColumns = ReportDealLocation(
    locations,
    locationId,
    handleSelectLocation
  );


  const nextStep = () => {
    const passStep = (param) => {
      const newArr = [...steps];
      newArr[param].passed = true;
      setSteps(newArr);
      setStep(step + 1);
      if (orderQuery) {
        setOrderQuery('');
        setClientPage(prevFilters => ({ ...prevFilters, query: '' }));
      }
      if (locationQuery) {
        setLocationQuery('');
        setLocationsPage(prevFilters => ({ ...prevFilters, query: '' }));
      }
    };

    switch (step) {
      case 1:
        if (clientId || otherClient.length > 1) {
          passStep(0);
        } else {
          setSnowNotification({
            show: true,
            message: I18n.t('providers.leases.new.client_error'),
            color: 'red',
          });
        }
        break;
      case 2:
        if (locationId || otherLocationAddress.length > 1) {
          passStep(1);
        } else {
          setSnowNotification({
            show: true,
            message: I18n.t('providers.leases.new.address_error'),
            color: 'red',
          });
        }
        break;
    }
  };


  const prevStep = () => {
    setStep(step - 1);
    if (orderQuery) {
      setOrderQuery('');
      setClientPage(prevFilters => ({ ...prevFilters, query: '' }));
    }
    if (locationQuery) {
      setLocationQuery('');
      setLocationsPage(prevFilters => ({ ...prevFilters, query: '' }));
    }
  };


  const reportDealSubmit = (values) => {
    // const { areaRented, areaRentedDontKnow } = values;
    // const reportedLocation = locations.find(location => location.id === locationId);

    setFormValues(values);
    reportADeal(values);
  };


  const reportADeal = (values) => {
    const {
      areaRented,
      areaRentedDontKnow,
      comment,
      currencyId,
      deactivateLocation,
      expectedEnd,
      expectedEndDontKnow,
      monthlyRent,
      monthsCount,
      numberOfDesks,
      occupiedAt,
      occupiedAtDontKnow,
      totalAmount,
      totalAmountDontKnow,
    } = values;


    const attrsGeneral = {
      clientId: clientId ? parseInt(clientId) : null,
      comment,
      currencyId: currencyId ? parseInt(currencyId.value) : null,
      deactivateLocation,
      enquiryId: parseInt(query.get('enquiry_id')) || null,
      locationId: locationId ? parseInt(locationId) : null,
      occupiedAt,
      otherClient,
      otherLocationAddress,
      providerDealId: parseInt(query.get('provider_deal_id')) || null,
    };


    const attrs = {
      dk: {
        ...attrsGeneral,
        areaRented: areaRented === 0 || areaRented ? areaRented : null,
        areaRentedDontKnow,
        expectedEnd,
        expectedEndDontKnow,
        occupiedAtDontKnow,
        totalAmount,
        totalAmountDontKnow,
      },
      'default': {
        ...attrsGeneral,
        areaRented: areaRented === 0 || areaRented ? areaRented : null,
        numberOfDesks: parseInt(numberOfDesks),
        monthlyRent,
        monthsCount: parseInt(monthsCount),
      },
      virtualOffice: {
        ...attrsGeneral,
        monthlyRent,
        monthsCount: parseInt(monthsCount),
      },
      meetingRoom: {
        ...attrsGeneral,
        totalAmount,
      }
    };

    reportDeal({
      variables: { attributes: attrs[schemaName] },

      update(cache, result) {},
      refetchQueries: ['getUnresolvedProviderDeals'],

      onCompleted: () => {
        setShowModal(true);
      },

      onError: ({ graphQLErrors, networkError }) => {
        console.log('graphQLErrors', graphQLErrors);
        console.log('networkError', networkError);
      },
    }).catch(error => console.log('Error', error));
  };


  const values = {
    clientPage,
    currentLocale,
    handleOtherClient: setOtherClient,
    handleOtherLocationAddress: setOtherLocationAddress,
    loadingLocations: loadingLocations || loadingLocation,
    loadingOrders: loadingOrders || loadingOrder,
    locationId,
    locationsColumns,
    locationsList: locations,
    locationsPage,
    locationsTotalCount,
    locationQuery,
    orders,
    ordersColumns,
    ordersTotalCount,
    orderQuery,
    otherClient,
    otherLocationAddress,
    providerId,
    providerOnAdvertisingStrategy,
    schemaName,
    selectedLocationStatus,
    setClientPage,
    setLocationsPage,
    showModal,
    siteKey,
    siteName,
    step,
    submitHandler: reportDealSubmit,
    changeSearchInput,
    nextStep,
    prevStep,
  };


  return (
    <div>
      <Context.Provider value={values}>
        <div className="reportdeal">
          <ReportDealProgress steps={steps} activeStep={step} />

          <FormMessageBlock
            show={showNotification}
            closeNotification={handleCloseNotification}
          />

          <ReportSteps />

        </div>
      </Context.Provider>
    </div>
  );
}

ReportDeal.propTypes = {
  siteKey: PropTypes.string,
  siteName: PropTypes.string,
  providerOnAdvertisingStrategy: PropTypes.bool,
};

ReportDeal.defaultProps = {
  siteKey: '',
  siteName: '',
  providerOnAdvertisingStrategy: false,
};

export default ReportDeal;
