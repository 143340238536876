import React, {useState} from 'react';
import { useMutation } from "@apollo/client";

import { ADD_CLIENT_RELATION } from "../ApolloClient/mutations";
import ModalNetworkError from "../Errors/ModalNetworkError";
import { FileLoader } from "../Loaders";
import ProviderModal from "./ProviderModal";

import './AlreadyKnownLeadStatusModal.sass';


const AlreadyKnownLeadStatusModal = ({ showModal, handleClose, clientId, callbackFunction }) => {

  const [loading, setLoading] = useState(false);

  const [addClientRelation, { error }] = useMutation(ADD_CLIENT_RELATION, {
    onCompleted: data => {
      if (!data.error) {
        callbackFunction ? callbackFunction(closeModal) : closeModal();
      }
    }
  });

  const handleActivate = () => {
    setLoading(true);
    addClientRelation({ variables: { clientId }});
  };


  const closeModal = () => {
    setLoading(false);
    handleClose();
  };


  return (
    <ProviderModal modalClass="known-lead-location-modal"
                   showModal={showModal}
                   handleClose={handleClose}>

      { error && <ModalNetworkError /> }

      <div className="known-lead-location-modal__headline">
        { I18n.t('providers.enquiries.pop_up.label') }
      </div>

      <div className="known-lead-location-modal__explanation">
        { I18n.t('providers.enquiries.pop_up.body') }
      </div>

      { loading && !error && <FileLoader /> }

      <div className="known-lead-location-modal__buttons-wrapper">
        <div className={`provider-modal__button green ${loading ? 'inactive' : ''}`} onClick={handleActivate}>
          { I18n.t('providers.enquiries.pop_up.confirm_button') }
        </div>
        <div className={`provider-modal__button ${loading ? 'inactive' : ''}`} onClick={handleClose}>
          { I18n.t('providers.enquiries.pop_up.cancel_button') }
        </div>
      </div>

    </ProviderModal>
  )
}

export default AlreadyKnownLeadStatusModal;
