import React, { useState, Fragment } from 'react';
import { useLazyQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";

import DuplicateImg from "../../../src/images/provider/duplicate.svg";
import { COPY_LOCATION } from "../ApolloClient/mutations";
import { GET_LOCATION_COPY_JOB } from "../ApolloClient/queries";
import { FileLoader } from "../Loaders";
import ProviderModal from "./ProviderModal";

import './DuplicateLocationModal.sass';


const DuplicateLocationModal = ({ currentLocale, showModal, handleClose, locationId }) => {

  const history = useHistory();

  const [loading, setLoading] = useState(null);
  const [pollInterval, setPollInterval] = useState(0);

  const [locationCopyJob] = useLazyQuery(GET_LOCATION_COPY_JOB, {
    pollInterval,
    onCompleted: data => {
      const { duplicateLocationId, state } = data.locationCopyJob;

      if (state === 'done') {
        history.push(`${currentLocale}/new_providers/locations/info/${duplicateLocationId}`);
        setLoading(false);
        handleClose();
      }
    },
  });

  const [copyLocation, { error }] = useMutation(COPY_LOCATION, {
    onCompleted: data => {
      const { copyJobId } = data.copyLocation;

      if (copyJobId) {
        setPollInterval(2000);
        locationCopyJob({ variables: { id: Number(copyJobId) }})
          .catch(error => {
            setLoading(false);
            console.log('error', error);
          });
      }
    },
  });


  const handleCopy = () => {
    setLoading(true);
    copyLocation({ variables: { id: Number(locationId) }})
      .catch(error => console.log('error', error));
  };


  return (
    <ProviderModal modalClass="duplicate-location-modal"
                   showModal={showModal}
                   handleClose={handleClose}>

      <img src={DuplicateImg} width={241} height={167} alt="Deactivate" />

      <div className="duplicate-location-modal__headline">
        { I18n.t('providers.locations.edit.copy_location') }
      </div>

      { !loading &&
        <Fragment>
          <div className={`duplicate-location-modal__explanation ${error ? 'error' : ''}`}>
            { !error
              ? I18n.t('providers.copy_location_job.new.copy_location_middle_page_text')
              : I18n.t('shared.copy_location_create_status.fail_message')
            }
          </div>

          <div className="provider-modal__button" onClick={handleCopy}>
            { !error
              ? I18n.t('providers.copy_location_job.new.copy_button')
              : I18n.t('shared.copy_location_create_status.try_again_link')
            }
          </div>
        </Fragment>
      }

      { loading &&
        <Fragment>
          <div className="duplicate-location-modal__loading-subheader">
            { I18n.t('shared.copy_location_create_status.copy_message') }
          </div>

          <div className="duplicate-location-modal__loading-explanation">
            { I18n.t('shared.copy_location_create_status.copy_time_message') }
          </div>

          <FileLoader />
        </Fragment>
      }
    </ProviderModal>
  )
}


export default DuplicateLocationModal;
