import React, { useEffect, useState } from 'react';
import { debounce } from "throttle-debounce";

import { SvgIco } from "../../SharedComponents/Icons";
import QualityScoreInfoModal from "../Modals/QualityScoreInfoModal";

import './index.sass';


const defaultQualityScoreData = (locationId, locale, partnerBadgeUrl, kind) => ({
  description: {
    availableScoreLabel: '+10',
    id: 'description',
    infoLink: locationId && `${locale}/new_providers/locations/edit/${locationId}/description#description`,
    name: I18n.t('locations.scores.description'),
    score: 0,
  },
  energyRating: {
    availableScoreLabel: '+10',
    id: 'energy_rating',
    hidden: kind === 'parking',
    infoLink: locationId && `${locale}/new_providers/locations/edit/${locationId}/facts#energyRating`,
    name: I18n.t('locations.scores.energy_label'),
    score: 0,
  },
  facilities: {
    availableScoreLabel: '+10',
    hidden: kind === 'parking',
    id: 'facilities',
    infoLink: locationId && `${locale}/new_providers/locations/edit/${locationId}/facts#facilities`,
    name: I18n.t('locations.scores.facilities'),
    score: 0,
  },
  floorPlan: {
    availableScoreLabel: '+10',
    id: 'floor_plan',
    infoLink: locationId && `${locale}/new_providers/locations/edit/${locationId}/visuals#floor-plans`,
    name: I18n.t('locations.scores.floor_plan'),
    score: 0,
  },
  photos: {
    availableScoreLabel: '+5 min / +10 max ',
    id: 'photos',
    infoLink: locationId && `${locale}/new_providers/locations/edit/${locationId}/visuals#photo`,
    name: I18n.t('locations.scores.pictures'),
    score: 0,
  },
  price: {
    availableScoreLabel: '+10',
    id: 'price',
    infoLink: locationId && `${locale}/new_providers/locations/edit/${locationId}/economy#prices`,
    name: I18n.t('locations.scores.price'),
    score: 0,
  },
  prospectus: {
    availableScoreLabel: '+10',
    id: 'prospectus',
    infoLink: locationId && `${locale}/new_providers/locations/edit/${locationId}/visuals#prospectuses`,
    name: I18n.t('locations.scores.prospectus'),
    score: 0,
  },
  providerPartner: {
    availableScoreLabel: '+10',
    id: 'partner_badge',
    infoLink: partnerBadgeUrl || '',
    name: I18n.t('locations.scores.partner_badge'),
    noHash: true,
    score: 0,
  },
  video: {
    availableScoreLabel: '+10',
    id: 'video',
    infoLink: locationId && `${locale}/new_providers/locations/edit/${locationId}/visuals#vimeo-id`,
    name: I18n.t('locations.scores.video'),
    score: 0,
  },
  virtualTour: {
    availableScoreLabel: '+10',
    id: 'virtual_tour',
    infoLink: locationId && `${locale}/new_providers/locations/edit/${locationId}/visuals#virtual-tour`,
    name: I18n.t('locations.scores.virtual_tour'),
    score: 0,
  },
  totalScore: 0,
});


export const scoreCalculator = (data, locale, partnerBadgeUrl) => {
  const scoreData = { ...defaultQualityScoreData(data.id || null, locale, partnerBadgeUrl, data.kind) }
  const photosCount = data.photos_current_count || (data.photos && data.photos.length);
  const floorPlanPresent = data.floor_plans_current_count || (data.floorPlans && data.floorPlans.length);
  const prospectusPresent = data.prospectus_current_count || (data.prospectus && data.prospectus.id);

  const setScore = (score, section) => {
    scoreData[section].score = score;
    scoreData.totalScore = scoreData.totalScore + score;
  };

  const pricePresent = (price) =>
    (typeof price === 'number' || typeof price === 'string') && Number(price) >= 0;

  if (photosCount) {
    photosCount >= 10
      ? setScore(10, 'photos')
      : photosCount >= 4
      ? setScore(5, 'photos')
      : null;
  };

  if (data.description && Object.keys(data.description).length) {
    let maxSymbols = 0;

    Object.keys(data.description).map(key =>
      {
        if (data.description[key]) {
          const txt = document.createElement("textarea");

          txt.innerHTML = data.description[key].replace(/<[^>]+>/g, '');

          const descriptionLength = (txt.value && txt.value.length) || 0;

          descriptionLength > maxSymbols && (maxSymbols = descriptionLength);
        }
      }
    )
    maxSymbols >= 650 && setScore(10, 'description');
  };

  data.facilityIds && data.facilityIds.length && setScore(10, 'facilities');
  (data.energyRating || data.exemptedFromEnergyRating) && setScore(10, 'energyRating');
  data.virtualTour && setScore(10, 'virtualTour');
  floorPlanPresent && setScore(10, 'floorPlan');
  prospectusPresent && setScore(10, 'prospectus');
  data.vimeoId && setScore(10, 'video');
  data.providerPartner && setScore(10, 'providerPartner');

  ( pricePresent(data.annualElectricityCostWithCentsFrom)       ||
    pricePresent(data.annualElectricityCostWithCentsTo)         ||
    pricePresent(data.annualHeatingCostWithCentsFrom)           ||
    pricePresent(data.annualHeatingCostWithCentsTo)             ||
    pricePresent(data.annualOperationalCostWithCentsFrom)       ||
    pricePresent(data.annualOperationalCostWithCentsTo)         ||
    pricePresent(data.annualRentWithCentsFrom)                  ||
    pricePresent(data.annualRentWithCentsTo)                    ||
    pricePresent(data.annualTotalCostWithCentsFrom)             ||
    pricePresent(data.annualTotalCostWithCentsTo)               ||
    pricePresent(data.annualWaterCostWithCentsFrom)             ||
    pricePresent(data.annualWaterCostWithCentsTo)               ||
    pricePresent(data.depositAndPrepaidRent)                    ||
    pricePresent(data.disposalPrice)                            ||
    pricePresent(data.fixedAnnualOperationalCost)                ||
    pricePresent(data.hourlyRentWithCentsFrom)                  ||
    pricePresent(data.hourlyRentWithCentsTo)                    ||
    pricePresent(data.monthlyRentPerSpaceWithCentsFrom)         ||
    pricePresent(data.monthlyRentPerSpaceWithCentsTo)           ||
    pricePresent(data.salePrice)                                ||
    pricePresent(data.workstationCostPerMonthFrom)              ||
    pricePresent(data.workstationCostPerMonthTo)                ||
    pricePresent(data.yearlyElectricityCostPerM2WithCentsFrom)  ||
    pricePresent(data.yearlyElectricityCostPerM2WithCentsTo)    ||
    pricePresent(data.yearlyHeatingCostPerM2WithCentsFrom)      ||
    pricePresent(data.yearlyHeatingCostPerM2WithCentsTo)        ||
    pricePresent(data.yearlyOperationalCostPerM2WithCentsFrom)  ||
    pricePresent(data.yearlyOperationalCostPerM2WithCentsTo)    ||
    pricePresent(data.yearlyRentPerM2WithCentsFrom)             ||
    pricePresent(data.yearlyRentPerM2WithCentsTo)               ||
    pricePresent(data.yearlyRentalIncome)                       ||
    pricePresent(data.yearlyWaterCostPerM2WithCentsFrom)        ||
    pricePresent(data.yearlyWaterCostPerM2WithCentsTo)
  ) && setScore(10, 'price');

  data.kind === 'parking' && (scoreData.totalScore = scoreData.totalScore + 20);

  return scoreData;
};


export const visibilityQuality = (score) =>
  score === 100
    ? 'excellent'
    : score >= 70
    ? 'good'
    : score >= 40
    ? 'medium'
    : 'low';


export const ActionIcon = ({ animation, color, icon, iconSize }) => {
  const [animated, setAnimated] = useState(false);
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    setFirstRender(false);
  }, []);

  useEffect(() => {
    setAnimated(!firstRender && animation && icon === 'check');
  }, [icon]);

  return (
    <div className={`score-action-icon`} style={{ background: color || '#FFA300' }}>
      <SvgIco name={icon || 'arrow_up'} size={iconSize || 20} />
      { animated && <div className="score-action-icon animated" /> }
    </div>
  )
};


export const ScoreChart = ({ score, stroke, width }) => {

  const pieScore = Math.round(score / 2);
  const visibilityState = visibilityQuality(score);


  return (
    <div className={`score-chart ${visibilityState}`}>
      <div className="score-chart__pie-wrapper">
        <div className="pie default" style={{"--p": 50, "--w": `${width}px`, "--b": `${stroke || 10}px`}} />
        <div className="pie" style={{"--p": pieScore, "--w": `${width}px`, "--b": `${stroke || 10}px` }} />
      </div>

      <div className="score-chart__percents-info">
        <div className="score-chart__percents">
          { score }
          <ActionIcon iconSize={16} />
        </div>
        / 100 { I18n.t('provider.quality_score_chart.points') }
      </div>

      <div className="score-chart__visibility-label-wrapper">
        <div className="score-chart__visibility-label">
          { I18n.t(`provider.quality_score_chart.visibility_state.${visibilityState}`) }
        </div>
      </div>
    </div>
  )
};


export const ScoreActionTooltip = ({ filled, label }) =>
  <div className={`score-action-tooltip ${filled ? 'filled' : ''}`}>
    <ActionIcon
      animation={!!filled}
      color={filled ? '#77BC1F' : ''}
      icon={filled ? 'check' : ''}
      iconSize={16}
    />
    <div dangerouslySetInnerHTML={{ __html: label }} />
  </div>;


export const QualityScoreBanner = ({ data, qualityScoreLink, siteName, sticky, stickyTop }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    !sticky && window.addEventListener('scroll', debounce(10, handleScroll));
  }, []);

  const handleScroll = () => {
    const banner = document.getElementById('quality-score-banner');
    const bannerBottom = banner && banner.getBoundingClientRect().bottom;

    banner && bannerBottom < 72 && setIsSticky(true);
    banner && bannerBottom > 72 && setIsSticky(false);
  };


  return (
    <div className={`quality-score-banner ${sticky ? 'sticky' : ''}`} id="quality-score-banner" key="quality-score-banner"
         onClick={() => isSticky && setModalIsOpen(prevState => !prevState)}
         style={sticky && stickyTop && { top: `${stickyTop}px` }}>

      { isSticky &&
        <QualityScoreBanner
          data={data}
          qualityScoreLink={qualityScoreLink}
          siteName={siteName}
          sticky={true}
          stickyTop={stickyTop}
          key="sticky"
        />
      }

      <QualityScoreInfoModal
        data={data}
        handleClose={() => setModalIsOpen(false)}
        qualityScoreLink={qualityScoreLink}
        siteName={siteName}
        showModal={modalIsOpen} />

      <ActionIcon iconSize={sticky ? 24 : 36} />
      <div className="quality-score-banner__content-wrapper">
        <div className="quality-score-banner__content">
          <div className="quality-score-banner__title">
            { I18n.t('providers.locations.edit.scores.title') }: { data.totalScore } <span>/ 100</span>
          </div>
          { !sticky && data.totalScore !== 100 &&
            <div className="quality-score-banner__description">
              { I18n.t('provider.quality_score_banner.description_text') }
            </div>
          }
        </div>
        <div className="quality-score-banner__info-modal-link" onClick={() => !isSticky && setModalIsOpen(prevState => !prevState)}>
          { !sticky && I18n.t('apps.lb_showroom.showroom.list.details_tooltip') }
          <SvgIco name="expand-more" size={20} />
        </div>
      </div>
    </div>
  )
};










