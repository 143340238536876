import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";

import { useDebouncedEffect } from "../../SharedComponents/hooks";
import SearchableDropdown from "../../SharedComponents/Forms/SearchableDropdown";
import BasicDropdown from "../../SharedComponents/Forms/BasicDropdown";
import RadioButtons from "../../SharedComponents/Forms/RadioButtons";
import { SvgIco } from "../../SharedComponents/Icons";
import NetworkError from "../Errors/NetworkError";
import Page404 from '../Errors/Page404';
import TokenizedFileModalLink from "../Modals/TokenizedFileModalLink";
import { GET_LOCATIONS_OPTIONS_LIST, GET_ORDERS, GET_ORDERS_XLS_TOKEN } from "../ApolloClient/queries";
import { RESET_NEW_CLIENTS_COUNT } from "../ApolloClient/mutations";
import ClientsTable from "./ClientsTable";

import '../Table/TablePage.sass';
import './index.sass';


const Clients = ({ asPreview,
                   currencySymbolFormatter,
                   currentLocale,
                   enquiryStates,
                   isNumber,
                   locale,
                   newClientsCount,
                   providerId,
                   resetNewClients,
                   resourceNotFoundError,
                 }) => {

  const { id } = useParams();

  const [orders, setOrders] = useState([]);
  const [queryValue, setQueryValue] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [initialLoad, setInitialLoad] = useState(true);
  const [locationsSearchValue, setLocationsSearchValue] = useState(id || null);
  const [showLoading, setShowLoading] = useState(true);

  const [filters, setFilters] = useState({
    enquiryState: 'all',
    locationId: (id && Number(id)) || null,
    orderBy: 'created_at',
    orderDirection: 'desc',
    page: 1,
    perPage: asPreview ? 4 : 20,
    query: '',
    showOnlyContactOrders: false,
  });

  const { enquiryState, orderBy, orderDirection, page, perPage, showOnlyContactOrders } = filters;

  const [getLocationsOptionsList, { data: locationsOptionsData }] = useLazyQuery(GET_LOCATIONS_OPTIONS_LIST);

  const { loading: ordersLoading, error, refetch: refetchOrders } = useQuery(GET_ORDERS, {
    variables: { ...filters },
    onCompleted: data => {
      !asPreview && initialLoad && getLocationsOptionsList().catch(error => console.log('error', error));
      setOrders(data.orders.orders);
      setTotalCount(data.orders.totalCount);
      setInitialLoad(false);
    },
  });

  const [resetNewClientsCount] = useMutation(RESET_NEW_CLIENTS_COUNT);

  const loading = showLoading && ordersLoading;

  useEffect(() => {
    refetchOrders().catch(error => console.log(error));

    if (newClientsCount) {
      resetNewClients();
      resetNewClientsCount({
        variables: { providerId: Number(providerId) },
      }).catch(error => console.log('Error', error));
    }
  }, []);

  useEffect(() => {
    loading && setOrders(Array(perPage).fill({}));
  }, [loading]);

  useEffect(() => {
    refetchOrders().catch(error => console.log(error));
  }, [filters]);

  useDebouncedEffect(() => {
    handleFilters({query: queryValue, page: 1});
  }, [queryValue], 500);


  const { alreadyKnown, lost, resolved, unresolved } = enquiryStates;

  const statusOptions = [
    {value: 'all', label: I18n.t('generic.all'), className: !enquiryState ? 'selected' : null },
    {value: 'unresolved', label: unresolved},
    {value: 'already_known', label: alreadyKnown},
    {value: 'lost', label: lost},
    {value: 'resolved', label: resolved},
  ];

  const perPageOptions = [
    { value: '10', label: '10' },
    { value: '20', label: '20' },
    { value: '40', label: '40' },
  ];

  const radioOptions = [
    { label: I18n.t('providers.locations.filter.my_locations'), value: true, checked: showOnlyContactOrders },
    { label: I18n.t('providers.orders.filter.all_locations'), value: false, checked: !showOnlyContactOrders },
  ];

  const locationsList = (locationsOptionsData && locationsOptionsData.locations.locations) || [];

  const locationsSearchOptions = [{ label: I18n.t('generic.all'), value: null }].concat(locationsList.map(location =>
    ({ label: `${location.address}${location.areaFrom ? `, ${location.areaFrom} ${location.areaUnit || ''}` : ''}`, value: location.id })));


  const handleFilters = (newFilters) =>
    setFilters(prevFilters => ({
      ...prevFilters,
      ...newFilters,
    }));


  const handleOrderBy = (field) => {
    const newOrderDirection = field === orderBy && orderDirection === 'asc' ? 'desc' : 'asc';

    handleFilters({ orderBy: field, orderDirection: newOrderDirection });
  };


  const handleLocationsSelect = (value) => {
    setLocationsSearchValue(value);
    handleFilters({ locationId: Number(value) || value, page: 1 });
  };

  if (resourceNotFoundError(error) || !isNumber(id)) return <Page404 currentLocale={currentLocale} />;
  if (!asPreview && error) return <NetworkError />;

  if (!(asPreview && totalCount === 0 && !loading)) {
    return (
      <div className={`clients table-page ${ asPreview ? 'preview' : '' }`}>

        { !asPreview &&
          <div className="table-page__filters-wrapper">

            <div className="table-page__filters-left">
              <div className="search-input-wrapper">
                <input
                  type="text"
                  className="search-input"
                  value={queryValue}
                  placeholder={I18n.t('generic.search')}
                  onChange={(e) => setQueryValue(e.target.value)}
                  maxLength="256" />
                <SvgIco name="search" size={16} />
              </div>

              <SearchableDropdown
                options={locationsSearchOptions}
                value={locationsSearchValue}
                classNamePrefix={'locations-search-select'}
                placeholder={I18n.t("providers.orders.filter.select_location")}
                handleChange={handleLocationsSelect} />

              <BasicDropdown
                options={statusOptions}
                wrapperClass="clients__status-dropdown"
                placeholder={I18n.t('providers.statistics.index.status')}
                handleChange={(value) => handleFilters({ enquiryState: value || '', page: 1 })}
                value={enquiryState} />

              <RadioButtons
                options={radioOptions}
                handleChange={(value) => handleFilters({ showOnlyContactOrders: value, page: 1 })} />
            </div>

            { !asPreview && totalCount > 0 &&
              <TokenizedFileModalLink
                dataName="orders"
                linkLabel="Excel"
                query={GET_ORDERS_XLS_TOKEN}
                variables={{ ...filters, page: null, perPage: null, xls: true }}
              />
            }
          </div>
        }

        <div className="table-page__title-wrapper">
          <h3>{ I18n.t('providers.providers.show.customers') } ({ totalCount })</h3>
          { asPreview &&
            <Link to={`${currentLocale}/new_providers/orders`} className="table-page__see-all-link">
              <span>{ I18n.t('provider.tables.see_all_link') }</span>
            </Link>
          }
        </div>

        <ClientsTable
          currencySymbolFormatter={currencySymbolFormatter}
          currentLocale={currentLocale}
          currentPage={page}
          disableSortBy={true}
          handleOrderBy={handleOrderBy}
          hiddenColumnsList={['contactInfo']}
          hideBottomFilters={!!asPreview}
          initialLoad={initialLoad}
          loading={loading}
          locale={locale}
          orders={orders}
          orderBy={orderBy}
          orderDirection={orderDirection}
          perPage={perPage}
          perPageOptions={perPageOptions}
          refetchOrders={refetchOrders}
          setPage={(page) => handleFilters({ page })}
          setPerPage={(perPage) => handleFilters({ perPage, page: 1 })}
          setShowLoading={setShowLoading}
          totalCount={totalCount} />

      </div>
    );
  } else {
    return null;
  }
}


export default Clients;
