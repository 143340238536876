import React from 'react';

import './index.sass';


const AdvertisementBlock = ({ children }) => {

  return (
    // Please, do not use any keywords(advertising, etc) in className to avoid blocking.
    <div className="prm-block">
      <div className="prm-block__wrapper">
        { children }
      </div>
    </div>
  )
}

export default AdvertisementBlock;
